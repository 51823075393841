import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

export const ModalDeleteItem = ({
  isOpen,
  title,
  bodyText,
  onCancel,
  onSubmit,
  size,
  submitLabel,
}) => (
  <Modal size={size} isOpen={isOpen} centered>
    <ModalHeader>
      <div>{title}</div>
    </ModalHeader>
    <ModalBody>
      <p>{bodyText}</p>
      <div className="float-right">
        <Button className="ml-2 mr-2" color="primary" onClick={onSubmit}>
          {submitLabel}
        </Button>
        <Button
          className="ml-2 mr-2"
          outline
          color="primary"
          onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </ModalBody>
  </Modal>
);

ModalDeleteItem.defaultProps = {
  size: 'sm',
  submitLabel: 'OK',
};

ModalDeleteItem.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  bodyText: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  size: PropTypes.string,
  submitLabel: PropTypes.string,
};
