import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';

const getStatus = (status) => {
  switch (status) {
    case 'payment_waived':
    case 'completed':
      return {
        color: 'success',
        text: 'completed',
      };
    case 'draft':
    case 'pending':
    case 'intention_triggered':
    case 'invoiced':
      return {
        color: 'warning',
        text: 'processing',
      };
    case 'failed':
      return {
        color: 'danger-alt',
        text: 'failed',
      };
    default:
      return 'secondary';
  }
};

export const PurchaseStatus = ({ status }) => {
  const { color, text } = getStatus(status);

  return (
    <Badge pill className="text-capitalize text-white py-2 px-3" color={color}>
      {text}
    </Badge>
  );
};

PurchaseStatus.propTypes = {
  status: PropTypes.string,
};
