import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';

import { isInPast } from '../../../utils';
import { ButtonSnooze } from '../../Button';
import {
  FormField,
  FormFieldCheckbox,
  FormFieldDate,
  FormLabel,
} from '../../Form';

export const validationSchemaForFormDefaultFields = {
  description: Yup.string().required('Description is required'),

  title: Yup.string().required('Title is required'),

  type: Yup.string().required('Select a type of task'),

  reminderDate: Yup.string().when('isEditing', {
    is: false,
    then: Yup.string().test({
      name: 'reminderDate',
      message: 'Action date must be in the future',
      test: (value) => !isInPast(value),
    }),
  }),
};

export const TaskFieldsDefault = ({
  isManager,
  setFieldValue,
  values,
  isTenantOrOwner,
}) => {
  const [showReminderDate, setShowReminderDate] = useState(false);

  const handleChangeDueDate = useCallback(
    (e) => {
      if (isInPast(e)) {
        setFieldValue('reminderDate', '');
        setShowReminderDate(false);
      } else {
        setShowReminderDate(true);
      }
    },
    [setFieldValue]
  );

  const handleCheckboxChange = useCallback(
    (e) => {
      const { name, checked } = e.target;
      setFieldValue(name, checked);
    },
    [setFieldValue]
  );

  return (
    <>
      <FormGroup>
        <FormLabel for="title" isRequired>
          Title
        </FormLabel>
        <FormField name="title" disabled={isTenantOrOwner} />
      </FormGroup>
      <FormGroup>
        <FormLabel for="description" isRequired>
          Description
        </FormLabel>
        <FormField
          name="description"
          rows={6}
          type="textarea"
          disabled={isTenantOrOwner}
        />
      </FormGroup>
      <Row>
        <Col xs={4} md={6}>
          <FormGroup>
            <div className="d-flex flex-row justify-content-between">
              <FormLabel for="dueDate">Due date</FormLabel>
              <ButtonSnooze dueDate={values.dueDate} onClick={setFieldValue} />
            </div>
            <div className="d-flex align-items-center">
              <FormFieldDate
                name="dueDate"
                onChange={handleChangeDueDate}
                disabled={isTenantOrOwner}
              />
              {isManager && values.type === 'bill' && (
                <FormFieldCheckbox
                  className="d-flex align-items-center w-100 m-0"
                  classNameLabel="text-normal ms-3"
                  checked={values.overdueNotificationEnabled}
                  name="overdueNotificationEnabled"
                  text="Notify Debtor When Overdue"
                  onChange={handleCheckboxChange}
                />
              )}
            </div>
          </FormGroup>
        </Col>
        {isManager && (
          <>
            {showReminderDate && (
              <Col xs={6} md={3}>
                <FormGroup>
                  <FormLabel for="reminderDate">Action date</FormLabel>
                  <FormFieldDate name="reminderDate" />
                </FormGroup>
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};

TaskFieldsDefault.propTypes = {
  isManager: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  isTenantOrOwner: PropTypes.bool,
};

TaskFieldsDefault.defaultProps = {
  isManager: false,
  values: {},
};
