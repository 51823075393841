import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { FormFeedback, InputGroup, InputGroupAddon } from 'reactstrap';

import './styles.scss';

export const DateField = ({
  name,
  datePickerProps,
  popperPlacement,
  showPrepend,
  size,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(name);
  const invalid = meta.touched && meta.error;
  // const valid = meta.touched && !meta.error;

  const value = useMemo(() => Date.parse(field.value), [field.value]);

  const handleChange = (value) => {
    // setTouched(true);
    setValue(value);
  };

  return (
    <>
      <InputGroup data-testid="field-date-picker" size={size}>
        <div className="d-flex w-100 mr-lg-2">
          {showPrepend && (
            <InputGroupAddon addonType="prepend">
              <span className="input-group-text">
                <FontAwesomeIcon icon={['far', 'calendar-alt']} />
              </span>
            </InputGroupAddon>
          )}
          <DatePicker
            autoComplete="off"
            className={`form-control ${invalid ? 'is-invalid' : ''}`}
            dateFormat={['dd-MM-yyyy', 'dd/MM/yyyy', 'dd-MM-yy', 'dd/MM/yy']}
            disabled={props.disabled}
            disabledKeyboardNavigation
            name={name}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={handleChange}
            placeholderText="dd-mm-yyyy"
            popperPlacement={popperPlacement}
            selected={value}
            customInput={props.customInput}
            {...datePickerProps}
          />
        </div>

        {invalid && <FormFeedback valid={false}>{meta.error}</FormFeedback>}
      </InputGroup>
    </>
  );
};

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/boolean-prop-naming
  showPrepend: PropTypes.bool,
  popperPlacement: PropTypes.string,
  datePickerProps: PropTypes.object,
  customInput: PropTypes.node,
  size: PropTypes.string,
};

DateField.defaultProps = {
  disabled: false,
  showPrepend: true,
  value: new Date(),
  datePickerProps: {},
};

export default DateField;
