/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Alert, Col, Container, Row } from 'reactstrap';

import { useIsMobile, useLocationParams } from '@app/hooks';
import { Header } from '@app/modules/Header';
import { NavSub } from '@app/modules/Nav';
import { useRolesContext } from '@app/modules/Profile';
import { SmsCreditWidget } from '@app/modules/Sms';
import { fetchAgencies } from '@app/redux/agency';
import { getAgenciesAsFilters } from '@app/redux/agency/selectors';
import { selectProfileData } from '@app/redux/profile';
import { selectSmsFeatureComingSoon } from '@app/redux/settings/selectors';
import { getManagerPrimaryAgency } from '@app/redux/users';

import { BulkMessaging } from './BulkMessaging';
import { CreateBulkMessage } from './BulkMessaging/CreateBulkMessage';
import { EditBulkMessage } from './BulkMessaging/EditBulkMessage';
import FeatureComingSoon from './FeatureComingSoon';
import { Plans } from './Plans';
import { PurchaseHistory } from './PurchaseHistory';
import { SentHistory } from './SentHistory';
import { Settings } from './Settings';
import { NAV_ITEMS } from './constants';
import { useFetchAgencySmsAccount } from './hooks/use-sms-accounts';
import './styles.scss';

export const Sms = () => {
  const params = useLocationParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const isMobile = useIsMobile();
  const profile = useSelector(selectProfileData);
  const agencyOptions = useSelector(getAgenciesAsFilters);
  const primaryAgency = useSelector(({ users }) =>
    getManagerPrimaryAgency(users, profile?.id)
  );
  const isSmsFeatureComingSoon = useSelector(selectSmsFeatureComingSoon);
  const [currentAgencyOption, setCurrentAgencyOption] = useState(null);
  const { isPrincipal, isCorporateUser } = useRolesContext();

  const {
    data: agencySmsAccount,
    refetch,
    isFetched: isFetchedAgencyAccount,
  } = useFetchAgencySmsAccount({
    agencyId: currentAgencyOption?.value,
  });
  const { smsAccount, hasUnpaidPlatformCharges, hasPaymentAccount } =
    agencySmsAccount || {};

  const areRoutesDisabled = smsAccount === null || hasUnpaidPlatformCharges;

  // set initial agency to primary agency if manager
  // otherwise set to the first agency on list
  useEffect(() => {
    if (agencyOptions.length && currentAgencyOption === null) {
      const primaryAgencyOption = agencyOptions.find(({ value }) =>
        params?.agencyId
          ? value === parseInt(params.agencyId)
          : value === primaryAgency?.id
      );

      setCurrentAgencyOption(primaryAgencyOption || agencyOptions[0]);
    }
  }, [primaryAgency, agencyOptions, currentAgencyOption, params]);

  useEffect(() => {
    if (currentAgencyOption?.value) {
      refetch();
    }
  }, [currentAgencyOption, refetch]);

  useEffect(() => {
    dispatch(fetchAgencies({}));
  }, [dispatch]);

  useEffect(() => {
    if (smsAccount === null) {
      history.push('/sms/plans');
    }
  }, [smsAccount, history]);

  const navItemsAccess = useMemo(() => {
    if (isPrincipal || isCorporateUser) {
      return NAV_ITEMS;
    }

    return NAV_ITEMS.filter(({ limitedAccess }) => !limitedAccess);
  }, [isPrincipal, isCorporateUser]);

  return (
    <div className="sms-page-container">
      {isSmsFeatureComingSoon ? (
        <FeatureComingSoon />
      ) : (
        <>
          <Header
            title="SMS"
            className="sms-header"
            subHeaderComponent={
              <NavSub
                items={navItemsAccess}
                areRoutesDisabled={areRoutesDisabled}>
                {!isMobile && (
                  <SmsCreditWidget credits={smsAccount?.creditBalance} />
                )}
              </NavSub>
            }>
            {agencyOptions?.length > 1 && (
              <div className="agency-selector">
                <Select
                  placeholder="Agency"
                  onChange={(value) => setCurrentAgencyOption(value)}
                  options={agencyOptions}
                  value={currentAgencyOption}
                />
              </div>
            )}
          </Header>
          <Container>
            {isSmsFeatureComingSoon !== undefined && (
              <>
                {isMobile && (
                  <Row>
                    <Col>
                      <SmsCreditWidget
                        credits={smsAccount?.creditBalance}
                        isGradient
                      />
                    </Col>
                  </Row>
                )}
                {isFetchedAgencyAccount && (
                  <SmsAlerts
                    hasNoSmsAccount={smsAccount === null}
                    smsAccountStatus={smsAccount?.status}
                    hasOngoingPurchase={smsAccount?.hasOngoingPurchase}
                    hasUnpaidPlatformCharges={hasUnpaidPlatformCharges}
                    hasPaymentAccount={hasPaymentAccount}
                  />
                )}
                <Row>
                  <Switch>
                    {(isPrincipal || isCorporateUser) && [
                      <Route
                        key="sms-plan"
                        path="/sms/plans"
                        // eslint-disable-next-line react/jsx-no-bind
                        render={(props) => (
                          <Plans
                            agencyId={currentAgencyOption?.value}
                            smsAccountStatus={smsAccount?.status}
                            hasOngoingPurchase={smsAccount?.hasOngoingPurchase}
                            hasPaymentAccount={hasPaymentAccount}
                            propertyCount={agencySmsAccount?.propertyCount}
                            {...props}
                          />
                        )}
                      />,
                      <Route
                        key="sms-purchase-history"
                        path="/sms/purchase-history"
                        // eslint-disable-next-line react/jsx-no-bind
                        render={(props) => (
                          <PurchaseHistory
                            agencyId={currentAgencyOption?.value}
                            {...props}
                          />
                        )}
                        exact
                      />,
                      <Route
                        key="sms-bulk-sms"
                        path="/sms/bulk-messaging"
                        render={(props) => (
                          <BulkMessaging
                            {...props}
                            agencyId={currentAgencyOption?.value}
                          />
                        )}
                        exact
                      />,
                      <Route
                        key="sms-create-bulk-message"
                        path="/sms/bulk-messaging/create"
                        render={(props) => (
                          <CreateBulkMessage
                            {...props}
                            currentAgencyOption={currentAgencyOption}
                          />
                        )}
                        exact
                      />,
                      <Route
                        key="sms-edit-bulk-message"
                        path="/sms/bulk-messaging/edit/:id"
                        render={(props) => (
                          <EditBulkMessage
                            {...props}
                            currentAgencyOption={currentAgencyOption}
                          />
                        )}
                        exact
                      />,
                      <Route
                        key="sms-settings"
                        path="/sms/settings"
                        render={() => <Settings smsAccount={smsAccount} />}
                        exact
                      />,
                    ]}
                    <Route
                      key="sms-sent-history"
                      path="/sms/sent-history"
                      // eslint-disable-next-line react/jsx-no-bind
                      render={(props) => (
                        <SentHistory
                          agencyId={currentAgencyOption?.value}
                          smsAccountStatus={smsAccount?.status}
                          disabled={
                            smsAccount?.hasOngoingPurchase ||
                            !hasPaymentAccount ||
                            hasUnpaidPlatformCharges
                          }
                          propertyCount={primaryAgency?.propertyCount}
                          {...props}
                        />
                      )}
                    />
                    {/* Default route using Redirect */}
                    <Redirect
                      to={
                        isPrincipal || isCorporateUser
                          ? '/sms/plans'
                          : '/sms/sent-history'
                      }
                    />
                  </Switch>
                </Row>
              </>
            )}
          </Container>
        </>
      )}
    </div>
  );
};

const SmsAlerts = ({
  hasNoSmsAccount,
  smsAccountStatus,
  hasOngoingPurchase,
  hasUnpaidPlatformCharges,
  hasPaymentAccount,
}) => {
  const alert = useMemo(() => {
    if (hasNoSmsAccount && hasUnpaidPlatformCharges) {
      return {
        color: 'warning',
        message:
          "Uh oh. There are some unpaid subscription fees. Let's get them up to date to get access to SMS. Please contact support if you need assistance.",
      };
    } else if (hasNoSmsAccount) {
      return {
        color: 'warning',
        message:
          'We need to get SMS set up for you. Please contact support to get this actioned.',
      };
    } else if (hasUnpaidPlatformCharges) {
      return {
        color: 'warning',
        message:
          "Uh oh. There are some unpaid subscription fees. Let's get them up to date to get access to SMS. Please contact support if you need assistance.",
      };
    } else if (hasOngoingPurchase) {
      return {
        color: 'info',
        message:
          'You have an ongoing purchase. Please wait for the transaction to complete.',
      };
    } else if (smsAccountStatus === 'inactive') {
      return {
        color: 'danger-alt',
        message: (
          <>
            Uh oh. There was a failed charge. Please reprocess your
            payment&nbsp;
            <Link to="/sms/purchase-history">here</Link>.
          </>
        ),
      };
    } else if (hasPaymentAccount === false) {
      return {
        color: 'warning',
        message:
          'You currently do not have a payment account set up. Please set up a payment account to purchase SMS credits.',
      };
    }

    return null;
  }, [
    hasNoSmsAccount,
    smsAccountStatus,
    hasOngoingPurchase,
    hasUnpaidPlatformCharges,
    hasPaymentAccount,
  ]);

  if (!alert) return null;

  return (
    <Row>
      <Col sm={12}>
        <Alert color={alert.color}>{alert.message}</Alert>
      </Col>
    </Row>
  );
};

SmsAlerts.propTypes = {
  hasNoSmsAccount: PropTypes.bool,
  hasOngoingPurchase: PropTypes.bool,
  smsAccountStatus: PropTypes.string,
  hasUnpaidPlatformCharges: PropTypes.bool,
  hasPaymentAccount: PropTypes.bool,
};

SmsAlerts.defaultProps = {
  hasNoSmsAccount: false,
  hasOngoingPurchase: false,
  smsAccountStatus: '',
};
