import React from 'react';
import { Link } from 'react-router-dom';

import { Header } from '@app/modules/Header';

const FeatureComingSoon = () => {
  return (
    <>
      <Header title="SMS" />
      <div className="coming-soon-banner text-center align-content-center">
        <header>
          <span>&#127873;</span>
          <br />
          COMING SOON
          <h1>SMS is Almost Here</h1>
        </header>
        <p>
          Get ready to send quick SMS notifications directly within the app.
          <br />
          Stay connected with ease – it’s almost time to try it out!
        </p>
        <Link className="px-4 py-3 d-inline-block" to="/dashboard">
          Back to Dashboard
        </Link>
      </div>
    </>
  );
};

export default FeatureComingSoon;
