/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import { Button } from 'reactstrap';

import { useToggle } from '@app/hooks';
import { ButtonDestroy, ButtonEdit, ButtonIcon } from '@app/modules/Button';
import { LeaseScheduleConfirm } from '@app/modules/Lease';
import { LeaseFormWarning } from '@app/modules/Lease/Form';
import { Link } from '@app/modules/Link';
import { ModalConfirm } from '@app/modules/Modal';
import { activateLease } from '@app/redux/lease';
import { isOverFortnightAgo } from '@app/utils';

import { ScheduleAgencyBillsModal } from '../Activation/ScheduleAgencyBillsModal';
import { ReleaseBond as ReleaseBondModal } from '../ReleaseBond/ReleaseBond';
import '../style.scss';
import { useLeaseCancel } from '../useLeaseFlex';

const Cancel = ({ leaseId, canRefund, isOutline, ...props }) => {
  const { mutate } = useLeaseCancel();

  const handleCancelLease = () => {
    mutate({ leaseId });
  };

  return (
    <ButtonDestroy
      onConfirm={handleCancelLease}
      size="md"
      {...(isOutline
        ? {
            color: 'secondary',
            className: 'border-secondary px-2 mr-3',
          }
        : { className: 'py-3' })}
      modal={{
        title: 'Confirmation',
        body: canRefund
          ? 'Cancel the lease and refund deposit to tenant?'
          : 'Cancel the lease?',
      }}
      {...props}>
      Cancel
    </ButtonDestroy>
  );
};
Cancel.propTypes = {
  leaseId: PropTypes.number.isRequired,
  canRefund: PropTypes.bool,
  isOutline: PropTypes.bool,
};

const Edit = ({ onClick, isOutline, ...props }) => {
  return (
    <ButtonEdit
      onClick={onClick}
      {...(isOutline
        ? {
            color: 'secondary',
            className: 'border-secondary',
          }
        : { className: 'py-3' })}
      {...props}>
      Edit
    </ButtonEdit>
  );
};
Edit.propTypes = {
  onClick: PropTypes.func,
  isOutline: PropTypes.bool,
};

const RequestDeposit = () => <Button color="secondary">Request Deposit</Button>;

const INITIAL_MODAL_STATE = {
  warning: false,
  customization: false,
  confirmation: false,
};
const Activate = ({ disabled, lease, isOutline, isActivating, ...props }) => {
  const dispatch = useDispatch();

  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { startDate, id, propertyId } = lease;
  const isPaymentOverFortnightAgo = isOverFortnightAgo(new Date(startDate));

  const toggleWarningModal = () =>
    setModalState((prev) => ({ ...prev, warning: !prev.warning }));

  const toggleCustomizationModal = () =>
    setModalState((prev) => ({
      ...INITIAL_MODAL_STATE,
      customization: !prev.customization,
    }));

  const toggleBillsConfirmModal = () =>
    setModalState((prev) => ({
      ...INITIAL_MODAL_STATE,
      confirmation: !prev.confirmation,
    }));

  const handleActivateLease = () => {
    if (isPaymentOverFortnightAgo) {
      toggleWarningModal();
    } else {
      toggleCustomizationModal();
    }
  };

  const onActivateLease = () => {
    dispatch(activateLease({ leaseId: id }));
    toggleBillsConfirmModal();
  };

  return (
    <>
      <ButtonIcon
        color={disabled ? 'gray' : 'success'}
        icon={['far', 'thumbs-up']}
        {...(isOutline
          ? {
              className: 'border-success',
            }
          : { className: 'py-3' })}
        disabled={disabled || isActivating}
        outline
        onClick={handleActivateLease}
        {...props}>
        {isActivating ? (
          <>
            Activating
            <PulseLoader size={6} color="#dee2e6" />
          </>
        ) : (
          'Activate'
        )}
      </ButtonIcon>
      {modalState.warning && (
        <ModalConfirm isOpen={modalState.warning} size="lg" title="Warning">
          <LeaseFormWarning
            onSubmit={toggleCustomizationModal}
            onCancel={toggleWarningModal}
          />
        </ModalConfirm>
      )}
      {modalState.customization && (
        <ScheduleAgencyBillsModal
          isOpen={modalState.customization}
          toggleModal={toggleCustomizationModal}
          onSubmit={toggleBillsConfirmModal}
          leaseId={id}
          propertyId={propertyId}
        />
      )}
      {modalState.confirmation && (
        <ModalConfirm
          isOpen={modalState.confirmation}
          size="md"
          title="These bills will be scheduled before lease activation">
          <LeaseScheduleConfirm
            onSubmit={onActivateLease}
            onCancel={toggleBillsConfirmModal}
            scheduledLeaseItems={[]}
          />
        </ModalConfirm>
      )}
    </>
  );
};
Activate.propTypes = {
  disabled: PropTypes.bool,
  isActivating: PropTypes.bool,
  lease: PropTypes.object,
  isOutline: PropTypes.bool,
};

const Manage = ({ leaseId, propertyId }) => (
  <Link
    className="ml-0 d-flex align-items-center"
    color="primary"
    to={`/property/${propertyId}/lease/${leaseId}`}
    outline>
    View
  </Link>
);
Manage.propTypes = {
  leaseId: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
  label: PropTypes.string,
};

const ReleaseBond = ({ bondCents, bondNumber, leaseId, isOutline }) => {
  const [modalState, setModalState] = useToggle();

  const toggleReleaseBond = useCallback(() => {
    setModalState();
  }, [setModalState]);

  return (
    <>
      <ButtonIcon
        color="secondary"
        icon={['far', 'usd-circle']}
        {...(isOutline
          ? {
              color: 'secondary',
              className: 'border-secondary',
            }
          : { className: 'py-3' })}
        onClick={toggleReleaseBond}>
        Claim Bond
      </ButtonIcon>
      {modalState.isOpen && (
        <ReleaseBondModal
          modalState={modalState}
          toggle={toggleReleaseBond}
          bondCents={bondCents}
          bondNumber={bondNumber}
          leaseId={leaseId}
        />
      )}
    </>
  );
};
ReleaseBond.propTypes = {
  bondCents: PropTypes.number,
  bondNumber: PropTypes.string,
  leaseId: PropTypes.number,
  isOutline: PropTypes.bool,
};

const ViewTransactions = ({ leaseId, propertyId, isFromList }) => (
  <Link
    className={`ml-2 d-flex align-items-center text-secondary ${
      isFromList ? 'border-secondary px-2' : ''
    }`}
    to={`/property/${propertyId}/transactions?leaseId=${leaseId}${
      isFromList ? '&fromList=true' : ''
    }`}>
    View Transactions
  </Link>
);
ViewTransactions.propTypes = {
  leaseId: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
  isFromList: PropTypes.bool,
};

const ViewLease = ({ leaseId, onViewLease }) => (
  <Button color="primary" onClick={() => onViewLease({ leaseId })}>
    View Lease
  </Button>
);
ViewLease.propTypes = {
  leaseId: PropTypes.number.isRequired,
  onViewLease: PropTypes.func.isRequired,
};

export const LeaseActions = ({ children }) => (
  <div className="d-flex flex-row-reverse px-4 pb-4 lease-actions">
    {children}
  </div>
);

LeaseActions.propTypes = {
  children: PropTypes.node,
};

LeaseActions.Cancel = Cancel;
LeaseActions.Edit = Edit;
LeaseActions.RequestDeposit = RequestDeposit;
LeaseActions.Activate = Activate;
LeaseActions.Manage = Manage;
LeaseActions.ReleaseBond = ReleaseBond;
LeaseActions.ViewTransactions = ViewTransactions;
LeaseActions.ViewLease = ViewLease;
