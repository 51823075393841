import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';

import { useIsMobile } from '@app/hooks';
import { NavIcon } from '@app/modules/Nav';

import { StepperStep, StepperSteps } from './Step';
import './styles.scss';
import useStepper from './useStepper';

// eslint-disable-next-line react/prop-types
export const Stepper = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [{ currentStep, steps }, { setCurrentStep }] = useStepper();
  const isMobile = useIsMobile();

  useEffect(() => {
    setIsNavOpen(false);
  }, [isMobile]);

  const toggleNav = useCallback(() => setIsNavOpen(!isNavOpen), [isNavOpen]);

  const handleStepClick = (index) => {
    if (isMobile) {
      setIsNavOpen(false);
    }

    setCurrentStep(index);
  };

  const StepNavigation = () => (
    <div className="step-navigation">
      <nav aria-label="Progress">
        <ol role="list">
          {steps.length
            ? steps.map((step, stepIndex) => (
                <li key={step.id} className="position-relative d-flex">
                  {/* COMPLETE */}
                  {currentStep > stepIndex ? (
                    <a
                      className="completed d-flex align-items-center w-100 group hover:cursor-pointer"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => handleStepClick(stepIndex)}>
                      <span className="d-flex align-items-center  px-6 py-2 text-sm font-weight-light">
                        <span className="d-flex align-items-center  justify-content-center flex-shrink-0 w-10 h-10 transition-colors ease-out rounded-full bg-primary-600 group-hover:bg-primary-800">
                          <FontAwesomeIcon
                            color="#0daf21"
                            icon={['far', 'circle-check']}
                          />
                        </span>
                        <span className="ml-4 text-sm font-weight-light text-primary-600">
                          {step.name}
                        </span>
                      </span>
                    </a>
                  ) : // CURRENT
                  currentStep === stepIndex ? (
                    <a
                      href={step.href}
                      className="d-flex align-items-center  px-6 py-2 text-sm font-weight-bold"
                      aria-current="step">
                      <span className="d-flex align-items-center  justify-content-center flex-shrink-0 w-10 h-10 transition-colors ease-in border-2 rounded-full border-primary-600">
                        <FontAwesomeIcon icon={['far', 'circle']} />
                      </span>
                      <span className="ml-4 text-sm font-weight-bold text-primary-600">
                        {step.name}
                      </span>
                    </a>
                  ) : (
                    // NEXT
                    <a
                      href={step.href}
                      className="d-flex align-items-center group">
                      <span className="d-flex align-items-center  px-6 py-2 text-sm font-weight-light">
                        <span className="d-flex align-items-center  justify-content-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                          <FontAwesomeIcon icon={['far', 'circle']} />
                        </span>
                        <span className="ml-4 text-sm font-weight-light text-gray-500 group-hover:text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </a>
                  )}

                  {stepIndex !== steps.length - 1 && (
                    <>
                      {/* Arrow separator for lg screens and up */}
                      {/* <div
                      className="position-absolute top-0 right-0 d-none w-5 h-100 d-block"
                      aria-hidden="true">
                      <svg
                        className="w-100 h-100 text-gray-300"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none">
                        <path
                          d="M0 -2L20 40L0 82"
                          vectorEffect="non-scaling-stroke"
                          stroke="currentcolor"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div> */}
                    </>
                  )}
                </li>
              ))
            : null}
        </ol>
      </nav>
    </div>
  );

  return (
    <div className="stepper-container">
      {isMobile ? (
        <>
          <NavIcon
            handleClickNavIcon={toggleNav}
            isNavOpen={isNavOpen}
            className="m-0"
          />
          {isNavOpen ? (
            <StepNavigation />
          ) : (
            <div className="selected-step-section">{children}</div>
          )}
        </>
      ) : (
        <>
          <StepNavigation />
          <div className="selected-step-section">{children}</div>
        </>
      )}
    </div>
  );
};

Stepper.Step = StepperStep;
Stepper.Steps = StepperSteps;

export default Stepper;
