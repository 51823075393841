/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Col, Container, Row } from 'reactstrap';

import { useIsMobile, useLocationParams } from '@app/hooks';
import { useDebouncedFetch } from '@app/hooks/useDebouncedFetch';
import { formatDate } from '@app/utils';

import { MessageStatus } from '../MessageStatus';
import { useFetchSmsDeliveries } from '../hooks/use-sms-accounts';
import { MessageInfoModal } from './MessageInfoModal';
import { SENT_HISTORY_COLUMNS } from './constants';
import './styles.scss';

export const TaskMessages = ({ agencyId, filter }) => {
  const isMobile = useIsMobile();
  const [item, setItem] = useState(null);
  const filterParams = {
    createdFrom: filter.createdFrom,
    createdTo: filter.createdTo,
    [filter.searchBy]: filter.searchValue,
    type: filter.type,
  };

  const params = useLocationParams();
  const {
    data = [],
    isLoading,
    refetch,
  } = useFetchSmsDeliveries({
    params: {
      agencyId,
      perPage: params.perPage || 10,
      page: params.page || 1,
      ...filterParams,
      status: Object.keys(filter?.status || {}).filter(
        (key) => filter.status[key]
      ),
    },
  });
  const { debouncedRefetch } = useDebouncedFetch({
    fetchFn: refetch,
  });

  // Fetch is debounced due to text filter
  useEffect(() => {
    if (agencyId) {
      debouncedRefetch();
    }
  }, [
    filter.createdFrom,
    filter.createdTo,
    filter.searchValue,
    filter.type,
    filter.status,
    debouncedRefetch,
    agencyId,
    params.page,
  ]);

  const handleFetchSMSDelivery = (id) => {
    setItem(id);
  };

  const handleClose = () => {
    setItem(null);
  };

  const renderMessages = () => {
    if (data.length === 0) {
      return (
        <Row lg={12} className="m-0 p-5">
          <Col className="text-center">No messages found</Col>
        </Row>
      );
    }

    return (
      <>
        {data.map(
          (
            { recipient, createdAt, recipientPhoneNumber, status, message, id },
            index
          ) => (
            <Row
              className="m-0 px-3 py-2 text-left no-gutters text-small task-messages-list align-items-center pointer"
              key={`task-message-${index}-`}
              onClick={() => handleFetchSMSDelivery(id)}>
              <Col className="col-8 col-md-2 order-0">
                <strong>{recipient}</strong>
              </Col>
              <Col className="col-6 col-md-2 order-2 order-md-1 text-muted">
                <span className="text-muted m-0 d-inline d-md-none">
                  Sent to:&nbsp;
                </span>

                {recipientPhoneNumber || '-'}
              </Col>
              <Col className="col-12 col-md-3 order-4 order-md-1 mt-2 mt-md-0 message-container">
                <span>{message}</span>
              </Col>
              <Col className="col-4 col-md-2 order-1 order-md-2 pl-0 pl-md-3 text-right text-md-left">
                <MessageStatus status={status} />
              </Col>
              <Col className="col-12 col-md-3 text-muted order-3 order-md-4">
                {formatDate(createdAt, 'shortWithTime')}
              </Col>
            </Row>
          )
        )}
      </>
    );
  };

  return (
    <Container className="p-0 mb-1">
      {!isMobile && (
        <Row
          lg={12}
          className="m-0 px-3 py-2 text-left no-gutters task-messages-header">
          {SENT_HISTORY_COLUMNS.map((column) => (
            <Col
              key={column.id}
              lg={column.size || 2}
              className={column.className}>
              {column.name}
            </Col>
          ))}
        </Row>
      )}
      {isLoading ? (
        <Row className="m-0 p-5 text-left no-gutters text-small">
          <Col className="text-center">
            <PulseLoader color="#dee2e6" />
          </Col>
        </Row>
      ) : (
        renderMessages()
      )}
      {item !== null && (
        <MessageInfoModal
          isOpen={item !== null}
          toggle={handleClose}
          messageId={item}
          agencyId={agencyId}
        />
      )}
    </Container>
  );
};

TaskMessages.propTypes = {
  agencyId: PropTypes.number,
  filter: PropTypes.object,
};
