import { createContext, useCallback, useContext } from 'react';

const INITIAL_STATE = {
  propertyId: null,
  lease: null,
};

export const LeaseStateContext = createContext(INITIAL_STATE);
export const LeaseDispatchContext = createContext(undefined);

export const useLeaseState = () => {
  const leaseState = useContext(LeaseStateContext);
  if (typeof leaseState === 'undefined') {
    throw new Error('useLeaseState must be used within a LeaseProvider');
  }
  return leaseState;
};

export const useLeaseDispatch = () => {
  const setState = useContext(LeaseDispatchContext);

  if (typeof dispatch === 'undefined') {
    throw new Error('useLeaseDispatch must be used within a LeaseProvider');
  }

  const updateLease = useCallback(
    (lease) => setState((state) => ({ ...state, lease })),
    [setState]
  );

  const updatePropertyId = useCallback(
    (propertyId) => setState((state) => ({ ...state, propertyId })),
    [setState]
  );

  return {
    updateLease,
    updatePropertyId,
  };
};
