import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import { ButtonIcon } from './Icon';

export const ButtonCopyToClipboard = ({
  children,
  text = '',
  id,
  ...props
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleToggle = useCallback(() => {
    if (isCopied) return; // Prevent duplicate clicks
    setIsCopied(true);

    const timer = setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isCopied]);

  const handleClick = useCallback(() => {
    copy(text);
    handleToggle();
  }, [handleToggle, text]);

  const trimmedId = id
    ? id
        .replace(/\s+/g, '')
        .replace(/[^a-zA-Z0-9-]/g, '')
        .toLowerCase()
    : null;

  const iconProps = isCopied
    ? {
        icon: ['fas', 'check'],
        color: 'success',
      }
    : { icon: ['fas', 'copy'] };

  return (
    <span>
      <ButtonIcon
        id={trimmedId}
        {...iconProps}
        onClick={handleClick}
        {...props}>
        {children}
      </ButtonIcon>

      {trimmedId && (
        <UncontrolledTooltip target={trimmedId} placement="top">
          {isCopied ? 'Copied to clipboard' : 'Copy to clipboard'}
        </UncontrolledTooltip>
      )}
    </span>
  );
};

ButtonCopyToClipboard.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  id: PropTypes.string,
};
