import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup } from 'reactstrap';
import * as Yup from 'yup';

import { FormButtons, FormField, FormLabel } from '.';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const INITIAL_VALUES = {
  email: '',
  password: '',
};

export const FormLogin = ({ onSubmit }) => {
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await onSubmit(values);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={LoginSchema}
      // eslint-disable-next-line react/jsx-no-bind
      onSubmit={handleSubmit}>
      {({ isValid, isSubmitting }) => (
        <Form className="login-form mb-1">
          <FormGroup>
            <FormLabel for="email">Email</FormLabel>
            <FormField name="email" placeholder="Enter Email" />
          </FormGroup>
          <FormGroup>
            <FormLabel for="password">Password</FormLabel>
            <FormField
              name="password"
              placeholder="Enter Password"
              type="password"
            />
          </FormGroup>
          <FormButtons
            btnSubmit={{ text: 'Login' }}
            className="justify-content-start"
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

FormLogin.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
