/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import {
  Button,
  Col,
  CustomInput,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import { DateRange } from '../DateRange';
import { BULK_SMS_RECIPIENTS } from '../constants';
import { INITIAL_FILTER } from './constants';

export const AdvancedFilterModal = ({
  filters,
  setFilters,
  modalState,
  toggleFilterModal,
}) => {
  const [createdDate, setCreatedDate] = useState({
    createdFrom: filters.createdFrom,
    createdTo: filters.createdTo,
  });
  const [sentDate, setSentDate] = useState({
    sentFrom: filters.sentFrom,
    sentTo: filters.sentTo,
  });
  const [recipients, setRecipients] = useState(filters.recipients);
  const [status, setStatus] = useState(filters.status);

  const handleOnCheckBoxChange = useCallback(
    ({ target: { name, checked } }) => {
      setStatus((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    },
    []
  );

  const onClearFilter = useCallback(() => {
    setFilters(INITIAL_FILTER);
    toggleFilterModal();
  }, [setFilters, toggleFilterModal]);

  const onApplyFilter = useCallback(() => {
    setFilters((state) => ({
      ...state,
      ...createdDate,
      ...sentDate,
      recipients,
      status,
    }));
    toggleFilterModal();
  }, [
    status,
    createdDate,
    sentDate,
    recipients,
    setFilters,
    toggleFilterModal,
  ]);

  return (
    <Modal
      className="bulk-sms-advanced-filter"
      toggle={toggleFilterModal}
      isOpen={modalState.isOpen}
      size="lg"
      centered>
      <ModalHeader toggle={toggleFilterModal}>Advanced Filters</ModalHeader>
      <ModalBody>
        <Row>
          <Col className="col-md-6 col-12 mb-2">
            <label>Created At</label>
            <DateRange
              fromDateName="createdFrom"
              fromDateValue={createdDate.createdFrom}
              fromDateLabel="Created From"
              toDateName="createdTo"
              toDateValue={createdDate.createdTo}
              toDateLabel="Created To"
              onChange={setCreatedDate}
            />
          </Col>
          <Col className="col-md-6 col-12 mb-2">
            <label>Sent at</label>
            <DateRange
              fromDateName="sentFrom"
              fromDateValue={sentDate.sentFrom}
              fromDateLabel="Sent From"
              toDateName="sentTo"
              toDateValue={sentDate.sentTo}
              toDateLabel="Sent To"
              onChange={setSentDate}
            />
          </Col>
          <Col className="col-md-6 col-12 mb-2">
            <label htmlFor="recipients">Recipients</label>
            <Select
              id="recipients"
              name="recipients"
              placeholder="Search for recipients"
              options={BULK_SMS_RECIPIENTS}
              value={recipients}
              onChange={(selected) => setRecipients(selected)}
              isMulti
            />
          </Col>
          <Col className="col-md-6 col-12 mb-2">
            <label htmlFor="status">Status</label>
            <div className="mt-2 sms-custom-input">
              <CustomInput
                className="d-inline-block mr-3"
                checked={status.draft}
                onChange={handleOnCheckBoxChange}
                type="checkbox"
                id="draftStatus"
                name="draft"
                label="Draft"
              />
              <CustomInput
                className="d-inline-block"
                checked={status.sent}
                onChange={handleOnCheckBoxChange}
                type="checkbox"
                id="sentStatus"
                name="sent"
                label="Sent"
              />
            </div>
          </Col>
        </Row>
      </ModalBody>
      <div className="text-right p-3">
        <Button
          color="primary-alt"
          className="mr-3"
          type="button"
          onClick={onClearFilter}
          outline>
          Clear All
        </Button>
        <Button color="primary-alt" type="button" onClick={onApplyFilter}>
          Filter
        </Button>
      </div>
    </Modal>
  );
};

AdvancedFilterModal.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  modalState: PropTypes.object.isRequired,
  toggleFilterModal: PropTypes.func.isRequired,
};
