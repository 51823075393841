/* eslint-disable react/jsx-no-bind */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Alert, Button, Col, FormGroup, Label, Row } from 'reactstrap';

import { ButtonDestroy, ButtonIcon } from '@app/modules/Button';
import { FormFieldSearchUser } from '@app/modules/Form';
import { FormFieldPhoneNumber } from '@app/modules/Form/Field/FormFieldPhoneNumber';
import { InputField, RadioField } from '@app/modules/Form/FormikFields';
import { USER_TYPES } from '@app/redux/users';

import { useLeaseState } from '../../Provider';
import { useReplacePrimaryTenant } from '../../useLeaseFlex';
import { ModalContentContainer } from './ModalContentContainer';

const Tenants = ({ leaseData, isRenew }) => {
  const [isAddingExistingUser, setIsAddingExistingUser] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { values, setFieldValue, errors, validateForm } = useFormikContext();
  const { leaseState } = useLeaseState();
  const { lease } = leaseData || leaseState;
  const {
    mutate: replacePrimaryTenant,
    isLoading,
    isSuccess,
  } = useReplacePrimaryTenant();

  useEffect(() => {
    if (isAddingExistingUser) {
      validateForm();
    }
  }, [isAddingExistingUser, validateForm]);

  useEffect(() => {
    if (isSuccess) {
      setShowAlert(true);
    }
  }, [isSuccess]);

  const hasPrimaryTenant = Boolean(lease.hasTenant && lease.primaryTenant.id);
  const isDisabled = hasPrimaryTenant || isAddingExistingUser;

  const handleReplacePrimaryTenant = (index) => {
    replacePrimaryTenant({
      leaseId: lease.id,
      tenants: {
        primary: values?.tenants?.secondary[index],
        secondary: [
          ...values.tenants.secondary.map((item, i) => {
            if (index === i) {
              return { ...item, destroy: true };
            }
            return item;
          }),
          {
            ...values?.tenants?.primary,
          },
        ],
      },
    });
  };

  const onDismiss = () => setShowAlert(false);

  const handleUserSelect = useCallback(
    (_, data) => {
      if (data && data.length) {
        const [user] = data;
        setFieldValue('tenants.primary.firstName', user.firstName);
        setFieldValue('tenants.primary.lastName', user.lastName);
        setFieldValue('tenants.primary.email', user.email);
        setFieldValue('tenants.primary.phoneNumber', user.phoneNumber);

        if (values.tenants.primary?.type === 'company') {
          if (user.kind === 'company' && user.company?.legalName) {
            setFieldValue('tenants.primary.company', user.company.legalName);
            setFieldValue('tenants.primary.taxNumber', user.company.taxNumber);
          }
        }

        setIsAddingExistingUser(true);
      }
    },
    [setFieldValue, values.tenants.primary.type]
  );

  const handleCustomUserInput = useCallback(
    (value) => {
      setIsAddingExistingUser(false);
      setFieldValue('tenants.primary.email', value);
    },
    [setFieldValue]
  );

  return (
    <>
      <h3 className="mb-2">
        {isRenew
          ? "Let's get this lease renewed"
          : 'Who will be living in the property'}
      </h3>
      <ModalContentContainer>
        <small className="d-block mb-3">
          {`In this lease arrangement, the primary tenant is ${
            isRenew ? 'remaining and is ' : ''
          }the person you'll mostly communicate with. They are also the tenant who can
          control how rent will be paid. Other tenants will be able to pay funds
          to the primary tenant's wallet for rent and bills.${
            isRenew ? 'You can add them below.' : ''
          }`}
        </small>
        <h5 className="mb-3">Primary Tenant</h5>
        <Alert color="success" isOpen={showAlert} toggle={onDismiss}>
          Tenant swapped <FontAwesomeIcon icon={['far', 'circle-check']} />
        </Alert>
        <Row>
          <Col>
            <div role="group" className="radio-group-wrapper">
              <RadioField
                name="tenants.primary.type"
                label="Personally"
                value="private"
                disabled={isDisabled}
              />
              <RadioField
                name="tenants.primary.type"
                label="Company/Trust"
                value="company"
                disabled={isDisabled}
              />
            </div>
          </Col>
        </Row>

        {values.tenants?.primary?.type === 'company' && (
          <Row>
            <Col>
              <FormGroup>
                <Label for="tenants.primary.company">Company Name</Label>
                <InputField
                  name="tenants.primary.company"
                  readOnly={isDisabled}
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="tenants.primary.taxNumber">ABN/ACN</Label>
                <InputField
                  name="tenants.primary.taxNumber"
                  disabled={isDisabled}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <FormGroup>
              <Label for="tenants.primary.firstName">First Name</Label>
              <InputField
                name="tenants.primary.firstName"
                disabled={isDisabled}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="tenants.primary.lastName">Last Name</Label>
              <InputField
                name="tenants.primary.lastName"
                disabled={isDisabled}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label for="tenants.primary.email">Email</Label>
              {hasPrimaryTenant && values.tenants.primary.email ? (
                <InputField
                  name="tenants.primary.email"
                  type="email"
                  disabled={isDisabled}
                />
              ) : (
                <FormFieldSearchUser
                  data-testid="field-user-email"
                  name="tenants.primary.email"
                  type={USER_TYPES.tenant}
                  onChange={handleUserSelect}
                  onInputChange={handleCustomUserInput}
                  isDisabled={hasPrimaryTenant}
                />
              )}
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <FormFieldPhoneNumber
                name="tenants.primary.phoneNumber"
                disabled={isDisabled}
                value={values?.tenants?.primary?.phoneNumber}
                setFieldValue={setFieldValue}
                error={errors?.tenants?.primary?.phoneNumber}
              />
            </FormGroup>
          </Col>
        </Row>

        <FieldArray
          name="tenants.secondary"
          render={({ push, remove, replace, form }) => (
            <SecondaryTenants
              push={push}
              remove={remove}
              replace={replace}
              form={form}
              hasPrimaryTenant={hasPrimaryTenant}
              isSwitchingTenants={isLoading}
              handleReplacePrimaryTenant={handleReplacePrimaryTenant}
              canSwitchPrimary={
                (lease.isDraft || lease.isPending) && !lease.hasDeposit
              }
            />
          )}
        />
      </ModalContentContainer>
    </>
  );
};

Tenants.propTypes = {
  leaseData: PropTypes.object,
  isRenew: PropTypes.bool,
};
Tenants.defaultProps = {
  leaseData: null,
  isRenew: false,
};

const SecondaryTenants = ({
  push,
  remove,
  replace,
  hasPrimaryTenant,
  canSwitchPrimary,
  isSwitchingTenants,
  handleReplacePrimaryTenant,
}) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();

  const isPrimaryTenantValid =
    hasPrimaryTenant ||
    Boolean(
      (touched?.tenants?.primary || touched?.['tenants.primary.email']) &&
        !errors?.tenants?.primary
    );

  const isSecondaryTenantsNotEmpty = useMemo(
    () => values.tenants.secondary && values.tenants.secondary.length > 0,
    [values.tenants.secondary]
  );

  const isAddDisabled = Boolean(
    isSecondaryTenantsNotEmpty &&
      errors?.tenants?.secondary?.[values.tenants.secondary.length - 1]
  );

  const handleAddTenant = useCallback(() => {
    push({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      type: 'private',
      isNew: true,
    });
  }, [push]);

  const handleDeleteTenant = (index) => {
    if (values.tenants.secondary[index]?.id) {
      replace(index, {
        ...values.tenants.secondary[index],
        destroy: true,
      });
    } else {
      remove(index);
    }
  };

  const handleUserSelect = useCallback(
    (_, data, index) => {
      if (data && data.length) {
        const [user] = data;

        const tenant = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          type: user.kind === 'company' ? 'company' : 'private',
        };

        replace(index, tenant);

        if (user.kind === 'company' && user.company?.legalName) {
          replace(index, {
            ...tenant,
            company: user.company.legalName,
            taxNumber: user.company.taxNumber,
            type: 'company',
          });
        }
      }
    },
    [replace]
  );

  const handleCustomUserInput = useCallback(
    (value, index) => {
      replace(index, {
        ...values.tenants.secondary[index],
        email: value,
      });
    },
    [replace, values.tenants.secondary]
  );

  return (
    <div className="mb-3">
      {isSecondaryTenantsNotEmpty && <h5 className="mb-3">Other Tenants</h5>}
      {isSecondaryTenantsNotEmpty &&
        values.tenants.secondary.map((tenant, index) => (
          <Fragment key={index}>
            {!tenant.destroy ? (
              <Row key={index}>
                <Col>
                  <Row className="align-items-center">
                    <Col>
                      <div role="group" className="radio-group-wrapper">
                        <RadioField
                          name={`tenants.secondary.${index}.type`}
                          label="Personally"
                          value="private"
                          disabled={!tenant.isNew}
                        />
                        <RadioField
                          name={`tenants.secondary.${index}.type`}
                          label="Company/Trust"
                          value="company"
                          disabled={!tenant.isNew}
                        />
                      </div>
                    </Col>
                    {tenant?.id && canSwitchPrimary ? (
                      <Col xs="3">
                        <ButtonIcon
                          className="mx-1 color-secondary"
                          data-testid="button-switch-primary"
                          icon={['far', 'user']}
                          size="1x"
                          disabled={isSwitchingTenants}
                          onClick={() => handleReplacePrimaryTenant(index)}>
                          Set as Primary
                        </ButtonIcon>
                      </Col>
                    ) : null}
                    <Col xs="1">
                      <ButtonDestroy
                        btnCancel={{ text: 'Cancel' }}
                        btnSubmit={{ text: 'Delete', color: 'danger' }}
                        className="mx-1"
                        data-testid="button-delete-report"
                        icon={['far', 'trash-alt']}
                        size="md"
                        onClick={() => handleDeleteTenant(index)}>
                        {/* <span className="d-md-none ml-2">Delete</span> */}
                      </ButtonDestroy>
                    </Col>
                  </Row>
                  {tenant.type === 'company' && (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label
                            for={`tenants.secondary.${index}.company.legalName`}>
                            Company Name
                          </Label>
                          <InputField
                            name={`tenants.secondary.${index}.company.legalName`}
                            disabled={!tenant.isNew}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label
                            for={`tenants.secondary.${index}.company.taxNumber`}>
                            ABN/ACN
                          </Label>
                          <InputField
                            name={`tenants.secondary.${index}.company.taxNumber`}
                            disabled={!tenant.isNew}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for={`tenants.secondary.${index}.firstName`}>
                          First Name
                        </Label>
                        <InputField
                          name={`tenants.secondary.${index}.firstName`}
                          disabled={!tenant.isNew}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for={`tenants.secondary.${index}.lastName`}>
                          Last Name
                        </Label>
                        <InputField
                          name={`tenants.secondary.${index}.lastName`}
                          disabled={!tenant.isNew}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for={`tenants.secondary.${index}.email`}>
                          Email
                        </Label>
                        {!tenant.isNew ? (
                          <InputField
                            name={`tenants.secondary.${index}.email`}
                            type="email"
                            disabled={true}
                          />
                        ) : (
                          <FormFieldSearchUser
                            data-testid={`tenants.secondary.${index}.email`}
                            name={`tenants.secondary.${index}.email`}
                            type={USER_TYPES.tenant}
                            onChange={(ids, data) =>
                              handleUserSelect(ids, data, index)
                            }
                            onInputChange={(value) =>
                              handleCustomUserInput(value, index)
                            }
                          />
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for={`tenants.secondary.${index}.phoneNumber`}>
                          Mobile
                        </Label>

                        <FormFieldPhoneNumber
                          name={`tenants.secondary.${index}.phoneNumber`}
                          disabled={!tenant.isNew}
                          value={tenant?.phoneNumber}
                          setFieldValue={setFieldValue}
                          error={
                            errors?.tenants?.secondary?.[index]?.phoneNumber
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : null}
          </Fragment>
        ))}

      {isPrimaryTenantValid && (
        <Button
          outline
          color="secondary"
          onClick={handleAddTenant}
          disabled={isAddDisabled}>
          + Add
        </Button>
      )}
    </div>
  );
};

SecondaryTenants.propTypes = {
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  hasPrimaryTenant: PropTypes.bool,
  isSwitchingTenants: PropTypes.bool,
  handleReplacePrimaryTenant: PropTypes.func,
  canSwitchPrimary: PropTypes.bool,
};

export default Tenants;
