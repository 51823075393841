/* eslint-disable react/jsx-no-bind */
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import { getProperty } from '@app/redux/property';

import Stepper, { useStepper } from '../../common/stepper';
import { useRenewLease } from '../../useLeaseFlex';
import { AgencyBills } from '../Steps/AgencyBills';
import { CommercialOutgoingItems } from '../Steps/CommercialOutgoingItems';
import CommercialOutgoingsOptions from '../Steps/CommercialOutgoingsOptions';
import LeaseDates from '../Steps/LeaseDates';
import { RentalAdjustments } from '../Steps/RentalAdjustments';
import Tenants from '../Steps/Tenants';

const LEASE_STEPS = [
  {
    name: 'Tenants',
    content: <Tenants isRenew />,
    fields: ['tenants'],
  },
  {
    name: 'Lease dates',
    content: <LeaseDates isRenew />,
    fields: ['leaseStartDate', 'endDate', 'leaseTerm', 'periodic'],
  },
  {
    name: 'Rent Adjustments',
    content: <RentalAdjustments />,
    fields: [
      'payFrequency',
      'rentDollars',
      'effectiveFrom',
      'reasonForChange',
      'scheduleAdjustment',
    ],
  },
  {
    name: 'Monthly Outgoings',
    content: <CommercialOutgoingsOptions isRenew />,
    fields: [],
    type: 'commercial',
  },
  {
    name: 'Monthly Outgoing Bills',
    content: <CommercialOutgoingItems />,
    fields: [],
    type: 'commercial',
  },
  {
    name: 'Agency Bills',
    content: <AgencyBills />,
    fields: [],
  },
];

const Steps = ({ propertyId, leaseId }) => {
  const [
    state,
    { incrementCurrentStep, decrementCurrentStep, setCurrentStep },
  ] = useStepper();
  const {
    submitForm,
    setFieldValue,
    values,
    validateForm,
    setTouched,
    setFieldTouched,
  } = useFormikContext();

  const { mutate } = useRenewLease();

  const property = useSelector(({ property }) =>
    getProperty(property, propertyId)
  );

  const STEP_MARKERS = useMemo(
    () =>
      property.propertyType === 'commercial' ? { OUTGOING_OPTIONS: 3 } : null,
    [property.propertyType]
  );

  const LEASE_RENEWAL_STEPS = useMemo(() => {
    // filter based on condition then add IDS
    return LEASE_STEPS.reduce((acc, curr) => {
      if (
        property.propertyType !== 'commercial' &&
        curr.type === 'commercial'
      ) {
        return [...acc];
      } else {
        return [...acc, { ...curr, id: acc.length }];
      }
    }, []);
  }, [property.propertyType]);

  const confirmLabel = useMemo(() => {
    if (state.canContinue) {
      return 'Next';
    } else {
      return 'Renew';
    }
  }, [state.canContinue]);

  const handleNext = async () => {
    const validationErrors = await validateForm();
    const hasValidationError = LEASE_RENEWAL_STEPS[
      state.currentStep
    ].fields.some((field) => validationErrors.hasOwnProperty(field));

    if (hasValidationError) {
      const errorFields = {};
      LEASE_RENEWAL_STEPS[state.currentStep].fields.forEach((fieldName) => {
        errorFields[fieldName] = true;
      });
      if (state.currentStep === 0) {
        setFieldTouched('tenants.primary.firstName');
        setFieldTouched('tenants.primary.lastName');
      } else {
        setTouched(errorFields);
      }
    } else {
      if (state.canContinue) {
        // treat added secondary tenants as existing tenants to retain value in between steps
        if (values?.tenants?.secondary) {
          const tenants = values.tenants.secondary.map((tenant) => ({
            ...tenant,
            isNew: false,
          }));
          setFieldValue('tenants.secondary', tenants);
        }

        // skip outgoing items if outgoing options opted to skip
        if (
          STEP_MARKERS !== null &&
          state.currentStep === STEP_MARKERS.OUTGOING_OPTIONS &&
          values?.monthlyOutgoings === 'false'
        ) {
          setCurrentStep(state.currentStep + 2);
        } else incrementCurrentStep();
      } else {
        const tenants = {
          ...(Object.hasOwn(values, 'tenants')
            ? {
                tenants: {
                  ...(Object.hasOwn(values.tenants, 'secondary')
                    ? { secondary: values.tenants.secondary }
                    : {}),
                },
              }
            : {}),
        };

        // data for renewal
        // lease data to update lease
        // leaseRent to create rent change
        mutate({
          leaseId,
          leaseData: {
            leaseStartDate: values?.leaseStartDate,
            endDate: values?.endDate,
            periodic: values?.periodic === 'true',
            lastRenewedAt: new Date(),
          },
          // scheduling of lease rent is optional
          ...(values?.effectiveDate
            ? {
                leaseRent: {
                  reason: values?.reason,
                  annualRentCents: values?.annualRentCents,
                  effectiveDate: values?.effectiveDate,
                },
              }
            : {}),
          ...tenants,
          shouldScheduleAgencyBills: values?.hasAgencyBills === 'true',
        });
        submitForm();
      }
    }
  };

  return (
    <Stepper>
      <Stepper.Steps>
        {LEASE_RENEWAL_STEPS.map((leaseStep) => (
          <Stepper.Step key={leaseStep.id} {...leaseStep}>
            <>
              {leaseStep.content}
              <div className="action-buttons">
                <Button
                  color="secondary"
                  className="mr-3"
                  onClick={decrementCurrentStep}>
                  Previous
                </Button>
                <Button
                  color="primary"
                  onClick={() => handleNext(leaseStep.id)}>
                  {confirmLabel}
                </Button>
              </div>
            </>
          </Stepper.Step>
        ))}
      </Stepper.Steps>
    </Stepper>
  );
};

Steps.propTypes = {
  propertyId: PropTypes.number.isRequired,
  currentStep: PropTypes.number,
  leaseId: PropTypes.number.isRequired,
};

Steps.defaultProps = {
  currentStep: 0,
};

export default Steps;
