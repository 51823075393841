import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { useRolesContext } from '@app/modules/Profile';

import { BillieForm } from './Form';

export const Billie = ({ history, location }) => {
  const { isManager } = useRolesContext();

  return isManager ? (
    <BillieForm history={history} location={location} />
  ) : (
    <Redirect to="/" />
  );
};

Billie.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
