import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { usePaymentsNav } from '@app/modules/Payment/hooks';
import { useRolesContext } from '@app/modules/Profile';

import { Billing } from './Billing';
import { PaymentsIntention } from './Intention';
import { PaymentsList } from './List';
import { PaymentsSettings } from './Settings';
import { TenantPaymentSettings } from './TenantPaymentSettings';
// import { TenantWallet } from './TenantWallet';
import TenantWalletv2 from './Walletv2';

export const Payments = (props) => {
  const { location } = props;
  const { showGlobalPaymentSettings } = usePaymentsNav();
  const { isTenant, isCorporateUser, isPrincipal } = useRolesContext();
  const hasCorporateOrPrincipalAccess = isCorporateUser || isPrincipal;

  useEffect(() => {
    // Redirect in case any emails point to global settings
    if (
      !showGlobalPaymentSettings &&
      location.pathname === '/payments/settings'
    ) {
      props.history.replace('/payments');
    }
  }, [location.pathname, props.history, showGlobalPaymentSettings]);

  return (
    <>
      <Switch>
        <Route path="/payments" component={PaymentsList} exact />
        {isTenant && (
          <Route path="/payments/wallet" component={TenantWalletv2} />
        )}

        <Route
          path="/payments/settings"
          component={isTenant ? TenantPaymentSettings : PaymentsSettings}
        />
        {hasCorporateOrPrincipalAccess && (
          <Route path="/payments/billing" component={Billing} />
        )}
        <Route path="/payments/:intentionId" component={PaymentsIntention} />
      </Switch>
    </>
  );
};

Payments.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
