import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import { toClassName } from '../../utils';

const ICONS = {
  default: ['far', 'credit-card-front'],
  mastercard: ['fab', 'cc-mastercard'],
  master: ['fab', 'cc-mastercard'],
  visa: ['fab', 'cc-visa'],
};

export const PaymentCardIcon = ({ cardType, className, ...props }) => {
  const icon = ICONS[cardType] || ICONS['default'];
  const classNames = [];

  return (
    <FontAwesomeIcon
      icon={icon}
      className={toClassName(classNames, className)}
      color="#343A40"
      {...props}
    />
  );
};

PaymentCardIcon.defaultProps = {
  cardType: 'default',
  className: '',
};

PaymentCardIcon.propTypes = {
  cardType: PropTypes.string,
  className: PropTypes.string,
};
