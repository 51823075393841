import PropTypes from 'prop-types';
import React from 'react';
import { Badge, Col, Row } from 'reactstrap';

import bpayImg from '../../assets/bpay.svg';

export const PaymentBpayIcon = ({
  hasActiveIcon,
  isShowProcessingTime,
  children,
}) => {
  return (
    <div className="d-flex">
      <Row className="flex-fill">
        <Col xs={3} md={2} xl={1}>
          <img src={bpayImg} height="60px" />
        </Col>
        <Col className="pl-3">
          <Row>
            <Col>
              <strong>Paying via BPAY</strong>
              {hasActiveIcon && (
                <Badge pill className="ml-2" color="success">
                  Active
                </Badge>
              )}
            </Col>
            {isShowProcessingTime && (
              <Col xs={8}>
                <span>Processing Time: 1 - 3 Business days</span>
              </Col>
            )}
          </Row>
          {children}
        </Col>
      </Row>
    </div>
  );
};

PaymentBpayIcon.propTypes = {
  children: PropTypes.node,
  hasActiveIcon: PropTypes.bool,
  isShowProcessingTime: PropTypes.bool,
};
