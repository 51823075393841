import React from 'react';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer text-center text-muted bg-white d-print-none w-100 mt-auto">
      <div className="py-3">
        &#x24B8; Managed Platforms Pty Ltd {currentYear || '2024'}
        <br />
        <a
          className="btn-link ml-1"
          href="https://www.managedapp.com.au/terms-conditions"
          target="_blank"
          rel="noopener noreferrer">
          Terms &amp; Conditions
        </a>
        <span className="mx-2">|</span>
        <a
          className="btn-link"
          href="https://www.managedapp.com.au/privacy"
          target="_blank"
          rel="noopener noreferrer">
          Privacy Policy
        </a>
        <span className="mx-2">|</span>
        <a
          className="btn-link"
          href="https://support.managedapp.com.au/en/"
          target="_blank"
          rel="noopener noreferrer">
          Support
        </a>
      </div>
    </footer>
  );
};
