import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'reactstrap';
import * as Yup from 'yup';

import { validatePhoneNumber } from '@app/utils';

import {
  FormButtons,
  FormFieldsForMultipleUsers,
  defaultPropsForUser,
  formatUserForSubmit,
  validationSchemaForUser,
} from '.';

export const FormPropertyTenants = ({
  secondaryTenants,
  onSubmit,
  isLoading,
}) => {
  const formik = useFormik({
    initialValues: {
      secondaryTenants: secondaryTenants.map((tenant) =>
        defaultPropsForUser(tenant || {})
      ),
    },
    validationSchema: () =>
      Yup.object().shape({
        secondaryTenants: Yup.array().of(
          Yup.object().shape({
            ...validationSchemaForUser,
            phoneNumber: Yup.string()
              .transform((value) => value.replace(/\s/g, ''))
              .when('id', {
                is: (value) => value === '' || value === undefined,
                then: Yup.string().test(
                  'mobile-validation',
                  'Valid mobile number is required',
                  (mobileNumber) => {
                    // mobile numbers are optional
                    if (!mobileNumber?.length || mobileNumber === '0') {
                      return true;
                    }

                    const { isValid } = validatePhoneNumber({ mobileNumber });

                    return isValid;
                  }
                ),
              }),
          })
        ),
      }),
    onSubmit: (values) => {
      onSubmit(
        values.secondaryTenants.map((tenant) => formatUserForSubmit(tenant))
      );
    },
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <FormFieldsForMultipleUsers
          canSubmit={!!onSubmit}
          type="tenant"
          values={formik.values.secondaryTenants}
          setFieldValue={formik.setFieldValue}
        />
        {formik.touched && (
          <FormButtons
            isSubmitting={formik.isSubmitting && isLoading}
            isValid={formik.isValid && formik.dirty}
          />
        )}
      </Form>
    </FormikProvider>
  );
};

FormPropertyTenants.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  secondaryTenants: PropTypes.array.isRequired,
};

export default FormPropertyTenants;
