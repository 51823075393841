import { useQueryClient } from '@tanstack/react-query';
import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { useStepper } from '@app/modules/LeaseFlex/common/stepper';
import {
  QUERY_KEYS,
  useAddBankAccount,
  useCreateTokenBank,
  useEnablePayments,
  useSetDisbursementAccount,
} from '@app/modules/Payment/hooks/use-assembly';
import { selectUserFingerprint } from '@app/redux/profile';
import { selectUserKind } from '@app/redux/users/selectors';

import { useHostedFields } from '../../hooks/use-hosted-fields';
import BankAccountForm from './BankAccountForm';
import CompanyForm from './CompanyForm';
import Signature from './Signature';
import Stepper from './Stepper';
import { AddBankSchema, DEFAULT_VALUES, STEP_FIELDS } from './constants';
import { getResponseErrorMessage, parseZaiFieldName } from './utils';

export const AddBankAccount = ({ isOpen, setModal, onSuccessCallback }) => {
  // Selectors
  const fingerprint = useSelector(selectUserFingerprint);
  const userKind = useSelector(selectUserKind);

  const hostedFieldsBank = useHostedFields();

  // Queries
  const queryClient = useQueryClient();
  const { mutate: addBankAccount } = useAddBankAccount();
  const { mutate: setDisbursement } = useSetDisbursementAccount();
  const { refetch } = useCreateTokenBank({
    fingerprint,
  });
  const { mutate: enablePayments } = useEnablePayments();

  const [
    { currentStep, canContinue },
    {
      decrementCurrentStep,
      incrementCurrentStep,
      reset: resetStepper,
      setCurrentStep,
    },
  ] = useStepper();

  const generateTokenBank = useCallback(async () => {
    const { data } = await refetch();

    return data;
  }, [refetch]);

  const handleAddBankAccount = useCallback(
    ({
      promisepayId,
      setAsDisbursementAccount = false,
      signature,
      extraInfo,
      callbackSuccess,
    }) => {
      addBankAccount({
        fingerprint,
        promisepayId,
        callback: () => {
          // enable disbursement
          enablePayments({
            fingerprint,
            promisepayId,
            amountCents: 100000,
            signature,
            extraInfo,
          });

          if (setAsDisbursementAccount) {
            setDisbursement({
              promisepayId,
              fingerprint,
            });
          }

          callbackSuccess();
        },
      });
    },
    [addBankAccount, fingerprint, enablePayments, setDisbursement]
  );

  const handleFormSubmit = useCallback(
    async (values, actions) => {
      actions.setSubmitting(true);
      actions.setStatus('submitting');
      const tokenData = await generateTokenBank();

      const createBankAccountPayload = {
        token: tokenData.token,
        user_id: tokenData.promisepayUserId,
        bank_name: values.bankName,
        account_type: values.accountType,
        holder_type: values.holderType,
        country: values.country || 'AUS',
      };

      try {
        const zaiData = await hostedFieldsBank.createBankAccount(
          createBankAccountPayload
        );

        const promisepayId = zaiData?.bank_accounts?.id;
        if (promisepayId) {
          handleAddBankAccount({
            promisepayId,
            setAsDisbursementAccount: values.setAsDisbursementAccount,
            signature: values.signature,
            extraInfo: {
              name: values.extraInfo.name,
              position: values.extraInfo.position,
              companyAddress: values.extraInfo.companyAddress,
            },
            callbackSuccess: () => {
              console.log('callback success');
              queryClient.invalidateQueries(QUERY_KEYS.FETCH_ACCOUNTS);
              resetStepper();
              actions.setStatus('submitted');
              actions.setSubmitting(false);
              onSuccessCallback();
            },
          });
        }
      } catch (response) {
        for (let key in response.errors) {
          const fieldName = parseZaiFieldName(key);

          if (fieldName) {
            actions.setFieldError(fieldName, getResponseErrorMessage(key));
          }
        }

        setCurrentStep(0);

        actions.setStatus('failed');
        actions.setSubmitting(false);
      }
    },
    [
      handleAddBankAccount,
      generateTokenBank,
      hostedFieldsBank,
      onSuccessCallback,
      queryClient,
      resetStepper,
      setCurrentStep,
    ]
  );

  const { setFieldValue, setTouched, validateForm, ...formik } = useFormik({
    initialValues: DEFAULT_VALUES,
    validationSchema: AddBankSchema,
    onSubmit: handleFormSubmit,
  });

  const hideModal = useCallback(() => {
    formik.resetForm();
    resetStepper();
    setModal(false);
  }, [formik, resetStepper, setModal]);

  const handleStepBack = useCallback(() => {
    decrementCurrentStep();
  }, [decrementCurrentStep]);

  const isStepFieldsValid = useCallback(async () => {
    // Get the fields for the current step
    const fieldsToValidate = STEP_FIELDS[0];

    // Mark fields as touched and validate them
    const touchedFields = fieldsToValidate.reduce((acc, field) => {
      acc[field] = true;
      return acc;
    }, {});

    setTouched(touchedFields, true);

    // Await Formik validation for the specific fields
    const errors = await validateForm();

    return fieldsToValidate.every((field) => !errors[field]);
  }, [setTouched, validateForm]);

  const handleSubmit = useCallback(async () => {
    const isValid = await isStepFieldsValid();

    if (!isValid) {
      return;
    }

    if (canContinue) {
      incrementCurrentStep();
    } else {
      formik.submitForm();
    }
  }, [isStepFieldsValid, canContinue, incrementCurrentStep, formik]);

  /**
   * An array of steps for adding a bank account.
   * Each step contains a name and content to be rendered.
   */
  const addBankAccountSteps = [
    {
      name: 'Bank Details Form',
      content: (
        <BankAccountForm
          hostedFieldsBank={hostedFieldsBank}
          onCancel={hideModal}
          onSubmit={handleSubmit}
        />
      ),
    },
    ...(userKind === 'company'
      ? [
          {
            name: 'Company Details',
            content: (
              <CompanyForm
                onCancel={hideModal}
                onBack={handleStepBack}
                onSubmit={handleSubmit}
              />
            ),
          },
        ]
      : []),
    {
      name: 'Digital Signature',

      content: (
        <Signature
          onBack={handleStepBack}
          onCancel={hideModal}
          onSubmit={handleSubmit}
        />
      ),
    },
  ].map((step, index) => ({ ...step, id: index }));

  useEffect(() => {
    if (userKind && userKind === 'company') {
      setFieldValue('isCompany', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, userKind]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={hideModal}
      centered
      size="lg"
      className="add-bank-account-container">
      <ModalHeader toggle={hideModal}>Add a Bank Account</ModalHeader>
      <ModalBody>
        <FormikProvider value={{ setFieldValue, ...formik }}>
          <form id="bank-account-form" data-testid="bank-account-form">
            <Stepper>
              <Stepper.Steps>
                <Stepper.Step id={0} persistent>
                  <div
                    className={`${currentStep !== 0 ? 'd-none' : 'd-block'}`}>
                    <BankAccountForm
                      hostedFieldsBank={hostedFieldsBank}
                      onCancel={hideModal}
                      onSubmit={handleSubmit}
                    />
                  </div>
                </Stepper.Step>
                {addBankAccountSteps.slice(1).map((step) => (
                  <Stepper.Step key={step.id} {...step}>
                    {step.content}
                  </Stepper.Step>
                ))}
              </Stepper.Steps>
            </Stepper>
          </form>
        </FormikProvider>
      </ModalBody>
    </Modal>
  );
};

AddBankAccount.propTypes = {
  isOpen: PropTypes.bool,
  setModal: PropTypes.func,
  onSuccessCallback: PropTypes.func,
};
