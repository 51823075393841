/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';

import { useIsMobile } from '@app/hooks';
import { ButtonIcon } from '@app/modules/Button';
import { ModalDeleteItem } from '@app/modules/Modal';
import { PropertyLeaseOutgoingsTotals } from '@app/modules/Property';

import { useLeaseState } from '../../Provider';
import {
  useCreateOutgoingEstimateItem,
  useDeleteOutgoingEstimateItem,
  useFetchOutgoingsEstimate,
  useUpdateOutgoingEstimateItem,
} from '../../useLeaseFlex';
import { HeaderComponent } from './HeaderComponent';
import { LocalInput } from './LocalInput';
import { ModalContentContainer } from './ModalContentContainer';

const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  annualEstimateCents: Yup.number()
    .typeError('Amount must be a number')
    .required('Amount is required')
    .min(0, 'Amount must be equal or greater than 0'),
  percentageTenantSplit: Yup.number()
    .typeError('Tenant Split must be a number')
    .required('Tenant Split is required')
    .min(0, 'Tenant Split must be equal or greater than 0')
    .max(100, 'Tenant Split must be equal or less than 100'),
});

export const CommercialOutgoingItems = () => {
  const { leaseState } = useLeaseState();
  const [showAdd, setShowAdd] = useState(false);
  const [outgoingsEstimateItems, setOutgoingsEstimateItems] = useState([]);
  const [toAddItemValues, setToAddItemValues] = useState({
    title: '',
    annualEstimateCents: 0,
    percentageTenantSplit: 0,
  });

  const [toEditItemValues, setToEditItemValues] = useState({
    title: '',
    annualEstimateCents: 0,
    percentageTenantSplit: 0,
    id: '',
  });

  const [errors, setErrors] = useState([]);

  const [itemToDelete, setItemToDelete] = useState(null);
  const isMobile = useIsMobile();

  const { mutateAsync: deleteItem } = useDeleteOutgoingEstimateItem();
  const { mutateAsync: addItem } = useCreateOutgoingEstimateItem();
  const { mutateAsync: updateItem } = useUpdateOutgoingEstimateItem();

  const { data } = useFetchOutgoingsEstimate({
    leaseId: leaseState?.lease?.id,
    outgoingsId: leaseState?.lease?.currentCommercialOutgoingsEstimate.id,
  });

  useEffect(() => {
    setOutgoingsEstimateItems(data?.outgoingsEstimate?.items || []);
  }, [data]);

  const toggleAdd = () => {
    setShowAdd(!showAdd);
    setToEditItemValues(null);
    setErrors([]);
  };

  const toggleEdit = (values) => {
    setShowAdd(false);
    setToEditItemValues(values);
    setErrors([]);
  };

  const handleValidation = async ({ data, callback }) => {
    await schema
      .validate(data, { abortEarly: false })
      .then(() => {
        if (callback) {
          callback();
        }
        setErrors([]);
      })
      .catch((err) => {
        setErrors(
          err.errors.reduce((acc, curr) => {
            if (curr.includes('Title')) {
              return { ...acc, title: curr };
            } else if (curr.includes('Amount')) {
              return { ...acc, amount: curr };
            } else {
              return { ...acc, split: curr };
            }
          }, {})
        );
      });
  };

  const handleAddItem = () => {
    const { annualEstimateCents, percentageTenantSplit, title } =
      toAddItemValues;
    const payload = {
      leadeId: leaseState?.lease?.id,
      outgoingsEstimateItem: {
        title,
        annualEstimateCents: annualEstimateCents * 100,
        percentageTenantSplit: percentageTenantSplit * 100,
      },
    };
    handleValidation({
      data: toAddItemValues,
      callback: () => {
        addItem({ leaseId: leaseState?.lease?.id, payload }).then((data) => {
          setOutgoingsEstimateItems(data?.outgoingsEstimate?.items || []);
          setToAddItemValues({
            title: '',
            annualEstimateCents: 0,
            percentageTenantSplit: 0,
          });
          toggleAdd();
        });
      },
    });
  };

  const handleUpdateItem = async () => {
    const { annualEstimateCents, percentageTenantSplit, title, id } =
      toEditItemValues;
    const payload = {
      leadeId: leaseState?.lease?.id,
      outgoingsEstimateItem: {
        title,
        annualEstimateCents: annualEstimateCents * 100,
        percentageTenantSplit: percentageTenantSplit * 100,
      },
    };

    await schema
      .validate({ ...toEditItemValues }, { abortEarly: false })
      .then(() => {
        updateItem({
          leaseId: leaseState?.lease?.id,
          itemId: id,
          payload,
        }).then((data) => {
          setOutgoingsEstimateItems(data?.outgoingsEstimate?.items || []);
          setToEditItemValues({
            title: '',
            annualEstimateCents: 0,
            percentageTenantSplit: 0,
            id: '',
          });
          toggleEdit(null);
        });
      })
      .catch((err) => {
        setErrors(
          err.errors.reduce((acc, curr) => {
            if (curr.includes('Title')) {
              return { ...acc, title: curr };
            } else if (curr.includes('Amount')) {
              return { ...acc, amount: curr };
            } else {
              return { ...acc, split: curr };
            }
          }, {})
        );
      });
  };

  const handleDeleteItem = () => {
    deleteItem({ leaseId: leaseState?.lease?.id, itemId: itemToDelete })
      .then((data) =>
        setOutgoingsEstimateItems(data?.outgoingsEstimate?.items || [])
      )
      .finally(() => setItemToDelete(null));
  };

  const handleOnChange = ({ target }) => {
    setToAddItemValues({ ...toAddItemValues, [target.name]: target.value });
    handleValidation({
      data: { ...toAddItemValues, [target.name]: target.value },
    });
  };

  const handleEditOnChange = ({ target }) => {
    setToEditItemValues({ ...toEditItemValues, [target.name]: target.value });
    handleValidation({
      data: { ...toEditItemValues, [target.name]: target.value },
    });
  };

  const renderAddBillComponent = () => (
    <Row className="d-flex align-items-baseline">
      <Col xs={12} md={4} className="text-left">
        <LocalInput
          name="title"
          label="Title"
          value={toAddItemValues.title}
          onChange={handleOnChange}
          error={errors?.title}
          isMobile={isMobile}
          isEdit
        />
      </Col>
      <Col xs={12} md={2}>
        <LocalInput
          name="annualEstimateCents"
          label="Annual Amount"
          value={toAddItemValues.annualEstimateCents}
          prepend="$"
          onChange={handleOnChange}
          error={errors?.amount}
          isMobile={isMobile}
          isEdit
        />
      </Col>
      <Col xs={12} md={2}>
        <LocalInput
          name="percentageTenantSplit"
          label="Tenant Split"
          value={toAddItemValues.percentageTenantSplit}
          append="%"
          onChange={handleOnChange}
          error={errors?.split}
          isMobile={isMobile}
          isEdit
        />
      </Col>
      <Col xs={12} md={2}>
        <LocalInput
          name="tenantAmount"
          label="Tenant Amount"
          value={
            (toAddItemValues.annualEstimateCents *
              100 *
              (toAddItemValues.percentageTenantSplit / 100)) /
            100
          }
          prepend="$"
          isMobile={isMobile}
        />
      </Col>
      <Col xs={12} md={2} className="d-flex">
        <Button
          outline
          color="secondary"
          onClick={handleAddItem}
          disabled={!!Object.keys(errors).length}>
          Save
        </Button>
        {isMobile ? (
          <Button
            outline
            color="secondary"
            icon={['fas', 'times-circle']}
            size="xs"
            onClick={toggleAdd}>
            Cancel
          </Button>
        ) : (
          <ButtonIcon
            color="text-secondary"
            icon={['fas', 'times-circle']}
            size="xs"
            onClick={toggleAdd}
          />
        )}
      </Col>
    </Row>
  );

  const renderBillsComponent = () => {
    if (!outgoingsEstimateItems.length) {
      return (
        <Row className="text-center m-0 pt-3">
          <Col>No items found. Please click on Add Button to add items</Col>
        </Row>
      );
    }

    return (
      <>
        {outgoingsEstimateItems.map(
          ({
            title,
            id,
            annualEstimateCents,
            percentageTenantSplit,
            tenantAmountCents,
          }) => {
            const isEdit = id === toEditItemValues?.id;

            return (
              <Row key={`outgoing-item-${id}-edit`}>
                <Col md={4} className="text-left">
                  <LocalInput
                    name="title"
                    label="Title"
                    value={isEdit ? toEditItemValues.title : title}
                    onChange={handleEditOnChange}
                    error={errors?.title}
                    isMobile={isMobile}
                    isEdit={isEdit}
                  />
                </Col>
                <Col md={2}>
                  <LocalInput
                    name="annualEstimateCents"
                    title="Annual Amount"
                    value={
                      isEdit
                        ? toEditItemValues.annualEstimateCents
                        : annualEstimateCents / 100
                    }
                    onChange={handleEditOnChange}
                    prepend="$"
                    error={errors?.amount}
                    isMobile={isMobile}
                    isEdit={isEdit}
                  />
                </Col>
                <Col md={2}>
                  <LocalInput
                    name="percentageTenantSplit"
                    label="Tenant Split"
                    value={
                      isEdit
                        ? toEditItemValues.percentageTenantSplit
                        : percentageTenantSplit
                    }
                    onChange={handleEditOnChange}
                    append="%"
                    error={errors?.split}
                    isMobile={isMobile}
                    isEdit={isEdit}
                  />
                </Col>
                <Col md={2}>
                  <LocalInput
                    name="tenantAmount"
                    label="Tenant Amount"
                    value={
                      isEdit
                        ? (toEditItemValues.annualEstimateCents *
                            100 *
                            (toEditItemValues.percentageTenantSplit / 100)) /
                          100
                        : tenantAmountCents / 100
                    }
                    prepend="$"
                    isMobile={isMobile}
                  />
                </Col>
                <Col md={2}>
                  <FormGroup className="d-flex">
                    {isEdit ? (
                      <>
                        <Button
                          outline
                          color="secondary"
                          onClick={handleUpdateItem}
                          disabled={!!Object.keys(errors).length}>
                          Save
                        </Button>
                        {isMobile ? (
                          <Button
                            outline
                            color="secondary"
                            onClick={() => toggleEdit(null)}>
                            Cancel
                          </Button>
                        ) : (
                          <ButtonIcon
                            color="text-secondary"
                            icon={['fas', 'times-circle']}
                            size="xs"
                            onClick={() => toggleEdit(null)}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {isMobile ? (
                          <>
                            <Button
                              outline
                              className="text-primary"
                              onClick={() => {
                                toggleEdit({
                                  title,
                                  annualEstimateCents:
                                    annualEstimateCents / 100,
                                  percentageTenantSplit,
                                  id,
                                });
                              }}>
                              Edit
                            </Button>
                            <ButtonIcon
                              color="danger"
                              className="text-danger pr-0"
                              icon={['far', 'trash-alt']}
                              onClick={() => setItemToDelete(id)}
                            />
                          </>
                        ) : (
                          <>
                            <ButtonIcon
                              className="text-primary"
                              icon={['fas', 'edit']}
                              size="xs"
                              onClick={() => {
                                toggleEdit({
                                  title,
                                  annualEstimateCents:
                                    annualEstimateCents / 100,
                                  percentageTenantSplit,
                                  id,
                                });
                              }}
                            />
                            <ButtonIcon
                              color="danger"
                              className="text-danger pl-0"
                              icon={['far', 'trash-alt']}
                              size="xs"
                              onClick={() => setItemToDelete(id)}
                            />
                          </>
                        )}
                      </>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            );
          }
        )}
        <Row className="mt-3">
          <Col md={12}>
            <PropertyLeaseOutgoingsTotals
              totalAnnualEstimateCents={
                data?.outgoingsEstimate?.totalAnnualEstimateCents
              }
              totalMonthlyTenantAmountCents={
                data?.outgoingsEstimate?.totalMonthlyTenantAmountCents
              }
              classNames="px-4"
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <h3>Commercial Outgoings Bills</h3>
      <ModalContentContainer>
        <HeaderComponent
          isMobile={isMobile}
          toggleAdd={toggleAdd}
          show={showAdd}
          items={[
            {
              title: 'Bill Name',
              size: 4,
              className: 'px-1 text-left',
            },
            {
              title: 'Annual Amount',
              size: 2,
            },
            {
              title: 'Tenant Split %',
              size: 2,
            },
            {
              title: 'Tenant Amount',
              size: 2,
            },
          ]}
        />
        {showAdd && renderAddBillComponent()}
        {renderBillsComponent()}
        <ModalDeleteItem
          isOpen={!!itemToDelete}
          title="Are you sure?"
          bodyText="This bill will be removed."
          onSubmit={handleDeleteItem}
          onCancel={() => setItemToDelete(null)}
        />
      </ModalContentContainer>
    </>
  );
};
