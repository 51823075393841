/* eslint-disable react/jsx-no-bind */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form } from 'reactstrap';

import { useToggle } from '@app/hooks';
import { FormLabel } from '@app/modules/Form';
import { InputField, SelectField } from '@app/modules/Form/FormikFields';
import { selectProfileData } from '@app/redux/profile';
import { formatDate } from '@app/utils';

import { SmsOverview } from '../SmsOverview';
import { TopUpSmsAccountModal } from '../TopUpSmsAccountModal';
import { BULK_SMS_RECIPIENTS } from '../constants';
import { useFetchRequiredCredits, useSendBulkSms } from '../hooks/use-bulk-sms';
import { BulkMessageSchema, INITIAL_MODAL_STATE } from './constants';

export const BulkMessageForm = ({
  initialValues,
  onSave,
  onSaveButtonText,
  isSaving,
  currentAgencyOption,
  sentAt,
}) => {
  const history = useHistory();
  const user = useSelector(selectProfileData);
  const [modalState, toggleModal] = useToggle(INITIAL_MODAL_STATE);

  const senderName = `${user.firstName} ${user.lastName}`;
  const isViewMode = !!sentAt;

  const { mutate: sendBulkSms, isLoading: isLoadingSendBulkSms } =
    useSendBulkSms();
  const formik = useFormik({
    initialValues,
    validationSchema: BulkMessageSchema,
    onSubmit: (values) => {
      onSave(values);
    },
    enableReinitialize: true,
  });

  const {
    data,
    refetch,
    isSuccess: isFetchRequiredCreditsSuccess,
    isFetching: isFetchRequiredCreditsLoading,
    remove,
  } = useFetchRequiredCredits({
    params: {
      agencyId: currentAgencyOption?.value,
      body: formik.values.message,
      toTenants: formik.values.recipients?.includes('tenants'),
      toOwners: formik.values.recipients?.includes('owners'),
    },
  });

  useEffect(() => {
    if (isFetchRequiredCreditsSuccess) {
      const hasEnoughCredits = data?.creditBalance > data?.requiredCredits;

      toggleModal({
        isOpen: true,
        hasEnoughCredits,
      });
    }
  }, [isFetchRequiredCreditsSuccess, data, toggleModal, remove]);

  const { validateForm, setTouched } = formik;

  const isSubmitting =
    isSaving ||
    isLoadingSendBulkSms ||
    isFetchRequiredCreditsLoading ||
    modalState.isOpen;

  useEffect(() => {
    remove();

    return () => {
      remove();
    };
  }, [remove]);

  // remove cached data after successful fetch to ensure fresh data on each send (even on minor changes in the message)
  // did it here instead of the query declaration since setting cache at state times didn't work as expected
  const handleToggleModal = () => {
    toggleModal();
    remove();
  };

  const handlePreviewAndSend = async () => {
    setTouched({
      message: true,
      recipients: true,
    });
    await validateForm(formik.values).then((errors) => {
      if (Object.keys(errors).length === 0) {
        refetch();
      }
    });
  };

  const handleSendMessage = () => {
    sendBulkSms(
      {
        body: formik.values.message,
        toTenants: formik.values.recipients?.includes('tenants'),
        toOwners: formik.values.recipients?.includes('owners'),
        agencyId: currentAgencyOption?.value,
        ...(initialValues ? { id: initialValues.id } : {}),
      },
      {
        onSuccess: () => {
          toggleModal();
          history.push('/sms/bulk-messaging');
        },
      }
    );
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          onSubmit={formik.handleSubmit}
          className="bg-gray-100 p-3 create-bulk-sms-form">
          <div className="form-group">
            <FormLabel for="recipients">To</FormLabel>
            <SelectField
              id="recipients"
              name="recipients"
              placeholder="Search for recipients"
              options={BULK_SMS_RECIPIENTS}
              isMulti={true}
              isDisabled={isViewMode || isSubmitting}
            />
          </div>
          <div className="form-group">
            <FormLabel for="sender">From</FormLabel>
            <SelectField
              id="sender"
              name="sender"
              placeholder="Search for recipients"
              options={[
                {
                  label: `${currentAgencyOption?.label} - ${user.firstName}`,
                  value: user.id,
                },
              ]}
              isDisabled
            />
          </div>
          <div className="form-group">
            <FormLabel for="message">Message</FormLabel>
            <InputField
              className="form-control"
              id="message"
              name="message"
              type="textarea"
              rows={6}
              onChange={formik.handleChange}
              value={formik.values.message}
              disabled={isViewMode || isSubmitting}
            />
          </div>
          {isViewMode ? (
            <p className="text-muted text-small">{`Sent: ${formatDate(
              sentAt,
              'shortWithTime'
            )}`}</p>
          ) : (
            <div className="d-flex justify-content-between mt-3">
              <Button
                name="save-as-draft"
                color="primary-alt"
                outline
                disabled={isSubmitting}>
                {isSaving ? (
                  <FontAwesomeIcon
                    className="text-primary-alt mr-1"
                    icon={['far', 'spinner']}
                    spin
                  />
                ) : (
                  onSaveButtonText
                )}
              </Button>
              <div>
                <Button
                  tag={Link}
                  color="primary-alt"
                  className="mr-2"
                  outline
                  to="/sms/bulk-messaging"
                  disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button
                  name="preview-and-send"
                  color="primary-alt"
                  type="button"
                  disabled={isSubmitting}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={handlePreviewAndSend}>
                  Preview and Send{' '}
                  {isFetchRequiredCreditsLoading && (
                    <FontAwesomeIcon
                      className="mr-1"
                      icon={['far', 'spinner']}
                      spin
                    />
                  )}
                </Button>
              </div>
            </div>
          )}
        </Form>
      </FormikProvider>
      {!modalState.hasEnoughCredits && modalState.isOpen && (
        <TopUpSmsAccountModal
          modalState={modalState}
          toggleModal={handleToggleModal}
          isLoading={isSaving}
          onSubmit={() => onSave(formik.values)}
          creditBalance={data?.creditBalance}
          creditsNeeded={data?.requiredCredits}
          note="Don't worry! We'll save your message so you can send it later."
        />
      )}
      {modalState.hasEnoughCredits && modalState.isOpen && (
        <SmsOverview
          modalState={modalState}
          toggleModal={handleToggleModal}
          isLoading={isLoadingSendBulkSms}
          onSubmit={handleSendMessage}
          creditsNeeded={data?.requiredCredits}
          size="lg"
          senderName={senderName}
          body="Please review the details above before sending your message. Ensure the recipient, message, and total credits are correct."
          {...formik.values}
        />
      )}
    </>
  );
};

BulkMessageForm.propTypes = {
  initialValues: PropTypes.shape({
    message: PropTypes.string,
    recipients: PropTypes.array,
    sender: PropTypes.number,
    id: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
  onSaveButtonText: PropTypes.string,
  isSaving: PropTypes.bool,
  currentAgencyOption: PropTypes.object.isRequired,
  sentAt: PropTypes.string,
};
BulkMessageForm.defaultProps = {
  initialValues: {
    message: '',
    recipients: [],
    sender: '',
  },
  onSaveButtonText: 'Save',
  isSaving: false,
  sentAt: '',
};
