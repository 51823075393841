import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { formatDate, httpClient } from '@app/utils';

export const QUERY_KEYS = {
  EXPORT_TASKS_EXCEL: 'exportTasksExcel',
  EXPORT_TASKS_EXCEL_COUNT: 'exportTasksExcelCount',
};

const exportTasksExcel = async (params) => {
  try {
    return await httpClient.post('/tasks/export_tasks_excel', null, { params });
  } catch (error) {
    console.error('errors: ', error);
    throw error;
  }
};

const getTasksExcelCount = async (params) => {
  try {
    const response = await httpClient.get('/tasks/export_tasks_excel_count', {
      params,
    });

    return response?.data?.count || 0;
  } catch (error) {
    console.error('errors: ', error);
    throw error;
  }
};

const transformQueryParams = (queryParams = {}) => {
  const {
    assigneeId,
    priority,
    propertyId,
    status,
    statusNotIn,
    type,
    category,
    excludeAgencyBills,
    fromDate,
    toDate,
  } = queryParams;

  const params = {
    'q[assigneeIdEq]': assigneeId,
    'q[priorityEq]': priority,
    'q[propertyIdEq]': propertyId,
    'q[taskTypeKeyEq]': decorateTaskType(type),
    ...(fromDate ? { 'q[dueDateGteq]': formatDate(fromDate, 'au') } : {}),
    ...(toDate ? { 'q[dueDateLteq]': formatDate(toDate, 'au') } : {}),
  };

  if (!status) {
    params['q[taskStatusKeyNotIn]'] = ['completed', 'declined'];
  } else {
    params['q[taskStatusKeyEq]'] = status;
  }

  if (statusNotIn) {
    params['q[taskStatusKeyNotIn]'] = statusNotIn;
  }

  if (category) {
    params['q[taskCategoryKey]'] = category;
  }

  if (excludeAgencyBills === 'true') {
    params['q[excludeAgencyBills]'] = true;
  }

  return params;
};

const decorateTaskType = (type) => (type === 'arrears' ? 'arrear' : type);

export const useTaskExcelExport = () => {
  return useMutation({
    mutationFn: (queryParams) =>
      exportTasksExcel(transformQueryParams(queryParams)),
    onSuccess: () => {
      toast.success(
        'Success: Your report is being prepared and will be sent to your email shortly.'
      );
    },
    onError: (error) => {
      toast.error('Error: Failed to export tasks');
      throw error;
    },
  });
};

export const useTaskExcelExportCount = (params) => {
  return useQuery(
    [QUERY_KEYS.EXPORT_TASKS_EXCEL_COUNT, params],
    () => getTasksExcelCount(transformQueryParams(params)),
    {
      retry: false,
      placeholderData: 0,
    }
  );
};
