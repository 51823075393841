import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { ContentDefinition } from '@app/modules/Content';
import {
  DateField,
  InputField,
  RadioField,
  SelectField,
} from '@app/modules/Form/FormikFields';
import { LeasePaymentValues } from '@app/modules/Lease';
import {
  formatDate,
  nextEffectiveDate,
  toCents,
  toFrequencyAmountsCents,
} from '@app/utils';

import { RENTAL_ADJUSTMENT_OPTIONS } from '../constants';
import { ModalContentContainer } from './ModalContentContainer';

export const RentalAdjustments = () => {
  const {
    values: {
      scheduleAdjustment,
      rentDollars,
      payFrequency,
      startDate,
      effectiveFrom,
      effectiveDate,
      rentPaidUpUntil,
    },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (effectiveFrom) {
      const effectiveDate = nextEffectiveDate(
        new Date(effectiveFrom),
        new Date(startDate),
        payFrequency
      );

      setFieldValue('effectiveDate', formatDate(effectiveDate));
    }
  }, [effectiveFrom, payFrequency, setFieldValue, startDate]);

  const annualRentCents = useMemo(() => {
    const amountsCents = toFrequencyAmountsCents(toCents(rentDollars));
    const annualRentCents = Math.round(amountsCents[payFrequency]);

    setFieldValue('annualRentCents', annualRentCents);

    return annualRentCents;
  }, [payFrequency, rentDollars, setFieldValue]);

  return (
    <>
      <h3>Do you want to schedule some upcoming rental adjustments?</h3>
      <ModalContentContainer>
        <FormGroup>
          <Label for="scheduleAdjustment">
            Are there any rental adjustments to schedule?
          </Label>
          <div role="group" className="radio-group-wrapper">
            <RadioField name="scheduleAdjustment" label="Yes" value="true" />
            <RadioField name="scheduleAdjustment" label="No" value="false" />
          </div>
        </FormGroup>
        {scheduleAdjustment === 'true' && (
          <>
            <Row className="mb-3">
              <Col>
                <LeasePaymentValues value={annualRentCents} />
              </Col>
            </Row>
            <FormGroup>
              <Label for="rentDollars">Rent</Label>
              <InputField name="rentDollars" type="number" prepend="$" />
            </FormGroup>
            <FormGroup>
              <Label for="effectiveFrom">Effective From</Label>
              <DateField name="effectiveFrom" />
            </FormGroup>
            <FormGroup>
              <Label for="reasonForChange">Reason for change</Label>
              <SelectField
                name="reasonForChange"
                options={RENTAL_ADJUSTMENT_OPTIONS}
              />
            </FormGroup>
            <Row className="my-3">
              <Col xs={6}>
                <ContentDefinition
                  label="Rent Paid Until"
                  value={formatDate(rentPaidUpUntil)}
                />
              </Col>
              <Col xs={6}>
                <ContentDefinition
                  label="Adjustment Applies From"
                  value={effectiveDate}
                />
              </Col>
            </Row>
            <small>
              <b>Note:&nbsp;</b>Rent adjustments will take effect starting from
              the next unpaid rental period following the effective date.
            </small>
          </>
        )}
      </ModalContentContainer>
    </>
  );
};
