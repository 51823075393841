import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';

import { ButtonDestroy } from '@app/modules/Button';
import { CardLight } from '@app/modules/Card';
import { TooltipIcon } from '@app/modules/Common/TooltipIcon';
import { FormField } from '@app/modules/Form';
import { removeNote, upsertNote } from '@app/redux/lease/actions';

export const AgencyNotes = ({ lease }) => {
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      note: lease?.agencyNote?.body,
    },
    validationSchema: Yup.object().shape({
      note: Yup.string(),
    }),
    onSubmit: (values) => {
      dispatch(
        upsertNote({
          leaseId: lease.id,
          id: lease.agencyNote?.id,
          body: values.note,
        })
      );
      closeForm();
    },
  });

  const closeForm = () => setIsEditing(false);
  const handleDeleteNote = () =>
    dispatch(removeNote({ leaseId: lease.id, id: lease.agencyNote?.id }));

  return (
    <CardLight className="mt-2">
      <CardHeader className="bg-white pb-0">
        <Row>
          <Col className="d-flex align-items-baseline">
            <CardTitle tag="h5">Internal Notes</CardTitle>
            <TooltipIcon
              content="These notes are only viewable by the agency"
              label="internal-notes-card"
            />
          </Col>
          <Col className="text-right" hidden={!isEditing}>
            <Button
              color="outline"
              size="sm"
              className="mr-2"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={closeForm}>
              Cancel
            </Button>
            <Button color="primary" size="sm" onClick={formik.handleSubmit}>
              Save
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody
        className={isEditing ? 'pt-0' : 'py-0'}
        style={{ maxHeight: '12em', overflowY: 'auto' }}>
        {isEditing ? (
          <FormikProvider value={formik}>
            <form>
              <FormField name="note" rows={6} type="textarea" />
            </form>
          </FormikProvider>
        ) : (
          <>
            <Row hidden={!lease.agencyNote}>
              <Col style={{ whiteSpace: 'pre-wrap' }}>
                {lease.agencyNote?.body}
              </Col>
              <Col xs={2} hidden={true}>
                <ButtonDestroy
                  modal={{
                    title: 'Remove agency note?',
                  }}
                  // eslint-disable-next-line react/jsx-no-bind
                  onConfirm={handleDeleteNote}
                />
              </Col>
            </Row>
          </>
        )}
      </CardBody>
      <CardFooter style={{ backgroundColor: 'inherit' }} hidden={isEditing}>
        <Button
          color="primary"
          outline
          block
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => setIsEditing(true)}>
          {lease.agencyNote ? 'Edit' : 'Add'} Note
        </Button>
      </CardFooter>
    </CardLight>
  );
};

AgencyNotes.propTypes = {
  lease: PropTypes.object.isRequired,
};
