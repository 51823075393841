import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';

import { formatDate } from '@app/utils';

import { MessageStatus } from './MessageStatus';
import { getMessageProperties } from './constants';

export const MessageInfoStatus = ({
  status,
  failedAt,
  createdAt,
  sentAt,
  failureReason,
  isBulkMessage,
}) => {
  const { color } = getMessageProperties(status, isBulkMessage);

  return (
    <div
      className={`my-3 p-3 message-status-container message-status-container--${color} border border-${color} text-small`}>
      <Row className="align-items-center mb-1">
        <Col md={6}>
          <MessageStatus
            className={`message-status message-status--${color}`}
            status={status}
            isBulkMessage={isBulkMessage}
          />
        </Col>
        <Col className="col-6 text-right font-weight-bold">
          <span>{formatDate(failedAt || sentAt, 'shortWithTime')}</span>
        </Col>
      </Row>
      {createdAt && (
        <Row className="align-items-center mt-1">
          <Col md={6}>Created At</Col>
          <Col className="col-6 text-right font-weight-bold">
            <span>{formatDate(createdAt, 'short')}</span>
          </Col>
        </Row>
      )}
      {failedAt && (
        <Row className="mt-1">
          <Col md={6}>Failure Reason</Col>
          <Col className="col-6 text-right font-weight-bold">
            {failureReason}
          </Col>
        </Row>
      )}
    </div>
  );
};

MessageInfoStatus.propTypes = {
  status: PropTypes.string,
  failedAt: PropTypes.string,
  createdAt: PropTypes.string,
  sentAt: PropTypes.string,
  failureReason: PropTypes.string,
  isBulkMessage: PropTypes.bool,
};
