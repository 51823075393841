import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'reactstrap';

export const LeaseInformationSectionCol = ({ children, ...props }) => {
  return (
    <Col {...props}>
      <div className="border rounded p-3 h-100">{children}</div>
    </Col>
  );
};

LeaseInformationSectionCol.propTypes = {
  children: PropTypes.node,
  ...Col.propTypes,
};
