import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import { useIsMobile, useLocationParams } from '@app/hooks';
import { Pagination } from '@app/modules/Pagination';
import { centsToDollar, formatDate } from '@app/utils';

import {
  PURCHASE_HISTORY_COLUMNS,
  PURCHASE_HISTORY_INITIAL_FILTER,
} from '../constants';
import {
  useFetchSmsCreditPurchases,
  useReprocessCreditPurchase,
} from '../hooks/use-sms-accounts';
import '../styles.scss';
import { Filter } from './Filter';
import { PurchaseStatus } from './PurchaseStatus';

export const PurchaseHistory = ({ agencyId }) => {
  const isMobile = useIsMobile();
  const [filter, setFilter] = useState(PURCHASE_HISTORY_INITIAL_FILTER);

  const params = useLocationParams();
  const { data, isLoading } = useFetchSmsCreditPurchases({
    params: {
      agencyId,
      perPage: params.perPage,
      page: params.page,
      ...filter,
      status: Object.keys(filter?.status || {}).filter(
        (key) => filter.status[key]
      ),
    },
  });
  const { mutate, isLoading: isReprocessLoading } =
    useReprocessCreditPurchase();

  const renderList = () => {
    if (data.length === 0) {
      return (
        <Row lg={12} className="m-0 p-5">
          <Col className="text-center">
            No purchases found. Purchase credits{' '}
            <Link to="/sms/plans">here</Link>.
          </Col>
        </Row>
      );
    } else {
      return (
        <>
          {data.map(
            (
              {
                name,
                priceCents,
                createdAt,
                completedAt,
                status,
                id,
                numberOfCredits,
                invoiceUrl,
              },
              index
            ) => (
              <Row
                className="m-0 px-3 py-2 text-left no-gutters text-small purchase-list align-items-center"
                key={`purchase-${index}-${id}`}>
                <Col className="col-md-2 col-8 order-0">
                  <strong>{name}</strong>
                </Col>
                <Col className="d-none d-md-inline col-1 order-1">
                  {numberOfCredits}
                </Col>
                <Col className="col-md-1 col-8 text-muted text-muted order-2 order-md-2">
                  <span className="d-inline d-md-none">{`${numberOfCredits} Credits for `}</span>
                  {centsToDollar(priceCents)}
                </Col>
                <Col className="col-md-2 col-4 d-flex order-3 order-md-3 justify-content-end justify-content-md-start">
                  <PurchaseStatus status={status} />
                </Col>
                <Col className="col-md-3 col-12 text-muted order-4">
                  <span className="d-inline d-md-none">Purchased on: </span>
                  <strong>{formatDate(createdAt, 'shortWithTime')}</strong>
                </Col>
                <Col className="col-md-2 col-9 order-5 text-muted order-md-5">
                  <span className="d-inline d-md-none">Completed on </span>
                  {formatDate(completedAt, 'shortDayMonthYear') || '- - -'}
                </Col>
                <Col className="col-3 col-md-1 d-flex justify-content-end justify-content-md-center order-6">
                  {invoiceUrl && (
                    <a
                      id={`download-invoice-${id}`}
                      className="btn btn-link text-nowrap"
                      href={invoiceUrl}
                      target="_blank"
                      rel="noopener noreferrer">
                      <FontAwesomeIcon
                        icon={['fas', 'download']}
                        className="text-primary-alt"
                      />
                      <UncontrolledTooltip
                        placement="right"
                        target={`download-invoice-${id}`}>
                        Download Invoice
                      </UncontrolledTooltip>
                    </a>
                  )}
                  {status === 'failed' && (
                    <Button
                      className="py-1 px-3 text-small"
                      size="sm"
                      color="primary-alt"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => mutate({ agencyId, id })}
                      disabled={isReprocessLoading}>
                      {isReprocessLoading ? 'Retrying' : 'Retry'}
                    </Button>
                  )}
                </Col>
              </Row>
            )
          )}
        </>
      );
    }
  };

  return (
    <Col>
      <Card className="purchase-history">
        <CardHeader className="bg-white border-400">
          <CardTitle className="mb-0" tag="h5">
            Purchase History
          </CardTitle>
        </CardHeader>
        <CardBody className="p-0">
          <Container className="p-0 mb-1">
            <Filter filters={filter} setFilters={setFilter} />
            {!isMobile && (
              <Row
                lg={12}
                className="m-0 px-3 py-2 text-left no-gutters purchase-history-header">
                {PURCHASE_HISTORY_COLUMNS.map((column) => (
                  <Col
                    key={column.accessor}
                    lg={column.size || 2}
                    className={column.className}>
                    {column.header}
                  </Col>
                ))}
              </Row>
            )}
            {isLoading ? (
              <Row className="m-0 p-5 text-left no-gutters text-small">
                <Col className="text-center">
                  <PulseLoader color="#dee2e6" />
                </Col>
              </Row>
            ) : (
              renderList()
            )}
          </Container>
        </CardBody>
      </Card>
      <Pagination
        className="mt-3 sms-pagination"
        name="credit_purchases"
        isReload={false}
      />
    </Col>
  );
};

PurchaseHistory.propTypes = {
  propertyCount: PropTypes.number,
  agencyId: PropTypes.number,
};
