/* eslint-disable react/prop-types */
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { Button, CustomInput, FormGroup, Label } from 'reactstrap';

import { InputField, SelectField } from '@app/modules/Form/FormikFields';
import { PaymentDda } from '@app/modules/Payment';

import PaymentDdaError from './PaymentDdaError';
import {
  ACCOUNT_TYPE_OPTIONS,
  HOLDER_TYPE_OPTIONS,
  STEPS_INDEX,
} from './constants';
import { stepHasErrors } from './utils';

const fieldStyles = {
  input: {
    color: '#495057',
  },
};

const requiredFieldsValidation = (fieldType) => {
  switch (fieldType) {
    case 'bankAccountName':
      return 'Account name is required';
    case 'bankRoutingNumber':
      return 'BSB is required';
    case 'bankAccountNumber':
      return 'Account number is required';
    default:
      return 'Enter valid data';
  }
};

const BankAccountForm = ({
  hostedFieldsBank,
  disabled,
  onCancel,
  onSubmit,
}) => {
  const { values, setFieldValue, setFieldError, errors } = useFormikContext();

  const hasErrors = stepHasErrors(errors, STEPS_INDEX.bank);

  const isAddingBankAccount = disabled;
  const isDisabledClassName = isAddingBankAccount ? 'disabled' : '';

  useEffect(() => {
    const accountName = hostedFieldsBank.create('bankAccountName', {
      placeholder: 'Account Holder Full Name',
      styles: fieldStyles,
    });
    const routingNumber = hostedFieldsBank.create('bankRoutingNumber', {
      styles: fieldStyles,
    });
    const accountNumber = hostedFieldsBank.create('bankAccountNumber', {
      styles: fieldStyles,
    });
    accountName.mount('#account-name-field');
    routingNumber.mount('#routing-number-field');
    accountNumber.mount('#account-number-field');

    [accountName, routingNumber, accountNumber].forEach((field) => {
      field.on('change', (event) => {
        const error =
          !event.valid || event.error
            ? event?.error?.message || requiredFieldsValidation(event.fieldType)
            : undefined;

        if (error) {
          setFieldError(event.fieldType, error);
        } else {
          setFieldError(event.fieldType, undefined); // Clear the error
        }
      });
    });
  }, []);

  const handleChangeAgreement = useCallback(
    () => setFieldValue('agreement', !values.agreement),
    [setFieldValue, values.agreement]
  );

  const handleChangeDisbursement = useCallback(
    () =>
      setFieldValue(
        'setAsDisbursementAccount',
        !values.setAsDisbursementAccount
      ),
    [setFieldValue, values.setAsDisbursementAccount]
  );

  return (
    <>
      <div className="step-content">
        <div className="form-content">
          <div className="mb-2 form-group">
            <label htmlFor="bank-name-field" className="ml-1">
              Bank Name
              <span className="text-danger ml-1">*</span>
            </label>
            <InputField
              label="Bank Name"
              data-testid="bank-name-field"
              name="bankName"
              id="bank-name-field"
              required
            />
          </div>
          <div className="mb-2 form-group">
            <label htmlFor="account-name-field" className="ml-1">
              Account Name
              <span className="text-danger ml-1">*</span>
            </label>
            <div
              id="account-name-field"
              data-testid="account-name-field"
              className={`assembly-field form-control ${
                errors.bankAccountName ? 'is-invalid' : ''
              }
              ${isDisabledClassName}
              `}
            />
            {errors.bankAccountName && (
              <div className="invalid-feedback">{errors.bankAccountName}</div>
            )}
          </div>
          <div className="mb-2 form-group">
            <div className="d-flex gap-3">
              <div className="flex-fill">
                <label htmlFor="routing-number-field" className="ml-1">
                  BSB
                  <span className="text-danger ml-1">*</span>
                </label>
                <div
                  id="routing-number-field"
                  data-testid="routing-number-field"
                  className={`assembly-field form-control  ${
                    errors.bankRoutingNumber ? 'is-invalid' : ''
                  }
                  ${isDisabledClassName}
                  `}
                />
                {errors.bankRoutingNumber && (
                  <div className="invalid-feedback">
                    {errors.bankRoutingNumber}
                  </div>
                )}
              </div>
              <div className="flex-fill">
                <label htmlFor="account-number-field" className="ml-1">
                  Account Number
                  <span className="text-danger ml-1">*</span>
                </label>
                <div
                  id="account-number-field"
                  data-testid="account-number-field"
                  className={`assembly-field form-control  ${
                    errors.bankAccountNumber ? 'is-invalid' : ''
                  }
                   ${isDisabledClassName}
                  `}
                />
                {errors.bankAccountNumber && (
                  <div className="invalid-feedback">
                    {errors.bankAccountNumber}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mb-3 form-group">
            <div className="d-flex gap-3">
              <div className="flex-fill">
                <FormGroup>
                  <Label required for="accountType">
                    Account Type
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <SelectField
                    name="accountType"
                    options={ACCOUNT_TYPE_OPTIONS}
                  />
                </FormGroup>
              </div>
              <div className="flex-fill">
                <FormGroup>
                  <Label required for="holderType">
                    Holder Type
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <SelectField
                    name="holderType"
                    options={HOLDER_TYPE_OPTIONS}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <CustomInput
            className="mb-1"
            id="setAsDisbursementAccount"
            name="setAsDisbursementAccount"
            type="checkbox"
            label="Set as disbursement account"
            disabled={isAddingBankAccount}
            onChange={handleChangeDisbursement}
            checked={values.setAsDisbursementAccount}
          />
          <div className="form-group mb-3">
            <PaymentDda
              isChecked={values.agreement}
              onChange={handleChangeAgreement}
              title=""
              className="aggreement-checkbox"
            />

            <PaymentDdaError />
          </div>
        </div>
      </div>
      <div className="modal-action-buttons">
        <div className="secondary-actions">
          <Button type="button" color="primary" outline onClick={onCancel}>
            Cancel
          </Button>
        </div>
        <div className="primary-actions">
          <Button
            type="button"
            color="primary"
            disabled={hasErrors}
            onClick={onSubmit}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default BankAccountForm;
