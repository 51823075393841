/* eslint-disable react/jsx-no-bind */
import format from 'date-fns/format';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';

import { DateField, RadioField } from '@app/modules/Form/FormikFields';

import { useLeaseState } from '../../Provider';
import { useUpdateOutgoingsEstimate } from '../../useLeaseFlex';
import { ModalContentContainer } from './ModalContentContainer';

export const CommercialOutgoings = ({ isRenew }) => {
  const { values, setFieldValue } = useFormikContext();
  const { leaseState } = useLeaseState();
  const { mutate: updateOutgoings } = useUpdateOutgoingsEstimate();

  const handleUpdateOutgoings = (payload) => {
    updateOutgoings({
      leaseId: leaseState?.lease?.id,
      outgoingsId: leaseState?.lease?.currentCommercialOutgoingsEstimate.id,
      payload,
    });
  };

  const handleChangeChargeDate = (value) => {
    setFieldValue('chargeFromDate', value);
    handleUpdateOutgoings({ chargeFromDate: format(value, 'dd-MM-yyyy') });
  };

  const handleGSTOnChange = (value) => {
    setFieldValue('chargeGST', value);
    handleUpdateOutgoings({ gstIncluded: value === 'true' });
  };

  return (
    <>
      <h3>Commercial Outgoing Bill Options</h3>
      <ModalContentContainer>
        <FormGroup>
          <Label for="monthlyOutgoings">
            Do you want to charge monthly outgoings for this lease?
          </Label>
          <div role="monthlyOutgoings" className="radio-group-wrapper">
            <RadioField name="monthlyOutgoings" label="Yes" value="true" />
            <RadioField
              name="monthlyOutgoings"
              label="No, skip for now"
              value="false"
            />
          </div>
        </FormGroup>
        {values.monthlyOutgoings === 'true' && (
          <>
            {!isRenew && (
              <FormGroup>
                <Label for="chargeDateCustom">
                  When do you want to start charging outgoings?
                </Label>
                <div
                  role="chargeDateCustom"
                  className="radio-group-wrapper mb-3">
                  <RadioField
                    name="chargeDateCustom"
                    label="Use the lease first payment date"
                    value="true"
                    onChange={() => {
                      setFieldValue('chargeDateCustom', 'true');
                      handleUpdateOutgoings({
                        chargeFromDate: format(
                          new Date(leaseState?.lease?.startDate),
                          'dd-MM-yyyy'
                        ),
                      });
                    }}
                  />
                  <RadioField
                    name="chargeDateCustom"
                    label="Choose custom date"
                    value="false"
                  />
                </div>
                {values.chargeDateCustom === 'false' && (
                  <DateField
                    name="chargeFromDate"
                    className="mt-3"
                    datePickerProps={{
                      onChange: handleChangeChargeDate,
                    }}
                  />
                )}
              </FormGroup>
            )}

            <FormGroup>
              <Label for="chargeGST">Include GST?</Label>
              <div role="chargeGST" className="radio-group-wrapper">
                <RadioField
                  name="chargeGST"
                  label="Yes"
                  value="true"
                  onChange={() => handleGSTOnChange('true')}
                />
                <RadioField
                  name="chargeGST"
                  label="No"
                  value="false"
                  onChange={() => handleGSTOnChange('false')}
                />
              </div>
            </FormGroup>
          </>
        )}
      </ModalContentContainer>
    </>
  );
};

CommercialOutgoings.propTypes = {
  isRenew: PropTypes.bool,
};
CommercialOutgoings.defaulProps = {
  isRenew: false,
};

export default CommercialOutgoings;
