import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { useIsMobile } from '@app/hooks';
import { UploaderFiles } from '@app/modules/Uploader';
import {
  formatNumberWithCommas,
  isConditionReport,
  isLeaseAgreement,
} from '@app/utils';

import { useLeaseState } from '../../Provider';
import { CurrentTenantInformation } from '../../common/information/CurrentTenantInformation';
import { ModalContentContainer } from './ModalContentContainer';

export const Overview = () => {
  const { values } = useFormikContext();

  const { leaseState } = useLeaseState();
  const { lease } = leaseState;
  const isMobile = useIsMobile();

  const formatDateValue = (dateValue) =>
    format(new Date(dateValue), 'dd-MM-yyyy');

  const attachments = useMemo(
    () => ({
      conditionReport: (lease?.attachments || []).filter(isConditionReport),
      leaseAgreement: (lease?.attachments || []).filter(isLeaseAgreement),
    }),
    [lease]
  );

  return (
    <>
      <h3>Rental Summary</h3>
      <ModalContentContainer>
        <Section
          title="Lease dates"
          data={[
            {
              title: 'Start Date',
              value: formatDateValue(values.leaseStartDate),
            },
            {
              title: 'End Date',
              value: formatDateValue(values.endDate),
            },
          ]}
        />
        <Section
          title="Rent amount"
          data={[
            {
              title: 'Amount',
              value: `$${formatNumberWithCommas(values.rentDollars)} (${
                values.payFrequency
              })`,
            },
          ]}
        />
        <Section
          title="First Payment"
          data={[
            {
              value: formatDateValue(values.startDate),
            },
          ]}
        />
        <Section
          title="Rental Bond"
          data={
            values?.bondDollars
              ? [
                  {
                    title: 'Amount',
                    value: `$${formatNumberWithCommas(values.bondDollars)}`,
                  },
                  {
                    title: 'ID',
                    value: values?.bondNumber,
                    className: 'font-weight-bold',
                  },
                ]
              : [
                  {
                    value: 'None',
                  },
                ]
          }
        />
        <Section
          title="Routine Inspection"
          data={
            values?.inspectionDate
              ? [
                  {
                    title: '',
                    value: `${formatDateValue(values.inspectionDate)} (every ${
                      values.inspectionDateFrequencyInMonths
                    } months)`,
                  },
                ]
              : [{ value: 'None' }]
          }
        />
        <Section
          title="Rent Review"
          data={
            values?.reviewDate
              ? [
                  {
                    title: '',
                    value: `${formatDateValue(values.reviewDate)} (every ${
                      values.reviewDateFrequencyInMonths
                    } months)`,
                  },
                ]
              : [{ value: 'None' }]
          }
        />
        <Section
          title="Documents"
          className={isMobile ? 'm-0' : ''}
          content={
            <div>
              <FormGroup className="d-flex flex-column mb-1">
                <Label for="leaseUpload">Tenancy Agreement</Label>
                <UploaderFiles
                  attachments={attachments.leaseAgreement}
                  attachableType="Lease"
                  attachableId={lease.id}
                  className="d-flex flex-wrap"
                  onDestroyComplete={null}
                />
              </FormGroup>
              <FormGroup className="d-flex flex-column mb-1">
                <Label for="ingoingReportUpload">Ingoing Report</Label>
                <UploaderFiles
                  attachments={attachments.leaseAgreement}
                  attachableType="Lease"
                  attachableId={lease.id}
                  className="d-flex flex-wrap"
                  onDestroyComplete={null}
                />
              </FormGroup>
            </div>
          }
        />
        <Section
          title="Primary Tenant"
          className={isMobile ? 'm-0' : ''}
          content={
            <div className="d-flex flex-column">
              <CurrentTenantInformation primaryTenant={lease?.primaryTenant} />
            </div>
          }
        />
        <Section
          title="Other Tenants"
          className={isMobile ? 'm-0' : ''}
          content={
            <div className="d-flex flex-column">
              <CurrentTenantInformation
                secondaryTenants={lease?.secondaryTenants}
              />
            </div>
          }
          isLast
        />
      </ModalContentContainer>
    </>
  );
};

const Section = ({ title, data, content, isLast, className }) => {
  return (
    <Row className={className}>
      <Col xs={12} md={4} className="font-weight-bold">
        {title}
      </Col>
      <Col xs={12} md={8}>
        <Row>
          {content ? (
            <>{content}</>
          ) : (
            data.map(({ title, value, className }, i) => (
              <Col
                xs={12}
                md={6}
                key={`${title}-${i}`}
                className={`p-md-0 ${className ?? ''}`}>
                {title ? `${title}: ` : ''}
                {value}
              </Col>
            ))
          )}
        </Row>
      </Col>
      {!isLast && (
        <Col xs={12}>
          <hr />
        </Col>
      )}
    </Row>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  content: PropTypes.node,
  isLast: PropTypes.bool,
  className: PropTypes.string,
};
Section.defaultProps = {
  title: 'MISSING TITLE',
  data: [],
  content: null,
  isLast: false,
  className: '',
};

export default Overview;
