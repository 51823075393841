/* eslint-disable react/jsx-no-bind */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Badge,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  List,
  Row,
} from 'reactstrap';

import { useIsOpen } from '@app/hooks';
import { ButtonIcon } from '@app/modules/Button';
import { CardLight } from '@app/modules/Card';
import { Link } from '@app/modules/Link';
import { TenantsInformationModal } from '@app/modules/Property/TenantsInformationModal';

import { formatDate } from '../../../utils';
import { Divider } from '../Divider';
import { EditCurrentTenants } from './EditCurrentTenants';

export const CurrentTenantCard = ({
  primaryTenant,
  secondaryTenants,
  tenantStartDate,
  leaseId,
  canEdit,
  canViewTenantContactDetails,
}) => {
  const TOGGLE_OPTS = [
    {
      label: 'All',
      value: compact([primaryTenant, ...secondaryTenants]),
    },
    {
      label: 'Primary Tenant',
      value: primaryTenant ? [primaryTenant] : [],
    },
    {
      label: 'Secondary Tenants',
      value: [...secondaryTenants],
    },
  ];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [dropdownValue, setDropdownValue] = useState('All');
  const tenants = TOGGLE_OPTS.find(
    (option) => option.label === dropdownValue
  ).value;
  const [isTenantsInfoModalOpen, actions] = useIsOpen();

  const handleDropdownToggle = () => setIsDropdownOpen((isOpen) => !isOpen);

  const toggleEditTenantModal = () => setShow((prev) => !prev);

  const PrimaryBadge = () => (
    <Badge className="justify-end" color="success">
      Primary
    </Badge>
  );
  const SecondaryBadge = () => (
    <Badge
      className="justify-end"
      style={{ color: 'white', backgroundColor: '#FF7D46' }}>
      Other
    </Badge>
  );

  return (
    <CardLight>
      <CardHeader className="bg-white">
        <div className="d-flex justify-content-between align-items-center">
          <CardTitle tag="h5">Tenants</CardTitle>
          {/* eslint-disable-next-line react/jsx-no-bind */}
          <div className="d-flex align-items-center">
            {tenants.length > 0 && canViewTenantContactDetails ? (
              <ButtonIcon
                icon={['far', 'eye']}
                onClick={actions.handleOpen}></ButtonIcon>
            ) : null}
            <Dropdown isOpen={isDropdownOpen} toggle={handleDropdownToggle}>
              <DropdownToggle
                className="small"
                tag="span"
                style={{ cursor: 'pointer' }}
                caret>
                {dropdownValue}
              </DropdownToggle>
              <DropdownMenu>
                {TOGGLE_OPTS.map((option, i) => (
                  <DropdownItem
                    key={`tenant-section-dropdown-item-${i}`}
                    active={option.label === dropdownValue}
                    onClick={() => setDropdownValue(option.label)}>
                    {option.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </CardHeader>
      <CardBody
        className="pt-0"
        style={{ maxHeight: '22em', overflowY: 'auto' }}>
        <Row>
          <Col xs={12}>
            {tenants.length === 0 ? (
              <p className="text-center">There are currently no tenants.</p>
            ) : null}
            {tenants.map((tenant, i) => (
              <React.Fragment key={`tenant-info-${tenant.id}`}>
                {i > 0 ? <Divider /> : null}
                <List type="unstyled mb-0">
                  <li className="mb-2">
                    <Row>
                      <Col>
                        <FontAwesomeIcon
                          icon={['far', 'user']}
                          className="mr-2"
                        />
                        {canViewTenantContactDetails ? (
                          <Link
                            to={`/contacts/tenants/${tenant.id}`}>{`${tenant.firstName} ${tenant.lastName}`}</Link>
                        ) : (
                          `${tenant.firstName} ${tenant.lastName}`
                        )}
                      </Col>
                      <Col
                        xs={3}
                        md={2}
                        lg={4}
                        hidden={dropdownValue !== 'All'}>
                        {i === 0 ? <PrimaryBadge /> : <SecondaryBadge />}
                      </Col>
                    </Row>
                  </li>
                  <li className="mb-2" hidden={!canViewTenantContactDetails}>
                    <span
                      className="d-inline-block text-truncate align-top"
                      style={{ maxWidth: '100%' }}>
                      <FontAwesomeIcon
                        icon={['far', 'envelope']}
                        className="mr-2"
                      />
                      {tenant.email}
                    </span>
                  </li>
                  <li className="mb-3" hidden={!canViewTenantContactDetails}>
                    <FontAwesomeIcon icon={['far', 'phone']} className="mr-2" />
                    <span>
                      {tenant.phoneNumber === '0' ? '-' : tenant.phoneNumber}
                    </span>
                  </li>
                  <li>
                    <span className="small">{`Living in property since: ${formatDate(
                      tenantStartDate
                    )}`}</span>
                  </li>
                </List>
              </React.Fragment>
            ))}
          </Col>
        </Row>
      </CardBody>
      <CardFooter style={{ backgroundColor: 'inherit' }} hidden={!canEdit}>
        <Button color="primary" outline block onClick={toggleEditTenantModal}>
          Edit Tenants
        </Button>
      </CardFooter>

      {show && (
        <EditCurrentTenants
          show={show}
          toggle={toggleEditTenantModal}
          leaseId={leaseId}
        />
      )}
      <TenantsInformationModal
        isOpen={isTenantsInfoModalOpen}
        onClose={actions.handleClose}
        tenant={primaryTenant}
        secondaryTenants={secondaryTenants || []}
      />
    </CardLight>
  );
};

CurrentTenantCard.propTypes = {
  primaryTenant: PropTypes.object,
  secondaryTenants: PropTypes.array,
  tenantStartDate: PropTypes.string,
  leaseId: PropTypes.number,
  canEdit: PropTypes.bool,
  canViewTenantContactDetails: PropTypes.bool,
};
CurrentTenantCard.defaultProps = {
  canEdit: false,
  canViewTenantContactDetails: false,
};
