import { format } from 'date-fns';
import { sumBy } from 'lodash';

import { parseDateIgnoreTz } from '@app/utils/date';

export const formatMonthYear = (date) => {
  const parsed = parseDateIgnoreTz(date);
  return format(parsed, 'MMMM yyyy');
};

export const totalPlatformChargeSum = (platformCharges) =>
  sumBy(platformCharges, 'totalAmountCents');

export const formatTabName = (year) => {
  const yearShort = year.toString().slice(-2);
  const previousYearShort = (year - 1).toString().slice(-2);

  return `FY ${yearShort}/${previousYearShort}`;
};
