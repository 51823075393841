import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import {
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';

import { ButtonDestroy, ButtonSubmit } from '@app/modules/Button';
import { CardLight } from '@app/modules/Card';
import { FormField } from '@app/modules/Form';
import { fetchAgency, selectAgency, updateAgency } from '@app/redux/agency';

export const BillingEmails = ({ agencyId, isLoading }) => {
  const agency = useSelector(selectAgency);
  const billingEmails = agency?.billingEmails
    ? agency.billingEmails.sort()
    : [];

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('Email is required')
        .email('Invalid email address')
        .notOneOf(billingEmails, 'Email is already in the list'),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        updateAgency({
          id: agency.id,
          billingEmails: [...billingEmails, values.email],
        })
      );

      resetForm();
    },
  });

  useEffect(() => {
    if (agencyId) {
      dispatch(fetchAgency({ agencyId }));
    }
  }, [agencyId, dispatch]);

  const handleDeleteEmail = (email) => {
    dispatch(
      updateAgency({
        id: agency.id,
        billingEmails: billingEmails.filter(
          (billingEmail) => billingEmail !== email
        ),
      })
    );
  };

  return (
    <CardLight>
      <CardHeader className="d-flex justify-content-between bg-white border-400">
        <CardTitle className="mb-0 mr-2" tag="h5">
          Accounts Email
        </CardTitle>
      </CardHeader>
      <CardBody className="pb-1">
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col>
                <FormField
                  name="email"
                  placeholder="billings@agency.com"
                  disabled={isLoading}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  append={
                    <ButtonSubmit
                      outline
                      color="secondary"
                      disabled={isLoading}>
                      + Add
                    </ButtonSubmit>
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <ListGroup className="mx-1" flush>
                  {billingEmails.length === 0 && (
                    <ListGroupItem className="text-center">
                      {isLoading ? (
                        <PulseLoader color="#dee2e6" />
                      ) : (
                        'No emails set'
                      )}
                    </ListGroupItem>
                  )}
                  {billingEmails.map((email, index) => {
                    return (
                      <ListGroupItem key={index} className="py-2 pl-1">
                        <Row>
                          <Col>
                            <FontAwesomeIcon
                              icon={['far', 'envelope']}
                              className="mr-2"
                            />
                            {email}
                          </Col>
                          <Col xs={1}>
                            <ButtonDestroy
                              btnSubmit={{ text: 'Delete', color: 'danger' }}
                              className="mx-1"
                              data-testid="button-delete-email"
                              icon={['far', 'trash-alt']}
                              modal={{
                                body: `Remove email from the list?`,
                              }}
                              // eslint-disable-next-line react/jsx-no-bind
                              onConfirm={() => handleDeleteEmail(email)}
                            />
                          </Col>
                        </Row>
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              </Col>
            </Row>
          </form>
        </FormikProvider>
      </CardBody>
    </CardLight>
  );
};

BillingEmails.propTypes = {
  agencyId: PropTypes.number,
  isLoading: PropTypes.bool,
};
