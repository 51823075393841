import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import localStorage from 'store';

import { ButtonIcon } from '@app/modules/Button';
import { Filter } from '@app/modules/Filter';
import { Header } from '@app/modules/Header';
import { Link } from '@app/modules/Link';
import { Pagination } from '@app/modules/Pagination';
import { useRolesContext } from '@app/modules/Profile';
import { PropertyList } from '@app/modules/Property';
import { canCreateProperty as canCreatePropertySelector } from '@app/redux/profile';
import {
  fetchProperties,
  selectIsPropertyLoading,
  selectProperties,
} from '@app/redux/property';
import { toQueryObject } from '@app/utils';

import { AdvancedFilters } from './AdvancedFilters';
import './styles.scss';

const initialFilters = {
  residential: false,
  commercial: false,
  vacant: false,
  leased: false,
  draft: false,
  pendingActivateDueToDeposit: false,
  pendingActivate: false,
  pendingClearance: false,
  active: false,
  missingBankDetails: false,
  invalidBankDetails: false,
  withArchived: false,
};

const PropertiesComponent = ({ history, location }) => {
  const dispatch = useDispatch();
  const params = useMemo(
    () => toQueryObject(location.search),
    [location.search]
  );

  const userType = localStorage.get('userType');
  const agencyId = localStorage.get('agencyId');

  // redux state
  const users = useSelector((state) => state.users);
  const profile = useSelector((state) => state.profile);
  const isLoading = useSelector(selectIsPropertyLoading);
  const properties = useSelector(selectProperties);

  const { isCorporateUser, isManager } = useRolesContext();

  // TODO: create appropriate selectors
  const currentManagerAgency =
    userType === 'manager' &&
    Object.values(users.manager?.data || {})?.find(
      (item) => item?.agency?.id === agencyId
    )?.agency;
  // const manager = getManager(users, params.managerId);
  const canCreateProperty = canCreatePropertySelector(profile);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filterValues, setFilterValues] = useState(initialFilters);

  const isAllFilterValuesFalse = useMemo(() => {
    return Object.keys(filterValues).every((k) => !filterValues[k]);
  }, [filterValues]);

  const isList = params.view === 'list';

  useEffect(() => {
    // update the filterValues local state when the component is rendered.
    let updatedFilterValues = {};
    updatedFilterValues['residential'] = !!(
      params['propertyType'] && params['propertyType'].includes('residential')
    );
    updatedFilterValues['commercial'] = !!(
      params['propertyType'] && params['propertyType'].includes('commercial')
    );
    updatedFilterValues['draft'] = !!(
      params['leaseStatus'] && params['leaseStatus'].includes('draft')
    );
    updatedFilterValues['pendingActivateDueToDeposit'] = !!(
      params['leaseStatus'] &&
      params['leaseStatus'].includes('pendingActivateDueToDeposit')
    );
    updatedFilterValues['pendingActivate'] = !!(
      params['leaseStatus'] && params['leaseStatus'].includes('pendingActivate')
    );
    updatedFilterValues['pendingClearance'] = !!(
      params['leaseStatus'] &&
      params['leaseStatus'].includes('pendingClearance')
    );
    updatedFilterValues['active'] = !!(
      params['leaseStatus'] && params['leaseStatus'].includes('active')
    );
    updatedFilterValues['withArchived'] = !!(
      params['withArchived'] && params['withArchived'] === 'true'
    );
    updatedFilterValues['missingBankDetails'] = !!(
      params['missingBank'] && params['missingBank'] === 'true'
    );
    updatedFilterValues['invalidBankDetails'] = !!(
      params['invalidBank'] && params['invalidBank'] === 'true'
    );

    if (params.managerIds) {
      updatedFilterValues.managerIds = params.managerIds.split(',');
    }

    if (params.agencyIds) {
      updatedFilterValues.agencyIds = params.agencyIds.split(',');
    }

    if (params.address) {
      updatedFilterValues.address = params.address;
    }

    setFilterValues(updatedFilterValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Redirect to Property Show if only 1 property
    if (!canCreateProperty && properties.length === 1) {
      history.push(`/property/${properties[0].id}`);
    }
  }, [canCreateProperty, history, properties]);

  useEffect(() => {
    dispatch(fetchProperties({ ...params }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    params.address,
    params.managerIds,
    params.agencyIds,
    params.managerId,
    params.page,
    params.withArchived,
    params.propertyType,
    params.leaseStatus,
    params.missingBank,
    params.invalidBank,
  ]);

  const handleOnFiltersClick = useCallback(() => {
    setIsFiltersOpen(!isFiltersOpen);
  }, [isFiltersOpen]);

  const handleOnClose = useCallback(() => {
    setIsFiltersOpen(false);
  }, []);

  const filtersToParamsConversion = useCallback(() => {
    let trueFilterValues = Object.keys(filterValues).filter(
      (k) => filterValues[k]
    );
    let propertyType = trueFilterValues.filter(
      (trueFilter) =>
        trueFilter === 'residential' || trueFilter === 'commercial'
    );
    // let archived = trueFilterValues.includes("archived");
    let leaseStatus = trueFilterValues.filter(
      (trueFilter) =>
        trueFilter === 'draft' ||
        trueFilter === 'pendingActivateDueToDeposit' ||
        trueFilter === 'pendingActivate' ||
        trueFilter === 'pendingClearance' ||
        trueFilter === 'active'
    );
    let missingBank = trueFilterValues.includes('missingBankDetails');
    let invalidBank = trueFilterValues.includes('invalidBankDetails');
    let withArchived = trueFilterValues.includes('withArchived');
    const newParams = {
      missingBank: missingBank,
      withArchived: withArchived,
      invalidBank: invalidBank,
    };
    if (propertyType.length > 0)
      newParams['propertyType'] = propertyType.join(',');
    if (leaseStatus.length > 0)
      newParams['leaseStatus'] = leaseStatus.join(',');

    if (filterValues.managerIds) {
      const managerIds = filterValues.managerIds;
      newParams['managerIds'] =
        managerIds.length > 0 ? managerIds.join(',') : managerIds;
    }

    if (filterValues.agencyIds) {
      const agencyIds = filterValues.agencyIds;
      newParams['agencyIds'] =
        agencyIds.length > 0 ? agencyIds.join(',') : agencyIds;
    }

    if (filterValues.address) {
      newParams['address'] = filterValues.address;
    }

    return newParams;
  }, [filterValues]);

  return (
    <Filter
      name="properties"
      isSaved={false}
      isSubmitOnChange={false}
      filterValues={filterValues}
      filtersToParamsConversion={filtersToParamsConversion}>
      <Header title="Properties" isLoading={isLoading}>
        <div className="d-flex">
          {canCreateProperty && !isCorporateUser && (
            <Link
              className="d-flex align-items-center"
              color="primary"
              to="/property/create">
              Add a property
            </Link>
          )}
        </div>
      </Header>
      <Container>
        <div className="property-list-actions-wrapper">
          {isManager && (
            <>
              {isAllFilterValuesFalse ? (
                <ButtonIcon
                  className="text-center justify-content-center"
                  color="primary"
                  style={{ border: '1px solid' }}
                  icon={['fas', 'filter']}
                  outline
                  onClick={handleOnFiltersClick}>
                  Filters
                </ButtonIcon>
              ) : (
                <ButtonIcon
                  className="text-center justify-content-center"
                  color="white"
                  buttonColor="primary"
                  icon={['far', 'filter']}
                  onClick={handleOnFiltersClick}>
                  Filters
                </ButtonIcon>
              )}
            </>
          )}
          <Filter.View name="view" values={['grid', 'list']} />
          <Filter.Search
            data-testid="properties-search"
            label="Enter Street Address"
            name="address"
            isSubmitOnChange={true}
            noOptions={true}
          />
        </div>
        <Row data-testid="property-list">
          <PropertyList isList={isList} />
        </Row>
        {!isLoading && <Pagination className="mt-2" name="properties" />}
        <AdvancedFilters
          isFiltersOpen={isFiltersOpen}
          handleOnClose={handleOnClose}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          filtersToParamsConversion={filtersToParamsConversion}
          defaultParams={initialFilters}
          isCommercialModuleEnabled={
            currentManagerAgency?.commercialModuleEnabled
          }
        />
      </Container>
    </Filter>
  );
};

PropertiesComponent.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export const Properties = memo(PropertiesComponent);
