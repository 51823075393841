import PropTypes from 'prop-types';
import React from 'react';
import { PulseLoader } from 'react-spinners';
import { Table } from 'reactstrap';

import { useSortableData } from '@app/hooks';
import { Pagination } from '@app/modules/Pagination';
import { SortableColumn } from '@app/modules/Task/SortableColumn';

import { InspectionReportListItem } from '..';
import { CardLight } from '../../Card';

export const InspectionReportList = (props) => {
  const {
    handleSortReports,
    sortConfig,
    reports,
    path,
    propertyId,
    userId,
    onDelete,
    onUploaderComplete,
    isLoading,
  } = props;

  const columns = [
    { id: 'inspectionType', name: 'Inspection Type', sortable: true },
    { id: 'date', name: 'Date', sortable: true },
    { id: 'tenant', name: 'Tenant', sortable: true },
    { id: 'status', name: 'Status', sortable: true },
    { id: 'logo', name: '' },
    { id: 'actions', name: 'Actions' },
  ];

  const sortProps = useSortableData(handleSortReports, sortConfig);

  return (
    <div className="mt-4">
      <CardLight className="pt-2 pb-1 px-3 mb-3" data-testid="report-list">
        <Table responsive borderless className="m-0">
          <thead className="border-bottom">
            <tr>
              {columns.map((column) => (
                <th key={column.id} className="border-top-0 px-md-2">
                  {column.sortable ? (
                    <SortableColumn column={column} {...sortProps} />
                  ) : (
                    column.name
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="text-center py-4">
                  <PulseLoader size={12} color="#dee2e6" />
                </td>
              </tr>
            ) : reports.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="text-center py-4">
                  No inspection reports found.
                </td>
              </tr>
            ) : (
              reports.map((report) => (
                <InspectionReportListItem
                  key={`report-${report.id}`}
                  path={path}
                  propertyId={propertyId}
                  report={report}
                  userId={userId}
                  onDelete={onDelete}
                  onUploaderComplete={onUploaderComplete}
                />
              ))
            )}
          </tbody>
        </Table>
      </CardLight>

      <Pagination name="property_inspection_reports" isReload={false} />
    </div>
  );
};

InspectionReportList.propTypes = {
  onDelete: PropTypes.func,
  onUploaderComplete: PropTypes.func,
  path: PropTypes.string,
  propertyId: PropTypes.number,
  reports: PropTypes.array,
  userId: PropTypes.number,
  handleSortReports: PropTypes.func,
  sortConfig: PropTypes.object,
  isLoading: PropTypes.bool,
};

InspectionReportList.defaultProps = {
  path: '',
  reports: [],
  isLoading: false,
};
