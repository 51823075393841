/* eslint-disable react/boolean-prop-naming */
import * as TabsPrimitive from '@radix-ui/react-tabs';
import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

import { cn } from '@app/utils';

import './styles.scss';

const TabsContext = createContext({ variant: 'default' });

export const Tabs = ({ variant = 'line', className, ...props }) => (
  <TabsContext.Provider value={{ variant }}>
    <TabsPrimitive.Root className={cn('TabsRoot', className)} {...props} />
  </TabsContext.Provider>
);

Tabs.propTypes = {
  asChild: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  activationMode: PropTypes.oneOf(['automatic', 'manual']),
  variant: PropTypes.oneOf(['line', 'outline']),
};

export const TabList = ({ className, ...props }) => (
  <TabsPrimitive.List
    className={cn('TabsList text-muted', className)}
    {...props}
  />
);

TabList.propTypes = {
  className: PropTypes.string,
  loop: PropTypes.bool,
};

export const TabTrigger = ({ className, ...props }) => {
  const { variant } = useContext(TabsContext);

  return (
    <TabsPrimitive.Trigger
      className={cn('TabsTrigger', `TabsTrigger--${variant}`, className)}
      {...props}
    />
  );
};

TabTrigger.propTypes = {
  asChild: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export const TabsContent = ({ className, ...props }) => (
  <TabsPrimitive.TabsContent
    className={cn('TabsContent', className)}
    {...props}
  />
);

TabsContent.propTypes = {
  asChild: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  forceMount: PropTypes.bool,
};
