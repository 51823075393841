/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ContentLoader from 'react-content-loader';
import { Button, Card, CardBody, UncontrolledTooltip } from 'reactstrap';

import { UserAvatar, UserStatus } from '@app/modules/User';

export const CardUserProfile = ({ user, onEdit, isLoading, isNotesShown }) => {
  const toolTipRef = useRef();

  const name = `${user.firstName || ''} ${user.lastName || ''}`;
  const agencyNotes = isNotesShown ? user?.agencyNote?.body : null;

  return (
    <Card className="user-profile-card mb-3" data-testid="card-user-profile">
      <CardBody className="d-flex flex-column">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <div className="user-info d-flex align-items-center gap-3 flex-1">
            {isLoading ? (
              <Skeleton />
            ) : (
              <>
                <UserAvatar size={2} user={user} />
                <div className="font-weight-medium text-dark">
                  <div className="d-flex align-items-center gap-3 mb-1">
                    <h5 className="text-capitalize mb-0">{name}</h5>
                    <UserStatus status={user.status} />
                  </div>
                  <div className="text-muted small">{user.email}</div>
                  {/* Validate phone number */}
                  <div className="text-muted small">{user?.phoneNumber}</div>
                </div>
              </>
            )}
          </div>
          <div className="actions-wrapper d-flex flex-column gap-3">
            {!isLoading && (
              <>
                <div className="actions d-flex flex-column flex-md-row justify-content-end gap-3">
                  {onEdit && (
                    <Button color="primary" onClick={onEdit}>
                      Edit
                    </Button>
                  )}
                </div>
                {agencyNotes && (
                  <>
                    <p
                      ref={toolTipRef}
                      className="agency-notes text-small text-muted text-truncate m-0">
                      {agencyNotes}
                    </p>
                    <UncontrolledTooltip
                      id="agency-notes-tooltip"
                      target={toolTipRef}
                      placement="top">
                      {agencyNotes}
                    </UncontrolledTooltip>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

CardUserProfile.propTypes = {
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  isNotesShown: PropTypes.bool,
  onEdit: PropTypes.func,
};

CardUserProfile.defaultProps = {};

const Skeleton = (props) => (
  <ContentLoader viewBox="0 0 300 98" height={98} width="100%" {...props}>
    <circle cx="40" cy="53" r="35" />
    <rect x="98" y="25" rx="4" ry="4" width="254" height="11" />
    <rect x="98" y="45" rx="3" ry="3" width="200" height="9" />
    <rect x="98" y="65" rx="3" ry="3" width="175" height="9" />
  </ContentLoader>
);
