import PropTypes from 'prop-types';
import React from 'react';
import ContentLoader from 'react-content-loader';

export const PropertyAddress = ({
  className,
  isArchived = false,
  postcode,
  state,
  street,
  suburb,
  isLoading,
}) => {
  return (
    <div className={`address ${className}`}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <strong>{street}</strong>
          {isArchived && (
            <small className="text-danger mx-2 pb-1">(Archived)</small>
          )}
          <br />
          {suburb}
          {state && `, ${state}`}
          {postcode && `, ${postcode}`}
        </>
      )}
    </div>
  );
};

PropertyAddress.propTypes = {
  className: PropTypes.string,
  isArchived: PropTypes.bool,
  postcode: PropTypes.string,
  state: PropTypes.string,
  street: PropTypes.string,
  suburb: PropTypes.string,
  isLoading: PropTypes.bool,
};

PropertyAddress.defaultProps = {
  isArchived: false,
};

const Skeleton = (props) => (
  <ContentLoader viewBox="0 0 300 45" height={45} width="100%" {...props}>
    <rect x="0" y="10" rx="4" ry="4" width="254" height="10" />
    <rect x="0" y="30" rx="3" ry="3" width="200" height="8" />
  </ContentLoader>
);
