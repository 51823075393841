import { ERROR, FETCH, FETCH_SUCCESS, UPDATE_STATUS } from './constants';

export const initialState = {
  data: [],
  isLoading: false,
};

export const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    case ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_STATUS:
      const { ids, status } = payload;
      return {
        ...state,
        data: state.data.map((platformCharge) =>
          ids.includes(platformCharge.id)
            ? { ...platformCharge, status }
            : platformCharge
        ),
      };
    default:
      return state;
  }
};
