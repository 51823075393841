import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import * as Yup from 'yup';

import {
  useCreateActivateTasks,
  useDeleteActivationTask,
  useResetActivationTasks,
  useUpdateActivationTask,
} from '@app/modules/LeaseFlex/useLeaseFlex';

import { toCents, toDollarAmount, toDollars } from '../../../utils';
import { ButtonDestroy, ButtonIcon } from '../../Button';
import { FormButtons, FormField, FormLabel } from '../../Form';
import { AddLeaseItem } from './AddLeaseItem';

const validationSchemaForLeaseItem = {
  title: Yup.string().required('Title is required'),
  amountCents: Yup.number()
    .required('Amount is required')
    .min(0, 'Amount must be equal or greater than 0'),
  referenceNumber: Yup.string().matches(/^([A-Za-z0-9\s])*$/, {
    message: 'Payment Reference may only contain letters and numbers',
  }),
};

export const LeaseFormTask = ({
  className,
  onSubmit,
  leaseItems,
  handleActivationTasks,
  propertyId,
  leaseId,
}) => {
  const [isShowAddItem, setIsShowAddItem] = useState(false);
  const [leaseItemEdit, setLeaseItemEdit] = useState();

  const { mutate: deleteTask } = useDeleteActivationTask();
  const { mutate: createActivationTask } = useCreateActivateTasks();
  const { mutate: updateActivationTask } = useUpdateActivationTask();
  const { mutate: resetActivationTasks } = useResetActivationTasks();

  const formik = useFormik({
    displayName: 'LeaseFormTask',
    initialValues: {
      leaseItems,
    },
    validationSchema: () => {
      return Yup.object().shape({
        leaseItems: Yup.array().of(
          Yup.object().shape(validationSchemaForLeaseItem)
        ),
      });
    },
  });
  const { values, setValues, touched, errors, isSubmitting, isValid } = formik;

  const onDeleteTask = useCallback(
    (id) => () => {
      if (id) {
        deleteTask(
          { propertyId, taskId: id },
          {
            onSuccess: () => {
              setValues({
                leaseItems: values.leaseItems.filter((item) => item.id !== id),
              });
            },
          }
        );
      }
    },
    [deleteTask, propertyId, values, setValues]
  );

  useEffect(() => {
    if (values.leaseItems) {
      handleActivationTasks(values.leaseItems);
    }
  }, [values, handleActivationTasks]);

  useEffect(() => {
    setValues({ leaseItems: leaseItems });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseItems]);

  const handleAddActivationTask = useCallback(
    (newLeaseItem) => {
      createActivationTask(
        { propertyId, leaseId, leaseItem: newLeaseItem },
        {
          onSuccess: ({ leaseItems }) => {
            // handleActivationTasks(response.data.leaseItems);
            setValues({ leaseItems });
            setIsShowAddItem(false);
          },
        }
      );
    },
    [createActivationTask, propertyId, leaseId, setValues]
  );

  const cancelEditingLeaseItem = useCallback(
    (leaseItem) => () => {
      if (leaseItem) {
        const changedItems = values.leaseItems.map((item) =>
          item.id === leaseItem.id ? leaseItemEdit : item
        );
        setValues({ leaseItems: changedItems });
        setLeaseItemEdit({});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [leaseItemEdit, values.leaseItems]
  );

  const handleUpdateLeaseItem = useCallback(
    (item) => () => {
      if (item) {
        updateActivationTask(
          {
            propertyId,
            taskId: item.id,
            payload: { leaseId, leaseItem: item },
          },
          {
            onSuccess: ({ leaseItem }) => {
              const changedItems = values.leaseItems.map((item) =>
                item.id === leaseItem.id ? { ...leaseItem } : item
              );
              setValues({ leaseItems: changedItems });
              setLeaseItemEdit({});
            },
            onError: () => {
              cancelEditingLeaseItem();
            },
          }
        );
      }
    },
    [
      updateActivationTask,
      propertyId,
      leaseId,
      values.leaseItems,
      setValues,
      cancelEditingLeaseItem,
    ]
  );

  const handleResetActivationTasks = useCallback(() => {
    resetActivationTasks(
      { propertyId, leaseId },
      {
        onSuccess: ({ leaseItems }) => {
          setValues({ leaseItems });
        },
      }
    );
  }, [leaseId, propertyId, resetActivationTasks, setValues]);

  const editLeaseItem = useCallback(
    (item) => () => {
      if (leaseItemEdit && leaseItemEdit?.id !== item.id) {
        // previous edit was not finished - clear the previous edited data by resetting the values.leaseItems.
        const changedItems = values.leaseItems.map((item) =>
          item.id === leaseItemEdit.id ? leaseItemEdit : item
        );
        setValues({ leaseItems: changedItems });
      }
      setLeaseItemEdit(item);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [leaseItemEdit, values.leaseItems]
  );

  const onChangeAmount = useCallback(
    (id) => (e) => {
      if (id) {
        const changedItems = values.leaseItems.map((item) =>
          item.id === id
            ? { ...item, amountCents: toCents(e.target.value) }
            : item
        );
        setValues({ leaseItems: changedItems });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.leaseItems]
  );

  const onChangeReference = useCallback(
    (id) => (e) => {
      if (id) {
        const changedItems = values.leaseItems.map((item) =>
          item.id === id
            ? {
                ...item,
                referenceNumber: e.target.value,
              }
            : item
        );
        setValues({ leaseItems: changedItems });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.leaseItems]
  );

  const onChangeTitle = useCallback(
    (id) => (e) => {
      if (id) {
        const changedItems = values.leaseItems.map((item) =>
          item.id === id
            ? {
                ...item,
                title: e.target.value,
              }
            : item
        );
        setValues({ leaseItems: changedItems });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.leaseItems]
  );

  const handleShowAddLeaseForm = useCallback(
    (type) => () => {
      type === 'add' ? setIsShowAddItem(true) : setIsShowAddItem(false);
    },
    [setIsShowAddItem]
  );

  const calculateTotalAmount = useMemo(
    () => values.leaseItems.reduce((acc, item) => acc + item.amountCents, 0),
    [values.leaseItems]
  );

  const checkErrorOnLeaseItem = useCallback(
    (index) => {
      const errorObj =
        errors && errors?.leaseItems && errors?.leaseItems[index];
      return errorObj && Object.keys(errorObj).length > 0;
    },
    [errors]
  );

  const checkError = useCallback(
    (type, index) => {
      const leaseItemErrorsObj =
        errors && errors?.leaseItems && errors?.leaseItems[index];
      switch (type) {
        case 'title':
          return leaseItemErrorsObj?.title;
        case 'amountCents':
          return leaseItemErrorsObj?.amountCents;
        case 'referenceNumber':
          return leaseItemErrorsObj?.referenceNumber;
        default:
          return '';
      }
    },
    [errors]
  );

  return (
    <FormikProvider value={formik}>
      <Form className={className}>
        <Row
          className="align-items-center text-center mb-1 no-gutters"
          style={{
            height: '2.5rem',
            lineHeight: '2.5rem',
          }}>
          <Col md={2} className="outgoings-dark-bg h-100" />
          <Col md={3} className="outgoings-dark-bg text-left pl-1">
            <strong>Bill Title</strong>
          </Col>
          <Col md={3} className="outgoings-light-bg border-left border-white">
            <strong>Amount</strong>
          </Col>
          <Col md={3} className="outgoings-light-bg border-left border-white">
            <strong>Payment Reference</strong>
          </Col>
          <Col md={1} className="outgoings-light-bg h-100" />
        </Row>
        <FormGroup style={{ maxHeight: '50vh', overflow: 'auto' }}>
          {values.leaseItems.map(
            ({ id, title, amountCents, referenceNumber }, index) => (
              <Row
                key={`leaseItem-${id}`}
                className="align-items-center mt-3 mb-3 mr-3 no-gutters">
                <Col md={1} className="text-center">
                  <ButtonDestroy
                    btnCancel={{ text: 'Cancel' }}
                    btnSubmit={{ text: 'Delete', color: 'danger' }}
                    className="ml-1 fa-lg text-danger"
                    icon={['far', 'trash-alt']}
                    modal={{
                      title: 'Are you sure?',
                      body: 'This bill will be removed',
                    }}
                    onConfirm={onDeleteTask(id)}
                  />
                </Col>
                <Col md={1} className="text-center">
                  {leaseItemEdit?.id === id ? (
                    <FontAwesomeIcon
                      onClick={cancelEditingLeaseItem({
                        id,
                        title,
                        amountCents,
                        referenceNumber,
                      })}
                      icon={['fas', 'times-circle']}
                      className="fa-lg text-primary"
                      style={{ cursor: 'pointer' }}
                      type="button"
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={editLeaseItem({
                        id,
                        title,
                        amountCents,
                        referenceNumber,
                      })}
                      icon={['fas', 'edit']}
                      className="ml-1 fa-lg text-primary"
                      style={{ cursor: 'pointer' }}
                      type="button"
                    />
                  )}
                </Col>
                <>
                  <Col md={3}>
                    {leaseItemEdit?.id === id ? (
                      <FormField
                        className={`form-control mr-2 ${
                          checkError('title', index) ? 'is-invalid' : ''
                        }`}
                        data-testid={`lease-item-title-${id}`}
                        name="title"
                        placeholder="Title"
                        value={title}
                        onChange={onChangeTitle(id)}
                        errors={checkError('title', index)}
                        touched={
                          touched &&
                          touched.leaseItems &&
                          touched?.leaseItems[index]?.title
                        }
                      />
                    ) : (
                      <FormLabel for="title" id={'tooltipTitleName-' + id}>
                        {title.length > 18 ? title.slice(0, 16) + '...' : title}
                      </FormLabel>
                    )}
                  </Col>
                  {leaseItemEdit?.id !== id && title.length > 18 ? (
                    <UncontrolledTooltip
                      target={'tooltipTitleName-' + id}
                      placement="bottom">
                      {title}
                    </UncontrolledTooltip>
                  ) : null}
                </>
                <Col md={3} className="text-center px-2">
                  {leaseItemEdit?.id === id ? (
                    <FormField
                      min="1"
                      name="amountCents"
                      prepend="$"
                      step="any"
                      type="number"
                      value={toDollarAmount(amountCents)}
                      onChange={onChangeAmount(id)}
                      errors={checkError('amountCents', index)}
                      touched={
                        touched &&
                        touched.leaseItems &&
                        touched?.leaseItems[index]?.amountCents
                      }
                    />
                  ) : (
                    toDollars(amountCents)
                  )}
                </Col>
                <>
                  <Col md={3} className="text-center px-2">
                    {leaseItemEdit?.id === id ? (
                      <FormField
                        className={`form-control ${
                          checkError('referenceNumber', index)
                            ? 'is-invalid'
                            : ''
                        }`}
                        data-testid={`field-reference-number-${id}`}
                        name="referenceNumber"
                        placeholder="Payment Reference"
                        value={referenceNumber}
                        onChange={onChangeReference(id)}
                        errors={checkError('referenceNumber', index)}
                        touched={
                          touched &&
                          touched.leaseItems &&
                          touched?.leaseItems[index]?.referenceNumber
                        }
                      />
                    ) : (
                      <FormLabel
                        for="referenceNumber"
                        id={'tooltipReferenceNumber-' + id}>
                        {referenceNumber.length > 18
                          ? referenceNumber.slice(0, 16) + '...'
                          : referenceNumber}
                      </FormLabel>
                    )}
                  </Col>
                  {leaseItemEdit?.id !== id && referenceNumber.length > 18 ? (
                    <UncontrolledTooltip
                      target={'tooltipReferenceNumber-' + id}
                      placement="bottom">
                      {referenceNumber}
                    </UncontrolledTooltip>
                  ) : null}
                </>
                <Col md={1} className="text-center">
                  <Button
                    className={`w-100 ${
                      leaseItemEdit?.id !== id || checkErrorOnLeaseItem(index)
                        ? 'text-muted border-400'
                        : 'primary'
                    }`}
                    style={{
                      pointerEvents:
                        leaseItemEdit?.id !== id || checkErrorOnLeaseItem(index)
                          ? 'none'
                          : 'all',
                    }}
                    color={
                      leaseItemEdit?.id !== id || checkErrorOnLeaseItem(index)
                        ? 'text-muted'
                        : 'primary'
                    }
                    outline
                    disabled={
                      leaseItemEdit?.id !== id || checkErrorOnLeaseItem(index)
                    }
                    onClick={handleUpdateLeaseItem({
                      id,
                      title,
                      amountCents,
                      referenceNumber,
                    })}>
                    Save
                  </Button>
                </Col>
              </Row>
            )
          )}
        </FormGroup>
        {isShowAddItem && (
          <AddLeaseItem
            handleAddLeaseItem={handleAddActivationTask}
            handleShowAddLeaseForm={handleShowAddLeaseForm('cancel')}
          />
        )}
        <div className="mb-5 d-flex">
          <Col md={5} className="px-1">
            <ButtonIcon
              buttonColor="primary"
              style={{ border: '1px solid' }}
              icon={['fas', 'plus-circle']}
              size="lg"
              className={isShowAddItem ? 'text-muted' : 'white'}
              outline
              disabled={isShowAddItem}
              onClick={handleShowAddLeaseForm('add')}>
              <span className="ml-2 font-weight-bold">Add Bill</span>
            </ButtonIcon>
          </Col>
          {values.leaseItems.length > 0 && (
            <Col className="ml-2">
              Total : {toDollars(calculateTotalAmount)}
            </Col>
          )}
        </div>
        <FormButtons
          btnSubmit={{ text: 'Continue' }}
          btnCancel={{ text: 'Restore Defaults', color: 'danger' }}
          onCancel={handleResetActivationTasks}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          isValid={isValid && !(leaseItemEdit && leaseItemEdit?.id)}
          isFormButtonsJustifyBetween={true}
        />
      </Form>
    </FormikProvider>
  );
};

LeaseFormTask.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  leaseItems: PropTypes.array.isRequired,
  handleActivationTasks: PropTypes.func,
  propertyId: PropTypes.number,
  leaseId: PropTypes.number,
};
