import PropTypes from 'prop-types';
import React from 'react';

export const Recipients = ({ recipients }) => {
  return recipients.map((contact, index) => (
    <span
      key={`${contact}-${index}`}
      className="border rounded px-1 mr-1 text-capitalize">
      {contact}
    </span>
  ));
};

Recipients.propTypes = {
  recipients: PropTypes.array,
};
