import { useFormikContext } from 'formik';
import React from 'react';
import { Col, Row } from 'reactstrap';

import { useLeaseState } from '../../Provider';
import { ModalContentContainer } from './ModalContentContainer';

export const DepositCollection = () => {
  const { leaseState } = useLeaseState();
  const { values } = useFormikContext();

  return (
    <>
      <h3>
        {leaseState?.lease?.canActivate
          ? 'Deposit Paid'
          : `Let's start to collect your deposit`}
      </h3>
      <ModalContentContainer>
        <Row>
          <Col xs={6} className="font-weight-bold">
            Deposit Amount
          </Col>
          <Col xs={6}>
            {values?.deposit ? `$${values?.deposit}` : 'No deposit needed'}
          </Col>
        </Row>
      </ModalContentContainer>
    </>
  );
};

DepositCollection.propTypes = {};
DepositCollection.defaultProps = {};

export default DepositCollection;
