import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { Badge } from 'reactstrap';

import bpayImg from '@app/assets/bpay.svg';
import { PaymentIcon } from '@app/containers/Payments/Walletv2/PaymentMethodItem/PaymentIcon';
import { useIsOpen } from '@app/hooks';
import { ButtonCopyToClipboard, ButtonToggler } from '@app/modules/Button';
import { CardLight } from '@app/modules/Card';
import {
  getPaymentMethod,
  useFetchAccounts,
} from '@app/modules/Payment/hooks/use-assembly';

export const PaymentMethod = ({ params, bpayAccount, virtualAccount }) => {
  const { data: account, isLoading } = useFetchAccounts(params, {
    select: getPaymentMethod,
  });

  const [isOpen, { handleToggle }] = useIsOpen();

  return (
    <CardLight
      className="mt-2 mb-3"
      data-testid="contact-bpay-details"
      title="Payment Method">
      {isLoading ? (
        <div>
          <Skeleton />
          <hr />
          <Skeleton />
        </div>
      ) : (
        <>
          {account && (
            <>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex align-items-center">
                  <PaymentIcon type={account.type || 'bank'} size="2xl" />
                  <strong className="mr-2">
                    {account.type === 'bank' ? account.bankName : 'Credit Card'}
                  </strong>
                  <Badge pill color={'success'}>
                    Active
                  </Badge>
                </div>
                <PaymentMethodDetails
                  label="Name"
                  value={
                    account.type !== 'bank'
                      ? account.fullName
                      : account.accountName
                  }
                />
                <PaymentMethodDetails
                  label="Account Number"
                  value={account.number}
                />
                <div>
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={['fas', 'turtle']}
                    color="#979797"
                    size="lg"
                  />
                  <span className="text-note">
                    {account.type === 'bank'
                      ? '1-3 business days'
                      : '1 business day'}
                  </span>
                </div>
              </div>
              <hr />
            </>
          )}
          <div className="d-flex flex-column gap-2">
            <div className="d-flex align-items-center gap-2">
              <FontAwesomeIcon
                icon={['far', 'money-check-alt']}
                size="2xl"
                color="#142C61"
              />
              <strong>Fast Bank Transfer (OSKO)</strong>
              {!account && virtualAccount?.status === 'active' && (
                <Badge pill className="mr-2" color={'success'}>
                  Active
                </Badge>
              )}
            </div>

            <PaymentMethodDetails label="Bank Name" value="Cuscal" />
            <PaymentMethodDetails
              label="Account Name"
              value={virtualAccount?.accountName}
              canCopyDetails
            />
            <PaymentMethodDetails
              label="BSB"
              value={virtualAccount?.routingNumber}
              canCopyDetails
            />
            <PaymentMethodDetails
              label="Account Number"
              value={virtualAccount?.accountNumber}
              canCopyDetails
            />
            <div>
              <FontAwesomeIcon
                className="mr-2"
                icon={['fas', 'bolt']}
                color="#E4CE0B"
                size="lg"
              />

              <span className="text-note">Instant</span>
            </div>
          </div>

          {isOpen && (
            <>
              <hr />
              <div className="d-flex flex-column gap-2">
                <div className="d-flex align-items-center gap-2">
                  <img src={bpayImg} height="32px" alt="BPAY logo" />
                  <strong>Paying via BPAY</strong>
                </div>
                <PaymentMethodDetails
                  label="Biller Code"
                  value={bpayAccount?.bpayBillerCode}
                  canCopyDetails
                />
                <PaymentMethodDetails
                  label="Ref Number"
                  value={bpayAccount?.bpayReference}
                  canCopyDetails
                />
                <div>
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={['fas', 'turtle']}
                    color="#979797"
                    size="lg"
                  />
                  <span className="text-note">1-3 business days</span>
                </div>
              </div>
            </>
          )}

          <div className="d-flex justify-content-center mt-3">
            <ButtonToggler isActive={isOpen} onClick={handleToggle} isReverse>
              Other payment methods
            </ButtonToggler>
          </div>
        </>
      )}
    </CardLight>
  );
};

PaymentMethod.propTypes = {
  params: PropTypes.object,
  bpayAccount: PropTypes.shape({
    bpayBillerCode: PropTypes.string,
    bpayReference: PropTypes.string,
  }),
  virtualAccount: PropTypes.shape({
    status: PropTypes.string,
    routingNumber: PropTypes.string,
    accountNumber: PropTypes.string,
  }),
};

export const PaymentMethodDetails = ({
  label,
  value,
  canCopyDetails = false,
}) => (
  <div className="d-flex justify-content-between">
    <span>{label}</span>
    {value && (
      <div className="d-flex">
        <span className="text-capitalize">{value}</span>
        {canCopyDetails && (
          <ButtonCopyToClipboard
            className="pl-1 pr-0"
            text={value}
            id={`copy-button-${value}`}
          />
        )}
      </div>
    )}
  </div>
);

PaymentMethodDetails.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  canCopyDetails: PropTypes.bool,
};

const Skeleton = (props) => (
  <>
    <ContentLoader viewBox="0 0 300 26" width="100%" height="26">
      <rect x="0" y="0" rx="3" ry="3" width="60" height="14" />
      <rect x="70" y="0" rx="3" ry="3" width="100" height="12" />
      <rect x="180" y="0" rx="3" ry="3" width="40" height="12" />
    </ContentLoader>

    <ContentLoader viewBox="0 0 300 46" width="100%" height="46">
      <rect x="0" y="0" rx="3" ry="3" width="60" height="12" />
      <rect x="220" y="0" rx="3" ry="3" width="50" height="12" />
      <rect x="280" y="0" rx="3" ry="3" width="20" height="12" />

      <rect x="0" y="20" rx="3" ry="3" width="60" height="12" />
      <rect x="220" y="20" rx="3" ry="3" width="50" height="12" />
      <rect x="280" y="20" rx="3" ry="3" width="20" height="12" />
    </ContentLoader>
  </>
);
