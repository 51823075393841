import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';

export const PaymentVirtualAccountIcon = ({
  virtualAccountStatus,
  isShowProcessingTime,
  className,
  title,
  children,
}) => {
  const showWarningOrError =
    virtualAccountStatus === 'pending_activation' ||
    virtualAccountStatus === 'failed_activation';
  const checkWarningOrErrorStatus = useMemo(() => {
    if (virtualAccountStatus === 'pending_activation') {
      return 'warning';
    } else if (virtualAccountStatus === 'failed_activation') {
      return 'error';
    } else {
      return null;
    }
  }, [virtualAccountStatus]);

  return (
    <div className="d-flex">
      <Row className={`${className} flex-fill`}>
        <Col
          xs={isShowProcessingTime ? 1 : 3}
          xl={isShowProcessingTime ? 1 : 2}>
          <FontAwesomeIcon
            icon={['far', 'money-check-alt']}
            size="4x"
            color="#142C61"
          />
        </Col>
        <Col className="pl-3">
          <Row>
            <Col xs={isShowProcessingTime ? 4 : 12}>
              <strong>{title}</strong>
              {showWarningOrError && (
                <FontAwesomeIcon
                  id={'tooltipVirtualAccountIcon-' + virtualAccountStatus}
                  className={`ml-2 ${
                    checkWarningOrErrorStatus === 'warning'
                      ? 'text-warning'
                      : checkWarningOrErrorStatus === 'error'
                      ? 'text-danger'
                      : null
                  }`}
                  icon={['fas', 'exclamation-triangle']}
                />
              )}
              {showWarningOrError && (
                <UncontrolledTooltip
                  target={'tooltipVirtualAccountIcon-' + virtualAccountStatus}
                  placement="top">
                  {checkWarningOrErrorStatus === 'warning'
                    ? 'This account will complete activating shortly.'
                    : 'Activation failed'}
                </UncontrolledTooltip>
              )}
            </Col>
            {isShowProcessingTime && (
              <Col>
                <span>Processing Time: Instant</span>
              </Col>
            )}
          </Row>
          {children}
        </Col>
      </Row>
    </div>
  );
};

PaymentVirtualAccountIcon.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  virtualAccountStatus: PropTypes.string,
  isShowProcessingTime: PropTypes.bool,
};

PaymentVirtualAccountIcon.defaultProps = {
  title: 'Direct Payment',
};
