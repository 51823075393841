import { useQuery } from '@tanstack/react-query';
import capitalize from 'lodash/fp/capitalize';

import { httpClient } from '@app/utils';

export const QUERY_KEYS = {
  FETCH_PROFILE: 'fetchProfile',
};

const fetchProfile = async () => {
  try {
    const response = await httpClient.get('user/profile');

    if (response?.data?.user) {
      const profile = response?.data?.user;

      const accountName = `${capitalize(profile?.firstName) || ''} ${
        capitalize(profile?.lastName) || ''
      }`;

      return {
        ...response?.data?.user,
        accountName,
      };
    }

    return {};
  } catch (error) {
    console.error('error: ', error);
    throw new Error('Something went wrong');
  }
};

export const useProfile = () => {
  return useQuery([QUERY_KEYS.FETCH_PROFILE], fetchProfile, {
    retry: false,
    staleTime: 180000,
    refetchOnMount: 'always',
  });
};
