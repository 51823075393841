import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import { useLocationParams, usePrevious } from '@app/hooks';
import { AuthLayout } from '@app/modules/AuthLayout';
import { FormConfirmPassword, FormResetPassword } from '@app/modules/Form';
import { hideAlert } from '@app/redux/notifier';
import {
  confirmPassword,
  getMessage,
  getProfile,
  isAuthorized,
  requestAuthySMS,
  resetPassword,
} from '@app/redux/profile';

const ResetPasswordComponent = ({
  confirmPassword,
  isAuthorized,
  message,
  resetPassword,
}) => {
  const history = useHistory();

  const prevIsAuthorized = usePrevious(isAuthorized);
  const params = useLocationParams();
  const userEmail = params?.email;

  useEffect(() => {
    if (!prevIsAuthorized && isAuthorized) {
      hideAlert();
    }
  }, [isAuthorized, prevIsAuthorized]);

  const handleBackToLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const hasResetPasswordToken = params.resetPasswordToken;

  if (isAuthorized) return <Redirect to="/" />;

  return (
    <>
      {hasResetPasswordToken ? (
        <AuthLayout
          header="Change Password"
          subHeader="Create a new password to login.">
          <FormConfirmPassword
            buttonText="Update Password"
            hasTerms={false}
            resetPasswordToken={params.resetPasswordToken}
            isAuthyEnabled={params.isAuthyEnabled === 'true'}
            email={userEmail}
            onSubmit={confirmPassword}
          />
        </AuthLayout>
      ) : message ? (
        <AuthLayout
          header="Reset Link Sent!"
          subHeader="We've sent a link to reset your password."
          hasCheckIcon
          isAlertVisible={false}>
          <Button block color="primary" onClick={handleBackToLogin}>
            Continue to login
          </Button>
        </AuthLayout>
      ) : (
        <AuthLayout
          header="Password Recovery"
          subHeader="Enter your email to retrieve your password.">
          <FormResetPassword message={message} onSubmit={resetPassword} />
        </AuthLayout>
      )}
    </>
  );
};

ResetPasswordComponent.propTypes = {
  confirmPassword: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  hideAlert: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  resetPassword: PropTypes.func.isRequired,
  requestAuthySMS: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const user = getProfile(state.profile);

  return {
    isAuthorized: isAuthorized(state.profile),
    message: getMessage(state.profile),
    user,
  };
};

const mapDispatchToProps = {
  confirmPassword,
  hideAlert,
  requestAuthySMS,
  resetPassword,
};

export const ResetPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordComponent);
