import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { hideAlert } from '@app/redux/notifier';
import {
  enableAuthy,
  logoutUser,
  selectProfileData,
  selectProfileEmail,
  selectProfilePhoneNumber,
} from '@app/redux/profile';
import { obfuscatePhoneNumber } from '@app/utils/obfuscateParams';

import { AuthLayout } from '../AuthLayout';
import Form from '../SmsVerification/Form';
import useSendSecurityCode from '../SmsVerification/use-send-security-code';
import { Enable2FAConfirmation } from './Enable2FAConfirmation';
import { Enable2FAOverview } from './Enable2FAOverview';
import './styles.scss';

const STEPS = {
  overview: 'OVERVIEW',
  verification: 'VERIFICATION',
  confirmation: 'CONFIRMATION',
};

export const Enable2FA = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(selectProfileEmail);
  const phoneNumber = useSelector(selectProfilePhoneNumber);
  const { isEnable2faOnLoginEnabled, isEnableAuthySuccess } =
    useSelector(selectProfileData);
  const [step, setStep] = useState(STEPS.overview);

  const { mutate, isLoading, isSuccess } = useSendSecurityCode();

  useEffect(() => {
    if (isEnableAuthySuccess) {
      setStep(STEPS.confirmation);
    }
  }, [isEnableAuthySuccess]);

  const requestSecurityCode = useCallback(() => {
    mutate({ email: userEmail });
  }, [mutate, userEmail]);

  const verify = useCallback(
    ({ securityCode }) => {
      dispatch(hideAlert());
      dispatch(enableAuthy({ authyToken: securityCode }));
    },
    [dispatch]
  );

  const logout = useCallback(() => dispatch(logoutUser()), [dispatch]);

  const verifyPhoneNumber = useCallback(() => {
    setStep(STEPS.verification);
    requestSecurityCode();
  }, [requestSecurityCode]);

  const maskedNumber = obfuscatePhoneNumber(phoneNumber || '');

  const renderModalContent = () => {
    const { overview, verification, confirmation } = STEPS;

    switch (step) {
      case verification:
        return (
          <AuthLayout
            header="Enter Verification Code"
            subHeader={`Please enter the verification code we sent to ${maskedNumber}.`}
            isAlertVisible={false}>
            <Form
              onSubmit={verify}
              isRequestingSecurityCode={isLoading}
              requestSecurityCode={requestSecurityCode}
              isSecurityCodeSuccess={isSuccess}
              useDefaultTheme
            />
          </AuthLayout>
        );
      case confirmation:
        return (
          <AuthLayout
            header="Two-Factor Authentication (2FA) Enabled"
            subHeader="Your account is now protected with 2FA. Next time you log in, you’ll need to enter a verification code sent to your mobile number."
            hasCheckIcon>
            <Enable2FAConfirmation />
          </AuthLayout>
        );
      case overview:
      default:
        return (
          <AuthLayout header="Enable Two-Factor Authentication (2FA)">
            <Enable2FAOverview
              verifyPhoneNumber={verifyPhoneNumber}
              phoneNumber={phoneNumber}
              logout={logout}
            />
          </AuthLayout>
        );
    }
  };

  if (!isEnable2faOnLoginEnabled) {
    return <Redirect to="/" />;
  }

  return <>{renderModalContent()}</>;
};

Enable2FA.propTypes = {
  modalState: PropTypes.object,
  toggle: PropTypes.func,
};
