import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { InputField } from '@app/modules/Form/FormikFields';

import { ModalContentContainer } from './ModalContentContainer';

const RentalBond = () => (
  <>
    <h3>Rental bond</h3>
    <ModalContentContainer>
      <Row>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label for="bondNumber">Bond ID</Label>
            <InputField name="bondNumber" />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label for="bondDollars">Bond amount required</Label>
            <InputField name="bondDollars" type="number" prepend="$" />
          </FormGroup>
        </Col>
      </Row>
    </ModalContentContainer>
  </>
);

export default RentalBond;
