/* eslint-disable react/jsx-no-bind */
import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import './styles.scss';

export const Otp = ({ containerClassName = 'py-4' }) => {
  const { values, setFieldValue } = useFormikContext();

  const [focusedIndex, setFocusedIndex] = useState(null);
  const inputRefs = useRef([]);

  // Auto-focus the first input on load
  useEffect(() => {
    if (inputRefs && inputRefs?.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleKeyDown = useCallback(
    (e, index) => {
      if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== 'Backspace' &&
        e.key !== 'Delete' &&
        e.key !== 'Tab' &&
        !e.metaKey
      ) {
        e.preventDefault();
      }

      if (e.key === 'Delete' || e.key === 'Backspace') {
        setFieldValue(
          'securityCode',
          values.securityCode.substring(0, index) +
            values.securityCode.substring(index + 1)
        );

        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      }
    },
    [setFieldValue, values.securityCode]
  );

  const handleInput = useCallback(
    (e, index) => {
      const { value } = e.target;
      if (/^[0-9]$/.test(value)) {
        const newCode =
          values.securityCode.substring(0, index) +
          value +
          values.securityCode.substring(index + 1);

        setFieldValue('securityCode', newCode);

        if (index < 5) {
          inputRefs.current[index + 1].focus();
        }
      }
    },
    [setFieldValue, values.securityCode]
  );

  const handleFocus = useCallback((e, index) => {
    e.target.select();
    setFocusedIndex(index);
  }, []);

  const handlePaste = useCallback(
    (e) => {
      e.preventDefault();
      const text = e.clipboardData.getData('text');
      if (!new RegExp(`^[0-9]{6}$`).test(text)) {
        return;
      }

      setFieldValue('securityCode', text);

      const lastIndex = text.length - 1;
      inputRefs.current[lastIndex].focus();
      setFocusedIndex(lastIndex);
    },
    [setFieldValue]
  );

  const handleBlur = useCallback(() => {
    setFocusedIndex(null);
  }, []);

  return (
    <section className={containerClassName}>
      <div className="d-flex">
        {[...Array(6)].map((_, index) => (
          <Field key={index} name={`securityCode[${index}]`}>
            {({ field }) => (
              <input
                {...field}
                type="text"
                maxLength={1}
                value={values.securityCode[index] || ''}
                ref={(el) => (inputRefs.current[index] = el)}
                placeholder={focusedIndex === index ? '' : '-'}
                className="otp-input form-control text-center border rounded shadow-sm mx-1"
                onChange={(e) => handleInput(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onFocus={(e) => handleFocus(e, index)}
                onBlur={handleBlur}
                onPaste={handlePaste}
              />
            )}
          </Field>
        ))}
      </div>
    </section>
  );
};

Otp.propTypes = {
  containerClassName: PropTypes.string,
};
