/* eslint-disable react/jsx-no-bind */
import { add } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, Row } from 'reactstrap';

import { useToggle } from '@app/hooks';
import { ButtonIcon } from '@app/modules/Button';
import { ModalConfirm } from '@app/modules/Modal';
import { useRolesContext } from '@app/modules/Profile';
import { selectLeasesByCategory, updateLease } from '@app/redux/lease';
import { centsToDollar, daysBetween, formatDate } from '@app/utils';
import { getDailyRate, toDollars } from '@app/utils/currency';
import { fullMonthsInBetween } from '@app/utils/date';

import { canCreateLease as canCreateLeaseSelector } from '../../../redux/profile';
import { useHardTerminateLease } from '../useLeaseFlex';

export const LeaseAlerts = ({
  lease,
  handleCancelTermination,
  handleEditLease,
}) => {
  const dispatch = useDispatch();

  const canCreateLease = useSelector(({ profile }) =>
    canCreateLeaseSelector(profile)
  );
  const { active } = useSelector((state) =>
    selectLeasesByCategory(state, lease.propertyId)
  );
  const [modalState, toggleModal] = useToggle();
  const { mutate: hardTerminateLease } = useHardTerminateLease();

  const { isManager, isTenant } = useRolesContext();

  const {
    id: leaseId,
    terminationDate,
    terminationReason,
    depositCents,
    payFrequency,
    amountCents,
    startDate,
    // bools
    isTerminating,
    isExpired,
    isDraft,
    isPendingActivate,
    hasDepositIntention,
  } = lease;
  const rentPaidUpUntil = lease?.rentPaidUpUntil || startDate;

  const owedAmount = useMemo(() => {
    const dailyRate = amountCents?.['annually']
      ? getDailyRate('yearly', amountCents['annually'])
      : 0;
    let fullPayments = 0;
    let extraDaysToPay = 0;

    switch (payFrequency) {
      case 'monthly':
        fullPayments = fullMonthsInBetween(terminationDate, rentPaidUpUntil);
        extraDaysToPay = daysBetween(
          terminationDate,
          add(new Date(rentPaidUpUntil), {
            months: fullPayments,
          })
        );

        return (
          fullPayments * amountCents['monthly'] + extraDaysToPay * dailyRate
        );
      case 'weekly':
        fullPayments = Math.trunc(
          daysBetween(terminationDate, rentPaidUpUntil) / 7
        );
        extraDaysToPay = daysBetween(terminationDate, rentPaidUpUntil) % 7;

        return (
          fullPayments * amountCents['weekly'] + extraDaysToPay * dailyRate
        );
      case 'fortnightly':
        fullPayments = Math.trunc(
          daysBetween(terminationDate, rentPaidUpUntil) / 14
        );
        extraDaysToPay = daysBetween(terminationDate, rentPaidUpUntil) % 14;

        return (
          fullPayments * amountCents['fortnightly'] + extraDaysToPay * dailyRate
        );
      default:
        return 1;
    }
  }, [amountCents, payFrequency, rentPaidUpUntil, terminationDate]);

  const handleRemoveLease = () => {
    dispatch(updateLease({ id: leaseId, depositCents: 0 }));
  };

  const handleTerminateLease = () => {
    hardTerminateLease({ leaseId });
    toggleConfirmTermination();
  };

  const toggleConfirmTermination = useCallback(() => {
    toggleModal({ isOpen: !modalState.isOpen, callback: null });
  }, [modalState.isOpen, toggleModal]);

  return (
    <>
      {!!active?.length && isPendingActivate && !isTenant ? (
        <Alert color="warning">
          Unable to activate lease since the property already has an active
          lease
        </Alert>
      ) : null}
      {depositCents && isDraft ? (
        <Alert color="warning">
          {hasDepositIntention
            ? 'The deposit needs to be paid before we can activate this lease.'
            : 'The deposit needs to be requested and paid before we can activate this lease.'}
          <div className="d-flex justify-content-between text-muted pt-2 text-small">
            <span>Deposit: {centsToDollar(lease?.depositCents)}</span>
            {canCreateLease && (
              <div>
                <Button
                  className="text-decoration-underline text-muted text-small"
                  color="link"
                  onClick={handleRemoveLease}>
                  Remove
                </Button>
                <Button
                  className="text-small"
                  color="link"
                  onClick={handleEditLease}>
                  Edit
                </Button>
              </div>
            )}
          </div>
        </Alert>
      ) : null}
      {isTerminating && !isExpired && (
        <Row className="pt-0 px-3">
          <Col
            xs={12}
            className="d-flex justify-content-between align-items-center alert alert-danger">
            <span>
              Terminating on <strong>{formatDate(terminationDate)}</strong>
              <br />
              {!isTenant && (
                <>
                  <small>Reason: {terminationReason}</small>
                  <br />
                </>
              )}
              <small>Rent owing till vacate: ${toDollars(owedAmount)}</small>
            </span>
            {isManager && (
              <>
                <ButtonIcon
                  icon={['far', 'ban']}
                  onClick={handleCancelTermination}>
                  Cancel Termination
                </ButtonIcon>
                <ButtonIcon
                  icon={['far', 'check']}
                  onClick={toggleConfirmTermination}>
                  Complete Termination
                </ButtonIcon>
              </>
            )}
          </Col>
        </Row>
      )}
      <ModalConfirm
        isOpen={modalState.isOpen}
        size="md"
        btnCancel={{ text: 'Close' }}
        btnSubmit={{ text: 'Confirm' }}
        onCancel={toggleConfirmTermination}
        onSubmit={handleTerminateLease}>
        <h5>Complete termination for lease?</h5>
        <hr />
        <p>
          This lease is currently scheduled to automatically terminate on{' '}
          <b>{terminationDate}</b>.
          <br />
          <br />
          Confirming this action will complete termination and mark this lease
          as terminated/expired.
        </p>
      </ModalConfirm>
    </>
  );
};

LeaseAlerts.propTypes = {
  lease: PropTypes.object,
  handleCancelTermination: PropTypes.func,
  handleEditLease: PropTypes.func,
};
LeaseAlerts.defaultProps = {
  handleCancelTermination: null,
  handleEditLease: null,
};
