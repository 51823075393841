import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import startCase from 'lodash/fp/startCase';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, CardBody, CardText, Container } from 'reactstrap';

import { CardWalletBasic } from '@app/modules/Card/Wallet';
import { Link } from '@app/modules/Link';
import { PaymentTransaction } from '@app/modules/Payment';
import { PropertyUserIcon } from '@app/modules/Property';
import { TabList, TabTrigger, Tabs, TabsContent } from '@app/modules/Tabs';
import {
  adjustIntention,
  destroyIntention,
  fetchIntentionsCompleted,
  fetchIntentionsPayable,
  fetchIntentionsPayablePastLeases,
} from '@app/redux/intention';
import { fetchLease, getLease } from '@app/redux/lease';
import { hasError as hasErrorSelector, showAlert } from '@app/redux/notifier';
import { getPagination } from '@app/redux/pagination';
import { canApplyCredit as canApplyCreditSelector } from '@app/redux/profile';
import { fetchProperty } from '@app/redux/property';
import { fetchTenant } from '@app/redux/users';
import { centsToDollar, formatDate } from '@app/utils';

import { useIntentionPaymentStatus } from './useIntentionPaymentStatus';
import useIntentions, { PAGE_SIZE } from './useIntentions';

const FREQUENCY_SHOW_TOOLTIP = ['weekly_withdrawal', 'monthly_withdrawal'];

export const PropertyTransactions = ({ history, location, property }) => {
  const dispatch = useDispatch();
  // const toolTipRef = useRef();

  // redux state
  const payablePagination = useSelector((state) =>
    getPagination(state.pagination, 'details')
  );

  const payablePastLeasePagination = useSelector((state) =>
    getPagination(state.pagination, 'details_past_leases')
  );

  const completedPagination = useSelector((state) =>
    getPagination(state.pagination, 'intentions')
  );

  const queryParams = new URLSearchParams(location.search);
  const leaseId = parseInt(queryParams.get('leaseId') || property.leaseId);
  const fromList = queryParams.get('fromList');
  const lease = useSelector((state) => getLease(state.lease, leaseId));
  const canApplyCredit = useSelector((state) =>
    canApplyCreditSelector(state.profile)
  );
  const hasError = useSelector((state) => hasErrorSelector(state));

  const isLatestLease = leaseId === property.leaseId;

  const primaryTenantId = lease?.primaryTenant?.id;

  const [payablePastLeaseState, { onLoadMore: onLoadMorePayablePastLeases }] =
    useIntentions({
      type: 'payablePastLeases',
      property,
      leaseId,
    });

  const [payableState, { onLoadMore: onLoadMorePayable }] = useIntentions({
    property,
    leaseId,
  });

  const [completedState, { onLoadMore: onLoadMoreCompleted }] = useIntentions({
    type: 'completed',
    property,
    leaseId,
  });

  // check if theres intention processing
  const processingIntention = useMemo(
    () =>
      payableState.list.length
        ? payableState.list.find((a) => a.isProcessing)
        : undefined,
    [payableState.list]
  );

  const { isSuccess, data } = useIntentionPaymentStatus(
    processingIntention?.id
  );

  useEffect(() => {
    if (isSuccess && data) {
      if (data.status === 'completed') {
        // calling updated property will trigger refetch of payable/completed intentions
        dispatch(fetchProperty({ propertyId: property.id }));
        dispatch(
          showAlert({
            color: 'success',
            message: `<strong>Success:</strong> ${
              data.message || 'Your payment is completed.'
            }`,
          })
        );
      } else if (data.status === 'processing') {
        dispatch(
          showAlert({
            color: 'warning',
            message:
              'A payment is currently processing. You will be able to process further payments as soon as it completes.',
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch, isSuccess, payableState.list]);

  const handleRemoveIntention = useCallback(
    (intentionId, taskId) =>
      dispatch(
        destroyIntention({
          intentionId,
          taskId,
          leaseId: lease.id,
          propertyId: property.id,
          currentPage: payableState.page,
        })
      ),
    [dispatch, lease.id, property.id, payableState.page]
  );

  const handleSubmit = useCallback(
    ({ intentionId, ...values }) =>
      dispatch(
        adjustIntention({
          ...values,
          intentionId,
          leaseId: lease.id,
          propertyId: property.id,
        })
      ),
    [dispatch, lease.id, property.id]
  );

  // Side effects

  useEffect(() => {
    if (leaseId && !lease.id) {
      dispatch(fetchLease({ leaseId }));
    }
  }, [dispatch, lease.id, leaseId]);

  useEffect(() => {
    if (lease && lease?.primaryTenant && primaryTenantId) {
      dispatch(fetchTenant({ tenantId: primaryTenantId }));
    }
  }, [dispatch, lease, primaryTenantId]);

  const tenant = useSelector(
    (state) => state.users.tenant.data[lease.primaryTenant?.id]
  );

  const handleClickPayment = useCallback(
    (property, intention) => {
      const url = `/payments/${intention.id}?leaseId=${intention.leaseId}&propertyId=${property.id}`;

      history.push(url);
    },
    [history]
  );

  useEffect(() => {
    if (property.id) {
      const intentionProps = {
        ...(!isLatestLease && { leaseId }),
        propertyId: property.id,
        property,
        page: 1,
        perPage: PAGE_SIZE,
      };

      // NOTE: Initial load for payable is 5,
      // BE limitation: per_page should be fixed value
      dispatch(fetchIntentionsPayable(intentionProps));
      dispatch(fetchIntentionsPayablePastLeases(intentionProps));
      dispatch(fetchIntentionsCompleted(intentionProps));
    }
  }, [dispatch, isLatestLease, leaseId, property, property.id]);

  const showTooltip = FREQUENCY_SHOW_TOOLTIP.includes(
    property?.primaryOwner?.withdrawalFrequency
  );

  const withdrawalFrequency = property?.primaryOwner?.withdrawalFrequency
    ? property.primaryOwner.withdrawalFrequency.split('_')[0]
    : 'instant';

  const tooltipContent = showTooltip
    ? `Funds are being held till the end of the ${withdrawalFrequency.slice(
        0,
        withdrawalFrequency.length - 2
      )}. Funds that are not needed for bills will be paid out to the owner on the first day of the new ${withdrawalFrequency.slice(
        0,
        withdrawalFrequency.length - 2
      )}.`
    : null;

  return (
    <>
      <Tabs defaultValue="outstanding">
        <div className="mb-3 bg-white border-top">
          <Container>
            <TabList>
              <TabTrigger value="outstanding">Outstanding</TabTrigger>
              <TabTrigger value="completed">All Completed</TabTrigger>
            </TabList>
          </Container>
        </div>

        <Container>
          {!isLatestLease ? (
            <Alert color="warning">
              <Link
                className="text-secondary mb-3"
                to={`/property/${property.id}/lease?${
                  fromList ? 'fromList=true' : `leaseId=${leaseId}`
                }`}>
                <FontAwesomeIcon icon={['far', 'chevron-left']} />
                &nbsp;Back to Lease
              </Link>
              <div className="d-flex justify-content-between h6-font-size mb-3">
                <div>
                  <p className="mb-0">
                    <strong>
                      You are viewing the transactions for a terminated lease
                    </strong>
                  </p>
                  <p className="mb-0">
                    Start Date: {formatDate(lease?.startDate)}
                  </p>
                  <p className="mb-0">
                    Termination Date: {formatDate(lease?.terminationDate)}
                  </p>
                  <p className="mb-0">
                    <span>
                      {`${startCase(lease.payFrequency)} Rent`}:{' '}
                      {lease.amountDollars &&
                        lease.amountDollars[lease.payFrequency]}
                    </span>
                  </p>
                </div>
                {lease.primaryTenant && (
                  <div className="text-right">
                    <strong>Tenant for this lease</strong>
                    <PropertyUserIcon
                      user={lease.primaryTenant}
                      role="tenant"
                    />
                  </div>
                )}
                {lease.secondaryTenants &&
                  lease.secondaryTenants.length > 0 && (
                    <div className="text-right">
                      <strong>Secondary tenants</strong>
                      {lease.secondaryTenants.map((tenant, index) => (
                        <PropertyUserIcon
                          key={index}
                          user={tenant}
                          role="tenant"
                        />
                      ))}
                    </div>
                  )}
              </div>
            </Alert>
          ) : (
            <div className="d-flex flex-column flex-md-row gap-3 mb-2">
              <Card className="h5-font-size w-100 mb-2">
                <CardBody className="p-3">
                  <CardText className="text-small mb-1">
                    {`${startCase(lease.payFrequency)} Rent`}
                  </CardText>
                  <CardText tag="h3">
                    {lease.amountDollars &&
                      lease.amountDollars[lease.payFrequency]}
                  </CardText>

                  <small className="d-block">
                    {lease.rentPaidUpUntil && (
                      <span>
                        Paid up until: {formatDate(lease.rentPaidUpUntil)}
                        {lease.arrears?.rentOverdueDays > 0 && (
                          <span className="text-danger">
                            {' '}
                            ({lease.arrears?.rentOverdueDays}{' '}
                            {pluralize('day', lease.arrears?.rentOverdueDays)}{' '}
                            overdue)
                          </span>
                        )}
                      </span>
                    )}
                  </small>
                </CardBody>
              </Card>
              <CardWalletBasic
                label={showTooltip ? 'Funds being held' : 'Wallet Balance'}
                amountCents={property.floatBalanceAmountCents}
                isLoading={false}
                className="w-100"
                tooltipContent={tooltipContent}>
                {property.paysViaRent && (
                  <span className="text-white">
                    Required: {centsToDollar(property.floatCents)}
                  </span>
                )}
              </CardWalletBasic>
            </div>
          )}

          <TabsContent value="outstanding">
            <Tabs
              defaultValue={isLatestLease ? 'active-leases' : 'past-leases'}
              variant="outline">
              <TabList>
                <TabTrigger value="active-leases" disabled={!isLatestLease}>
                  Active Leases
                </TabTrigger>
                {payablePastLeaseState.list.length > 0 && (
                  <TabTrigger value="past-leases">Past Leases</TabTrigger>
                )}
              </TabList>
              <TabsContent value="active-leases">
                <PaymentTransaction
                  canApplyCredit={isLatestLease && canApplyCredit}
                  intentions={payableState.list}
                  message="No upcoming payments."
                  hasError={hasError}
                  property={property}
                  onClickPayment={handleClickPayment}
                  onClickRemove={handleRemoveIntention}
                  onSubmit={handleSubmit}
                  isLoading={payableState.initialLoad}
                  tenantWalletBalance={tenant?.walletBalanceAmountCents}
                />
                <LoadMoreButton
                  loadMore={onLoadMorePayable}
                  isFetching={payableState.isFetching}
                  canLoadMore={
                    !!(
                      payableState.list.length &&
                      payableState.list.length < payablePagination.total
                    )
                  }
                />
              </TabsContent>
              <TabsContent value="past-leases">
                <PaymentTransaction
                  canApplyCredit={isLatestLease && canApplyCredit}
                  intentions={payablePastLeaseState.list}
                  message="No upcoming payments."
                  hasError={hasError}
                  property={property}
                  onClickPayment={handleClickPayment}
                  onClickRemove={handleRemoveIntention}
                  onSubmit={handleSubmit}
                  isLoading={payablePastLeaseState.initialLoad}
                  tenantWalletBalance={tenant?.walletBalanceAmountCents}
                />
                <LoadMoreButton
                  loadMore={onLoadMorePayablePastLeases}
                  isFetching={payablePastLeaseState.isFetching}
                  canLoadMore={
                    !!(
                      payablePastLeaseState.list.length &&
                      payablePastLeaseState.list.length <
                        payablePastLeasePagination.total
                    )
                  }
                />
              </TabsContent>
            </Tabs>
          </TabsContent>
          <TabsContent value="completed">
            <PaymentTransaction
              isCompleted={true}
              intentions={completedState.list}
              message="No completed payments."
              title="Completed"
              canApplyCredit={canApplyCredit}
              hasError={hasError}
              property={property}
              isLoading={completedState.initialLoad}
            />

            <LoadMoreButton
              loadMore={onLoadMoreCompleted}
              isFetching={completedState.isFetching}
              canLoadMore={
                !!(
                  completedState.list.length &&
                  completedState.list.length < completedPagination.total
                )
              }
            />
          </TabsContent>
        </Container>
      </Tabs>
    </>
  );
};

PropertyTransactions.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
};

export default memo(PropertyTransactions);

// TODO: extract this component
const LoadMoreButton = ({
  isFetching = false,
  canLoadMore = false,
  loadMore,
}) => {
  if (!canLoadMore) {
    return null;
  }

  return (
    <div className="d-flex justify-content-center mb-5">
      <Button color="primary" onClick={loadMore} disabled={isFetching}>
        {isFetching ? 'Loading...' : 'Load more'}
      </Button>
    </div>
  );
};

LoadMoreButton.propTypes = {
  isFetching: PropTypes.bool,
  canLoadMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
};
