import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: 'hsl(0,0%,80%)',
  marginBottom: 8,
  marginTop: 8,
  width: 1,
};

const IndicatorSeparator = ({ innerProps }) => (
  <span style={indicatorSeparatorStyle} {...innerProps} />
);

IndicatorSeparator.propTypes = {
  innerProps: PropTypes.object,
};

export const SearchIndicator = ({ children, ...props }) => (
  <components.Control {...props}>
    <span className="px-2">
      <FontAwesomeIcon icon={['far', 'search']} />
    </span>
    <IndicatorSeparator />
    {children}
  </components.Control>
);

SearchIndicator.propTypes = {
  children: PropTypes.node,
};
