import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardText } from 'reactstrap';

import { CardLight } from '@app/modules/Card';
import AccountDetails from '@app/modules/Payment/AccountDetails';

export const VirtualAccountCard = ({
  title,
  data,
  isLoading,
  children,
  className,
  badge,
}) => {
  const cardBodyContent = (
    <CardBody>
      <CardText className="text-center">
        Use your internet banking to transfer payment to the account below.
        <br />
        You can also set up recurring scheduled transfers to pay your wallet
        automatically.
        <br />
        These account details are unique to your wallet.
      </CardText>

      <AccountDetails
        accountName={data?.accountName}
        code={data?.routingNumber}
        refNumber={data?.accountNumber}
        isLoading={isLoading}
      />

      <CardText>
        <strong>Important:</strong>
        <br />
        Please note, your bank may show a warning message about the very first
        payment you make to a new BSB.
        <br />
        This is a <strong>standard practice</strong> and we encourage you to
        double check the details are above.
        <br />
        After your first payment, all future payments will be instant.
        <br />
        The Australian banking institutions has introduced the Scam Safe Accord.
        This is a fantastic step towards improved security for Australian
        consumers.
      </CardText>

      {children}
    </CardBody>
  );

  return title ? (
    <CardLight className={className} title={title} badge={badge}>
      {cardBodyContent}
    </CardLight>
  ) : (
    <Card className={className}>{cardBodyContent}</Card>
  );
};

VirtualAccountCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  badge: PropTypes.object,
};

VirtualAccountCard.defaultProps = {
  title: '',
  isLoading: false,
};

export default VirtualAccountCard;
