import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';

import { formatDate } from '@app/utils';

export const LogItem = ({ auditedAt, action }) => {
  return (
    <Row className="d-flex align-items-end text-right mb-1">
      <Col className="d-flex flex-row-reverse align-items-center">
        <div>
          <span>
            <small>
              <strong>{action}&nbsp;</strong>
              <span>at {formatDate(auditedAt, 'shortWithTime')}</span>
            </small>
          </span>
        </div>
      </Col>
    </Row>
  );
};

LogItem.propTypes = {
  auditedAt: PropTypes.string,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

LogItem.defaultProps = {
  auditedAt: '',
  action: '',
};
