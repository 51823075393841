import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';

export const HeaderComponent = ({ isMobile, toggleAdd, showAdd, items }) => {
  const AddButton = () => (
    <Button
      outline
      type="button"
      color="secondary"
      onClick={toggleAdd}
      disabled={showAdd}>
      + Add
    </Button>
  );

  if (isMobile)
    return (
      <Row className="d-flex justify-content-end m-0 mb-3">
        <AddButton />
      </Row>
    );

  return (
    <Row className="m-0 mb-3">
      {items.map(({ title, size, className }) => (
        <Col key={`table-header-${title}`} md={size} className={className}>
          {title}
        </Col>
      ))}
      <Col md={2}>
        <AddButton />
      </Col>
    </Row>
  );
};

HeaderComponent.propTypes = {
  isMobile: PropTypes.bool,
  toggleAdd: PropTypes.func.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  showAdd: PropTypes.bool,
  items: PropTypes.array,
};
HeaderComponent.defaultProps = {
  isMobile: false,
  showAdd: false,
  items: [],
};
