import PropTypes from 'prop-types';
import React from 'react';
import {
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';

// input without form integration
export const LocalInput = ({
  append,
  prepend,
  error,
  label,
  isMobile,
  isEdit,
  name,
  ...props
}) => (
  <FormGroup>
    {isMobile && label && <Label for={name}>{label}</Label>}
    <InputGroup>
      {prepend && (
        <InputGroupAddon addonType="prepend">{prepend}</InputGroupAddon>
      )}
      <Input
        disabled={!isEdit}
        name={name}
        {...props}
        invalid={!!error && isEdit}
      />
      {append && <InputGroupAddon addonType="append">{append}</InputGroupAddon>}
      {error && isEdit && <FormFeedback invalid>{error}</FormFeedback>}
    </InputGroup>
  </FormGroup>
);

LocalInput.propTypes = {
  name: PropTypes.string.isRequired,
  append: PropTypes.string,
  prepend: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  isMobile: PropTypes.bool,
  isEdit: PropTypes.bool,
};

LocalInput.defaultProps = {
  append: '',
  prepend: '',
  error: '',
  label: '',
  isMobile: false,
  isEdit: false,
};
