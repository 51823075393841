/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useToggle } from '@app/hooks';
import { ButtonIcon } from '@app/modules/Button';

import { PURCHASE_HISTORY_INITIAL_FILTER } from '../constants';
import '../styles.scss';
import { AdvancedFilterModal } from './AdvancedFilterModal';

export const Filter = ({ filters, setFilters }) => {
  const [modalState, toggleModalState] = useToggle();

  const hasAppliedFilters = useMemo(() => {
    return (
      JSON.stringify(filters) !==
      JSON.stringify(PURCHASE_HISTORY_INITIAL_FILTER)
    );
  }, [filters]);

  const toggleFilterModal = () => {
    toggleModalState();
  };

  return (
    <div
      className={`d-flex justify-content-between flex-md-row flex-column flex-wrap p-3 sms-filter`}>
      <ButtonIcon
        className="text-center justify-content-center"
        {...(hasAppliedFilters
          ? {
              color: 'white',
              buttonColor: 'primary-alt',
            }
          : {
              color: 'primary-alt',
              outline: true,
              style: { border: '1px solid' },
            })}
        icon={['fas', 'filter']}
        onClick={toggleFilterModal}>
        Filters
      </ButtonIcon>
      {modalState.isOpen && (
        <AdvancedFilterModal
          filters={filters}
          setFilters={setFilters}
          modalState={modalState}
          toggleFilterModal={toggleFilterModal}
        />
      )}
    </div>
  );
};

Filter.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};
