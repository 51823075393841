import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { showAlert } from '../../../redux/notifier';
import { httpClient } from '../../../utils';

export const QUERY_KEYS = {
  FETCH_ACCOUNTS: 'fetchAccounts',
  SET_DISBURSEMENT: 'setDisbursment',
  SET_DEFAULT_PAYMENT: 'setDefaultPayment',
  GENERATE_BANK_TOKEN: 'generateBankToken',
  GENERATE_CARD_TOKEN: 'generateCardToken',
  ADD_BANK_ACCOUNT: 'addBankAccount',
  ADD_CREDIT_CARD: 'addCreditCard',
  REMOVE_ACCOUNT: 'removeAccount',
  ENABLE_PAYMENTS: 'enablePayments',
  VERIFY_BANK_ACCOUNT: 'verifyBankAccount',
};

const fetchAccounts = async (params = {}) => {
  try {
    const response = await httpClient.get('assembly/list-accounts', {
      params,
    });

    return response?.data || {};
  } catch (error) {
    console.error('error: ', error);
    throw new Error('Something went wrong');
  }
};

const setDisbursementAccount = async ({ callback, ...payload }) => {
  try {
    const response = await httpClient.post(
      'assembly/set-disbursement-account',
      payload
    );

    return { data: response?.data, callback };
  } catch (error) {
    console.log('error', error);
    throw new Error('Something went wrong');
  }
};

const setDefaultPayment = async ({ callback, ...payload }) => {
  try {
    const response = await httpClient.post('assembly/set-default', payload);

    return { data: response?.data, callback };
  } catch (error) {
    console.log('error', error);
    throw new Error('Something went wrong');
  }
};

const createTokenCard = async (params) => {
  try {
    const response = await httpClient.get('assembly/generate-token/card', {
      params,
    });

    return response?.data;
  } catch (error) {
    console.error('error', error);
    throw new Error('Something went wrong');
  }
};

const createTokenBank = async (params) => {
  try {
    const response = await httpClient.get('assembly/generate-token/bank', {
      params,
    });

    return response?.data;
  } catch (error) {
    console.error('error', error);
    throw new Error('Something went wrong');
  }
};

const addBankAccount = async ({ callback, ...payload }) => {
  try {
    const response = await httpClient.post(
      'assembly/add-bank-account',
      payload
    );

    return {
      data: response?.data,
      callback,
    };
  } catch (error) {
    console.error('error', error);
    throw new Error('Something went wrong');
  }
};

/**
 * enablePayments
 *
 * @param {Object} params
 * @param {number} params.amountCents
 * @param {string} params.fingerprint
 * @param {string} params.promisepayId
 * @return {*}
 */
const enablePayments = async (params) => {
  try {
    const response = await httpClient.post(
      'assembly/add-promisepay-dda',
      params
    );

    return {
      data: response?.data,
    };
  } catch (error) {
    console.error('error', error);
    throw new Error('Something went wrong');
  }
};

const addCreditCard = async ({ callback, ...payload }) => {
  try {
    const response = await httpClient.post('assembly/add-credit-card', payload);

    return {
      data: response?.data,
      callback,
    };
  } catch (error) {
    console.error('error', error);
    throw new Error('Something went wrong');
  }
};

const removeAccount = async ({ type, promisepayId, ...params }) => {
  try {
    const response = await httpClient.delete(
      `assembly/account/${promisepayId}`,
      {
        params,
      }
    );

    return response?.data;
  } catch (error) {
    console.error('error', error);
    throw new Error('Something went wrong');
  }
};

export const getMaskedAccounts = (data) => {
  if (!data) return [];
  return [
    {
      name: 'Fast Bank Transfer (OSKO)',
      maskedValue: '',
      type: 'wallet',
      isDefault: data?.isPayByBpay,
      isVirtual: true,
    },
    ...data.bank.map((bank) => ({
      ...bank,
      name: bank.bankName,
      maskedValue:
        bank.accountNumber && bank.routingNumber
          ? `${bank.routingNumber} ${bank.accountNumber}`.replace(/X/g, '•')
          : '',
      type: 'bank',
    })),
    ...data.card.map((card) => ({
      ...card,
      name: card.fullName,
      maskedValue: card.number.replace(/X/g, '•').replace(/-/g, ' '),
    })),
  ];
};

export const getPaymentMethod = (data) => {
  if (!data) return null;

  if (data.hasDefaultPayment && !data.isPayByBpay) {
    const defaultAccount = [...data.bank, ...data.card].find(
      (account) => account.isDefault
    );

    if (defaultAccount) {
      if (!defaultAccount.type) {
        const accountNumber =
          defaultAccount.accountNumber && defaultAccount.routingNumber
            ? `${defaultAccount.routingNumber} ${defaultAccount.accountNumber}`
            : '';
        return {
          ...defaultAccount,
          type: 'bank',
          number: accountNumber.replace(/X/g, '•'),
        };
      }

      return {
        ...defaultAccount,
        number: defaultAccount.number.replace(/X/g, '•'),
      };
    }
  }

  return null;
};

export const getDisbursementAccount = (data, other) => {
  if (!data || !data.disbursementAccount) return null;

  return {
    ...data.disbursementAccount,
    routingNumber: data.disbursementAccount.routingNumber
      .replace(/X/g, '•')
      .replace(/-/g, ' '),
    accountNumber: data.disbursementAccount.accountNumber
      .replace(/X/g, '•')
      .replace(/-/g, ' '),
  };
};

export const useFetchAccounts = (params, useQueryOptions = {}) => {
  const queryKeys =
    params?.type && params?.id
      ? [QUERY_KEYS.FETCH_ACCOUNTS, params.type, params.id]
      : [QUERY_KEYS.FETCH_ACCOUNTS];

  return useQuery(queryKeys, () => fetchAccounts(params), {
    retry: false,
    staleTime: 180000,
    refetchOnMount: 'always',
    ...useQueryOptions,
  });
};

export const useSetDisbursementAccount = () => {
  return useMutation(setDisbursementAccount, {
    mutationKey: QUERY_KEYS.SET_DISBURSEMENT,
    onSuccess: async ({ data, callback }) => {
      if (callback) {
        callback();
      }

      return data;
    },
  });
};

export const useSetDefaultPaymentAccount = () => {
  return useMutation(setDefaultPayment, {
    mutationKey: QUERY_KEYS.SET_DEFAULT_PAYMENT,
    onSuccess: async ({ data, callback }) => {
      if (callback) {
        callback();
      }

      return data;
    },
  });
};

export const useCreateTokenCard = (query) => {
  return useQuery(
    [QUERY_KEYS.GENERATE_BANK_TOKEN],
    () => createTokenCard(query),
    {
      retry: false,
      enabled: false,
    }
  );
};

export const useCreateTokenBank = (query) => {
  return useQuery(
    [QUERY_KEYS.GENERATE_BANK_TOKEN],
    () => createTokenBank(query),
    {
      retry: false,
      enabled: false,
    }
  );
};

export const useAddBankAccount = () => {
  const dispatch = useDispatch();

  return useMutation(addBankAccount, {
    mutationKey: QUERY_KEYS.ADD_BANK_ACCOUNT,
    onSuccess: ({ data, callback }) => {
      if (callback) {
        callback();
      }

      dispatch(
        showAlert({
          color: 'success',
          message: 'Bank Account added',
        })
      );

      return data;
    },
  });
};

export const useAddCreditCard = () => {
  const dispatch = useDispatch();

  return useMutation(addCreditCard, {
    mutationKey: QUERY_KEYS.ADD_CREDIT_CARD,
    onSuccess: ({ data, callback }) => {
      if (callback) {
        callback();
      }

      dispatch(
        showAlert({
          color: 'success',
          message: 'Credit Card added',
        })
      );

      return data;
    },
  });
};

export const useRemoveAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(removeAccount, {
    mutationKey: QUERY_KEYS.REMOVE_ACCOUNT,
    onMutate: async ({ promisepayId: toDelete, type }) => {
      await queryClient.cancelQueries({
        queryKey: [QUERY_KEYS.FETCH_ACCOUNTS],
      });

      queryClient.setQueryData([QUERY_KEYS.FETCH_ACCOUNTS], (old) => {
        const data = type === 'bank' ? old.bank : old.card;
        const updatedData = data.map(({ promisepayId, ...rest }) => {
          return {
            ...rest,
            promisepayId,
            ...(promisepayId === toDelete && { isDeleting: true }),
          };
        });

        return { ...old, [type]: updatedData };
      });
    },
  });
};

// add-promisepay-dda
export const useEnablePayments = () => {
  return useMutation(enablePayments, {
    mutationKey: QUERY_KEYS.ENABLE_PAYMENTS,
  });
};

const verifyBankAccount = async ({ id, verificationToken }) => {
  const payload = { verificationToken };
  try {
    const response = await httpClient.post(
      `assembly/promisepay_bank_accounts/${id}/verify`,
      payload
    );
    return response?.data;
  } catch (error) {
    console.error('error', error);
    throw new Error('Something went wrong');
  }
};

export const useVerifyBankAccount = (id, verificationToken) => {
  return useMutation(verifyBankAccount, {
    mutationKey: QUERY_KEYS.VERIFY_BANK_ACCOUNT,
  });
};
