import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export const TooltipIcon = ({ content, label, icon }) => {
  const toolTipRef = useRef();

  return (
    <>
      <span ref={toolTipRef} className="text-left ml-1">
        <FontAwesomeIcon icon={icon} />
      </span>
      <UncontrolledTooltip
        id={`${label}-tooltip`}
        target={toolTipRef}
        placement="top">
        {content}
      </UncontrolledTooltip>
    </>
  );
};

TooltipIcon.propTypes = {
  content: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.array,
};

TooltipIcon.defaultProps = {
  icon: ['fas', 'circle-question'],
};
