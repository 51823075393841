import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import bpayImg from '@app/assets/bpay.svg';
import { PaymentCardIcon } from '@app/modules/Payment/CardIcon';

import { PAYMENT_METHOD_TYPES } from './constants';

export const PaymentIcon = ({ type, size }) => {
  if (!type) return null;

  switch (type) {
    case 'bank':
      return (
        <FontAwesomeIcon
          icon={['far', 'university']}
          className="text-dark mr-2"
          size={size}
        />
      );
    case 'wallet':
      return (
        <FontAwesomeIcon
          icon={['far', 'right-left']}
          className="text-dark mr-2"
          size={size}
        />
      );
    case 'bpay':
      return <img src={bpayImg} className="mr-2" width="18" alt="BPAY icon" />;
    case 'card':
    default:
      return (
        <PaymentCardIcon
          cardType={type}
          className="mr-2 text-dark"
          size={size}
        />
      );
  }
};

PaymentIcon.propTypes = {
  type: PropTypes.oneOf(PAYMENT_METHOD_TYPES),
  size: PropTypes.string,
};

export default PaymentIcon;
