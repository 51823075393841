import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import Item from './Item';
import { PAYMENT_METHOD_TYPES } from './constants';

const Basic = ({ type, name, accountNumber, info }) => {
  return (
    <div className="payment-method-item">
      <Item type={type} name={name} accountNumber={accountNumber} />
      <div className="info-container">
        <FontAwesomeIcon
          className="mr-2"
          icon={['fas', 'turtle']}
          color="#979797"
          size="lg"
        />
        <span className="text-note">{info}</span>
      </div>
    </div>
  );
};

Basic.propTypes = {
  type: PropTypes.oneOf(PAYMENT_METHOD_TYPES),
  name: PropTypes.string,
  accountNumber: PropTypes.string,
  info: PropTypes.string,
};

Basic.defaultProps = {
  info: '',
};

export default Basic;
