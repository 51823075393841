import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';
import toast from 'react-hot-toast';

import { httpClient } from '@app/utils';

export const QUERY_KEYS = {
  CREATE_BULK_SMS: 'createBulkSms',
  FETCH_BULK_SMS: 'fetchBulkSms',
  SEND_BULK_SMS: 'sendBulkSms',
  FETCH_BULK_SMS_LIST: 'fetchBulkSmsList',
  FETCH_REQUIRED_CREDITS: 'fetchRequiredCredits',
  UPDATE_BULK_SMS: 'updateBulkSms',
  DELETE_DRAFT_BULK_SMS: 'deleteDraftBulkSms',
};

const createBulkSms = async (payload) => {
  try {
    const response = await httpClient.post('sms/bulk_sms_messages', payload);

    return response?.data;
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

const fetchBulkSms = async ({ id, ...params }) => {
  try {
    const response = await httpClient.get(`sms/bulk_sms_messages/${id}`, {
      params,
    });

    return response?.data?.bulkSmsMessage || {};
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

const fetchBulkSmsList = async (payload) => {
  try {
    const response = await httpClient.get('sms/bulk_sms_messages', {
      params: payload,
    });

    return response?.data?.bulkSmsMessages || [];
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};
const sendBulkSms = async (payload) => {
  try {
    const response = await httpClient.post(
      'sms/bulk_sms_messages/send',
      payload
    );

    return response?.data;
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

const fetchRequiredCredits = async ({ params }) => {
  try {
    const response = await httpClient.get(
      'sms/bulk_sms_messages/retrieve-required-credits',
      { params }
    );

    return response?.data;
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

const updateBulkSms = async ({ id, ...payload }) => {
  try {
    const response = await httpClient.patch(
      `sms/bulk_sms_messages/${id}`,
      payload
    );

    return response?.data;
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

const deleteDraftBulkSms = async ({ id, ...params }) => {
  try {
    const response = await httpClient.delete(`sms/bulk_sms_messages/${id}`, {
      params,
    });

    return response?.data;
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

export const useDeleteDraftBulkSms = () => {
  return useMutation(deleteDraftBulkSms, {
    mutationKey: QUERY_KEYS.DELETE_DRAFT_BULK_SMS,
    onSuccess: () => {
      toast.success('Message deleted successfully', { duration: 2000 });
    },
  });
};

export const useCreateBulkSms = () => {
  return useMutation(createBulkSms, {
    mutationKey: QUERY_KEYS.CREATE_BULK_SMS,
    onSuccess: () => {
      toast.success(
        <div>
          <span>
            <strong>Message saved as draft!</strong> Your message has been saved
            as draft and can be sent later
          </span>
        </div>,
        { duration: 2000 }
      );
    },
  });
};

export const useFetchBulkSms = ({ params }) => {
  return useQuery(
    [QUERY_KEYS.FETCH_BULK_SMS, params?.id],
    () => fetchBulkSms(params),
    {
      enabled: !!params?.id && !!params?.agencyId,
      staleTime: 60000,
      refetchOnMount: 'always',
    }
  );
};
export const useSendBulkSms = () => {
  return useMutation(sendBulkSms, {
    mutationKey: QUERY_KEYS.SEND_BULK_SMS,
    onSuccess: () => {
      toast.success(
        <div>
          <span>
            <strong>Message sent successfully!</strong> Your message has been
            successfully processed and delivered
          </span>
        </div>,
        { duration: 2000 }
      );
    },
  });
};

export const useFetchRequiredCredits = ({ params }) => {
  return useQuery(
    [QUERY_KEYS.FETCH_REQUIRED_CREDITS],
    () =>
      fetchRequiredCredits({
        params,
      }),
    {
      enabled: false,
      retry: false,
    }
  );
};

export const useFetchBulkSmsList = ({ params }) => {
  return useQuery(
    [QUERY_KEYS.FETCH_BULK_SMS_LIST],
    () => fetchBulkSmsList(params),
    {
      enabled: !!params?.agencyId,
      staleTime: 60000,
      retry: false,
      refetchOnMount: 'true',
    }
  );
};

export const useUpdateBulkSms = () => {
  return useMutation(updateBulkSms, {
    mutationKey: QUERY_KEYS.UPDATE_BULK_SMS,
    onSuccess: () => {
      toast.success(
        <div>
          <span>
            <strong>Message saved successfully!</strong>
          </span>
        </div>,
        { duration: 2000 }
      );
    },
  });
};
