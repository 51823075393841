import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Badge, Button, Col, Container, Row } from 'reactstrap';

import { ExternalCreditorRecommendations } from '@app/modules/ExternalCreditor';
import { Link } from '@app/modules/Link';
import { useMarketplaceTagsToString } from '@app/modules/Marketplace';
import { UserStatus } from '@app/modules/User';
import { validatePhoneNumber } from '@app/utils';

import {
  EXTERNAL_CREDITOR_CLASSIFICATIONS,
  selectUser,
  sendInvite,
} from '../../redux/users';
import { useTogglePreferredExternalCreditor } from './hooks/useExternalCreditor';

export const ExternalCreditorHeader = ({ tradie }) => {
  const history = useHistory();
  const tags = useMarketplaceTagsToString(tradie.tagIds);
  const { agency } = useSelector(selectUser);
  const { mutate: togglePreferred, isLoading } =
    useTogglePreferredExternalCreditor();
  const dispatch = useDispatch();

  const isContactPage = history.location.pathname.includes(
    '/contacts/tradies/tradie/'
  );

  const isTradie =
    tradie?.classification === EXTERNAL_CREDITOR_CLASSIFICATIONS.tradie;

  const handleClickBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const isLinkedToAgency = useMemo(() => {
    if (tradie.agencies.length && agency?.id) {
      return tradie.agencies.some(({ id }) => agency.id === id);
    }

    return false;
  }, [agency, tradie]);

  const isPreferredByUserAgency = useMemo(() => {
    if (tradie.preferredAgencies.length && agency) {
      return tradie.preferredAgencies.some((preferredAgency) => {
        return preferredAgency.id === agency.id;
      });
    }

    return false;
  }, [tradie, agency]);

  const { value, isValid, error } = validatePhoneNumber({
    mobileNumber: tradie?.primaryContactMobile,
  });

  const handleSendInvite = useCallback(() => {
    dispatch(sendInvite({ role: 'externalCreditor', user: tradie }));
  }, [dispatch, tradie]);

  const handleTogglePreferred = useCallback(() => {
    togglePreferred({
      externalCreditorId: tradie?.id,
      agencyId: agency?.id,
      preferred: !isPreferredByUserAgency,
    });
  }, [togglePreferred, tradie, agency, isPreferredByUserAgency]);

  return (
    <div className="py-3 bg-white">
      <Container>
        <div className="mb-3 d-flex justify-content-between">
          <Link className="p-0" onClick={handleClickBack}>
            <FontAwesomeIcon className="mr-1" icon={['far', 'chevron-left']} />
            Back
          </Link>
          <div className="d-flex">
            {!tradie.isOnboarded && (
              <Button
                className="mr-3"
                color="secondary"
                data-testid="contact-send-invite-btn"
                disabled={false}
                size="sm"
                onClick={handleSendInvite}>
                {`${tradie.isInvited ? 'Res' : 'S'}end Invitation`}
              </Button>
            )}
            {isLinkedToAgency && (
              <Button
                onClick={handleTogglePreferred}
                className="mr-3"
                color="primary"
                size="sm"
                outline>
                {isLoading ? (
                  <FontAwesomeIcon
                    className="text-primary"
                    icon={['far', 'spinner']}
                    spin
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={[isPreferredByUserAgency ? 'fas' : 'far', 'star']}
                    className={'text-warning mr-2'}
                  />
                )}
                {`${isPreferredByUserAgency ? 'Remove' : 'Add'} as preferred`}
              </Button>
            )}
            {isTradie && (
              <Link
                className="text-capitalize"
                color="primary"
                size="sm"
                to={`/marketplace/tradie/${tradie.id}/financials`}>
                Financials
              </Link>
            )}
          </div>
        </div>
        <Row className="align-items-stretch">
          <Col xs={12} lg={6}>
            <h2>{tradie.promisepayUserPromisepayCompanyLegalName}</h2>
            <h3 className="font-weight-light">{tradie.name}</h3>
            {!!tags.length && <p>Tags: {tags}</p>}
            <h5 className="text-muted font-weight-lighter">
              {startCase(tradie.typeOf)}
            </h5>
            {isContactPage && <UserStatus status={tradie.status} />}
            {!tradie.isDisbursementAccountSet && (
              <Badge className={isContactPage ? `ml-2` : ''} color="danger">
                <FontAwesomeIcon
                  className="mr-2"
                  icon={['far', 'exclamation-triangle']}
                />
                Missing bank
              </Badge>
            )}
            {!tradie.isOnboarded && tradie.isInvited && (
              <UserStatus status="invited" />
            )}
          </Col>
          <Col xs={12} lg={6} className="d-flex flex-column text-right">
            <p className="mb-0">
              {tradie.primaryContactEmail && (
                <span className="d-inline-block text-nowrap mb-2">
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={['far', 'envelope']}
                    size="lg"
                  />
                  <a
                    href={`mailto:${tradie.primaryContactEmail}`}
                    className="btn-link">
                    {tradie.primaryContactEmail}
                  </a>
                </span>
              )}
              {isValid ? (
                <span className="d-inline-block text-nowrap mb-2 ml-3">
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={['far', 'phone']}
                    size="lg"
                  />
                  <a href={`tel:${value}`} className="btn-link">
                    {value}
                  </a>
                </span>
              ) : (
                <>
                  <br />
                  {error}
                </>
              )}
            </p>
            {!!tradie.preferredAgencies.length && (
              <div className="mt-4 mb-2">
                <p className="text-muted mb-1">Preferred by</p>
                <div className="row justify-content-end mx-0">
                  {tradie.preferredAgencies.map((agency) => (
                    <small
                      key={agency.id}
                      className="col-auto badge badge-pill badge-light bg-gray-200 text-muted ml-2">
                      {agency.tradingName}
                    </small>
                  ))}
                </div>
              </div>
            )}
          </Col>
        </Row>
        <hr />
        <ExternalCreditorRecommendations tradie={tradie} />
      </Container>
    </div>
  );
};

ExternalCreditorHeader.propTypes = {
  tradie: PropTypes.object,
};
