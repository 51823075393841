import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import { Modal } from 'reactstrap';

export const LoadingComponent = ({ isLoading }) => {
  const isNotifierLoading = useSelector((state) => state.notifier.isLoading);

  const isOpen = isNotifierLoading || isLoading || false;

  const modalOnClosed = useCallback(() => {
    document.body.classList.remove('modal-open');
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      onClosed={modalOnClosed}
      contentClassName="border-0 bg-transparent text-center">
      <h4 className="text-white mb-4">Processing</h4>
      <PulseLoader size={25} color="#dee2e6" />
      <span className="text-white mt-3">Please do not refresh the page</span>
    </Modal>
  );
};

LoadingComponent.propTypes = {
  isLoading: PropTypes.bool,
};

export const Loading = LoadingComponent;
