/* eslint-disable react/jsx-no-bind */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import { useToggle } from '@app/hooks';
import { ButtonIcon } from '@app/modules/Button';

import { AdvancedFilterModal } from './AdvancedFilterModal';
import { INITIAL_FILTER } from './constants';

export const Filter = ({ filters, setFilters }) => {
  const [modalState, toggleModalState] = useToggle();

  const hasAppliedFilters = useMemo(() => {
    return JSON.stringify(filters) !== JSON.stringify(INITIAL_FILTER);
  }, [filters]);

  const toggleFilterModal = () => {
    toggleModalState();
  };

  return (
    <div className="p-3 bulk-sms-filter">
      <ButtonIcon
        className="text-center justify-content-center"
        {...(hasAppliedFilters
          ? {
              color: 'white',
              buttonColor: 'primary-alt',
            }
          : {
              color: 'primary-alt',
              outline: true,
              style: { border: '1px solid' },
            })}
        icon={['fas', 'filter']}
        onClick={toggleFilterModal}>
        Filters
      </ButtonIcon>
      <InputGroup>
        <Input
          type="text"
          className="bulk-sms-search"
          placeholder="Search by message"
          name="search"
          onChange={({ currentTarget }) =>
            setFilters((state) => ({
              ...state,
              search: currentTarget.value,
            }))
          }
          value={filters.search}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText className="py-1">
            <FontAwesomeIcon icon={['far', 'search']} />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      {modalState.isOpen && (
        <AdvancedFilterModal
          filters={filters}
          setFilters={setFilters}
          modalState={modalState}
          toggleFilterModal={toggleFilterModal}
        />
      )}
    </div>
  );
};

Filter.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};
Filter.defaultProps = {};
