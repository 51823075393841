/* eslint-disable react/jsx-no-bind */
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';

import { SearchIndicator } from '@app/modules/Common';

import { FormLabel } from '..';

export const TypeaheadSelect = ({
  selectedOption,
  isLoading,
  isRequired,
  isSearchable,
  isClearable,
  name,
  options,
  disabled,
  onKeyDown,
  onSelect,
  label,
  placeholder,
}) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const selectedValue = selectedOption
      ? options.find(
          ({ value: optionValue }) => optionValue === selectedOption.value
        )
      : '';

    setSearchValue(selectedValue);
  }, [selectedOption, options]);

  const onClear = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleChange = useCallback(
    (values, action) => {
      if (action.action === 'clear') {
        onClear();
      } else onSelect(values);
    },
    [onSelect, onClear]
  );

  const debouncedSearch = useCallback(debounce(onKeyDown, 1000), [onKeyDown]);

  const handleKeyDown = useCallback(
    (event) => {
      event.stopPropagation();

      if (event.target && event.target.value.length > 1 && onKeyDown) {
        debouncedSearch(event.target.value);
      }
    },
    [debouncedSearch, onKeyDown]
  );

  return (
    <>
      {label && (
        <FormLabel for={name} isRequired={isRequired}>
          {label}
        </FormLabel>
      )}
      <Select
        name={name}
        onChange={handleChange}
        onKeyDown={onKeyDown && handleKeyDown}
        onFocus={(event) => {
          onClear();
          event.target.select();
        }}
        options={options}
        placeholder={placeholder}
        value={searchValue}
        defaultValue={null}
        isDisabled={disabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        {...(isSearchable && { components: { Control: SearchIndicator } })}
        blurInputOnSelect
      />
    </>
  );
};

TypeaheadSelect.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onSelect: PropTypes.func,
  onKeyDown: PropTypes.func,
  options: PropTypes.array,
  selectedOption: PropTypes.object,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
};

TypeaheadSelect.defaultProps = {
  disabled: false,
  options: [],
  onKeyDown: () => {},
  isLoading: false,
  isRequired: false,
  isSearchable: true,
  isClearable: true,
};
