import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

export const InspectionReportDownloadLink = (props) =>
  props.link && (
    <a
      className={props.className}
      href={props.link}
      target="_blank"
      rel="noopener noreferrer">
      {props.showIcon && (
        <FontAwesomeIcon
          icon={['far', 'cloud-download']}
          className="text-success mr-2"
        />
      )}
      <span>{props.text || 'Download'}</span>
    </a>
  );

InspectionReportDownloadLink.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  showIcon: PropTypes.bool,
};

InspectionReportDownloadLink.defaultProps = {
  className: 'btn btn-link text-nowrap',
  showIcon: true,
};
