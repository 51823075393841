import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'reactstrap';

import { Link } from '@app/modules/Link';

import { Divider } from '../../Divider';

export const CurrentTenantInformation = ({
  primaryTenant,
  secondaryTenants,
}) => {
  const tenants = compact([primaryTenant, ...secondaryTenants]);

  return tenants?.length ? (
    tenants.map((tenant, i) => (
      <React.Fragment key={`tenant-info-${tenant.id}`}>
        {i > 0 ? <Divider /> : null}
        <List type="unstyled mb-0">
          <li className="mb-2">
            <FontAwesomeIcon icon={['far', 'user']} className="mr-2" />
            <Link
              to={`/contacts/tenants/${tenant.id}`}>{`${tenant.firstName} ${tenant.lastName}`}</Link>
          </li>
          <li className="mb-2">
            <FontAwesomeIcon icon={['far', 'envelope']} className="mr-2" />
            <span>{tenant.email}</span>
          </li>
          <li className="mb-3">
            <FontAwesomeIcon icon={['far', 'phone']} className="mr-2" />
            <span>{tenant.phoneNumber === '0' ? '-' : tenant.phoneNumber}</span>
          </li>
        </List>
      </React.Fragment>
    ))
  ) : (
    <p className="text-center">There are currently no tenants.</p>
  );
};

CurrentTenantInformation.propTypes = {
  primaryTenant: PropTypes.object,
  secondaryTenants: PropTypes.array,
};
CurrentTenantInformation.defaultProps = {
  primaryTenant: undefined,
  secondaryTenants: [],
};
