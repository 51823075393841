import React, { Children, useEffect } from 'react';

import { useStepper } from './useStepper';

// eslint-disable-next-line react/prop-types
export const StepperSteps = ({ children }) => {
  const [{ currentStep, steps }, { setSteps }] = useStepper();

  useEffect(() => {
    const stepperSteps = Children.toArray(children)
      .filter(
        (child) =>
          (typeof child?.type === 'function' &&
            child?.type.name.toString() === 'StepperStep') ||
          child?.type.displayName === 'StepperStep'
      )
      .map((step) => step.props);

    setSteps(stepperSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSteps]);

  return (
    <div>
      {children &&
        Children.map(children, (child) => {
          if (child.props.persistent) return child;
          if (steps.length) {
            return child.props.id === steps[currentStep].id ? child : null;
          }
        })}
    </div>
  );
};

// eslint-disable-next-line react/prop-types
export const StepperStep = ({ children }) => <>{children}</>;

StepperStep.displayName = 'StepperStep';

export default StepperStep;
