import React from 'react';
import { FormGroup, Label } from 'reactstrap';

import { DateField } from '@app/modules/Form/FormikFields';

import { ModalContentContainer } from './ModalContentContainer';

const StartOfPayment = () => (
  <>
    <h2>When do you want the tenant to start paying?</h2>
    <ModalContentContainer>
      <small className="d-block mb-3">
        {`If you're onboarding an existing tenant and lease, use Paid to date + 1 day.`}
        <br />
        If this is a new tenancy, use the lease start date.
      </small>

      <FormGroup>
        <Label for="startDate">First payment date</Label>
        <DateField name="startDate" />
      </FormGroup>
    </ModalContentContainer>
  </>
);

export default StartOfPayment;
