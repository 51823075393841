/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';
import { Input } from 'reactstrap';

import { useToggle } from '@app/hooks';
import { ButtonIcon } from '@app/modules/Button';

import { SENT_HISTORY_SEARCH_BY_OPTIONS } from '../constants';
import { AdvancedFilterModal } from './AdvancedFilterModal';
import { INITIAL_FILTER } from './constants';
import './styles.scss';

export const Filter = ({ filters, setFilters }) => {
  const [modalState, toggleModalState] = useToggle();

  const hasAppliedFilters = useMemo(() => {
    return JSON.stringify(filters) !== JSON.stringify(INITIAL_FILTER);
  }, [filters]);

  const toggleFilterModal = () => {
    toggleModalState();
  };

  return (
    <div className="d-flex justify-content-between flex-md-row flex-column flex-wrap p-3 sms-filter">
      <ButtonIcon
        {...(hasAppliedFilters
          ? {
              color: 'white',
              buttonColor: 'primary-alt',
              className: 'text-center justify-content-center',
            }
          : {
              color: 'primary-alt',
              outline: true,
              className:
                'text-center justify-content-center border-primary-alt',
            })}
        icon={['fas', 'filter']}
        onClick={toggleFilterModal}>
        Filters
      </ButtonIcon>

      <div className="d-flex search-by-container">
        <Select
          className="search-by w-100"
          options={SENT_HISTORY_SEARCH_BY_OPTIONS}
          value={SENT_HISTORY_SEARCH_BY_OPTIONS.find(
            (option) => option.value === filters.searchBy
          )}
          onChange={({ value }) =>
            setFilters((state) => ({
              ...state,
              searchBy: value,
              searchValue: '',
            }))
          }
          isSearchable={false}
        />
        <Input
          type="text"
          className="form-control"
          placeholder="Search"
          name="searchValue"
          onChange={({ currentTarget }) =>
            setFilters((state) => ({
              ...state,
              searchValue: currentTarget.value,
            }))
          }
          value={filters.searchValue}
        />
      </div>
      {modalState.isOpen && (
        <AdvancedFilterModal
          filters={filters}
          setFilters={setFilters}
          modalState={modalState}
          toggleFilterModal={toggleFilterModal}
        />
      )}
    </div>
  );
};

Filter.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};
