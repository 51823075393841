/* eslint-disable react/jsx-no-bind */
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import { useIsMobile } from '@app/hooks';
import { ButtonIcon } from '@app/modules/Button';
import { RadioField } from '@app/modules/Form/FormikFields';
import { centsToDollar } from '@app/utils';

import { useLeaseState } from '../../Provider';
import {
  useCreateActivateTasks,
  useDeleteActivationTask,
  useFetchActivationTasks,
  useResetActivationTasks,
  useUpdateActivationTask,
} from '../../useLeaseFlex';
import { HeaderComponent } from './HeaderComponent';
import { LocalInput } from './LocalInput';
import { ModalContentContainer } from './ModalContentContainer';

const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  amountCents: Yup.number()
    .typeError('Amount must be a number')
    .required('Amount is required')
    .min(0, 'Amount must be equal or greater than 0'),
  referenceNumber: Yup.string().required('Reference Number is required'),
});

export const AgencyBills = () => {
  const isMobile = useIsMobile();
  const {
    values: { hasAgencyBills },
  } = useFormikContext();
  const { leaseState } = useLeaseState();
  const [showAdd, setShowAdd] = useState(false);
  const [leaseItems, setLeaseItems] = useState([]);
  const [toAddItemValues, setToAddItemValues] = useState({
    title: '',
    amountCents: 0,
    referenceNumber: '',
  });
  const [toEditItemValues, setToEditItemValues] = useState({
    title: '',
    amountCents: 0,
    referenceNumber: '',
    id: '',
  });

  const [errors, setErrors] = useState([]);

  const { data } = useFetchActivationTasks({ leaseId: leaseState?.lease?.id });
  const { mutateAsync: deleteItem } = useDeleteActivationTask();
  const { mutateAsync: addItem } = useCreateActivateTasks();
  const { mutateAsync: updateItem } = useUpdateActivationTask();
  const { mutateAsync: resetActivationTasks } = useResetActivationTasks();

  useEffect(() => {
    setLeaseItems(data?.leaseItems || []);
  }, [data]);

  const toggleAdd = () => {
    setShowAdd(!showAdd);
    setToEditItemValues(null);
    setErrors([]);
  };

  const toggleEdit = (values) => {
    setShowAdd(false);
    setToEditItemValues(values);
    setErrors([]);
  };

  const handleValidation = async ({ data, callback }) => {
    await schema
      .validate(data, { abortEarly: false })
      .then(() => {
        if (callback) {
          callback();
        }
        setErrors([]);
      })
      .catch((err) => {
        setErrors(
          err.errors.reduce((acc, curr) => {
            if (curr.includes('Title')) {
              return { ...acc, title: curr };
            } else if (curr.includes('Amount')) {
              return { ...acc, amountCents: curr };
            } else {
              return { ...acc, referenceNumber: curr };
            }
          }, {})
        );
      });
  };

  const handleOnChange = ({ target }) => {
    setToAddItemValues({ ...toAddItemValues, [target.name]: target.value });
    handleValidation({
      data: { ...toAddItemValues, [target.name]: target.value },
    });
  };

  const handleEditOnChange = ({ target }) => {
    setToEditItemValues({ ...toEditItemValues, [target.name]: target.value });
    handleValidation({
      data: { ...toEditItemValues, [target.name]: target.value },
    });
  };

  const handleResetActivationTasks = useCallback(() => {
    resetActivationTasks(
      {
        propertyId: leaseState?.propertyId,
        leaseId: leaseState?.lease?.id,
      },
      {
        onSuccess: ({ leaseItems }) => setLeaseItems(leaseItems),
      }
    );
  }, [leaseState.lease.id, leaseState.propertyId, resetActivationTasks]);

  const handleAddItem = () => {
    const { amountCents, referenceNumber, title } = toAddItemValues;
    handleValidation({
      data: toAddItemValues,
      callback: () => {
        addItem({
          propertyId: leaseState?.propertyId,
          leaseId: leaseState?.lease?.id,
          leaseItem: {
            title,
            amountCents: amountCents * 100,
            referenceNumber,
          },
        }).then((data) => {
          setLeaseItems(data?.leaseItems || []);
          setToAddItemValues({
            title: '',
            amountCents: 0,
            referenceNumber: '',
          });
          toggleAdd();
        });
      },
    });
  };

  const handleUpdateItem = async () => {
    const { amountCents, referenceNumber, title, id } = toEditItemValues;
    handleValidation({
      data: toEditItemValues,
      callback: () => {
        updateItem({
          propertyId: leaseState?.propertyId,
          taskId: id,
          payload: {
            leaseId: leaseState?.lease?.id,
            leaseItem: {
              title,
              amountCents: amountCents * 100,
              referenceNumber,
              id,
            },
          },
        }).then(({ leaseItem }) => {
          setLeaseItems(
            leaseItems.map((item) =>
              item.id === leaseItem.id ? leaseItem : item
            )
          );
          setToEditItemValues(null);
        });
      },
    });
  };

  const handleDeleteItem = ({ taskId }) => {
    deleteItem({ propertyId: leaseState?.propertyId, taskId }).then(() =>
      setLeaseItems(leaseItems.filter(({ id }) => id !== taskId))
    );
  };

  const renderAddBillComponent = () => (
    <Row className="d-flex align-items-baseline">
      <Col xs={12} md={4} className="text-left">
        <LocalInput
          name="title"
          value={toAddItemValues.title}
          onChange={handleOnChange}
          error={errors?.title}
          label="Title"
          isMobile={isMobile}
          isEdit
        />
      </Col>
      <Col xs={12} md={3}>
        <LocalInput
          name="amountCents"
          value={toAddItemValues.amountCents}
          prepend="$"
          onChange={handleOnChange}
          error={errors?.amountCents}
          label="Amount"
          isMobile={isMobile}
          isEdit
        />
      </Col>
      <Col xs={12} md={3}>
        <LocalInput
          name="referenceNumber"
          value={toAddItemValues.referenceNumber}
          onChange={handleOnChange}
          error={errors?.referenceNumber}
          label="Reference Number"
          isMobile={isMobile}
          isEdit
        />
      </Col>
      <Col xs={12} md={2} className="d-flex">
        <Button
          outline
          color="secondary"
          onClick={handleAddItem}
          disabled={!!Object.keys(errors).length}>
          Save
        </Button>
        {isMobile ? (
          <Button
            outline
            color="secondary"
            className="ml-auto"
            onClick={toggleAdd}>
            Cancel
          </Button>
        ) : (
          <ButtonIcon
            color="text-secondary"
            icon={['fas', 'times-circle']}
            size="xs"
            onClick={toggleAdd}
          />
        )}
      </Col>
    </Row>
  );

  const totalAmount = useMemo(
    () => leaseItems.reduce((acc, item) => acc + item.amountCents, 0),
    [leaseItems]
  );

  const renderLeaseItems = () => {
    if (!leaseItems?.length) {
      return (
        <Row className="text-center m-0 pt-3">
          <Col>No items found. Please click on Add Button to add items.</Col>
        </Row>
      );
    }

    return (
      <>
        {leaseItems.map(({ amountCents, id, referenceNumber, title }) => {
          const isEdit = id === toEditItemValues?.id;

          return (
            <Row key={`agency-bill-item-${id}`}>
              <Col md={4} className="text-left">
                <LocalInput
                  name="title"
                  value={isEdit ? toEditItemValues.title : title}
                  onChange={handleEditOnChange}
                  errors={errors?.title}
                  label="Title"
                  isMobile={isMobile}
                  isEdit={isEdit}
                />
              </Col>
              <Col md={3}>
                <LocalInput
                  name="amountCents"
                  prepend="$"
                  value={
                    isEdit
                      ? toEditItemValues.amountCents
                      : centsToDollar(amountCents)
                  }
                  onChange={handleEditOnChange}
                  errors={errors?.amountCents}
                  label="Amount"
                  isMobile={isMobile}
                  isEdit={isEdit}
                />
              </Col>
              <Col md={3}>
                <LocalInput
                  name="referenceNumber"
                  value={
                    isEdit ? toEditItemValues.referenceNumber : referenceNumber
                  }
                  onChange={handleEditOnChange}
                  errors={errors?.referenceNumber}
                  label="Payment Reference"
                  isMobile={isMobile}
                  isEdit={isEdit}
                />
              </Col>
              <Col md={2}>
                <FormGroup className="d-flex">
                  {isEdit ? (
                    <>
                      <Button
                        outline
                        color="secondary"
                        onClick={handleUpdateItem}
                        disabled={!!Object.keys(errors).length}>
                        Save
                      </Button>
                      {isMobile ? (
                        <Button
                          outline
                          color="secondary"
                          className="ml-auto"
                          onClick={() => toggleEdit(null)}>
                          Cancel
                        </Button>
                      ) : (
                        <ButtonIcon
                          color="text-secondary"
                          icon={['fas', 'times-circle']}
                          size="xs"
                          onClick={() => toggleEdit(null)}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {isMobile ? (
                        <>
                          <Button
                            outline
                            color="secondary"
                            onClick={() =>
                              toggleEdit({
                                title,
                                amountCents: amountCents / 100,
                                referenceNumber,
                                id,
                              })
                            }>
                            Edit
                          </Button>
                          <Button
                            outline
                            color="danger"
                            className="ml-auto"
                            onClick={() => handleDeleteItem({ taskId: id })}>
                            Delete
                          </Button>
                        </>
                      ) : (
                        <>
                          <ButtonIcon
                            className="text-primary"
                            icon={['fas', 'edit']}
                            size="xs"
                            onClick={() => {
                              toggleEdit({
                                title,
                                amountCents: amountCents / 100,
                                referenceNumber,
                                id,
                              });
                            }}
                          />
                          <ButtonIcon
                            color="danger"
                            className="text-danger pl-0"
                            icon={['far', 'trash-alt']}
                            size="xs"
                            onClick={() => handleDeleteItem({ taskId: id })}
                          />
                        </>
                      )}
                    </>
                  )}
                </FormGroup>
              </Col>
            </Row>
          );
        })}
        <Row className="mt-3">
          <Col md={4}>
            <Button
              type="button"
              outline
              color="danger"
              onClick={handleResetActivationTasks}>
              Restore Defaults
            </Button>
          </Col>
          <Col md={4}>
            <span className="font-weight-bold">
              Total Amount:&nbsp; {centsToDollar(totalAmount)}
            </span>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <h3>Do you want to schedule some agency bills during the renewal?</h3>
      <ModalContentContainer>
        <FormGroup>
          <Label for="hasAgencyBills">
            Are there any agency bills to schedule?
          </Label>
          <div role="group" className="radio-group-wrapper">
            <RadioField name="hasAgencyBills" label="Yes" value="true" />
            <RadioField name="hasAgencyBills" label="No" value="false" />
          </div>
        </FormGroup>
        {hasAgencyBills === 'true' && (
          <>
            <small className="d-block mb-3">
              These are owner bills payable to the agency. Note: If payment
              reference is empty, property address will be used.
            </small>
            <HeaderComponent
              isMobile={isMobile}
              toggleAdd={toggleAdd}
              show={showAdd}
              items={[
                {
                  title: 'Title',
                  size: 4,
                  className: 'px-1 text-left',
                },
                {
                  title: 'Amount',
                  size: 3,
                },
                {
                  title: 'Payment Reference',
                  size: 3,
                },
              ]}
            />
            {showAdd && renderAddBillComponent()}
            {renderLeaseItems()}
          </>
        )}
      </ModalContentContainer>
    </>
  );
};
