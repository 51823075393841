import PropTypes from 'prop-types';
import React from 'react';

import { TooltipIcon } from '../Common/TooltipIcon';

export const ContentDefinition = ({
  children,
  defaultValue,
  label,
  value,
  labelClassName,
  tooltip,
  ...props
}) => (
  <span {...props}>
    <small className={labelClassName}>
      <strong>{label}</strong>
      {tooltip && <TooltipIcon content={tooltip} label={label} />}
    </small>
    <br />
    <span data-testid="content-definition-value">
      {children || value || defaultValue}
    </span>
  </span>
);
ContentDefinition.defaultProps = {
  defaultValue: '-',
  labelClassName: '',
  tooltip: '',
};

ContentDefinition.propTypes = {
  children: PropTypes.node,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelClassName: PropTypes.string,
  tooltip: PropTypes.string,
};
