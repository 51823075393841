import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import * as Yup from 'yup';

import { FormButtons } from '../Form';

const FormResetPasswordComponent = (props) => {
  const {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    touched,
    values,
  } = props;

  return (
    <Form onSubmit={handleSubmit} className="reset-password-form">
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          id="email"
          type="email"
          name="email"
          placeholder="Enter Email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          valid={touched.email && !errors.email}
          invalid={touched.email && !!errors.email}
        />
        {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
      </FormGroup>
      <FormButtons
        btnSubmit={{ text: 'Send reset link' }}
        className="justify-content-start"
        isValid={isValid}
        isSubmitting={isSubmitting}
      />
    </Form>
  );
};

FormResetPasswordComponent.propTypes = {
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

const validate = withFormik({
  displayName: 'FormResetPassword',

  mapPropsToValues: () => ({
    email: '',
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  }),

  handleSubmit: (values, { props }) => props.onSubmit(values),
});

export const FormResetPassword = validate(FormResetPasswordComponent);
