import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';

import { addTenant, getLease } from '@app/redux/lease';

import Tenants from '../Modal/Steps/Tenants';
import { tenantSchema } from '../constants';

export const EditCurrentTenants = ({ show, toggle, leaseId }) => {
  const dispatch = useDispatch();
  const lease = useSelector(({ lease }) => getLease(lease, leaseId));

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      tenants: Yup.object().shape({
        primary: tenantSchema,
        secondary: Yup.array().of(tenantSchema),
      }),
    }),
    initialValues: {
      tenants: {
        primary: {
          ...(lease?.primaryTenant || {}),
          type: lease?.primaryTenant?.company ? 'company' : 'private',
          company: lease?.primaryTenant?.company?.legalName || '',
          taxNumber: lease?.primaryTenant?.company?.taxNumber || '',
        },
        secondary:
          lease?.secondaryTenants.map((tenant) => ({
            ...tenant,
            type: tenant?.company ? 'company' : 'private',
          })) || [],
      },
    },
    onSubmit: (values) => {
      const tenants = {
        secondary: values.tenants.secondary,
      };
      dispatch(addTenant({ leaseId, tenants, leaseFlex: true }));
      toggle();
    },
  });

  return (
    <Modal isOpen={show} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Tenants</ModalHeader>
      <ModalBody>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Tenants leaseData={{ lease }} />
          </form>
        </FormikProvider>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
        <Button color="primary" onClick={formik.handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

EditCurrentTenants.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  show: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  leaseId: PropTypes.number.isRequired,
};
EditCurrentTenants.defaultProps = {
  show: false,
};
