import PropTypes from 'prop-types';
import React from 'react';

export const ModalContentContainer = ({ children }) => {
  return <div className="lease-flex-modal-container px-2">{children}</div>;
};

ModalContentContainer.propTypes = {
  children: PropTypes.node,
};
