import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import { Alert, Badge, Button, CardBody } from 'reactstrap';

import {
  PaymentBpayIcon,
  PaymentBpayValue,
  PaymentVirtualAccountIcon,
  PaymentVirtualAccountValue,
} from '.';
import { createVirtualAccount } from '../../redux/users';
import { CardLight } from '../Card';
import { Link } from '../Link';

export const TenantDirectPayment = ({
  className,
  isAccountsLoading,
  isAccountsFetching,
  userId,
  isPayByBpay,
  bpayDetails,
  directPaymentDetails,
  onTogglePayByBpay,
}) => {
  const dispatch = useDispatch();
  const { virtualAccount } = directPaymentDetails;

  const handleGenerateVirtualAccount = useCallback(() => {
    dispatch(createVirtualAccount({ tenantId: userId }));
  }, [dispatch, userId]);

  return (
    <div
      className={`${className} mt-3 mb-5`}
      data-testid="other-payment-settings-card">
      <h4
        className="mb-3"
        data-testid="other-payment-settings-card-section-heading">
        Default Payment Settings
      </h4>
      {isAccountsLoading ? (
        <CardLight className="mb-3">
          <CardBody className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
            <PulseLoader color="#dee2e6" />
          </CardBody>
        </CardLight>
      ) : (
        <>
          <Alert className="mt-3" color="info" fade={false}>
            {isPayByBpay ? 'You are now making' : 'Note: Enable this to make'}{' '}
            your own payments using your internet banking provider
          </Alert>
          {isPayByBpay && (
            <CardLight className="my-3 border border-width-1 border-success">
              <CardBody>
                <PaymentVirtualAccountIcon
                  isShowProcessingTime
                  className={
                    !virtualAccount?.status ? 'text-start' : 'flex-fill'
                  }
                  title={
                    <>
                      <span>
                        {virtualAccount?.status
                          ? 'Fast Bank Transfer (OSKO)'
                          : 'EFT Bank Transfer to a unique BSB and Account Number'}
                      </span>
                      <Badge pill className="ml-2" color="success">
                        Active
                      </Badge>
                    </>
                  }
                  virtualAccountStatus={virtualAccount?.status}>
                  {!virtualAccount ? (
                    <Button
                      color="primary"
                      onClick={handleGenerateVirtualAccount}>
                      Generate Details
                    </Button>
                  ) : (
                    <PaymentVirtualAccountValue
                      virtualAccountBsb={virtualAccount?.routingNumber}
                      virtualAccountNumber={virtualAccount?.accountNumber}
                      virtualAccountStatus={
                        virtualAccount?.virtualAccountStatus
                      }
                    />
                  )}
                </PaymentVirtualAccountIcon>
              </CardBody>
            </CardLight>
          )}

          <CardLight
            className={`my-3 ${
              isPayByBpay ? 'border border-width-1 border-success' : ''
            }`}>
            <CardBody>
              {!isPayByBpay ? (
                <div className="d-flex justify-content-end">
                  <div className="w-md-50">
                    <Link
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={onTogglePayByBpay}
                      disabled={isAccountsFetching}>
                      <span className="nav-link">
                        I would like to make my own payments using BPAY or bank
                        transfer
                      </span>
                    </Link>
                  </div>
                </div>
              ) : (
                <PaymentBpayIcon hasActiveIcon isShowProcessingTime>
                  <PaymentBpayValue
                    bpayBillerCode={bpayDetails?.bpayBillerCode}
                    bpayReference={bpayDetails?.bpayReference}
                  />
                </PaymentBpayIcon>
              )}
            </CardBody>
          </CardLight>
        </>
      )}
    </div>
  );
};

TenantDirectPayment.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.number,
  isAccountsFetching: PropTypes.bool,
  isAccountsLoading: PropTypes.bool,
  isPayByBpay: PropTypes.bool,
  onTogglePayByBpay: PropTypes.func.isRequired,
  // props for showing bpay and bank transfer information
  bpayDetails: PropTypes.shape({
    bpayBillerCode: PropTypes.string,
    bpayReference: PropTypes.string,
  }),
  directPaymentDetails: PropTypes.shape({
    virtualAccount: PropTypes.object,
  }),
};
TenantDirectPayment.defaultProps = {
  className: '',
  userId: 0,
  isAccountsFetching: false,
  isAccountsLoading: false,
  isPayByBpay: false,
  bpayDetails: {
    bpayBillerCode: '',
    bpayReference: '',
  },
  directPaymentDetails: {
    virtualAccount: null,
  },
};
