import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Button, FormGroup } from 'reactstrap';
import * as Yup from 'yup';

import useSendSecurityCode from '@app/modules/SmsVerification/use-send-security-code';

import { FormLabelInput } from '../';

export const defaultPropsForAuthyToken = (props = {}) => {
  return {
    authyToken: '',
  };
};

export const validationSchemaForAuthyToken = {
  authyToken: Yup.string().required('Security code is required'),
};

export const AuthyForm = ({
  handleChange,
  handleBlur,
  touched,
  errors,
  email,
}) => {
  const { mutate, isLoading } = useSendSecurityCode();

  const handleRequestAuthySMS = useCallback(() => {
    if (email) {
      mutate({ email });
    }
  }, [mutate, email]);

  return (
    <div>
      <FormGroup className="authy-form-group">
        <FormLabelInput
          label="Enter the 6-digit code"
          name="authyToken"
          placeholder="123456"
          data-testid="authy-token-input"
          className="flex-fill"
          isRequired
          isTouched={touched.authyToken}
          error={errors.authyToken}
          handleChange={handleChange}
          handleBlur={handleBlur}
          customSubmitButton={
            <Button
              block
              color="light"
              data-testid="request-auth-btn"
              onClick={handleRequestAuthySMS}
              disabled={isLoading}>
              Send code
            </Button>
          }
        />
      </FormGroup>
    </div>
  );
};

AuthyForm.defaultProps = {
  errors: {},
  touched: {},
  values: {},
};

AuthyForm.propTypes = {
  email: PropTypes.string.isRequired,
  attributeName: PropTypes.string,
  className: PropTypes.string,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  touched: PropTypes.object,
  values: PropTypes.object,
};
