import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ButtonFavourite } from '@app/modules/Button';
import { useTogglePreferredExternalCreditor } from '@app/modules/ExternalCreditor/hooks/useExternalCreditor';
import { selectUserAgency } from '@app/redux/users';
import { validatePhoneNumber } from '@app/utils';

import { COLUMNS } from './constants';

export const ListRow = ({ tradie, hiddenColumns = [] }) => {
  const history = useHistory();
  const location = useLocation();

  const agency = useSelector(selectUserAgency);
  const { mutate: togglePreferred, isLoading } =
    useTogglePreferredExternalCreditor();

  const linkTo = {
    pathname: `${location.pathname}/tradie/${tradie.id}`,
  };

  const cols = COLUMNS.filter((column) => !hiddenColumns.includes(column.name));

  const isPreferredByUserAgency = useMemo(() => {
    if (tradie.preferredAgencies.length && agency) {
      return tradie.preferredAgencies.some((preferredAgency) => {
        return preferredAgency.id === agency.id;
      });
    }

    return false;
  }, [tradie, agency]);

  const handleRowClick = useCallback(
    (event) => {
      if (event.target.closest('a, button')) {
        return;
      }
      history.push(linkTo.pathname);
    },
    [history, linkTo.pathname]
  );

  const handleTogglePreferred = useCallback(() => {
    togglePreferred({
      externalCreditorId: tradie?.id,
      agencyId: agency?.id,
      preferred: !isPreferredByUserAgency,
    });
  }, [togglePreferred, tradie, agency, isPreferredByUserAgency]);

  const renderCell = (col) => {
    switch (col.id) {
      case 'businessName':
        return tradie.promisepayUserPromisepayCompanyLegalName;

      case 'contactName':
        return tradie.name;

      case 'email':
        return (
          tradie.primaryContactEmail && (
            <a
              href={`mailto:${tradie.primaryContactEmail}`}
              className="btn-link">
              {tradie.primaryContactEmail}
            </a>
          )
        );

      case 'mobile':
        const { value, isValid, error } = validatePhoneNumber({
          mobileNumber: tradie?.primaryContactMobile,
        });

        return isValid ? (
          <a href={`tel:${value}`} className="btn-link">
            {value}
          </a>
        ) : (
          error
        );

      case 'preferred':
        return (
          <ButtonFavourite
            isActive={isPreferredByUserAgency}
            onClick={handleTogglePreferred}
            disabled={isLoading}
          />
        );

      case 'action':
        return (
          <Link className="btn-link" to={linkTo.pathname}>
            View
          </Link>
        );

      default:
        return null;
    }
  };

  return (
    <tr data-testid="user-list-row" onClick={handleRowClick} role="button">
      {cols.map((col) => (
        <td
          key={col.id}
          className={`pl-1 ${
            col.id === 'businessName' ||
            (col.id === 'contactName' && 'text-nowrap')
          } ${col.id === 'adminFee' && 'text-center'}`}>
          {renderCell(col)}
        </td>
      ))}
    </tr>
  );
};

ListRow.propTypes = {
  tradie: PropTypes.object,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
};
