import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { updatePlatformChargesStatus } from '@app/redux/platformCharge';
import { STATUSES } from '@app/redux/platformCharge/constants';
import { httpClient } from '@app/utils';

export const QUERY_KEYS = {
  FETCH_AGENCIES: 'fetchAgencies',
  FETCH_OVERDUE_PAYMENTS: 'fetchOverduePayments',
  PROCESS_OVERDUE_PAYMENT: 'processOverduePayment',
};

const fetchAgencies = async () => {
  try {
    const response = await httpClient.get('agencies');

    return response?.data.agencies || [];
  } catch (error) {
    console.error('error: ', error);
    throw new Error('Something went wrong');
  }
};

export const useAgencies = ({ useQueryOptions = {} }) => {
  return useQuery([QUERY_KEYS.FETCH_AGENCIES], fetchAgencies, useQueryOptions);
};

const fetchOverduePayments = async ({ agencyId }) => {
  try {
    const response = await httpClient.get(
      `platform-charges/overdue_payments?agency_id=${agencyId}`
    );

    return response?.data || [];
  } catch (error) {
    console.error('error: ', error);
    throw new Error('Something went wrong');
  }
};

export const useOverduePayments = ({ agencyId, useQueryOptions = {} }) => {
  return useQuery(
    [QUERY_KEYS.FETCH_OVERDUE_PAYMENTS, agencyId],
    () => fetchOverduePayments({ agencyId }),
    {
      enabled: !!agencyId,
      ...useQueryOptions,
    }
  );
};

const processOverduePayment = async ({ agencyId, ...payload }) => {
  try {
    const response = await httpClient.post(
      `platform-charges/process_overdue_payment`,
      {
        agency_id: agencyId,
        ...payload,
      }
    );

    return response?.data;
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

export const useProcessOverduePayment = () => {
  const dispatch = useDispatch();
  // const queryClient = useQueryClient();

  return useMutation(processOverduePayment, {
    mutationKey: QUERY_KEYS.PROCESS_OVERDUE_PAYMENT,
    onSuccess: (_, variables) => {
      if (variables.platformChargeId) {
        dispatch(
          updatePlatformChargesStatus(
            [variables.platformChargeId],
            STATUSES.INTENTION_TRIGGERED
          )
        );
      }
      // TODO: revalidate useOverduePayments
      // return queryClient.invalidateQueries(QUERY_KEYS.FETCH_OVERDUE_PAYMENTS);
    },
    onError: (error) => error,
  });
};
