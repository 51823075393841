import { Field, getIn } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Input, {
  getCountries,
  parsePhoneNumber,
} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { InputGroup } from 'reactstrap';

import { FormLabel } from '../..';
import { CountrySelectWithIcon } from './CountrySelectWithIcon';
import './style.scss';

export const FormFieldPhoneNumber = ({
  name,
  setFieldValue,
  value,
  disabled,
}) => {
  const [country, setCountry] = useState('AU');
  const options = useMemo(() => getCountries(), []);

  useEffect(() => {
    const phoneNumber = parsePhoneNumber(value);

    setCountry(phoneNumber ? phoneNumber.country : 'AU');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (value) => setFieldValue(name, value),
    [setFieldValue, name]
  );

  return (
    <>
      <FormLabel
        for={name}
        helpText="This mobile number will be used for SMS communications">
        Mobile
      </FormLabel>
      <InputGroup className="pl-2">
        <Field name={name}>
          {({ field, form }) => {
            const { name, onBlur } = field;
            const error = getIn(form.errors, name);

            return (
              <>
                <div className="PhoneInput">
                  <CountrySelectWithIcon
                    labels={en}
                    value={country}
                    options={options}
                    onChange={setCountry}
                    disabled={disabled}
                  />
                  <Input
                    data-testid="field-user-phone-number"
                    className={
                      error ? 'invalid PhoneInputInput' : 'PhoneInputInput'
                    }
                    name={name}
                    country={country}
                    value={value}
                    onChange={handleChange}
                    onBlur={onBlur}
                    disabled={disabled}
                    international
                  />
                </div>
                {error && (
                  <p className="text-danger">
                    <small>{error}</small>
                  </p>
                )}
              </>
            );
          }}
        </Field>
      </InputGroup>
    </>
  );
};

FormFieldPhoneNumber.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};
FormFieldPhoneNumber.defaultProps = {
  value: '',
  disabled: false,
};
