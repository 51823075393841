import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

import { LeaseFormTask } from '@app/modules/Lease/Form';
import { ModalConfirm } from '@app/modules/Modal';

import { useFetchActivationTasks } from '../useLeaseFlex';

export const ScheduleAgencyBillsModal = ({
  isOpen,
  onSubmit,
  toggleModal,
  leaseId,
  propertyId,
}) => {
  const { data } = useFetchActivationTasks({ leaseId });

  return (
    <ModalConfirm
      isOpen={isOpen}
      size="lg"
      className="pb-0"
      title={
        <>
          <div className="d-inline-flex w-100 justify-content-between">
            <div>Schedule agency bills before activating this lease</div>
            <div>
              <Button color="primary" size="sm" onClick={toggleModal}>
                <FontAwesomeIcon icon={['far', 'times']} />
              </Button>
            </div>
          </div>
          <div className="h6 text-muted mt-2">
            If payment reference is empty, property address will be used
          </div>
        </>
      }>
      <LeaseFormTask
        onSubmit={onSubmit}
        onCancel={toggleModal}
        leaseItems={data?.leaseItems || []}
        // eslint-disable-next-line react/jsx-no-bind
        handleActivationTasks={() => {}}
        propertyId={propertyId}
        leaseId={leaseId}
      />
    </ModalConfirm>
  );
};

ScheduleAgencyBillsModal.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  leaseId: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
};
ScheduleAgencyBillsModal.defaultProps = {
  isOpen: false,
};
