import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'reactstrap';

import { CardLight } from '@app/modules/Card';

import { ListRow } from './ListRow';
import { COLUMNS } from './constants';

export const List = ({ tradies, hiddenColumns = [], isFiltered = false }) => (
  <CardLight className="pt-2 pb-1 px-3 mb-3" data-testid="user-list">
    <Table responsive hover className="m-0">
      <thead>
        <tr>
          {COLUMNS.filter((column) => !hiddenColumns.includes(column.name)).map(
            (column) => (
              <th key={column.id} className="border-top-0 pl-1">
                {column.name}
              </th>
            )
          )}
        </tr>
      </thead>
      <tbody>
        {tradies && tradies.length > 0 ? (
          tradies.map(
            (tradie) =>
              tradie.id && (
                <ListRow
                  key={`user-${tradie.id}`}
                  tradie={tradie}
                  hiddenColumns={hiddenColumns}
                />
              )
          )
        ) : (
          <tr>
            <td className="pl-1" colSpan={4}>
              {isFiltered
                ? 'Your search returned no results.'
                : 'Currently no tradies have been added.'}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </CardLight>
);

List.propTypes = {
  tradies: PropTypes.arrayOf(PropTypes.object),
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  isFiltered: PropTypes.bool,
};
