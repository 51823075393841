import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Card, FormGroup } from 'reactstrap';

import { FormLabel } from '@app/modules/Form';
import { UserSearchSelect } from '@app/modules/User';
import {
  EXTERNAL_CREDITOR_CLASSIFICATIONS,
  USER_TYPES,
  selectManagersAgencies,
} from '@app/redux/users';

export const MarketplaceFieldsSendToTradie = ({ onChange, propertyId }) => {
  const managerAgencies = useSelector(selectManagersAgencies);

  return (
    <Card className="bg-lavender text-left p-4 mb-3">
      <FormGroup className="mb-0">
        <FormLabel for="tradieIds" className="font-weight-bold" isRequired>
          Send workorder to
        </FormLabel>
        <UserSearchSelect
          name="tradieIds"
          ariaLabel="Send workorder to"
          canSendInvite
          isMulti={false}
          searchParams={{
            perPage: 6,
            classification: EXTERNAL_CREDITOR_CLASSIFICATIONS.tradie,
            'q[g][0][agency_external_creditors_preferred_eq]': true,
            'q[g][0][m]': 'and',
            'q[g][0][agency_external_creditors_agency_id_in]':
              managerAgencies.toString(),
            propertyId: propertyId,
          }}
          moreResultsParams={{
            perPage: 6,
            classification: EXTERNAL_CREDITOR_CLASSIFICATIONS.tradie,
            propertyId: propertyId,
            allCreditors: true,
          }}
          type={USER_TYPES.externalCreditor}
          onChange={onChange('tradieIds')}
          groupLabel="Preferred trades"
          moreResultsLabel="Search other trades"
          hasGroupedRansackQuery
        />
      </FormGroup>
    </Card>
  );
};

MarketplaceFieldsSendToTradie.propTypes = {
  onChange: PropTypes.func,
  propertyId: PropTypes.number,
};
