import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, CardTitle, Col } from 'reactstrap';

import { Pagination } from '@app/modules/Pagination';

import { BulkMessages } from './BulkMessages';
import { Filter } from './Filter';
import { INITIAL_FILTER } from './constants';

export const BulkMessaging = ({ agencyId }) => {
  const [filters, setFilters] = useState(INITIAL_FILTER);

  return (
    <Col>
      <Card>
        <CardHeader className="bg-white border-400 d-flex justify-content-between">
          <CardTitle className="mb-0" tag="h5">
            Bulk Messaging
          </CardTitle>
          <Button
            tag={Link}
            color="primary-alt"
            to="/sms/bulk-messaging/create">
            New Message
          </Button>
        </CardHeader>
        <CardBody className="m-0 p-0">
          <Filter
            filters={filters}
            setFilters={setFilters}
            type="bulk-messages-filter"
          />
          <BulkMessages agencyId={agencyId} filter={filters} />
        </CardBody>
      </Card>
      <Pagination className="mt-3" name="bulk_sms_messages" isReload={false} />
    </Col>
  );
};

BulkMessaging.propTypes = {
  agencyId: PropTypes.number,
};
