import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import {
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';

import { CardLight } from '@app/modules/Card';
import { InputField, SelectField } from '@app/modules/Form/FormikFields';

import { TOP_UP_OPTIONS } from './constants';
import './styles.scss';

export const Settings = ({ smsAccount }) => {
  const formik = useFormik({
    initialValues: {
      agencyName: smsAccount?.alphanumericId || '',
      autoTopup: 'never',
    },
    enableReinitialize: true,
  });

  return (
    <Col>
      <CardLight>
        <CardHeader className="bg-white border-400">
          <CardTitle className="mb-0" tag="h5">
            SMS Settings
          </CardTitle>
        </CardHeader>
        <CardBody className="m-3 sms-settings">
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Label for="agencyName">Sender ID</Label>
                <InputField
                  name="agencyName"
                  id="agencyName"
                  placeholder="Agency Name"
                  value={formik.values.agencyName}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label for="autoTopup">Auto Topup</Label>
                <SelectField
                  name="autoTopup"
                  options={TOP_UP_OPTIONS}
                  isDisabled
                />
              </FormGroup>
            </form>
          </FormikProvider>
        </CardBody>
        {/** TODO: enable when supported */}
        {/* <CardFooter className="bg-white d-flex flex-md-row-reverse flex-column">
          {smsAccount !== undefined && (
            <Button color="primary-alt" disabled>
              Save
            </Button>
          )}
        </CardFooter> */}
      </CardLight>
    </Col>
  );
};

Settings.propTypes = {
  smsAccount: PropTypes.object,
};
