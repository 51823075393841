import * as Dialog from '@radix-ui/react-dialog';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ModalContent from './Modal';
import { RenewLease } from './Modal/RenewLease';
import { LeaseStateContext } from './Provider';
import { useStepper } from './common/stepper';

const Lease = ({ propertyId }) => {
  const [
    { leaseIdToEdit, leaseIdToRenew },
    { setLeaseToEdit, setLeaseToRenew },
  ] = useStepper();

  // TODO: convert to userReducer
  const [leaseState, setLeaseState] = useState({
    propertyId,
  });

  return (
    // TODO: separate state and dispatcher to reduce unnecessary renders
    <LeaseStateContext.Provider value={{ leaseState, setLeaseState }}>
      <Dialog.Root
        open={!!leaseIdToEdit || !!leaseIdToRenew}
        onOpenChange={leaseIdToRenew ? setLeaseToRenew : setLeaseToEdit}>
        {leaseIdToRenew ? (
          <RenewLease propertyId={propertyId} />
        ) : (
          <ModalContent propertyId={propertyId} />
        )}
      </Dialog.Root>
    </LeaseStateContext.Provider>
  );
};

Lease.propTypes = {
  propertyId: PropTypes.number,
};

export default Lease;
