export const FORM_FIELDS = {
  0: [
    'leaseStartDate',
    'endDate',
    'leaseTerm',
    'tenantStartDate',
    'daysRentInvoiceInAdvance',
    'periodic',
  ],
  1: [
    'payFrequency',
    'rentDollars',
    'gstIncluded',
    'tenantPaysWater',
    'ingoingWaterMeterReading',
  ],
  2: ['bondNumber', 'bondDollars'],
  3: ['startDate'],
  4: ['deposit'],
  5: [
    'inspectionDate',
    'inspectionDateFrequencyInMonths',
    'reviewDate',
    'reviewDateFrequencyInMonths',
  ],
  6: ['leaseUpload', 'ingoingReportUpload'],
  7: ['tenants'],
  8: [],
  9: [],
};

export const MONTHS_OPTIONS = Array(12)
  .fill()
  .map((_, i) => ({
    value: i + 1,
    label: `${i + 1} month${i >= 1 ? 's' : ''}`,
  }));

export const RENTAL_ADJUSTMENT_OPTIONS = [
  {
    value: '',
    label: '-- Select --',
  },
  {
    value: 'review',
    label: 'Rent Review',
  },
  {
    value: 'prescheduled',
    label: 'Pre-scheduled Change',
  },
];
