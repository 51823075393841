import { useQuery } from '@tanstack/react-query';

import { propertyAddressString } from '@app/redux/property/helpers';
import { httpClient } from '@app/utils';

export const PROPERTY_QUERY_KEYS = {
  FETCH_PROPERTIES_BY_ADDRESS: 'fetchPropertiesByAddress',
};

const getPropertiesByAddress = async (params) => {
  try {
    const response = await httpClient.get('properties/search', {
      params,
    });

    return (
      response?.data?.properties.map((property) => ({
        label: propertyAddressString(property.address),
        value: property.id,
      })) || []
    );
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

/**
 * Custom hook to fetch properties by address.
 *
 * @param {Object} params - The parameters for the query.
 * @param {string} params.address - The address to fetch properties for.
 */
export const useFetchPropertiesByAddress = (params) => {
  return useQuery(
    [PROPERTY_QUERY_KEYS.FETCH_PROPERTIES_BY_ADDRESS, params.address],
    () => getPropertiesByAddress(params),
    {
      enabled: false,
      retry: false,
    }
  );
};
