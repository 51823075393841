import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import { CustomInput, FormGroup, FormText } from 'reactstrap';

import { useIsMobile, useToggle } from '@app/hooks';
import { updateUser } from '@app/redux/users';

import { CardLight } from '../Card';
import { ModalConfirm } from '../Modal';

export const PaymentAutomateSelector = ({
  isAutoBills,
  isAutoRent,
  tenantId,
  isLoading,
  isPayByBpay,
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [modalState, toggleModalState] = useToggle({ isOpen: false });
  const [autoPayBills, setAutoPayBills] = useState(isAutoBills);

  useEffect(() => {
    setAutoPayBills(isAutoBills);
  }, [isAutoBills]);

  const toggleModal = useCallback(() => {
    toggleModalState({ isOpen: !modalState.isOpen });
  }, [toggleModalState, modalState]);

  const handleUpdateAutoBillPayment = useCallback(() => {
    dispatch(
      updateUser({
        id: tenantId,
        type: 'tenant',
        autoBillPayment: !autoPayBills,
      })
    );
    toggleModal();
  }, [autoPayBills, dispatch, tenantId, toggleModal]);

  return (
    <CardLight
      className="pay-card mb-3"
      title="Your Automated Payment Settings">
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <PulseLoader color="#dee2e6" />
        </div>
      ) : (
        <>
          <CustomInput
            checked={isAutoRent}
            data-testid="payment-settings-auto-rent"
            id="paymentSettings-autoRent"
            label="Automatically Pay my Rent"
            name="autoRent"
            type="checkbox"
            disabled
          />
          <FormGroup>
            <CustomInput
              checked={autoPayBills}
              className="d-inline"
              data-testid="payment-settings-auto-bills"
              id="paymentSettings-autoBills"
              label="Automatically Pay my Bills"
              name="autoBills"
              type="checkbox"
              onChange={toggleModal}
            />
            <FormText
              className={isMobile ? 'd-block' : 'd-inline ml-1'}
              onClick={toggleModal}>
              {isPayByBpay
                ? `(when funds are available in your wallet, your bills will be
              processed as they fall due)`
                : '(bills will be processed automatically)'}
            </FormText>
          </FormGroup>
        </>
      )}
      <ModalConfirm
        isOpen={modalState.isOpen}
        size="md"
        btnCancel={{ text: 'Cancel' }}
        btnSubmit={{ text: 'Confirm', color: 'primary' }}
        title="Are you sure?"
        onCancel={toggleModal}
        onSubmit={handleUpdateAutoBillPayment}>
        {autoPayBills
          ? 'Disabling auto payments on bills would require you to manually pay bills before the due date.'
          : 'Enabling auto payments on bills will ensure we attempt to pay your bills on time.'}
      </ModalConfirm>
    </CardLight>
  );
};

PaymentAutomateSelector.propTypes = {
  isAutoBills: PropTypes.bool,
  isAutoRent: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPayByBpay: PropTypes.bool,
  tenantId: PropTypes.number,
};

PaymentAutomateSelector.defaultProps = {
  isAutoBills: true,
  isAutoRent: true,
  isLoading: false,
};
