import PropTypes from 'prop-types';
import React from 'react';
import { CardBody, CardHeader, CardTitle } from 'reactstrap';

import { CardLight } from '@app/modules/Card';
import { PropertyLeaseTenantLedgerReport } from '@app/modules/Property';

export const TenantLedgerDocuments = ({ lease }) => {
  return (
    <CardLight className="mt-2">
      <CardHeader className="bg-white">
        <CardTitle tag="h5">Tenant Ledger Documents</CardTitle>
      </CardHeader>
      <CardBody className="pt-0">
        <PropertyLeaseTenantLedgerReport lease={lease} />
      </CardBody>
    </CardLight>
  );
};

TenantLedgerDocuments.propTypes = {
  lease: PropTypes.object.isRequired,
};
