/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import * as Dialog from '@radix-ui/react-dialog';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';

import ManagedLogo from '@app/assets/managed.svg';
import { getLease, getLeaseModifications } from '@app/redux/lease';
import { toDollars } from '@app/utils';

import { useLeaseState } from '../../Provider';
import { useStepper } from '../../common/stepper';
import { renewValidationSchema } from '../../constants';
import '../styles.scss';
import Steps from './Steps';

const loadInitialValues = (
  { leaseStartDate, endDate, tenantStartDate, startDate, ...lease },
  modificationSchedule
) => {
  return {
    // step 1
    tenants: {
      primary: {
        ...(lease?.primaryTenant || {}),
        type: lease?.primaryTenant?.company ? 'company' : 'private',
        company: lease?.primaryTenant?.company?.legalName || '',
        taxNumber: lease?.primaryTenant?.company?.taxNumber || '',
      },
      secondary:
        lease?.secondaryTenants.map((tenant) => ({
          ...tenant,
          type: tenant?.company ? 'company' : 'private',
        })) || [],
    },
    // step 2 = DATES
    startDate,
    leaseStartDate,
    endDate,
    isCommercial: !!lease?.currentCommercialOutgoingsEstimate,
    daysRentInvoiceInAdvance: lease?.daysRentInvoiceInAdvance,
    periodic: lease?.periodic ? 'true' : 'false',
    // step 3 = RENT ADJUSTMENTS
    modificationSchedule,
    rentPaidUpUntil: lease?.rentPaidUpUntil,
    annualRentCents: lease?.annualRentCents,
    scheduleAdjustment: 'false',
    payFrequency: lease.payFrequency,
    rentDollars: lease?.amountCents
      ? toDollars(lease.amountCents[lease.payFrequency])
      : '',
    effectiveDate: null,
    effectiveFrom: null,
    reasonForChange: '',
    // outgoings
    monthlyOutgoings: lease?.currentCommercialOutgoingsEstimate
      ?.totalAnnualEstimateCents
      ? 'true'
      : 'false',
    chargeGST: lease?.currentCommercialOutgoingsEstimate?.gstIncluded
      ? 'true'
      : 'false',
    // agency bills
    hasAgencyBills: 'false',
  };
};

// eslint-disable-next-line react/prop-types
export const RenewLease = ({ propertyId }) => {
  const [{ leaseIdToRenew }, { reset, setLeaseToRenew }] = useStepper();
  const lease = useSelector((state) => getLease(state.lease, leaseIdToRenew));
  const modificationSchedule = useSelector(({ lease: leaseStore }) =>
    getLeaseModifications(leaseStore, lease.id).map(
      ({ effectiveDate }) => effectiveDate
    )
  );

  const { setLeaseState } = useLeaseState();

  const formik = useFormik({
    validationSchema: renewValidationSchema,
    initialValues: loadInitialValues(lease, modificationSchedule),
    onSubmit: () => {
      handleClose();
    },
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    reset();
    setLeaseToRenew(0);
  }, [formik, reset, setLeaseToRenew]);

  // set lease data
  useEffect(() => {
    if (lease?.id) {
      setLeaseState((state) => ({ ...state, lease }));
    }
  }, [lease, setLeaseState]);

  return (
    <Dialog.Portal>
      <Dialog.Overlay className="DialogOverlay">
        <Dialog.Content
          className="DialogContent"
          // eslint-disable-next-line react/jsx-no-bind
          onOpenAutoFocus={(event) => {
            event.preventDefault();
          }}>
          <Logo />

          <Container className="lease-steps-container my-3">
            <div className="card">
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                  <Steps
                    propertyId={propertyId}
                    currentStep={0}
                    leaseId={leaseIdToRenew}
                    isRenewed={!!lease.renewingLeaseId}
                  />
                </form>
              </FormikProvider>
            </div>
          </Container>

          <Dialog.Close asChild>
            {/* eslint-disable-next-line react/button-has-type */}
            <button
              className="IconButton"
              aria-label="Close"
              onClick={handleClose}>
              <FontAwesomeIcon icon={['far', 'xmark']} className="mr-1" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  );
};

export const Logo = () => (
  <div className="logo-container mb-4">
    <AspectRatio.Root ratio={16 / 3.5}>
      <img className="managed-logo" src={ManagedLogo} alt="Managed App logo" />
    </AspectRatio.Root>
  </div>
);
