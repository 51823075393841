import PropTypes from 'prop-types';
import React from 'react';

import { UserAvatar } from '@app/modules/User';

import { Recipients } from './BulkMessaging/Recipients';

export const UserContact = ({
  label,
  user,
  email,
  number,
  className,
  avatarUrl,
  recipients,
  avatarSize,
}) => (
  <div className={className}>
    <p className="mb-1">{label}</p>
    <div className="d-flex">
      {recipients ? (
        <Recipients recipients={recipients} />
      ) : (
        <>
          <UserAvatar
            size={avatarSize}
            className="mr-2"
            user={{ avatarUrl }}
            containerClassName="align-baseline"
          />
          <div className="d-flex flex-column justify-content-center">
            <small>
              <strong>{user}</strong>
            </small>
            <small>{email}</small>
            <small>{number}</small>
          </div>
        </>
      )}
    </div>
  </div>
);

UserContact.propTypes = {
  label: PropTypes.string,
  user: PropTypes.string,
  email: PropTypes.string,
  number: PropTypes.string,
  className: PropTypes.string,
  avatarUrl: PropTypes.string,
  recipients: PropTypes.array,
  avatarSize: PropTypes.number,
};

UserContact.defaultProps = {
  avatarSize: 1,
};
