import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { showAlert } from '@app/redux/notifier';
import { httpClient } from '@app/utils';

export const QUERY_KEYS = {
  SEND_RESET_PASSWORD: 'sendResetPassword',
};

const sendResetPassword = async ({ email }) => {
  try {
    const response = await httpClient.post('user/trigger-password-reset', {
      email,
    });

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

export const useSendResetPassword = () => {
  const dispatch = useDispatch();

  return useMutation(sendResetPassword, {
    mutationKey: QUERY_KEYS.SEND_RESET_PASSWORD,
    onSuccess: (_data, variables) => {
      dispatch(
        showAlert({
          color: 'success',
          message: 'The password reset email has been successfully sent.',
        })
      );
    },
  });
};
