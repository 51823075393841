import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardFooter } from 'reactstrap';

import { CardHeaderLight } from '.';

export const CardLight = ({
  children,
  title,
  badge,
  isLoading,
  footer,
  ...props
}) => (
  <Card {...props}>
    {title ? (
      <>
        <CardHeaderLight isLoading={isLoading} badge={badge}>
          {title}
        </CardHeaderLight>
        <CardBody>{children}</CardBody>
        {footer && <CardFooter>{footer}</CardFooter>}
      </>
    ) : (
      children
    )}
  </Card>
);

CardLight.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footer: PropTypes.node,
  badge: PropTypes.object,
};
