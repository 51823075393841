import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Col,
  CustomInput,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import { FilterContext } from '@app/modules/Filter';
import { toQueryObject, toQueryString } from '@app/utils';

import { AgencyAndManagerFilters } from './AgencyAndManagerFilters';

const AdvancedFiltersModal = ({
  isFiltersOpen,
  handleOnClose,
  filterValues,
  setFilterValues,
  filtersToParamsConversion,
  isCommercialModuleEnabled,
  defaultParams,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { actions } = useContext(FilterContext);

  const params = useMemo(
    () => toQueryObject(location.search),
    [location.search]
  );

  const handleOnCheckBoxChange = useCallback(
    (e) => {
      const { name, checked } = e.target;
      setFilterValues((prevState) => {
        const newValues = { ...prevState };
        newValues[name] = checked;
        return { ...newValues };
      });
    },
    [setFilterValues]
  );

  const handleClearAllFilters = useCallback(() => {
    actions.onReset({ defaultParams });

    setFilterValues((prevState) => {
      Object.keys(prevState).forEach((key) => {
        prevState[key] = false;
      });

      return { ...prevState, search: false };
    });
    [
      'propertyType',
      'missingBank',
      'invalidBank',
      'leaseStatus',
      'withArchived',
      'managerIds',
      'agencyIds',
      'address',
    ].forEach((e) => delete params[e]);

    history.replace({ search: toQueryString(params) });
  }, [setFilterValues, actions, defaultParams, history, params]);

  const handleApplyFilters = useCallback(() => {
    const newParams = filtersToParamsConversion();
    // Override propertyType, leaseStatus, missingBank, invalidBank, withArchived values to reflect newParams.
    const uniqueKeys = Object.keys(Object.assign({}, newParams, params));
    uniqueKeys.forEach((key) => {
      if (
        [
          'propertyType',
          'leaseStatus',
          'missingBank',
          'invalidBank',
          'withArchived',
          'managerIds',
          'agencyIds',
        ].includes(key)
      ) {
        params[key] = newParams[key];
      }
    });
    history.replace({ search: toQueryString(params) });
    handleOnClose();
  }, [handleOnClose, history, params, filtersToParamsConversion]);

  return (
    <Modal size="lg" isOpen={isFiltersOpen} centered>
      <ModalHeader cssModule={{ 'modal-title': 'w-100 mb-0' }}>
        <div className="d-inline-flex w-100 justify-content-between">
          <div>Advanced Filters</div>
          <div className="text-right px-md-0">
            <Button color="primary" onClick={handleOnClose}>
              <FontAwesomeIcon icon={['far', 'times']} />
            </Button>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="container">
          <AgencyAndManagerFilters setFilterValues={setFilterValues} />
          <Row className="mx-3 mb-3">
            <Col
              xs={{ size: 12, order: 1 }}
              sm={{ size: 12, order: 1 }}
              md={{ size: 4, order: 1 }}
              className="mb-2 text-primary">
              {'Property'}
            </Col>
            <Col
              xs={{ size: 12, order: 3 }}
              sm={{ size: 12, order: 3 }}
              md={{ size: 4, order: 2 }}
              className="mb-2 text-primary">
              {'Lease'}
            </Col>
            <Col
              xs={{ size: 12, order: 5 }}
              sm={{ size: 12, order: 5 }}
              md={{ size: 4, order: 3 }}
              className="mb-2 text-primary">
              {'Other'}
            </Col>
            <div className="mb-2 mb-sm-0 col-12 order-2 col-sm-12 order-sm-2 col-md-4 order-md-4">
              <CustomInput
                checked={filterValues['residential']}
                id="propertyFilterResidential"
                label="Residential"
                name="residential"
                type="checkbox"
                onChange={handleOnCheckBoxChange}
                disabled={!isCommercialModuleEnabled}
              />
              <CustomInput
                checked={filterValues['commercial']}
                id="propertyFilterCommercial"
                label="Commercial"
                name="commercial"
                type="checkbox"
                onChange={handleOnCheckBoxChange}
                disabled={!isCommercialModuleEnabled}
              />
              <CustomInput
                checked={filterValues['withArchived']}
                id="propertyFilterWithArchived"
                label="Include Archived"
                name="withArchived"
                type="checkbox"
                onChange={handleOnCheckBoxChange}
              />
            </div>
            <div className="mb-2 mb-sm-0 col-12 order-4 col-sm-12 order-sm-4 col-md-4 order-md-5">
              <CustomInput
                checked={filterValues['draft']}
                id="propertyFilterDraft"
                label="Vacant"
                name="draft"
                type="checkbox"
                onChange={handleOnCheckBoxChange}
              />
              <CustomInput
                checked={filterValues['pendingActivateDueToDeposit']}
                id="propertyFilterDepositPaid"
                label="Deposit Paid"
                name="pendingActivateDueToDeposit"
                type="checkbox"
                onChange={handleOnCheckBoxChange}
              />
              <CustomInput
                checked={filterValues['pendingActivate']}
                id="propertyFilterPendingActivate"
                label="Pending Activate"
                name="pendingActivate"
                type="checkbox"
                onChange={handleOnCheckBoxChange}
              />
              <CustomInput
                checked={filterValues['pendingClearance']}
                id="propertyFilterPendingClearance"
                label="Pending Clearance"
                name="pendingClearance"
                type="checkbox"
                onChange={handleOnCheckBoxChange}
              />
              <CustomInput
                checked={filterValues['active']}
                id="propertyFilterActive"
                label="Leased"
                name="active"
                type="checkbox"
                onChange={handleOnCheckBoxChange}
              />
            </div>
            <div className="mb-2 mb-sm-0 col-12 order-6 col-sm-12 order-sm-6 col-md-4 order-md-6">
              <CustomInput
                checked={filterValues['missingBankDetails']}
                id="propertyFilterMissingBankDetails"
                label="Missing Bank Details"
                name="missingBankDetails"
                type="checkbox"
                onChange={handleOnCheckBoxChange}
              />
              <CustomInput
                checked={filterValues['invalidBankDetails']}
                id="propertyFilterInvalidBankDetails"
                label="Invalid Bank Details"
                name="invalidBankDetails"
                type="checkbox"
                onChange={handleOnCheckBoxChange}
              />
            </div>
          </Row>
        </div>
        <div className="mt-3 mb-2" style={{ float: 'right' }}>
          <Button
            className="ml-2 mr-2"
            outline
            color="primary"
            onClick={handleClearAllFilters}>
            Clear all
          </Button>
          <Button
            className="ml-2 mr-2"
            color="primary"
            onClick={handleApplyFilters}>
            Apply
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

AdvancedFiltersModal.propTypes = {
  isFiltersOpen: PropTypes.bool,
  handleOnClose: PropTypes.func.isRequired,
  filterValues: PropTypes.object.isRequired,
  setFilterValues: PropTypes.func.isRequired,
  filtersToParamsConversion: PropTypes.func,
  isCommercialModuleEnabled: PropTypes.bool,
  defaultParams: PropTypes.object.isRequired,
};

export const AdvancedFilters = memo(AdvancedFiltersModal);
