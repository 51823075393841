import { useField } from 'formik';
import React from 'react';
import { FormFeedback } from 'reactstrap';

// TODO: promote to a common form component
const PaymentDdaError = () => {
  // eslint-disable-next-line no-unused-vars
  const [_field, meta] = useField('agreement');
  const invalid = meta.touched && meta.error;
  const valid = meta.touched && !meta.error;

  if (invalid) {
    return (
      <FormFeedback invalid={invalid} valid={valid}>
        {meta.error}
      </FormFeedback>
    );
  }

  return null;
};

export default PaymentDdaError;
