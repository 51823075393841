import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { getCountryCallingCode } from 'react-phone-number-input/input';

export const CountrySelect = ({ labels, options, onChange, ...rest }) => {
  const handleChange = useCallback(
    ({ target }) => {
      onChange(target.value);
    },
    [onChange]
  );

  return (
    <select {...rest} onChange={handleChange}>
      {options.map((country) => (
        <option key={country} value={country}>
          {labels[country]} +{getCountryCallingCode(country)}
        </option>
      ))}
    </select>
  );
};

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CountrySelect.defaultProps = {
  value: 'AU',
};
