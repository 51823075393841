import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';

import { getMessageProperties } from './constants';

export const MessageStatus = ({ status, className, isBulkMessage }) => {
  const { color, text } = getMessageProperties(status, isBulkMessage);

  return (
    <Badge
      pill
      className={`text-capitalize py-2 px-3 ${className}`}
      color={color}>
      {text}
    </Badge>
  );
};

MessageStatus.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
  isBulkMessage: PropTypes.bool,
};

MessageStatus.defaultProps = {
  className: '',
  isBulkMessage: false,
};
