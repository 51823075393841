import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Card, CardBody, CardFooter, Col, Container, Row } from 'reactstrap';

import { useToggle } from '@app/hooks';

import {
  useFetchSmsCreditPackList,
  usePurchaseSmsCreditPack,
} from '../hooks/use-sms-accounts';
import { Plan } from './Plan';
import { PurchaseConfirmation } from './PurchaseConfirmation';

const INITIAL_STATE = {
  isOpen: false,
  credit: 0,
  amount: 0,
  id: null,
};

export const Plans = ({
  propertyCount,
  agencyId,
  smsAccountStatus,
  disabled,
}) => {
  const { data } = useFetchSmsCreditPackList({
    params: { agencyId, smsAccountStatus },
  });
  const { mutate, isLoading, isSuccess, isError } = usePurchaseSmsCreditPack();
  const [modalState, toggleModal] = useToggle(INITIAL_STATE);
  const isSmsInactive = ['inactive', ''].includes(smsAccountStatus) || disabled;

  useEffect(() => {
    if (isSuccess || isError) {
      toggleModal(INITIAL_STATE);
    }
  }, [isError, isSuccess, toggleModal]);

  const toggleConfirmationModal = useCallback(
    (values) => {
      toggleModal({ isOpen: true, ...values });
    },
    [toggleModal]
  );

  const recommendedPlan = useMemo(() => {
    return data.find(
      ({ numberOfCredits }) =>
        (propertyCount < 100 && numberOfCredits <= 500) ||
        (propertyCount < 500 && numberOfCredits <= 1000) ||
        (propertyCount > 500 && numberOfCredits <= 5000)
    );
  }, [propertyCount, data]);

  const handleBuyCredit = useCallback(() => {
    mutate({ smsCreditPackId: parseInt(modalState.id), agencyId });
  }, [agencyId, modalState.id, mutate]);

  const closeModal = useCallback(() => {
    toggleModal(INITIAL_STATE);
  }, [toggleModal]);

  return (
    <Col>
      <Card className="h-100 sms-plans">
        <h2 className="my-4 text-white text-center">
          Buy more credit&nbsp;&#42;
        </h2>
        <CardBody>
          <Container>
            <Row className="row justify-content-center align-items-end sms-plans-wrapper">
              {data.map(({ id, numberOfCredits = 0, priceCents, name }) => (
                <Plan
                  key={id}
                  id={id}
                  credit={numberOfCredits}
                  amount={priceCents}
                  onClick={toggleConfirmationModal}
                  isRecommended={id === recommendedPlan?.id}
                  name={name}
                  disabled={isSmsInactive}
                />
              ))}
            </Row>
          </Container>
        </CardBody>
        <CardFooter className="d-flex flex-column bg-transparent">
          <div className="d-flex justify-content-between">
            <span>Properties: {propertyCount || '...'}</span>
            <span>Cost per SMS is $0.11 inc GST</span>
          </div>
          <span className="text-muted text-small mt-2">
            &#42;Only one credit purchase can be made at a time
          </span>
        </CardFooter>
        <PurchaseConfirmation
          modalState={modalState}
          toggle={closeModal}
          onConfirm={handleBuyCredit}
          isLoading={isLoading}
        />
      </Card>
    </Col>
  );
};

Plans.propTypes = {
  propertyCount: PropTypes.number,
  agencyId: PropTypes.number,
  smsAccountStatus: PropTypes.string,
  disabled: PropTypes.bool,
};

Plans.defaultProps = {
  smsAccountStatus: '',
  disabled: false,
};
