import { useCallback, useContext } from 'react';

import { StepperDispatchContext, StepperStateContext } from './Provider';
import {
  DECREMENT_CURRENT_STEP,
  INCREMENT_CURRENT_STEP,
  RESET,
  SET_CURRENT_STEP,
  SET_LEASE_TO_EDIT,
  SET_LEASE_TO_RENEW,
  SET_STEPS,
} from './constants';

export const useStepperState = () => {
  const slideOverState = useContext(StepperStateContext);
  if (typeof slideOverState === 'undefined') {
    throw new Error('useStepperState must be used within a StepperProvider');
  }
  return slideOverState;
};

export const useStepperDispatch = () => {
  const dispatch = useContext(StepperDispatchContext);

  if (typeof dispatch === 'undefined') {
    throw new Error('useStepperDispatch must be used within a StepperProvider');
  }

  const incrementCurrentStep = useCallback(() => {
    dispatch({
      type: INCREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const decrementCurrentStep = useCallback(() => {
    dispatch({
      type: DECREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const setSteps = useCallback(
    (steps) => dispatch({ type: SET_STEPS, payload: { steps } }),
    [dispatch]
  );

  const setCurrentStep = useCallback(
    (stepIndex) =>
      dispatch({
        type: SET_CURRENT_STEP,
        payload: { currentStep: stepIndex },
      }),
    [dispatch]
  );

  const setLeaseToEdit = useCallback(
    (id) => dispatch({ type: SET_LEASE_TO_EDIT, payload: { id } }),
    [dispatch]
  );

  const setLeaseToRenew = useCallback(
    (id) => dispatch({ type: SET_LEASE_TO_RENEW, payload: { id } }),
    [dispatch]
  );

  const reset = useCallback(() => dispatch({ type: RESET }), [dispatch]);

  return {
    incrementCurrentStep,
    decrementCurrentStep,
    setSteps,
    setCurrentStep,
    setLeaseToEdit,
    setLeaseToRenew,
    reset,
  };
};

export const useStepper = () => [useStepperState(), useStepperDispatch()];

export default useStepper;
