import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Button, CardText, Modal, ModalBody, ModalHeader } from 'reactstrap';

import {
  useOverduePayments,
  useProcessOverduePayment,
} from '@app/modules/Agency/hooks';
import { CardLight } from '@app/modules/Card';
import { useFetchAccounts } from '@app/modules/Payment/hooks/use-assembly';
import { selectManagerPrimaryAgency } from '@app/redux/users';
import { centsToDollar, formatDate } from '@app/utils';

import { OverdueChip } from './OverdueChip';
import { PaymentMethods } from './PaymentMethods';

const notifySuccess = (label) =>
  toast.success(
    label
      ? `Your payment for ${label} was successfully initiated and is now being processed.`
      : 'Payment successfully initiated and is now being processed.'
  );

const notifyError = (label) =>
  toast.error(
    label
      ? `Processing payment for ${label} failed. Please try again.`
      : 'Payment processing failed. Please try again.'
  );

export const ResolveOverdueModal = ({ isOpen, onCancel, onSubmit }) => {
  const primaryAgency = useSelector(selectManagerPrimaryAgency);

  const { isLoading } = useFetchAccounts();

  const { data: overduePayments } = useOverduePayments({
    agencyId: primaryAgency?.id,
    useQueryOptions: {
      select: (data) =>
        data.overduePayments.map((item) => ({
          ...item,
          label: formatDate(item.calculatedAt, 'shortMonthYear'),
          amount: item.totalAmountCents,
          state: 'default',
        })),
    },
  });

  const { mutate, isLoading: isProcessing } = useProcessOverduePayment();

  // Local state
  const [overdueItems, setOverdueItems] = useState([]);
  const [selectedOverdueItemId, setSelectedOverdueItemId] = useState(null);

  const itemsWithState = useMemo(() => {
    return overdueItems.map((item) => ({
      ...item,
      state:
        item.id === selectedOverdueItemId
          ? 'active'
          : item.state === 'active'
          ? 'default'
          : item.state,
    }));
  }, [overdueItems, selectedOverdueItemId]);

  const selectedOverdueItem = itemsWithState.find(
    (item) => item.id === selectedOverdueItemId
  );

  const unresolvedItemsCount = itemsWithState
    ? itemsWithState.reduce((count, item) => {
        return item.state === 'success' || item.state === 'processing'
          ? count + 1
          : count;
      }, 0)
    : 0;

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

  const agencyId = primaryAgency?.id;
  const promisepayId = selectedPaymentMethod?.promisepayId;

  // reset selected payment method when modal is closed
  useEffect(() => {
    return () => {
      setSelectedPaymentMethod(null);
    };
  }, []);

  useEffect(() => {
    if (overduePayments && overduePayments.length > 0) {
      const defaultOverduePayment = overduePayments.find(
        (item) => item.state === 'default'
      );

      if (defaultOverduePayment && defaultOverduePayment?.id) {
        const activeId = defaultOverduePayment.id;

        const updatedOverduePayments = overduePayments.map((item) => {
          if (item.id === activeId) {
            return {
              ...item,
              state: 'active',
            };
          }

          return item;
        });

        setOverdueItems(updatedOverduePayments);
        setSelectedOverdueItemId(activeId);
      }
    }
  }, [overduePayments]);

  const handleSelectMonth = (id) => () => {
    setSelectedOverdueItemId(id);
  };

  const handleSubmit = useCallback(() => {
    const filtered = itemsWithState.filter(
      (item) => item.id !== selectedOverdueItemId && item.state === 'default'
    );

    const selectedItem = itemsWithState.find(
      (item) => item.id === selectedOverdueItemId
    );

    setOverdueItems((prevState) => {
      return prevState.map((item) => {
        if (item.id === selectedOverdueItemId) {
          return {
            ...item,
            state: 'processing',
          };
        }
        return item;
      });
    });

    // // trigger payment with selected payment method and overdue item
    mutate(
      {
        agencyId,
        promisepayId,
        platformChargeId: selectedOverdueItemId,
      },
      {
        onSuccess: () => {
          notifySuccess(selectedItem?.label);

          if (filtered.length) {
            setSelectedOverdueItemId(filtered[0].id);
          } else {
            const overduePaymentIds = itemsWithState.map((item) => item.id);
            onSubmit(overduePaymentIds);
            setSelectedOverdueItemId(null);
          }
        },
        onError: () => {
          notifyError(selectedItem?.label);
        },
      }
    );
  }, [
    agencyId,
    itemsWithState,
    mutate,
    onSubmit,
    promisepayId,
    selectedOverdueItemId,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
      centered
      size="lg"
      className="resolve-overdue-container mobile-fullscreen">
      <ModalHeader toggle={onCancel}>
        <FontAwesomeIcon
          icon={['far', 'circle-exclamation-check']}
          className="mr-1"
        />
        Resolve account overdue
      </ModalHeader>
      <ModalBody>
        <CardLight>
          <div>
            {overdueItems && (
              <>
                <div className="d-flex justify-content-between">
                  <CardText>Select the Month to Pay</CardText>
                  {overdueItems.length > 1 && (
                    <CardText className="font-weight-bold">
                      {unresolvedItemsCount} of {overdueItems.length}
                    </CardText>
                  )}
                </div>

                <div className="overdue-chips-container">
                  {itemsWithState.map((item) => (
                    <OverdueChip
                      key={`item-${item.id}`}
                      state={item.state}
                      amount={centsToDollar(item.amount)}
                      label={item.label}
                      onClick={handleSelectMonth(item.id)}
                    />
                  ))}
                </div>
              </>
            )}

            <CardText>
              Select a Payment Method to Process Your Outstanding Balance.
            </CardText>

            <PaymentMethods
              onSelectPaymentMethod={setSelectedPaymentMethod}
              selectedPaymentMethod={{
                id: selectedPaymentMethod?.id,
                promisepayId: selectedPaymentMethod?.promisepayId,
              }}
            />
          </div>
          <div className="modal-footer-container d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <p className="text-muted mb-0 small">
              It may take up to 5 business days for your payment to reflect on
              billings page.
            </p>
            <div className="modal-footer-actions">
              {!isLoading && !selectedOverdueItemId ? (
                <Button
                  data-testid="close-button"
                  onClick={onCancel}
                  color="primary"
                  outline>
                  Close
                </Button>
              ) : (
                <>
                  <Button
                    type="button"
                    color="primary"
                    outline
                    onClick={onCancel}>
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    data-testid="form-submit-button"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={
                      isLoading || !selectedPaymentMethod || isProcessing
                    }>
                    Pay{' '}
                    {selectedOverdueItem &&
                      centsToDollar(selectedOverdueItem.amount)}
                  </Button>
                </>
              )}
            </div>
          </div>
        </CardLight>
      </ModalBody>
    </Modal>
  );
};

ResolveOverdueModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};
