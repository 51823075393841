import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import { useIsMobile } from '../../hooks';
import { NavBar, NavMain } from '../../modules/Nav';
import { getProfile } from '../../redux/profile';
import { canAccessASingleProperty } from '../../redux/property';

const NavigationComponent = ({
  isNavOpen,
  canAccessASingleProperty,
  user,
  handleClickNavIcon,
  isBpayOutEnabled,
}) => {
  const isMobile = useIsMobile();

  return (
    <NavBar isOpen={isNavOpen}>
      <Container
        id="navigation-main"
        className="px-3 h-100 align-items-start"
        style={{ width: !isMobile && '14.375rem' }}>
        <NavMain
          canAccessASingleProperty={canAccessASingleProperty}
          user={user}
          onHandleClickNavIcon={handleClickNavIcon}
          isBpayOutEnabled={isBpayOutEnabled}
        />
      </Container>
    </NavBar>
  );
};

NavigationComponent.propTypes = {
  canAccessASingleProperty: PropTypes.bool,
  handleClickNavIcon: PropTypes.func,
  user: PropTypes.object.isRequired,
  isBpayOutEnabled: PropTypes.bool,
  isNavOpen: PropTypes.bool,
};

const mapStateToProps = ({ profile, property, users }) => ({
  canAccessASingleProperty: canAccessASingleProperty(property),
  user: getProfile(profile),
  isBpayOutEnabled: profile.isBpayOutEnabled,
});

export const Navigation = connect(mapStateToProps)(NavigationComponent);
