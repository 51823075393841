import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  InspectionReportActions,
  InspectionReportList as InspectionReportListModule,
} from '@app/modules/Inspection';
import {
  createInspectionReport,
  createUploadedInspectionReport,
  deleteInspectionReport,
  fetchPropertyInspectionReports,
  getCreatedInspectionReport,
  getInspectionPropertyReports,
  resetCreatedReport,
  updateUploadedReport,
} from '@app/redux/inspection';
import { fetchLeases, getLeasesByProperty } from '@app/redux/lease';
import { getProfile } from '@app/redux/profile';
import { toQueryObject } from '@app/utils';

export const InspectionReportList = ({
  canStartReport,
  className,
  property,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = useMemo(
    () => toQueryObject(history.location.search),
    [history.location.search]
  );

  const createdReport = useSelector((state) =>
    getCreatedInspectionReport(state.inspection)
  );
  const isLoading = useSelector((state) => state.inspection.isLoading);
  const leases = useSelector((state) =>
    getLeasesByProperty(state.lease, property.id, true)
  );
  const reports = useSelector((state) =>
    getInspectionPropertyReports(state.inspection, property.id)
  );
  const userId = useSelector((state) => getProfile(state.profile).id);

  const TASK_SORT_PARAMETER = {
    date: 'inspection_date',
    inspectionType: 'type_of',
    tenant: 'tenant_name',
    status: 'status',
  };

  const DIRECTION = {
    ascending: 'asc',
    descending: 'desc',
  };

  const [sortConfig, setSortConfig] = useState({});

  const handleSortReports = useCallback(
    (sort) => {
      const isSameKey = sort.key === sortConfig.key;
      const direction =
        isSameKey && sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';

      setSortConfig({ key: sort.key, direction });
    },
    [sortConfig]
  );

  const handleCreateReport = useCallback(
    (values) =>
      dispatch(
        createInspectionReport({
          propertyId: property.id,
          ...values,
        })
      ),
    [dispatch, property.id]
  );

  const handleCreateUploadedReport = useCallback(
    (values) =>
      dispatch(
        createUploadedInspectionReport({
          propertyId: property.id,
          ...values,
        })
      ),
    [dispatch, property.id]
  );

  const handleDelete = useCallback(
    (reportId) => () =>
      dispatch(deleteInspectionReport({ reportId, propertyId: property.id })),
    [dispatch, property.id]
  );

  const handleUploadReportClosed = useCallback(() => {
    if (createdReport.id) {
      dispatch(resetCreatedReport());
    }
  }, [dispatch, createdReport.id]);

  const handleUploaderComplete = useCallback(
    (reportId) => (values) =>
      dispatch(updateUploadedReport({ ...values, reportId })),
    [dispatch]
  );

  useEffect(() => {
    if (property.id) {
      dispatch(
        fetchPropertyInspectionReports({
          propertyId: property.id,
          page: params.page,
          sortKey: TASK_SORT_PARAMETER[sortConfig.key] || 'inspection_date',
          sortDirection: DIRECTION[sortConfig.direction] || 'desc',
        })
      );

      if (leases.length === 0) {
        dispatch(fetchLeases({ propertyId: property.id }));
      }
    }
  }, [dispatch, property.id, params, sortConfig, leases.length]);

  return (
    <Container className={className} data-testid="inspection-report-list">
      <InspectionReportActions
        createdReport={createdReport}
        isLoading={isLoading}
        leases={leases}
        title="Inspection Reports"
        onCreateReport={
          canStartReport && property.isInspectionModuleEnabled
            ? handleCreateReport
            : null
        }
        onCreateUploadedReport={
          canStartReport ? handleCreateUploadedReport : null
        }
        onUploadClosed={canStartReport ? handleUploadReportClosed : null}
        onUploaderComplete={canStartReport ? handleUploaderComplete : null}
        isArchived={property.isArchived}
      />
      <InspectionReportListModule
        propertyId={property.id}
        reports={reports}
        path={location.pathname}
        userId={userId}
        onDelete={handleDelete}
        onUploaderComplete={handleUploaderComplete}
        isArchived={property.isArchived}
        handleSortReports={handleSortReports}
        sortConfig={sortConfig}
        isLoading={isLoading}
      />
    </Container>
  );
};

InspectionReportList.propTypes = {
  canStartReport: PropTypes.bool,
  className: PropTypes.string,
  property: PropTypes.object.isRequired,
};

InspectionReportList.defaultProps = {
  canStartReport: false,
};
