import PropTypes from 'prop-types';
import React from 'react';

import InspectionExpressLogo from '@app/assets/InspectionExpress.png';
import { useMessageClasses } from '@app/hooks';
import { CardPlain } from '@app/modules/Card';
import { LinkUser } from '@app/modules/Link';
import { UserAvatar } from '@app/modules/User';
import { toClassName } from '@app/utils';

export const InspectionExpressMessage = (props) => {
  const { reportUrl } = props;

  const className = useMessageClasses(false);

  return (
    <div className={toClassName([className.outer], '')}>
      <div className={toClassName([className.inner], '')}>
        <UserAvatar
          className="ml-1 mr-2"
          role={'manager'}
          size={0.65}
          user={{ avatarUrl: null }}
        />
        <span className="text-muted">
          <LinkUser
            hasLink={false}
            userId={null}
            userName={'Inspection Express replied to Agency'}
            userType={'manager'}
          />
        </span>
      </div>
      <div className={'w-75'}>
        <CardPlain className="border mr-1">
          Inspection completed and stored securely in{' '}
          <a href={reportUrl} target={'_blank'}>
            Inspection Express
          </a>
          <img
            className="inspection-express-logo"
            src={InspectionExpressLogo}
            alt="Inspection Express"
          />
        </CardPlain>
      </div>
    </div>
  );
};

InspectionExpressMessage.defaultProps = {
  reportUrl: null,
};

InspectionExpressMessage.propTypes = {
  reportUrl: PropTypes.string,
};
