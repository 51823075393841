import { createSelector } from '@reduxjs/toolkit';

export const getPlatformChargesState = (state) => state.platformCharges;

export const selectPlatformCharges = createSelector(
  getPlatformChargesState,
  (platformCharges) => platformCharges.data
);

export const selectIsPlatformChargesLoading = createSelector(
  getPlatformChargesState,
  (platformCharges) => platformCharges.isLoading
);
