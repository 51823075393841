import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import startCase from 'lodash/fp/startCase';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';

import { useDownloadLinks, useIsChanged } from '@app/hooks';
import { CardLight, CardUserProfile } from '@app/modules/Card';
import { CardWalletBasic } from '@app/modules/Card/Wallet';
import { useContactRole } from '@app/modules/Contacts/hooks';
import { Header } from '@app/modules/Header';
import { PastLeaseCard } from '@app/modules/Lease/PastLeaseCard';
import { PaymentMethod } from '@app/modules/Payment';
import {
  PaymentDisbursementAccountCard,
  Skeleton,
} from '@app/modules/Payment/DisbursementAccountCard';
import {
  getDisbursementAccount,
  useFetchAccounts,
} from '@app/modules/Payment/hooks/use-assembly';
import { useRolesContext } from '@app/modules/Profile';
import { PropertyCardTenant } from '@app/modules/Property';
import { DownloadReport } from '@app/modules/Report';
import { getPaymentAccount, isBpayPayment } from '@app/redux/assembly';
import { getOwnerCompany } from '@app/redux/company';
import {
  fetchProperty,
  getPropertiesFromIds,
  getPropertyOwnershipsFromIds,
  selectIsPropertyLoading,
} from '@app/redux/property';
import {
  fetchUser,
  getUserByType,
  getUserPropertyAccountIds,
  getUserPropertyIds,
  selectIsUsersLoading,
} from '@app/redux/users';

import './styles.scss';

const TenantComponent = ({
  history,
  params,
  properties,
  propertyIds,
  type,
  user,
}) => {
  const dispatch = useDispatch();
  const isChangedPropertyIds = useIsChanged(propertyIds);
  const isUsersLoading = useSelector(selectIsUsersLoading);
  const isPropertyLoading = useSelector(selectIsPropertyLoading);

  const {
    data: tenantDisbursementAccount,
    isLoading: isLoadingDisbursementAccount,
  } = useFetchAccounts(
    {
      tenantId: params.id,
    },
    {
      select: getDisbursementAccount,
      enable: false,
    }
  );

  const { isManager, isPrincipal, isCorporateUser } = useRolesContext();

  const contactRole = useContactRole(type);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isChangedPropertyIds) {
      // Check that property data exists, else fetch it
      propertyIds.forEach((id) => {
        if (Object.keys(properties[id]).length === 0) {
          dispatch(fetchProperty({ propertyId: id }));
        }
      });
    }
  }, [isChangedPropertyIds, properties, propertyIds]);

  useEffect(() => {
    if (!params.id || !params.type) return;

    dispatch(fetchUser(params));
  }, [params.type, params.id]);

  const handleProperty = useCallback(
    (property) => () => history.push(`/property/${property.id}`),
    [history]
  );

  const downloadLinks = useDownloadLinks(
    `/api/tenants/${params.id}/pay-in-report`,
    `${user.firstName} ${user.lastName}_pay-in-report`
  );

  const autoPaymentSelection = useMemo(
    () => [
      { title: 'Automatically pay my rent', value: user.autoRentPayment },
      { title: 'Automatically pay my bill', value: user.autoBillPayment },
    ],
    [user.autoBillPayment, user.autoRentPayment]
  );

  const show = useMemo(() => {
    return {
      bpayReports: contactRole.isTenant,
      properties:
        !isPropertyLoading && properties && Object.keys(properties).length > 0,
      autoPaySelection: (isManager || isPrincipal) && contactRole.isTenant,
      agencyNotes:
        (isManager || isPrincipal || isCorporateUser) &&
        (contactRole.isOwner || contactRole.isTenant),
    };
  }, [properties, contactRole]);

  const handleEdit = useCallback(
    () => history.push(`/contacts/${params.type}/${params.id}/edit`),
    [history, params.id, params.type]
  );

  return (
    <>
      <Header title={startCase(type)} />
      <Container>
        <CardUserProfile
          user={user}
          isLoading={isUsersLoading}
          onEdit={handleEdit}
          isNotesShown={show.agencyNotes}
        />

        <div className="cards-container mb-3">
          <div className="wallet-section">
            <CardWalletBasic
              amountCents={user?.walletBalanceAmountCents}
              isLoading={isUsersLoading}
            />
            {(user?.isPrimaryTenant ||
              user?.isPrimaryTenantOfSomeTerminatedLease) &&
              user?.virtualAccount && (
                <PaymentMethod
                  params={{
                    tenantId: params.id,
                  }}
                  bpayAccount={{
                    bpayBillerCode: user.bpayBillerCode,
                    bpayReference: user.bpayReference,
                  }}
                  virtualAccount={{
                    ...user.virtualAccount,
                    accountName: user
                      ? `${user?.firstName} ${user?.lastName}`
                      : '',
                  }}
                />
              )}
          </div>
          <div className="other-details mb-3">
            {show.autoPaySelection && (
              <>
                <PaymentDisbursementAccountCard
                  id={params?.id}
                  canWithdraw={user.isAllowedToWithdraw}
                  daysUntilWithdrawalEnabled={user.daysUntilWithdrawalEnabled}
                  tenantDisbursementAccount={tenantDisbursementAccount}
                  isLoading={isUsersLoading || isLoadingDisbursementAccount}
                />

                <CardLight
                  className="mb-3 h-100"
                  data-testid="show-autopayment-selection"
                  title="Automate my payments">
                  {isUsersLoading ? (
                    <>
                      <Skeleton width="190" />
                      <br />
                      <Skeleton width="190" />
                    </>
                  ) : (
                    autoPaymentSelection.map(({ title, value }) => (
                      <div key={`autopay-selection-${title}`}>
                        <FontAwesomeIcon
                          className={`mr-1 ${
                            value ? 'text-success' : 'text-danger'
                          }`}
                          icon={[
                            'far',
                            value ? 'check-circle' : 'times-circle',
                          ]}
                        />
                        {title}
                      </div>
                    ))
                  )}
                </CardLight>

                {show.bpayReports && (
                  <DownloadReport
                    buttonClassName="p-0"
                    className="h-100"
                    title="Tenant Pay in Report"
                    downloadLinks={downloadLinks}
                  />
                )}
              </>
            )}
            {Object.keys(properties).length > 0 && (
              <div>
                <>
                  <h5 className="pt-2">
                    {contactRole.isTenant ? 'Active Leases' : 'Properties'}
                  </h5>

                  <hr />
                </>

                {contactRole.isTenant &&
                  propertyIds.map(
                    (id) =>
                      properties[id] && (
                        <PropertyCardTenant
                          key={`property-${id}`}
                          property={properties[id]}
                          handleProperty={handleProperty}
                          isLoading={isPropertyLoading}
                        />
                      )
                  )}
              </div>
            )}

            {contactRole.isTenant && !!user.pastLeases?.length && (
              <div>
                <h5 className="pt-2">Past Leases</h5>
                <hr />
                {user.pastLeases.map((lease) => (
                  <PastLeaseCard
                    key={`past-lease-${lease.id}`}
                    onClick={handleProperty}
                    {...lease}
                  />
                ))}

                <PastLeaseCard
                  key={`asdfpast-lease-${user.pastLeases[0].id}`}
                  onClick={handleProperty}
                  {...user.pastLeases[0]}
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

TenantComponent.propTypes = {
  agencyId: PropTypes.number,
  company: PropTypes.object,
  history: PropTypes.object,
  isShowBpay: PropTypes.bool,
  ownerId: PropTypes.number,
  params: PropTypes.object,
  paymentAccount: PropTypes.object,
  properties: PropTypes.object,
  propertyOwnerships: PropTypes.object,
  propertyAccountIds: PropTypes.object,
  propertyIds: PropTypes.array,
  type: PropTypes.string,
  user: PropTypes.object,
  tenantDisbursementAccount: PropTypes.object,
};

TenantComponent.defaultProps = {
  company: {},
  isShowBpay: false,
  paymentAccount: {},
  propertyAccountIds: {},
  propertyOwnerships: {},
  properties: [],
  propertyIds: [],
  user: {},
};

// TODO: use redux selectors
const mapStateToProps = (state, props) => {
  const { params } = props.match || {};
  // const type = formatUserTypeParam(params.type);
  const type = 'tenant';
  const user = getUserByType(state.users, params.id, type);

  // Owner property disbursement IDs
  const propertyAccountIds = getUserPropertyAccountIds(
    state.users,
    params.id,
    type
  );

  // Owner and tenant associated property IDs
  const propertyIds = getUserPropertyIds(state.users, params.id, type);

  const agencyId = user.agency && user.agency.id;
  const ownerId = agencyId || user.id;

  return {
    agencyId,
    company: getOwnerCompany(state.company, ownerId),
    isShowBpay: isBpayPayment(state.assembly),
    // tenantDisbursementAccount: getDisbursementAccount(state.assembly),
    ownerId,
    params: { id: params.id, type: 'tenants' },
    paymentAccount: getPaymentAccount(state.assembly), // For Owners & Tenants
    properties: getPropertiesFromIds(state.property, propertyIds),
    propertyOwnerships: getPropertyOwnershipsFromIds(
      state.property,
      propertyIds
    ),
    propertyAccountIds,
    propertyIds,
    type,
    user,
  };
};

export const Tenant = connect(mapStateToProps)(TenantComponent);
