// import { get } from 'lodash';
import { STEP_FIELDS } from './constants';

/**
 * Checks if a specific step has any field errors.
 *
 * @param {Object} errors - formik errors object.
 * @param {number} step - The step number to check for errors.
 * @returns {boolean} - Returns true if the step has any errors, otherwise false.
 */
export const stepHasErrors = (errors, step) => {
  if (!errors || typeof errors !== 'object') return false;
  return STEP_FIELDS[step].some((field) => {
    return field.split('.').reduce((acc, key) => acc?.[key], errors);
  });
};

export const requiredFieldsValidation = (fieldType) => {
  switch (fieldType) {
    case 'bankAccountName':
      return 'Account name is required';
    case 'bankRoutingNumber':
      return 'BSB is required';
    case 'bankAccountNumber':
      return 'Account number is required';
    default:
      return 'Enter valid bank data';
  }
};

export const getResponseErrorMessage = (fieldType) => {
  switch (fieldType) {
    case 'routing_number':
      return 'Please enter a valid bank routing number';
    case 'account_number':
      return 'Please enter valid Account Number';
    case 'account_name':
      return 'Please enter valid Account Name';
    default:
      return 'Please enter valid data';
  }
};

export const parseZaiFieldName = (zaiFieldName) => {
  switch (zaiFieldName) {
    case 'account_name':
      return 'bankAccountName';
    case 'routing_number':
      return 'bankRoutingNumber';
    case 'account_number':
      return 'bankAccountNumber';
    default:
      return '';
  }
};
