export const SENT_HISTORY_COLUMNS = [
  {
    id: 'name',
    name: 'Name',
    size: 2,
  },
  {
    id: 'recipientPhoneNumber',
    name: 'Phone Number',
    size: 2,
  },
  {
    id: 'message',
    name: 'Message',
    size: 3,
  },
  {
    id: 'status',
    name: 'Status',
    className: 'pl-3',
    size: 2,
  },
  {
    id: 'createdAt',
    name: 'Created at',
    size: 3,
  },
];

export const INITIAL_FILTER = {
  createdFrom: null,
  createdTo: null,
  status: { pending: true, completed: true, failed: true },
  searchValue: '',
  searchBy: 'phone',
  type: '',
};

export const getMessageProperties = (status) => {
  switch (status) {
    case 'read':
    case 'delivered':
      return {
        color: 'success',
        text: 'completed',
      };
    case 'draft':
    case 'sent':
    case 'queued':
      return {
        color: 'warning',
        text: 'pending',
      };
    case 'failed':
    case 'canceled':
    case 'dead':
    case 'undelivered':
      return {
        color: 'danger',
        text: 'failed',
      };
    default:
      return 'secondary';
  }
};
