import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import {
  decorateLease,
  decorateModifications,
  fetchLeases,
} from '@app/redux/lease';
import { SUCCESS } from '@app/redux/lease/constants';
import { centsToDollar, httpClient } from '@app/utils';

export const QUERY_KEYS = {
  CREATE_ACTIVATION_TASKS: 'createActivationTasks',
  GET_ACTIVATION_TASKS: 'getActivationTasks',
  UPDATE_ACTIVATION_TASK: 'updateActivationTask',
  DELETE_ACTIVATION_TASKS: 'deleteActivationTask',
  RESET_ACTIVATION_TASKS: 'resetActivationTasks',
  DISBURSE_BOND: 'disburseBond',
  LEASE_CREATE: 'leaseCreate',
  LEASE_CANCEL: 'leaseCancel',
  RENEW_LEASE: 'renewLease',
  REPLACE_PRIMARY_TENANT: 'replacePrimaryTenant',
  REQUEST_DEPOSIT: 'requestDeposit',
  GET_OUTGOINGS_ESTIMATE: 'getOutgoingEstimate',
  UPDATE_OUTGOINGS_ESTIMATE: 'updateOutgoingEstimate',
  CREATE_OUTGOING_ESTIMATE_ITEM: 'createOutgoingEstimateItem',
  UPDATE_OUTGOING_ESTIMATE_ITEM: 'updateOutgoingEstimateItem',
  DELETE_OUTGOING_ESTIMATE_ITEM: 'deleteOutgoingEstimateItem',
  HARD_TERMINATE_LEASE: 'hardTerminateLease',
  GET_RENT_HISTORY: 'getRentHistory',
  DELETE_RENT_INCREASE: 'deleteRentIncrease',
};

const leaseCreate = async ({ propertyId, leaseId, ...payload }) => {
  try {
    const response = propertyId
      ? await httpClient.post(`properties/${propertyId}/lease`, payload)
      : await httpClient.put(`leases/${leaseId}`, payload);

    return decorateLease(response?.data?.lease);
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const leaseCancel = async ({ leaseId }) => {
  try {
    const response = await httpClient.post(`leases/${leaseId}/cancel`);

    return decorateLease(response?.data?.lease || {});
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const createActivationTasks = async ({ propertyId, ...payload }) => {
  try {
    const response = await httpClient.post(
      `properties/${propertyId}/tasks/create-lease-activation-task`,
      payload
    );

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const getActivationTasks = async ({ leaseId }) => {
  try {
    const response = await httpClient.get(`leases/${leaseId}/activation_tasks`);

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const updateActivationTask = async ({ propertyId, taskId, payload }) => {
  try {
    const response = await httpClient.patch(
      `properties/${propertyId}/tasks/${taskId}/update-lease-activation-task`,
      payload
    );

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const deleteActivationTask = async ({ taskId, propertyId }) => {
  try {
    const response = await httpClient.delete(
      `properties/${propertyId}/tasks/${taskId}`
    );

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const resetActivationTasks = async ({ propertyId, ...payload }) => {
  try {
    const response = await httpClient.post(
      `properties/${propertyId}/tasks/remove-lease-activation-tasks`,
      payload
    );

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const disburseBond = async ({ leaseId, ...payload }) => {
  try {
    const response = await httpClient.post(
      `leases/${leaseId}/disburse-bond`,
      payload
    );

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const renewLease = async ({ leaseId, ...payload }) => {
  try {
    const response = await httpClient.post(
      `leases/${leaseId}/renewals`,
      payload
    );

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const replacePrimaryTenant = async ({ leaseId, ...payload }) => {
  try {
    const response = await httpClient.put(
      `leases/${leaseId}/replace-primary-tenant`,
      payload
    );

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const getOutgoingEstimate = async ({ leaseId, outgoingsId }) => {
  try {
    const response = await httpClient.get(
      `/leases/${leaseId}/commercial/outgoings_estimates/${outgoingsId}`
    );

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const requestDeposit = async ({ leaseId, propertyId }) => {
  try {
    await httpClient.post(`leases/${leaseId}/request-deposit`);

    return { propertyId };
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const updateOutgoingEstimate = async ({ leaseId, outgoingsId, payload }) => {
  try {
    const response = await httpClient.put(
      `/leases/${leaseId}/commercial/outgoings_estimates/${outgoingsId}`,
      payload
    );

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const createOutgoingEstimateItem = async ({ leaseId, payload }) => {
  try {
    const response = await httpClient.post(
      `/leases/${leaseId}/commercial/outgoings_estimate_items`,
      payload
    );

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const updateOutgoingEstimateItem = async ({ leaseId, itemId, payload }) => {
  try {
    const response = await httpClient.put(
      `/leases/${leaseId}/commercial/outgoings_estimate_items/${itemId}`,
      payload
    );

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const deleteOutgoingEstimateItem = async ({ leaseId, itemId }) => {
  try {
    const response = await httpClient.delete(
      `/leases/${leaseId}/commercial/outgoings_estimate_items/${itemId}`
    );

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const hardTerminateLease = async ({ leaseId }) => {
  try {
    const response = await httpClient.post(`leases/${leaseId}/hard-terminate`);

    return response?.data;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const getRentHistory = async ({ leaseId, params }) => {
  try {
    const response = await httpClient.get(`leases/${leaseId}/history`, {
      params,
    });

    return decorateModifications(response?.data?.history);
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

const deleteRentIncrease = async ({ leaseId, rentId }) => {
  try {
    await httpClient.delete(`leases/${leaseId}/rent-modifications/${rentId}`);
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

export const useLeaseCreation = () => {
  const dispatch = useDispatch();

  return useMutation(leaseCreate, {
    mutationKey: QUERY_KEYS.LEASE_CREATE,
    onSuccess: (data) => {
      dispatch({
        type: SUCCESS,
        payload: {
          data: { [data.id]: data },
          result: data.id,
        },
      });

      return data;
    },
  });
};

export const useLeaseCancel = () => {
  const dispatch = useDispatch();

  return useMutation(leaseCancel, {
    mutationKey: QUERY_KEYS.LEASE_CANCEL,
    onSuccess: ({ propertyId }) => {
      if (propertyId) {
        dispatch(fetchLeases({ propertyId }));
      }

      return true;
    },
  });
};

export const useCreateActivateTasks = () => {
  const queryClient = useQueryClient();

  return useMutation(createActivationTasks, {
    mutationKey: QUERY_KEYS.CREATE_ACTIVATION_TASKS,
    onSuccess: ({ leaseItems }) => {
      queryClient.setQueryData([QUERY_KEYS.GET_ACTIVATION_TASKS], () => ({
        leaseItems,
      }));
      return true;
    },
  });
};

export const useFetchActivationTasks = ({ leaseId }) => {
  return useQuery(
    [QUERY_KEYS.GET_ACTIVATION_TASKS],
    () => getActivationTasks({ leaseId }),
    {
      retry: false,
    }
  );
};

export const useUpdateActivationTask = () => {
  const queryClient = useQueryClient();

  return useMutation(updateActivationTask, {
    mutationKey: QUERY_KEYS.UPDATE_ACTIVATION_TASK,
    onSuccess: ({ leaseItem }) => {
      queryClient.setQueryData(
        [QUERY_KEYS.GET_ACTIVATION_TASKS],
        ({ leaseItems }) => {
          return {
            leaseItems: leaseItems.map((item) => {
              if (item.id === leaseItem?.id) {
                return {
                  ...item,
                  ...leaseItem,
                };
              } else return item;
            }),
          };
        }
      );
      return true;
    },
  });
};

export const useDeleteActivationTask = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteActivationTask, {
    mutationKey: QUERY_KEYS.DELETE_ACTIVATION_TASKS,
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        [QUERY_KEYS.GET_ACTIVATION_TASKS],
        ({ leaseItems }) => ({
          leaseItems: leaseItems.filter(({ id }) => id !== variables.taskId),
        })
      );
      return true;
    },
  });
};

export const useResetActivationTasks = () => {
  const queryClient = useQueryClient();

  return useMutation(resetActivationTasks, {
    mutationKey: QUERY_KEYS.RESET_ACTIVATION_TASKS,
    onSuccess: ({ leaseItems }) => {
      queryClient.setQueryData([QUERY_KEYS.GET_ACTIVATION_TASKS], () => ({
        leaseItems,
      }));
      return true;
    },
  });
};

export const useDisburseBond = () => {
  const dispatch = useDispatch();

  return useMutation(disburseBond, {
    mutationKey: QUERY_KEYS.DISBURSE_BOND,
    onSuccess: ({ lease }) => {
      if (lease?.id) {
        dispatch({
          type: SUCCESS,
          payload: {
            data: { [lease.id]: decorateLease(lease) },
            result: lease.id,
            alert: `Bond Amount Claimed: ${centsToDollar(
              lease.bondReturnedCents
            )} from Bond ID: ${lease.bondNumber}`,
          },
        });
      }
      return true;
    },
  });
};

export const useRenewLease = () => {
  const dispatch = useDispatch();

  return useMutation(renewLease, {
    mutationKey: QUERY_KEYS.RENEW_LEASE,
    onSuccess: ({ lease }) => {
      // TODO: remove integration with current redux tree
      if (lease.propertyId) {
        dispatch({
          type: SUCCESS,
          payload: {
            data: { [lease.id]: decorateLease(lease) },
            result: lease.id,
            alert: 'Lease renewed',
          },
        });
      }

      return lease;
    },
  });
};

export const useReplacePrimaryTenant = () => {
  const dispatch = useDispatch();

  return useMutation(replacePrimaryTenant, {
    mutationKey: QUERY_KEYS.REPLACE_PRIMARY_TENANT,
    onSuccess: ({ lease }) => {
      // TODO: remove integration with current redux tree
      if (lease?.propertyId) {
        dispatch(fetchLeases({ propertyId: lease.propertyId }));
      }

      return lease;
    },
  });
};

export const useRequestDeposit = () => {
  const dispatch = useDispatch();

  return useMutation(requestDeposit, {
    mutationKey: QUERY_KEYS.REQUEST_DEPOSIT,
    onSuccess: ({ propertyId }) => {
      if (propertyId) {
        dispatch(fetchLeases({ propertyId }));
      }

      return true;
    },
  });
};

export const useFetchOutgoingsEstimate = ({ enabled = true, ...props }) => {
  return useQuery(
    [QUERY_KEYS.GET_OUTGOINGS_ESTIMATE],
    () => getOutgoingEstimate(props),
    {
      retry: false,
      enabled,
      refetchOnMount: 'always',
    }
  );
};

export const useUpdateOutgoingsEstimate = () => {
  return useMutation(updateOutgoingEstimate, {
    mutationKey: QUERY_KEYS.UPDATE_OUTGOINGS_ESTIMATE,
  });
};

export const useCreateOutgoingEstimateItem = () => {
  return useMutation(createOutgoingEstimateItem, {
    mutationKey: QUERY_KEYS.CREATE_OUTGOING_ESTIMATE_ITEM,
  });
};

export const useUpdateOutgoingEstimateItem = () => {
  return useMutation(updateOutgoingEstimateItem, {
    mutationKey: QUERY_KEYS.UPDATE_OUTGOING_ESTIMATE_ITEM,
  });
};

export const useDeleteOutgoingEstimateItem = () => {
  return useMutation(deleteOutgoingEstimateItem, {
    mutationKey: QUERY_KEYS.DELETE_OUTGOING_ESTIMATE_ITEM,
  });
};

export const useHardTerminateLease = () => {
  const dispatch = useDispatch();

  return useMutation(hardTerminateLease, {
    mutationKey: QUERY_KEYS.HARD_TERMINATE_LEASE,
    onSuccess: ({ lease }) => {
      if (lease?.propertyId) {
        dispatch(fetchLeases({ propertyId: lease.propertyId }));
      }

      return true;
    },
  });
};

export const useFetchRentHistory = ({ leaseId, page = 1 }) => {
  return useQuery(
    [QUERY_KEYS.GET_RENT_HISTORY],
    () => getRentHistory({ leaseId, params: { page, perPage: 10 } }),
    {
      retry: false,
      enabled: false,
    }
  );
};

export const useDeleteRentIncrease = () => {
  return useMutation(deleteRentIncrease, {
    mutationKey: QUERY_KEYS.DELETE_RENT_INCREASE,
  });
};
