import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Pagination as BootstrapPagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

import { getPagination } from '@app/redux/pagination';
import { toClassName } from '@app/utils';

import { usePagination } from './use-pagination';

export const Pagination = ({
  name,
  isReload,
  maxPagesShown,
  onClick,
  align,
  className: classNameProps,
}) => {
  const history = useHistory();

  const pagination = useSelector((state) =>
    getPagination(state.pagination, name)
  );

  const { page, totalPages } = pagination;

  const paginationItems = usePagination({
    maxPagesShown,
    pagination,
  });

  const showPagination = useMemo(
    () =>
      name &&
      page &&
      totalPages &&
      totalPages > 1 &&
      page > 0 &&
      page <= totalPages,
    [name, page, totalPages]
  );

  const className = useMemo(() => {
    return toClassName(['d-flex', `justify-content-${align}`], classNameProps);
  }, [align, classNameProps]);

  const handleClick = useCallback(
    (path) => (e) => {
      e.preventDefault();
      onClick(path);
    },
    [onClick]
  );

  const handleClickDefault = useCallback(
    (path) => (e) => {
      e.preventDefault();
      history.push(path);
    },
    [history]
  );

  return showPagination ? (
    <BootstrapPagination className={className}>
      {paginationItems.map((page) => (
        <PaginationItem
          key={`page-${page.label}`}
          active={page.isActive}
          disabled={page.isDisabled}>
          {onClick ? (
            <PaginationLink onClick={handleClick(page.href, page)}>
              {page.label}
            </PaginationLink>
          ) : isReload ? (
            <PaginationLink href={page.href}>{page.label}</PaginationLink>
          ) : (
            <PaginationLink onClick={handleClickDefault(page.href)}>
              {page.label}
            </PaginationLink>
          )}
        </PaginationItem>
      ))}
    </BootstrapPagination>
  ) : null;
};

Pagination.propTypes = {
  name: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['start', 'center', 'end']),
  className: PropTypes.string,
  isReload: PropTypes.bool, // Determines whether or not pagination links should refresh page
  maxPagesShown: PropTypes.number,
  onClick: PropTypes.func,
};

Pagination.defaultProps = {
  align: 'center',
  isReload: true,
  maxPagesShown: 5,
};
