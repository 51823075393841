import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectProfileData } from '@app/redux/profile';

export const useHostedFields = () => {
  const { hostedFieldsEnv } = useSelector(selectProfileData);

  const hostedFields = useMemo(
    () =>
      window.assembly.hostedFields({
        environment: hostedFieldsEnv,
      }),
    [hostedFieldsEnv]
  );

  return hostedFields;
};

export default useHostedFields;
