import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';

export const HoverPopover = ({ targetId, content, placement, children }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = useCallback(() => {
    setPopoverOpen((prev) => !prev);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setPopoverOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setPopoverOpen(false);
  }, []);

  return (
    <div
      id={targetId}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {children}
      <Popover
        placement={placement}
        isOpen={popoverOpen}
        target={targetId}
        toggle={togglePopover}>
        <PopoverBody>{content}</PopoverBody>
      </Popover>
    </div>
  );
};

HoverPopover.propTypes = {
  targetId: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
};

HoverPopover.defaultProps = {
  placement: 'bottom',
};
