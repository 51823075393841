import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { ButtonCancel, ButtonSubmit } from '@app/modules/Button';

import { MessageInfoStatus } from './MessageInfoStatus';
import { UserContact } from './UserContact';
import './styles.scss';

export const SmsOverview = ({
  modalState,
  toggleModal,
  isLoading,
  onSubmit,
  btnCancelText,
  btnSubmitText,
  title,
  creditsNeeded,
  recipients,
  senderName,
  message,
  body,
  status,
  createdAt,
  sentAt,
}) => {
  const isDraft = status === 'Draft';

  const handleToggleModal = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  return (
    <Modal
      id="message-preview-modal"
      isOpen={modalState.isOpen}
      toggle={handleToggleModal}
      size="md"
      centered>
      <ModalHeader className="py-2">
        <FontAwesomeIcon
          icon={['far', 'circle-exclamation-check']}
          className="mr-2"
        />
        {title}
      </ModalHeader>
      <ModalBody>
        {body && <p className="text-muted text-small">{body}</p>}
        <div>
          <UserContact
            className="border-bottom mb-3 pb-3"
            label="To"
            recipients={recipients}
          />
          <UserContact
            label="From"
            className="mb-2"
            user={senderName}
            size={1}
          />
        </div>
        {status && (
          <MessageInfoStatus
            status={status?.toLowerCase()}
            createdAt={createdAt}
            sentAt={sentAt}
            isBulkMessage
          />
        )}
        <p className="message-content p-3">{message}</p>
        {!isDraft && (
          <dl className="my-3 d-flex justify-content-between">
            <dt className="font-weight-light">Total SMS Credit</dt>
            <dd className="font-weight-bold">{creditsNeeded} Credits</dd>
          </dl>
        )}
        <div className="text-right mt-3">
          <ButtonCancel
            className="mr-2"
            color="primary-alt"
            onClick={handleToggleModal}>
            {btnCancelText}
          </ButtonCancel>
          {onSubmit && (
            <ButtonSubmit
              color="primary-alt"
              onClick={onSubmit}
              disabled={isLoading || creditsNeeded === 0}>
              {isLoading ? (
                <FontAwesomeIcon
                  className="text-white mr-1"
                  icon={['far', 'spinner']}
                  spin
                />
              ) : (
                btnSubmitText
              )}
            </ButtonSubmit>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

SmsOverview.propTypes = {
  modalState: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  btnCancelText: PropTypes.string,
  btnSubmitText: PropTypes.string,
  title: PropTypes.string,
  creditsNeeded: PropTypes.number,
  recipients: PropTypes.array,
  senderName: PropTypes.string,
  message: PropTypes.string,
  body: PropTypes.string,
  status: PropTypes.string,
  createdAt: PropTypes.string,
  sentAt: PropTypes.string,
};

SmsOverview.defaultProps = {
  isLoading: false,
  btnCancelText: 'Close',
  btnSubmitText: 'Send',
  title: 'Message Preview',
  body: '',
};
