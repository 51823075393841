import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { FormGroup, Label } from 'reactstrap';

import { usePrevious } from '@app/hooks';
import {
  InputField,
  RadioField,
  SelectField,
} from '@app/modules/Form/FormikFields';
import { LeasePaymentValues } from '@app/modules/Lease';
import {
  toCents,
  toDollars,
  toFrequencyAmountsCents,
  toYearAmountsCents,
} from '@app/utils';

import { ModalContentContainer } from './ModalContentContainer';

const RENT_FREQUENCY_OPTIONS = [
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'fortnightly',
    label: 'Fortnightly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
];

export const RentAmount = () => {
  const {
    values: { payFrequency, rentDollars, tenantPaysWater },
    touched,
    setFieldValue,
  } = useFormikContext();
  const previousPayFrequency = usePrevious(payFrequency);

  const frequencyLabel = useMemo(
    () => payFrequency.slice(0, -2),
    [payFrequency]
  );

  const amountCents = toFrequencyAmountsCents(toCents(rentDollars || 0));

  const annualRentCents = Math.round(amountCents[payFrequency]);

  // when pay frequency is changed, get previous pay frequency value and change rent amount
  useEffect(() => {
    if (touched.payFrequency) {
      const newAnnualRentCents = Math.round(amountCents[previousPayFrequency]);
      setFieldValue(
        'rentDollars',
        toDollars(toYearAmountsCents(newAnnualRentCents)?.[payFrequency])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, touched.payFrequency, payFrequency]);

  return (
    <>
      <h3>What is the rent amount</h3>
      <ModalContentContainer>
        <FormGroup>
          <Label for="payFrequency">Payable (Rent Frequency)</Label>
          <SelectField name="payFrequency" options={RENT_FREQUENCY_OPTIONS} />
        </FormGroup>
        <FormGroup>
          <Label for="rentDollars">Price per {frequencyLabel}</Label>
          <InputField name="rentDollars" type="number" prepend="$" />
        </FormGroup>
        <LeasePaymentValues className="mb-3" value={annualRentCents} />
        <FormGroup>
          <Label for="gstIncluded">Is GST Included?</Label>
          <div role="group" className="radio-group-wrapper">
            <RadioField name="gstIncluded" label="Yes" value="true" />
            <RadioField name="gstIncluded" label="No" value="false" />
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="tenantPaysWater">Do Tenants Pay Water?</Label>
          <div role="group1" className="radio-group-wrapper">
            <RadioField name="tenantPaysWater" label="Yes" value="true" />
            <RadioField name="tenantPaysWater" label="No" value="false" />
          </div>
        </FormGroup>
        {tenantPaysWater === 'true' && (
          <FormGroup>
            <Label for="ingoingWaterMeterReading">
              Entry Water Meter Reading <small>(optional)</small>
            </Label>
            <InputField name="ingoingWaterMeterReading" />
          </FormGroup>
        )}

        <div
          className="text-center font-weight-bold mt-5"
          hidden={rentDollars <= 0}>
          Rental invoices will be for ${rentDollars} each {frequencyLabel}
        </div>
      </ModalContentContainer>
    </>
  );
};

export default RentAmount;
