import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { FormCardAccount } from '@app/modules/Form';
import {
  QUERY_KEYS,
  useAddCreditCard,
  useCreateTokenCard,
} from '@app/modules/Payment/hooks/use-assembly';
import { selectUserFingerprint } from '@app/redux/profile';

import useHostedFields from '../hooks/use-hosted-fields';

export const AddCardAccount = ({ isOpen, setModal, onSuccessCallback }) => {
  const fingerprint = useSelector(selectUserFingerprint);
  const queryClient = useQueryClient();

  const { refetch, isFetching: isFetchingToken } = useCreateTokenCard({
    fingerprint,
  });
  const { mutate, isLoading: isAddingCreditCard } = useAddCreditCard();

  const hostedFieldsCard = useHostedFields();

  const hideModal = useCallback(() => {
    setModal(false);
  }, [setModal]);

  const createCardAccount = useCallback(
    async (token, promisepayUserId) => {
      hostedFieldsCard
        .createCardAccount({
          token,
          user_id: promisepayUserId,
        })
        .then((response) => {
          return response;
        });
    },
    [hostedFieldsCard]
  );

  const createTokenCard = async () => {
    const { data } = await refetch();

    return data;
  };

  const addCreditCard = useCallback(
    ({ promisepayId }) => {
      mutate({
        fingerprint,
        promisepayId,
        callback: () => {
          queryClient.invalidateQueries(QUERY_KEYS.FETCH_ACCOUNTS);
          onSuccessCallback();
        },
      });
    },
    [mutate, fingerprint, queryClient, onSuccessCallback]
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={hideModal}
      centered
      size="lg"
      className="add-card-account-container">
      <ModalHeader toggle={hideModal}>Add a Credit or Debit Card</ModalHeader>
      <ModalBody>
        <FormCardAccount
          onCancel={hideModal}
          onSubmit={addCreditCard}
          hostedFields={hostedFieldsCard}
          // eslint-disable-next-line react/jsx-no-bind
          createToken={createTokenCard}
          createCardAccount={createCardAccount}
          disabled={isFetchingToken || isAddingCreditCard}
          history={{}}
          canSkipConfirmation
        />
      </ModalBody>
    </Modal>
  );
};

AddCardAccount.propTypes = {
  isOpen: PropTypes.bool,
  setModal: PropTypes.func,
  onSuccessCallback: PropTypes.func,
};
