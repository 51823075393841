import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import { httpClient } from '@app/utils';

export const QUERY_KEYS = {
  UPDATE_WITHDRAWAL: 'updateWithdrawal',
};

const updateWithdrawal = async ({ id, payload }) => {
  try {
    const response = await httpClient.put(
      `tenants/${id}/update-withdrawal-permissions`,
      payload
    );

    return response?.data;
  } catch (error) {
    console.error('error', error);
    throw new Error('Something went wrong');
  }
};

export const useUpdateWithdrawal = () => {
  const dispatch = useDispatch();

  return useMutation(updateWithdrawal, {
    mutationKey: QUERY_KEYS.UPDATE_WITHDRAWAL,
    onSuccess: (data) => {
      toast.success('Withdrawal permissions updated');
      dispatch({
        type: 'users/fetchSuccess',
        payload: {
          data,
          props: { type: 'tenants' },
        },
      });
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};
