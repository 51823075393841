import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import InviteTradie from '@app/modules/Marketplace/InviteTradie';
import { useContactsLinks } from '@app/modules/Nav/hooks';
import { selectIsMarketplaceEnabled } from '@app/redux/profile';

import { MarketplaceTradieProfile } from '../Marketplace/Manager';
import { OwnerFinancials } from '../OwnerFinancials';
import { ContactsCreditor, ContactsCreditorFinancials } from './Creditor';
import { ContactsList } from './List';
import { ContactsServiceProvider } from './ServiceProvider';
import { Tenant } from './Tenants';
import { Tradies } from './Tradies';
import { ContactsUser } from './User';
import UserCreate from './UserCreate';
import { ContactsUserEdit } from './UserEdit';

export const Contacts = (props) => {
  const { history, location } = props;

  const isMarketplaceEnabled = useSelector(selectIsMarketplaceEnabled);

  const navigation = useContactsLinks();

  const renderContactsList = useCallback(
    (props) => <ContactsList {...props} navigation={navigation} />,
    [navigation]
  );

  useEffect(() => {
    if (typeof isMarketplaceEnabled !== 'boolean') return;

    if (
      location.pathname === '/contacts' ||
      (isMarketplaceEnabled &&
        location.pathname.includes('/contacts/creditors')) ||
      (!isMarketplaceEnabled &&
        location.pathname.includes('/contacts/service-providers'))
    ) {
      history.replace(
        isMarketplaceEnabled
          ? '/contacts/service-providers'
          : '/contacts/creditors'
      );
    }
  }, [history, isMarketplaceEnabled, location.pathname]);

  return (
    <Switch>
      <Route
        path="/contacts/service-providers"
        component={ContactsServiceProvider}
      />
      <Route path="/contacts/tradies" component={Tradies} exact />
      <Route
        path={'/contacts/tradies/invite'}
        // eslint-disable-next-line react/jsx-no-bind
        render={(props) => <InviteTradie {...props} isPreferred />}
        exact
      />

      <Route
        path={'/contacts/tradies/tradie/:tradieId'}
        component={MarketplaceTradieProfile}
        exact
      />

      <Route
        path="/contacts/creditors/:id"
        component={ContactsCreditor}
        exact
      />
      <Route
        path="/contacts/creditors/:id/financials"
        component={ContactsCreditorFinancials}
        exact
      />
      <Route
        path="/contacts/owners/:id/financials"
        component={OwnerFinancials}
        exact
      />
      <Route path="/contacts/:type/create" component={UserCreate} exact />
      <Route
        path="/contacts/:type/:id/edit"
        component={ContactsUserEdit}
        exact
      />
      <Route path="/contacts/tenants/:id" component={Tenant} exact />
      <Route path="/contacts/:type/:id" component={ContactsUser} exact />
      <Route path="/contacts/:type" render={renderContactsList} exact />
    </Switch>
  );
};

Contacts.propTypes = {
  history: PropTypes.object.isRequired,
  isMarketplaceEnabled: PropTypes.bool,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

Contacts.defaultProps = {
  isMarketplaceEnabled: false,
};
