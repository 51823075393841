/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import * as Dialog from '@radix-ui/react-dialog';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';

import ManagedLogo from '@app/assets/managed.svg';
import { fetchLeases, getLease } from '@app/redux/lease';
import { toDollars } from '@app/utils';

import { useLeaseState } from '../Provider';
import { useStepper } from '../common/stepper';
import { initialValues, validationSchema } from '../constants';
import Steps from './Steps';
import './styles.scss';

const loadInitialValues = ({
  leaseStartDate,
  endDate,
  tenantStartDate,
  ...lease
}) => {
  return {
    leaseStartDate,
    endDate,
    tenantStartDate,
    leased: tenantStartDate !== leaseStartDate ? 'true' : 'false',
    isCommercial: !!lease?.currentCommercialOutgoingsEstimate,
    daysRentInvoiceInAdvance: lease?.daysRentInvoiceInAdvance,
    periodic: lease?.periodic ? 'true' : 'false',
    // step 2
    payFrequency: lease.payFrequency, // select
    rentDollars: lease?.amountCents
      ? toDollars(lease.amountCents[lease.payFrequency])
      : '',
    gstIncluded: lease?.gstIncluded ? 'true' : 'false',
    tenantPaysWater: lease?.tenantPaysWater ? 'true' : 'false',
    ingoingWaterMeterReading: lease?.ingoingWaterMeterReading || 0,
    // outgoings
    monthlyOutgoings: lease?.currentCommercialOutgoingsEstimate
      ?.totalAnnualEstimateCents
      ? 'true'
      : 'false',
    chargeDateCustom:
      lease?.currentCommercialOutgoingsEstimate?.chargeFromDate ===
      lease?.startDate
        ? 'true'
        : 'false',
    chargeFromDate: lease?.currentCommercialOutgoingsEstimate?.chargeFromDate,
    chargeGST: lease?.currentCommercialOutgoingsEstimate?.gstIncluded
      ? 'true'
      : 'false',
    // step 3
    bondNumber: lease?.bondNumber || '',
    bondDollars: lease?.bondCents ? toDollars(lease.bondCents) : 0,
    // step 4
    startDate: lease?.startDate || new Date(),
    // step 5
    hasDeposit: 'true',
    deposit: toDollars(lease.depositCents),
    // step 6
    hasRoutineInspection: lease?.inspectionDate ? 'true' : 'false',
    inspectionDate: lease?.inspectionDate || '',
    inspectionDateFrequencyInMonths:
      lease?.inspectionDateFrequencyInMonths || 0, // select
    hasRentReview: lease?.reviewDate ? 'true' : 'false',
    reviewDate: lease?.reviewDate || '',
    reviewDateFrequencyInMonths: lease?.reviewDateFrequencyInMonths || 0, // select
    // step 7
    leaseUpload: '',
    ingoingReportUpload: '',
    // step 8
    tenants: {
      primary: {
        ...(lease?.primaryTenant || {}),
        type: lease?.primaryTenant?.company ? 'company' : 'private',
        company: lease?.primaryTenant?.company?.legalName || '',
        taxNumber: lease?.primaryTenant?.company?.taxNumber || '',
      },
      secondary:
        lease?.secondaryTenants.map((tenant) => ({
          ...tenant,
          type: tenant?.company ? 'company' : 'private',
        })) || [],
    },
  };
};

// eslint-disable-next-line react/prop-types
export const Modal = ({ propertyId }) => {
  const dispatch = useDispatch();
  const [{ leaseIdToEdit, changed }, { reset, setLeaseToEdit }] = useStepper();
  const lease = useSelector((state) => getLease(state.lease, leaseIdToEdit));

  const { setLeaseState } = useLeaseState();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: leaseIdToEdit ? loadInitialValues(lease) : initialValues,
    onSubmit: () => {
      handleClose({ refetch: true });
      setLeaseToEdit(0);
    },
  });

  const handleClose = useCallback(
    ({ refetch = false }) => {
      // if values changed, refetch leases
      if (changed || refetch) {
        dispatch(fetchLeases({ propertyId }));
      }
      setLeaseToEdit(0);
      formik.resetForm();
      reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changed, dispatch, propertyId]
  );

  // set lease data
  useEffect(() => {
    if (lease?.id) {
      setLeaseState((state) => ({ ...state, lease }));
    }
  }, [lease, setLeaseState]);

  return (
    <Dialog.Portal>
      <Dialog.Overlay className="DialogOverlay">
        <Dialog.Content
          className="DialogContent"
          // eslint-disable-next-line react/jsx-no-bind
          onOpenAutoFocus={(event) => {
            event.preventDefault();
          }}>
          <Logo />

          <Container className="lease-steps-container my-3">
            <div className="card">
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                  <Steps
                    propertyId={propertyId}
                    currentStep={lease?.currentStep || 0}
                    leaseId={leaseIdToEdit || 0}
                    isRenewed={lease.renewingLeaseId}
                  />
                </form>
              </FormikProvider>
            </div>
          </Container>

          <Dialog.Close asChild>
            {/* eslint-disable-next-line react/button-has-type */}
            <button
              className="IconButton"
              aria-label="Close"
              onClick={handleClose}>
              <FontAwesomeIcon icon={['far', 'xmark']} className="mr-1" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  );
};

export default Modal;

export const Logo = () => (
  <div className="logo-container mb-4">
    <AspectRatio.Root ratio={16 / 3.5}>
      <img className="managed-logo" src={ManagedLogo} alt="Managed App logo" />
    </AspectRatio.Root>
  </div>
);
