import { ERROR, FETCH, FETCH_SUCCESS } from './constants';

export const logic = [
  {
    type: FETCH,
    processOptions: {
      successType: FETCH_SUCCESS,
      failType: ERROR,
    },
    process({ httpClient }) {
      return httpClient
        .get(`/platform-charges`)
        .then((response) => response.data.platformCharges);
    },
  },
];
