import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ExternalCreditorFormNotificationSettings } from '@app/modules/ExternalCreditor/Form';
import { OwnerFormNotificationSettings } from '@app/modules/Owner/Form';
import { useRolesContext } from '@app/modules/Profile';
import { TenantFormNotificationSettings } from '@app/modules/Tenant/Form';
import { selectProfileData } from '@app/redux/profile';
import { selectUser, updateUser } from '@app/redux/users';

export const Notifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(selectProfileData);
  const user = useSelector(selectUser);

  const { isExternalCreditor, isOwner, isTenant } = useRolesContext();

  const isRoleAllowed = isOwner || isTenant;

  useEffect(() => {
    if (!isRoleAllowed) {
      history.replace('/profile');
    }
  }, [history, isRoleAllowed]);

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleSubmit = useCallback(
    (values) => {
      dispatch(updateUser({ ...values, id: profile.id, role: profile.role }));
    },
    [dispatch, profile.id, profile.role]
  );

  return (
    <>
      {profile.id && isOwner && (
        <OwnerFormNotificationSettings
          user={user}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isLoading={false}
          hasError={false}
        />
      )}
      {profile.id && isTenant && (
        <TenantFormNotificationSettings
          user={user}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isLoading={false}
          hasError={false}
        />
      )}
      {profile.id && isExternalCreditor && (
        <ExternalCreditorFormNotificationSettings
          user={user}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isLoading={false}
          hasError={false}
        />
      )}
    </>
  );
};

Notifications.propTypes = {};
