import * as Yup from 'yup';

import { formatDate, validatePhoneNumber } from '@app/utils';

export const initialValues = {
  // step 1
  leaseStartDate: new Date(),
  endDate: '',
  tenantStartDate: '',
  leased: 'false',
  daysRentInvoiceInAdvance: 30,
  isCommercial: false,
  periodic: 'false',
  // step 2
  payFrequency: 'weekly', // select
  rentDollars: null,
  gstIncluded: 'false',
  tenantPaysWater: 'false',
  ingoingWaterMeterReading: 0,
  // step 3
  monthlyOutgoings: 'false',
  chargeDateCustom: 'true',
  chargeGST: 'true',
  // step 3
  bondNumber: '',
  bondDollars: 0,
  // step 4
  startDate: new Date(),
  // step 5
  hasDeposit: 'false',
  deposit: null,
  // step 6
  hasRoutineInspection: 'false',
  inspectionDate: new Date(),
  inspectionDateFrequencyInMonths: 3, // select
  hasRentReview: 'false',
  reviewDate: new Date(),
  reviewDateFrequencyInMonths: 6, // select
  // step 7
  leaseUpload: '',
  ingoingReportUpload: '',
  // step 8
  tenants: {
    primary: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      type: 'private',
    },
    secondary: [],
  },
};

export const tenantSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .transform((value) => value.replace(/\s/g, ''))
    .test(
      'mobile-validation',
      'Valid mobile number is required',
      (mobileNumber) => {
        // mobile numbers are optional
        if (!mobileNumber?.length || mobileNumber === '0') {
          return true;
        }

        const { isValid } = validatePhoneNumber({ mobileNumber });

        return isValid;
      }
    ),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email address'),
});

export const validationSchema = Yup.object().shape({
  // step 1: Lease Dates
  leaseStartDate: Yup.string().required('Start Date is required'),
  leaseTerm: Yup.string(),
  endDate: Yup.string().required('End Date is required'),
  leased: Yup.boolean(),
  daysRentInvoiceInAdvance: Yup.number()
    .nullable()
    .when('isCommercial', {
      is: true,
      then: Yup.number()
        .required('Days is required')
        .min(1, 'Days must be greater than 0')
        .max(365, 'Days must be less than 366'),
    }),
  tenantStartDate: Yup.string().when('leased', {
    is: true,
    then: Yup.string().required('Tenant Start Date is Required'),
  }),
  // step 2
  payFrequency: Yup.string().required().default('weekly'), // select
  rentDollars: Yup.number()
    .min(10)
    .required('Rent Amount is required')
    .label('Rent Amount'),
  // step 3
  bondNumber: Yup.string(),
  bondDollars: Yup.number(),
  // // step 4
  startDate: Yup.string().required('First Payment Date is required'),
  // // step 5
  deposit: Yup.number(),
  // // step 7
  leaseUpload: Yup.string(),
  ingoingReportUpload: Yup.string(),
  // step 8
  tenants: Yup.object().shape({
    primary: tenantSchema,
    secondary: Yup.array().of(tenantSchema),
  }),
});

export const renewValidationSchema = () => {
  // 1 minute before midnight, so we can choose today.
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, -1);

  return Yup.object().shape({
    // step 1
    tenants: Yup.object().shape({
      primary: tenantSchema,
      secondary: Yup.array().of(tenantSchema),
    }),
    // step 2
    leaseStartDate: Yup.string().required('Start Date is required'),
    leaseTerm: Yup.string(),
    endDate: Yup.string().required('End Date is required'),
    daysRentInvoiceInAdvance: Yup.number().when('isCommercial', {
      is: true,
      then: Yup.number()
        .required('Days is required')
        .min(1, 'Days must be greater than 0')
        .max(365, 'Days must be less than 366'),
    }),
    // step 3
    effectiveFrom: Yup.date()
      .nullable()
      .when(
        ['scheduleAdjustment', 'modificationSchedule', 'effectiveDate'],
        // check if a rent update is already scheduled
        (scheduleAdjustment, modificationSchedule, effectiveDate) => {
          if (scheduleAdjustment === 'true') {
            if (modificationSchedule.length && effectiveDate) {
              return Yup.date().test({
                name: 'effectiveFrom',
                message: 'A rent update is already scheduled for this period',
                test: () => {
                  return !modificationSchedule.includes(
                    formatDate(effectiveDate, 'dateLocal')
                  );
                },
              });
            } else {
              return Yup.date()
                .nullable()
                .required('Effective from date is required')
                .min(startOfDay, 'Effective from date must be after today');
            }
          } else {
            return Yup.date().nullable();
          }
        }
      ),

    reasonForChange: Yup.string().when('scheduleAdjustment', {
      is: 'true',
      then: Yup.string().required('A reason for change is required'),
    }),

    rentDollars: Yup.number().when('scheduleAdjustment', {
      is: 'true',
      then: Yup.number()
        .min(10)
        .required('Rent Amount is required')
        .label('Rent Amount'),
    }),
  });
};

export const LEASE_CATEGORY = {
  active: 'ACTIVE',
  upcoming: 'UPCOMING',
  past: 'PAST',
};
