import { useQueryClient } from '@tanstack/react-query';
import { withFormik } from 'formik';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { SegmentedMessage } from 'sms-segments-calculator';
import * as Yup from 'yup';

import { QUERY_KEYS } from '@app/containers/Sms/hooks/use-sms-accounts';
import { useToggle } from '@app/hooks';
import { SelectField } from '@app/modules/Form/FormikFields';
import { useRolesContext } from '@app/modules/Profile';
import { SmsCreditBadge } from '@app/modules/Sms';
import { TopUpSmsAccountModal } from '@app/modules/Sms/TopUpSmsAccountModal';
import { getLeaseActive } from '@app/redux/lease';
import { VISIBILITY_TYPES_SMS } from '@app/redux/users/constants';

import { VISIBILITY_TYPES } from '../../../redux/users';
import { FormButtons, FormLabel, FormLabelInput } from '../../Form';
import { withOnComplete } from '../../Form/withOnComplete';

const TaskFormMessageComponent = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isDraft,
  isLoading,
  isOverlayed,
  isSubmitting,
  isValid,
  onCancel,
  touched,
  values,
  isSendAsSMS,
  propertyId,
  agencyId,
  onSubmit,
}) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [modalState, toggleModal] = useToggle();
  const { isManager, isPrincipal, isCorporateUser } = useRolesContext();
  const hasCorporateOrPrincipalAccess = isCorporateUser || isPrincipal;
  // TODO: KAN-1939 support sms sending from action centre
  const modalProps = {
    title: 'Insufficient SMS Credits',
    btnCancelText: 'Cancel',
    ...(hasCorporateOrPrincipalAccess
      ? {
          btnSubmitText: 'Top up Credits',
          onSubmit: () => history.push(`/sms/plans?agencyId=${agencyId}`),
        }
      : {
          btnSubmitText: 'Reply via email',
          body: `Your SMS credits are insufficient to send this message. Please contact your agency administrator to top up your account.`,
          note: `As an alternative, you can instead send this message as an email using the reply button instead.`,
          onSubmit: () =>
            onSubmit({
              ...values,
              notifyViaEmail: true,
              notifyViaSms: false,
            }),
        }),
  };
  const { smsAccount } =
    queryClient.getQueryData([QUERY_KEYS.FETCH_AGENCY_SMS_ACCOUNT, agencyId]) ||
    {};

  const SEND_TO_OPTIONS = isSendAsSMS ? VISIBILITY_TYPES_SMS : VISIBILITY_TYPES;
  const { segmentsCount, numberOfCharacters } = new SegmentedMessage(
    values.body
  );

  const activeLease = useSelector(({ lease }) =>
    getLeaseActive(lease, propertyId)
  );

  const numberOfOwners = activeLease?.owners?.length;
  const numberOfTenants = activeLease?.secondaryTenants?.length + 1;

  const totalNumberOfRecipients = useMemo(() => {
    switch (values.replyTo) {
      case 'all':
        return numberOfOwners + numberOfTenants;
      case 'owners':
        return numberOfOwners;
      case 'tenants':
        return numberOfTenants;
      default:
        return 0;
    }
  }, [values.replyTo, numberOfOwners, numberOfTenants]);

  // TODO: check which tenants have valid phone numbers
  const handleBalanceCheck = (props) => {
    if (smsAccount?.creditBalance < totalNumberOfRecipients * segmentsCount) {
      toggleModal();
    } else {
      handleSubmit(props);
    }
  };

  return (
    <Form>
      <FormGroup>
        {isManager && !isDraft && (
          <Row>
            <Col xs={12} className="my-2" style={{ minWidth: '160px' }}>
              <FormLabel for="replyTo" isRequired>
                {values?.notifyViaSms ? 'Send SMS to' : 'Reply to'}
              </FormLabel>
              <SelectField
                name="replyTo"
                placeholder="Select recipient"
                options={SEND_TO_OPTIONS}
                isSearchable={false}
              />
            </Col>
          </Row>
        )}
        <FormLabelInput
          type="textarea"
          label="Message"
          name="body"
          rows={6}
          isRequired
          value={values.body}
          isTouched={touched.body}
          error={errors.body}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        {values.notifyViaSms && (
          <span>
            <small className="text-muted">
              *International SMS is not yet supported
            </small>
          </span>
        )}
      </FormGroup>
      <div className="d-flex justify-content-between align-items-end flex-row-reverse">
        <FormButtons
          onCancel={onCancel}
          isSubmitting={isSubmitting && !isLoading}
          isValid={isValid}
          isOverlayed={isOverlayed}
          isFormButtonsJustifyBetween
          onSubmit={values?.notifyViaSms ? handleBalanceCheck : handleSubmit}
          btnSubmit={{
            text: values?.notifyViaSms ? 'Send' : 'Reply',
            color: 'primary',
          }}
        />
        {values.notifyViaSms && (
          <div className="m-0 d-flex flex-column">
            {values.notifyViaSms && (
              <p className="text-muted mb-2">{`Approx. ${numberOfCharacters} characters  / ${segmentsCount} SMS credit spent per recipient`}</p>
            )}
            <div className="m-0 d-flex align-items-center">
              <SmsCreditBadge
                credits={smsAccount?.creditBalance}
                contextString="Credits remaining"
                showTopUpLink
              />
            </div>
          </div>
        )}
      </div>
      {modalState.isOpen && (
        <TopUpSmsAccountModal
          modalState={modalState}
          toggleModal={toggleModal}
          creditBalance={smsAccount?.creditBalance}
          creditsNeeded={segmentsCount * totalNumberOfRecipients}
          {...modalProps}
        />
      )}
    </Form>
  );
};

TaskFormMessageComponent.propTypes = {
  dirty: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDraft: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOverlayed: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  replyTo: PropTypes.string,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  isSendAsSMS: PropTypes.bool,
  propertyId: PropTypes.number.isRequired,
  agencyId: PropTypes.number.isRequired,
};

TaskFormMessageComponent.defaultProps = {
  isOverlayed: true,
  isSendAsSMS: false,
};

const config = {
  displayName: 'TaskFormMessage',

  enableReinitialize: true,

  mapPropsToValues: ({ replyTo, isSendAsSMS }) => ({
    body: '',
    replyTo: isSendAsSMS ? '' : replyTo,
    notifyViaSms: isSendAsSMS,
    notifyViaEmail: !isSendAsSMS,
  }),

  validationSchema: Yup.object().shape({
    replyTo: Yup.string()
      .oneOf(['all', 'owners', 'tenants', 'agency', 'managers'])
      .required('Recipient is required  '),
    body: Yup.string().required('Message is required'),
  }),

  handleSubmit: (values, { props }) => props.onSubmit(values),
};

export const TaskFormMessage = compose(
  withFormik(config),
  withOnComplete
)(TaskFormMessageComponent);
