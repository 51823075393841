import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray } from 'formik';
import capitalize from 'lodash/fp/capitalize';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Button, Col, CustomInput, Row } from 'reactstrap';

import { FormFieldsForUser } from '.';
import { PropertyUserIcon } from '../../modules/Property';
import { getDeleteItemText } from '../../utils';
import { ModalDeleteItem } from '../Modal';

export const FormFieldsForMultipleUsers = ({
  canSubmit,
  type,
  values,
  setFieldValue,
}) => {
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [arrayHelpers, setArrayHelpers] = useState({});
  const [tenantTypeValue, setTenantTypeValue] = useState('private');
  const tenant = values[index];
  const fieldName = `secondary${capitalize(type)}s`;
  const modalText = getDeleteItemText(tenant, 'tenant', 'lease');
  const buttonText = `Add a${type === 'owner' ? 'n' : ''} ${type}`;

  const handleToggleModal = useCallback(
    (index, helpers) => {
      if (index) {
        setIndex(index);
      }
      if (helpers && helpers.replace) {
        setArrayHelpers(helpers);
      }
      setIsShowDeleteModal(!isShowDeleteModal);
    },
    [isShowDeleteModal]
  );

  const handleDeleteUser = useCallback(() => {
    if (
      arrayHelpers &&
      arrayHelpers.replace &&
      typeof arrayHelpers.replace === 'function'
    ) {
      tenant?.id
        ? arrayHelpers.replace(index, { ...tenant, _destroy: 1 })
        : arrayHelpers.remove(index);

      setIndex(0);
      setIsShowDeleteModal(false);
    }
  }, [arrayHelpers, tenant, index]);

  return (
    <>
      <FieldArray name={fieldName}>
        {({ push, remove, replace, form }) => {
          const handleTenantTypeValueChange = (e) => {
            setTenantTypeValue(e.target.value);
            const newTenant = values.filter((tenant) => !tenant.id)[0]; // tenant without id in values
            const newTenantIndex = values.findIndex((tenant) => !tenant.id);
            replace(newTenantIndex, {
              ...newTenant,
              tenantType: e.target.value,
            });
          };

          const handleAdd = () => {
            const tenantObj = {
              id: '',
              email: '',
              firstName: '',
              lastName: '',
              phoneNumber: '',
              legalName: '',
              taxNumber: '',
              tenantType: tenantTypeValue,
            };
            push(tenantObj);
          };

          return (
            <>
              {values.map((user, index) => (
                <Row
                  className={'mb-2 ' + (user._destroy ? 'd-none' : '')}
                  key={index}>
                  <Col className="d-flex flex-row align-items-center">
                    {user.id ? (
                      <>
                        {canSubmit && (
                          <button
                            className="btn text-danger"
                            type="button"
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={() =>
                              handleToggleModal(index, {
                                remove,
                                replace,
                              })
                            }>
                            <FontAwesomeIcon icon={['far', 'times-circle']} />
                          </button>
                        )}
                        <PropertyUserIcon
                          key={`user-${index}`}
                          className="mb-1 mb-sm-0"
                          disabled={false}
                          role={type}
                          user={user}
                        />
                      </>
                    ) : (
                      // add tenant form
                      <div className="flex-column mt-3">
                        <hr className="w-100 mt-0 mb-3" />
                        <div className="d-flex flex-row mt-4 mb-2">
                          {canSubmit && (
                            <button
                              className="btn text-danger align-self-start"
                              type="button"
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={() =>
                                handleToggleModal(index, {
                                  remove,
                                  replace,
                                })
                              }>
                              <FontAwesomeIcon icon={['far', 'times-circle']} />
                            </button>
                          )}
                          <div className="w-100 d-flex justify-content-end align-items-center">
                            <CustomInput
                              checked={tenantTypeValue === 'private'}
                              id="secondary-tenant-private"
                              className="pr-3"
                              label="Personally"
                              name="tenantTypeValue"
                              type="radio"
                              value="private"
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={handleTenantTypeValueChange}
                            />
                            <CustomInput
                              checked={tenantTypeValue === 'company'}
                              id="secondary-tenant-company"
                              label="Company / Trust"
                              name="tenantTypeValue"
                              type="radio"
                              value="company"
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={handleTenantTypeValueChange}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <FormFieldsForUser
                            className="w-100"
                            key={`user-${index}`}
                            attributeName={`secondary${capitalize(
                              type
                            )}s[${index}]`}
                            isDisabled={false}
                            tenantType={tenantTypeValue}
                            type={type}
                            setFieldValue={setFieldValue}
                            isSearchable
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              ))}
              {canSubmit && (
                <Button
                  type="button"
                  className={`mt-3 ${
                    values.filter((tenant) => !tenant.id).length > 0 && 'd-none'
                  }`}
                  color="primary"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={handleAdd}>
                  {buttonText}
                </Button>
              )}
            </>
          );
        }}
      </FieldArray>
      <ModalDeleteItem
        size="md"
        isOpen={isShowDeleteModal}
        title="Are you sure?"
        bodyText={modalText}
        onSubmit={handleDeleteUser}
        onCancel={handleToggleModal}
      />
    </>
  );
};

FormFieldsForMultipleUsers.defaultProps = {
  type: 'owner',
  values: [],
};

FormFieldsForMultipleUsers.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  values: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
};
