import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { version } from 'settings';

import checkIcon from '@app/assets/check-icon.svg';
import { Alert } from '@app/containers';
import { BrandLogo } from '@app/modules/Brand';

import './styles.scss';

export const AuthLayout = ({
  header = 'Welcome to Managed',
  subHeader = '',
  children,
  hasCheckIcon = false,
  hasBrandLogo = true,
  isAlertVisible = true,
}) => {
  return (
    <Container fluid className="m-0 p-0 auth-layout-container overflow-hidden">
      <Row className="h-100">
        {/* Left: Form Section */}
        <Col lg={6} className="d-flex form-section justify-content-center p-0">
          <div className="d-flex flex-column justify-content-between w-100 py-6">
            {hasBrandLogo && <BrandLogo className="brand-logo-wide" />}
            <div>
              {hasCheckIcon && (
                <div className="check-icon-container">
                  <img src={checkIcon} height={156} alt="Check Icon" />
                </div>
              )}
              <div className="mb-4">
                <h1 className="h4">{header}</h1>
                {subHeader && <p className="text-muted">{subHeader}</p>}
              </div>
              <>
                {isAlertVisible && <Alert containerClassName="p-0" />}
                {children}
              </>
            </div>
            <div className="text-center">
              <p className="text-muted">
                Get support anytime in our{' '}
                <a
                  className="btn-link text-decoration-underline"
                  href="https://support.managedapp.com.au/en/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Knowledge Base.
                </a>
              </p>
              {version && <p className="text-muted">{version}</p>}
            </div>
          </div>
        </Col>

        {/* Right: Image Section */}
        <Col lg={6} className="d-lg-block d-none p-0 image-section"></Col>
      </Row>
    </Container>
  );
};

AuthLayout.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  hasCheckIcon: PropTypes.bool,
  hasBrandLogo: PropTypes.bool,
  isAlertVisible: PropTypes.bool,
  children: PropTypes.node,
};
