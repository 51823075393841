import { includes, map } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { PulseLoader } from 'react-spinners';
import { Col, Container, Row } from 'reactstrap';

import { useOnce } from '@app/hooks';
import {
  ArrearsChart,
  ChartColumn,
  LeaseChartColumn,
  RadialChart,
} from '@app/modules/Dashboard';
import { TypeaheadSelect } from '@app/modules/Form/Field/TypeaheadSelect';
import { Header } from '@app/modules/Header';
import { useRolesContext } from '@app/modules/Profile';
import { fetchAgencies } from '@app/redux/agency';
import {
  selectDashboardAgenciesAndManagersFilters,
  selectDashboardData,
} from '@app/redux/dashboard/selectors';
import { selectProfileData } from '@app/redux/profile';
import { fetchManagers } from '@app/redux/users';
import { fullName } from '@app/utils';

import { fetchDashboardData } from '../../redux/dashboard';
import { COLORS, LEASES } from '../../redux/dashboard/constants';
import { DashboardProvider } from '../Dashboard';

const { RED, BLUE, YELLOW, GREEN, ORANGE } = COLORS;

export const Dashboard = () => {
  const dispatch = useDispatch();
  const { isManager, isPrincipal, isCorporateUser } = useRolesContext();

  const [data, setData] = useState();
  const [arrears, setArrears] = useState();
  const [currentAgencyOption, setCurrentAgencyOption] = useState();
  const [currentManagerOption, setCurrentManagerOption] = useState();

  const dashboardData = useSelector(selectDashboardData);
  const profile = useSelector(selectProfileData);
  const {
    isLoading: isFiltersLoading,
    filters: {
      managers: managerOptions,
      agencies: agencyOptions,
      managersGroupedByAgencyId: groupedManagerOptions,
    },
  } = useSelector(selectDashboardAgenciesAndManagersFilters);

  const filteredManagerOptions = useMemo(() => {
    return currentAgencyOption?.value
      ? groupedManagerOptions[currentAgencyOption.value]
      : managerOptions;
  }, [currentAgencyOption, managerOptions, groupedManagerOptions]);

  const dispatchDashboardData = useCallback(
    (managerId) =>
      dispatch(
        fetchDashboardData({
          params: { resourceType: 'manager', resourceId: managerId },
        })
      ),
    [dispatch]
  );

  useOnce(() => {
    dispatch(fetchAgencies({}));
    dispatch(fetchManagers());

    if (profile.id && !isCorporateUser && (isManager || isPrincipal)) {
      dispatchDashboardData(profile.id);
    }
  });

  useEffect(() => {
    if (currentManagerOption?.value) {
      dispatchDashboardData(currentManagerOption?.value);
    }
  }, [dispatchDashboardData, currentManagerOption]);

  useEffect(() => {
    // Initial dashboard loading. Set default selected values
    if (
      !isFiltersLoading &&
      !currentManagerOption &&
      filteredManagerOptions?.length
    ) {
      const managerOption = isCorporateUser
        ? filteredManagerOptions[0]
        : filteredManagerOptions.find(({ value }) => value === profile.id);
      const agencyOption = agencyOptions.find(
        ({ value }) => value === managerOption?.agencyId
      );

      setCurrentManagerOption(managerOption);

      if (!isCorporateUser) {
        setCurrentAgencyOption(agencyOption);
      }
    }

    // An agency is selected but current selected manager does not belong to that agency
    if (
      currentManagerOption?.value &&
      !includes(
        map(filteredManagerOptions, 'value'),
        currentManagerOption.value
      ) &&
      filteredManagerOptions
    ) {
      setCurrentManagerOption(filteredManagerOptions[0]);
    }
  }, [
    profile.id,
    currentManagerOption,
    agencyOptions,
    filteredManagerOptions,
    isFiltersLoading,
    isCorporateUser,
  ]);

  useEffect(() => {
    if (dashboardData) {
      setArrears({
        percent: {
          data: dashboardData.arrears.percent.percentage,
          days: dashboardData.arrears.percent.days,
        },
        days: { data: dashboardData.arrears.days },
      });
      setData(dashboardData);
    }
  }, [dashboardData, filteredManagerOptions]);

  const handleClick = useCallback(
    (url, param = '') => {
      window.open(
        `/reports/${url}resource_id=${
          currentManagerOption?.value || profile.id
        }&resource_type=manager&resource_label=${
          currentManagerOption?.label || fullName(profile)
        }&source_url=dashboard${param}`
      );
    },
    [profile.id, currentManagerOption]
  );

  const handleSearchManager = useCallback(
    (value) => dispatch(fetchManagers(value ? { search: value } : {})),
    [dispatch]
  );

  return (
    <DashboardProvider
      // eslint-disable-next-line react/jsx-no-bind
      handleClick={handleClick}
      isClickable={isManager || isPrincipal || isCorporateUser}>
      <Header title="Dashboard" />
      <Container className="wrapper d-flex flex-column justify-content-center align-items-center">
        {data ? (
          <>
            <Row className="w-100">
              <Col xs={6} lg={3}>
                <Select
                  placeholder="Agency"
                  isLoading={isFiltersLoading}
                  isClearable={true}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={setCurrentAgencyOption}
                  options={agencyOptions}
                  value={currentAgencyOption}
                />
              </Col>
              <Col xs={6} lg={3}>
                <TypeaheadSelect
                  placeholder="Manager"
                  isLoading={isFiltersLoading}
                  // eslint-disable-next-line react/jsx-no-bind
                  onSelect={setCurrentManagerOption}
                  onKeyDown={handleSearchManager}
                  options={filteredManagerOptions}
                  selectedOption={currentManagerOption}
                />
              </Col>
            </Row>
            <Row className="w-100">
              <Col className="py-4" xs={12} lg={8}>
                <ArrearsChart data={arrears} />
              </Col>
              <ChartColumn
                title="My Properties"
                className="px-0"
                xs={12}
                lg={4}>
                <RadialChart
                  labels={['Vacant', 'Pending', 'Leased']}
                  series={[
                    data?.portfolioMetrics?.vacant || 0,
                    data?.portfolioMetrics?.pending || 0,
                    data?.portfolioMetrics?.leased || 0,
                  ]}
                  colors={[YELLOW, BLUE, GREEN]}
                  total={data?.portfolioMetrics?.total || 0}
                  isClickable
                />
              </ChartColumn>
            </Row>
            <Row className="w-100">
              <LeaseChartColumn
                title="My Leases"
                labels={['Current', 'Periodic', 'Expired']}
                series={[
                  data?.leaseStatuses?.current || 0,
                  data?.leaseStatuses?.periodic || 0,
                  data?.leaseStatuses?.expired || 0,
                ]}
                colors={[GREEN, BLUE, YELLOW]}
                isSemiDonut
              />
              <LeaseChartColumn
                title="Expired Leases"
                labels={LEASES.DAYS_LABELS}
                series={data?.leases?.expired}
                colors={[GREEN, YELLOW, ORANGE, RED]}
                isExpired={true}
              />
            </Row>
            <Row className="w-100">
              <LeaseChartColumn
                title="Upcoming Leases Renewals"
                labels={LEASES.DAYS_LABELS}
                series={data?.leases?.upcoming}
                colors={[RED, ORANGE, YELLOW, GREEN]}
                isUpcoming={true}
              />
              <LeaseChartColumn
                title="Rent Reviews"
                labels={['Upcoming', 'Overdue', 'No Date']}
                series={[
                  data?.rentReviews?.upcoming || 0,
                  data?.rentReviews?.overdue || 0,
                  data?.rentReviews?.noDate || 0,
                ]}
                colors={[GREEN, YELLOW, BLUE]}
                isSemiDonut
                isRentReviews={true}
              />
            </Row>
          </>
        ) : (
          <PulseLoader color="#dee2e6" />
        )}
      </Container>
    </DashboardProvider>
  );
};
