export const SET_STEPS = 'SET_STEPS';
export const INCREMENT_CURRENT_STEP = 'INCREMENT_CURRENT_STEP';
export const DECREMENT_CURRENT_STEP = 'DECREMENT_CURRENT_STEP';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_LEASE_TO_EDIT = 'SET_LEASE_TO_EDIT';
export const SET_LEASE_TO_RENEW = 'SET_LEASE_TO_RENEW';
export const RESET = 'RESET';

export const INITIAL_STATE = {
  steps: [],
  currentStep: 0,
  canContinue: false,
  leaseIdToEdit: 0,
  leaseIdToRenew: 0,
  changed: false,
};
