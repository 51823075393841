import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import { Container, CustomInput } from 'reactstrap';

import { BrandLogo } from '../../../modules/Brand';
import { Filter } from '../../../modules/Filter';
import { Header } from '../../../modules/Header';
import { Link } from '../../../modules/Link';
import { Pagination } from '../../../modules/Pagination';
import {
  ReportHeading,
  ReportOverviewArrearsTable,
} from '../../../modules/Report';
import {
  exportArrearsReportCSV,
  fetchReportLeasesDetail,
  getReport,
  getReportIsLoading,
} from '../../../redux/report';
import { toQueryObject } from '../../../utils';

export const ReportsDetailArrears = ({ location }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => getReportIsLoading(state));
  const report = useSelector((state) => getReport(state));
  const [shouldIncludeTerminated, setShouldIncludeTerminated] = useState(false);

  const params = useMemo(
    () => ({
      ...toQueryObject(location.search),
      scope: shouldIncludeTerminated ? 'arrears' : 'activeArrears',
    }),
    [location.search, shouldIncludeTerminated]
  );

  const handleExport = useCallback(
    () => dispatch(exportArrearsReportCSV(params)),
    [params, dispatch]
  );

  useEffect(() => {
    if (params.resourceType) {
      dispatch(fetchReportLeasesDetail(params));
    }
  }, [dispatch, params]);

  const title = useMemo(() => {
    const { resourceType, resourceLabel } = params;
    const managerInTitle =
      resourceType === 'manager' && resourceLabel
        ? ` for ${resourceLabel}`
        : '';
    return `Leases in Arrears${managerInTitle}`;
  }, [params]);

  const toggleShowTerminatedArrears = useCallback(() => {
    setShouldIncludeTerminated(!shouldIncludeTerminated);
  }, [shouldIncludeTerminated]);

  return (
    <Filter name="reports" isSaved={true}>
      <Header title="Snapshot report details" isLoading={isLoading}>
        <BrandLogo className="d-none d-print-block" />
        <Link
          className="d-print-none"
          replace
          to={{
            pathname: params?.sourceUrl === 'dashboard' ? '/' : '/reports',
            search: location.search,
          }}>
          {params?.sourceUrl === 'dashboard'
            ? 'Back to Dashboard'
            : 'Back to Snapshot'}
        </Link>
      </Header>
      <Container>
        <ReportHeading title={title} onExport={handleExport} />
        <CustomInput
          id="includeTerminated"
          name="includeTerminated"
          label="Show Terminated Leases"
          type="checkbox"
          className="mb-2"
          value={shouldIncludeTerminated}
          checked={shouldIncludeTerminated}
          onChange={toggleShowTerminatedArrears}
        />
        {isLoading ? (
          <div className="d-block text-center m-5">
            <PulseLoader color="#dee2e6" />
          </div>
        ) : (
          <>
            <ReportOverviewArrearsTable leases={report.detail.leases} />
            <Pagination className="d-print-none mt-3" name="leases" />
          </>
        )}
      </Container>
    </Filter>
  );
};

ReportsDetailArrears.propTypes = {
  location: PropTypes.object.isRequired,
};
