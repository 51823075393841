import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { FormFeedback } from 'reactstrap';

export const SelectField = (props) => {
  const [field, meta, { setTouched }] = useField(props.name);
  const invalid = meta.touched && meta.error;
  const valid = meta.touched && !meta.error;

  const { setFieldValue } = useFormikContext();

  // flatten the options so that it will be easier to find the value
  const flattenedOptions = props.options?.flatMap((o) => {
    const isNotGrouped = 'value' in o;

    return isNotGrouped ? o : o.options;
  });

  // get the value using flattenedOptions and field.value
  const value = flattenedOptions?.filter((o) => {
    const isArrayValue = Array.isArray(field.value);

    if (isArrayValue) {
      const values = field.value;
      return values.includes(o.value);
    } else {
      return field.value === o.value;
    }
  });

  const handleChange = (val) => {
    const _val = val;

    setTouched(true);

    if (Array.isArray(_val)) {
      const values = _val.map((o) => o.value);
      setFieldValue(props.name, values);
    } else {
      setFieldValue(props.name, _val?.value);
    }
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <Select {...props} value={value} onChange={handleChange} />
      {invalid && (
        <FormFeedback invalid={invalid} valid={valid}>
          {meta.error}
        </FormFeedback>
      )}
    </>
  );
};

SelectField.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
};

SelectField.defaultProps = {
  options: [],
};

export default SelectField;
