import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import {
  Alert,
  Button,
  CardBody,
  Fade,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';

import { ButtonCopyToClipboard } from '@app/modules/Button';
import { CardLight } from '@app/modules/Card';
import {
  QUERY_KEYS,
  getMaskedAccounts,
  useFetchAccounts,
  useRemoveAccount,
  useSetDefaultPaymentAccount,
} from '@app/modules/Payment/hooks/use-assembly';
import {
  QUERY_KEYS as PROFILE_QUERY_KEYS,
  useProfile,
} from '@app/modules/Profile/use-profile';
import { selectUserFingerprint } from '@app/redux/profile';
import { selectUser } from '@app/redux/users/selectors';

import PaymentMethodItem from './PaymentMethodItem';

const PayIn = () => {
  const fingerprint = useSelector(selectUserFingerprint);
  const { virtualAccount } = useSelector(selectUser);
  const queryClient = useQueryClient();
  const { data: profile } = useProfile();

  const {
    data: accounts,
    refetch,
    isFetching,
    isLoading,
  } = useFetchAccounts(null, {
    select: getMaskedAccounts,
  });

  const {
    mutate: setDefaultPayment,
    isLoading: isSettingDefaultPayment,
    variables,
  } = useSetDefaultPaymentAccount();

  const {
    mutate: removeAccount,
    isLoading: isRemovingAccount,
    isSuccess: isRemoveAccountSuccess,
  } = useRemoveAccount();

  const handleChangePaymethod = useCallback(
    (promisepayId, fetchAfter = true) => {
      setDefaultPayment({
        promisepayId,
        fingerprint,
        ...(fetchAfter && {
          callback: () => {
            refetch();
            queryClient.invalidateQueries(PROFILE_QUERY_KEYS.FETCH_PROFILE);
          },
        }),
      });
    },
    [fingerprint, queryClient, refetch, setDefaultPayment]
  );

  const handleDeletePaymentMethod = useCallback(
    (account) => {
      removeAccount({
        fingerprint,
        promisepayId: account?.promisepayId,
        type: account?.type === 'bank' ? 'bank' : 'card',
      });
    },
    [fingerprint, removeAccount]
  );

  const isDisableAction =
    isFetching || isSettingDefaultPayment || isRemovingAccount;

  useEffect(() => {
    if (isRemoveAccountSuccess) {
      queryClient.invalidateQueries(QUERY_KEYS.FETCH_ACCOUNTS);
    }
  }, [isRemoveAccountSuccess, queryClient]);

  return (
    <div>
      <CardLight
        className="pay-card mb-3"
        data-testid="pay-in-card"
        title="Your Payment Methods"
        footer={
          <div className="d-flex justify-content-between align-items-center flex-column flex-md-row">
            <Link
              to="/payments/wallet/add-payment-method"
              className="add-payment-button order-1 order-md-0">
              <Button outline color="primary">
                + Add payment method
              </Button>
            </Link>
            <p className="text-note footer-text order-0 order-md-1">
              Fee may applied, see each invoice for fee details
            </p>
          </div>
        }>
        {isLoading ? (
          <CardBody className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
            <PulseLoader color="#dee2e6" />
          </CardBody>
        ) : (
          <ListGroup flush className="payment-method-container">
            {accounts &&
              accounts.map((account) => {
                let buttonText = 'Use as payment method';

                if (isDisableAction) {
                  if (account?.isDeleting) {
                    buttonText = 'Deleting payment method...';
                  } else if (
                    variables?.promisepayId === account?.promisepayId
                  ) {
                    buttonText = 'Setting payment method...';
                  }
                }

                return (
                  <div key={`${account.id}-${account.promisepayId}`}>
                    <ListGroupItem className="border-0">
                      <PaymentMethodItem
                        type={account.type}
                        name={account.name}
                        accountNumber={account.maskedValue}
                        isActive={account?.isDefault}
                        isInUse={account?.isInUse}
                        isVirtualAccount={account?.isVirtual}
                        isMarkedForVerification={
                          account?.isMarkedForVerification
                        }
                        // eslint-disable-next-line react/jsx-no-bind
                        onChangePaymentMethod={() =>
                          handleChangePaymethod(account?.promisepayId)
                        }
                        // eslint-disable-next-line react/jsx-no-bind
                        onDeletePaymentMethod={() =>
                          handleDeletePaymentMethod(account)
                        }
                        isLoading={isDisableAction}
                        buttonText={buttonText}
                        ddrFormUrl={account?.ddrFormUrl}
                      />
                      {account?.isVirtual && account?.isDefault && (
                        <Fade>
                          <Alert className="mt-3 mb-1" color="info">
                            <div className="mb-0">
                              Use your internet banking to transfer payment to
                              the account below. You can also set up recurring
                              scheduled transfers to pay your wallet
                              automatically. These account details are unique to
                              your wallet.
                              <br />
                              <div className="pt-2">
                                <p className="d-flex align-items-center mb-0">
                                  <strong className="mr-2">Bank Name:</strong>
                                  <span>Cuscal</span>
                                </p>
                                <p className="d-flex align-items-center mb-0">
                                  <strong className="mr-2">
                                    Account Name:
                                  </strong>
                                  <span>{profile?.accountName}</span>
                                  {profile?.accountName && (
                                    <ButtonCopyToClipboard
                                      text={profile?.accountName}
                                      id="copy-account-name-button"
                                    />
                                  )}
                                </p>
                                <p className="d-flex align-items-center mb-0">
                                  <strong className="mr-2">BSB:</strong>
                                  <span>{virtualAccount?.routingNumber}</span>
                                  {virtualAccount?.routingNumber && (
                                    <ButtonCopyToClipboard
                                      text={virtualAccount?.routingNumber}
                                      id="copy-routing-number-button"
                                    />
                                  )}
                                </p>
                                <p className="d-flex align-items-center  mb-0">
                                  <strong className="mr-2">
                                    Account Number:
                                  </strong>
                                  <span>{virtualAccount?.accountNumber}</span>
                                  {virtualAccount?.accountNumber && (
                                    <>
                                      <ButtonCopyToClipboard
                                        text={virtualAccount?.accountNumber}
                                        id="copy-account-number-button"
                                      />
                                    </>
                                  )}
                                </p>
                              </div>
                            </div>
                          </Alert>
                        </Fade>
                      )}
                    </ListGroupItem>
                  </div>
                );
              })}
          </ListGroup>
        )}
      </CardLight>
    </div>
  );
};

export default PayIn;
