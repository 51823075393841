import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardText } from 'reactstrap';

import { formatNumberWithCommas } from '@app/utils/currency';

import './styled.scss';

export const SmsCreditWidget = ({ credits = 0, isGradient }) => {
  return (
    <Card
      className={`sms-credit-widget ${
        isGradient ? 'card-gradient mb-3 text-primary-alt' : ''
      } `}
      data-testid="sms-credit-widget">
      <CardBody className="d-flex flex-column px-3 py-2">
        <div className="d-flex justify-content-between">
          <CardText className="mb-0" tag="strong">
            SMS Credit&nbsp;
          </CardText>
          <CardText className="mb-0" tag="strong">
            {formatNumberWithCommas(credits)}
          </CardText>
        </div>
      </CardBody>
    </Card>
  );
};

SmsCreditWidget.propTypes = {
  credits: PropTypes.number,
  isGradient: PropTypes.bool,
};

export const SmsCreditBadge = ({
  credits,
  contextString,
  canShowTopUpLink,
  isLoading,
}) => (
  <>
    <Badge
      pill
      className={`px-2 py-1 align-self-center border bg-transparent ${
        !credits ? 'text-danger border-danger' : 'text-primary border-primary'
      }`}>
      {isLoading
        ? 'Fetching SMS credits...'
        : `${formatNumberWithCommas(credits)} ${contextString}`}
    </Badge>
    {canShowTopUpLink && !credits && (
      <span className="ml-2">
        <small>
          You don&apos;t have sufficient credits to send. Top up your balance{' '}
          <Link to="/sms/plans">here</Link>.
        </small>
      </span>
    )}
  </>
);

SmsCreditBadge.propTypes = {
  credits: PropTypes.number,
  contextString: PropTypes.string,
  canShowTopUpLink: PropTypes.bool,
  isLoading: PropTypes.bool,
};

SmsCreditBadge.defaultProps = {
  credits: 0,
  contextString: '',
};
