import TagManager from 'react-gtm-module';
import { gtm as gtmId } from 'settings';
import localStorage from 'store';

export const initializeGtm = () => {
  if (gtmId) {
    const dataLayer = {
      agencyId: localStorage.get('agencyId'),
      userType: localStorage.get('userType'),
    };

    TagManager.initialize({ gtmId, dataLayer });
  }
};

export const setDataLayer = () => {
  const dataLayer = {
    agencyId: localStorage.get('agencyId'),
    userType: localStorage.get('userType'),
  };

  TagManager.dataLayer({ dataLayer });
};
