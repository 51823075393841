/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  Button,
  Col,
  CustomInput,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import { DateRange } from '../DateRange';
import { PURCHASE_HISTORY_INITIAL_FILTER } from '../constants';

export const AdvancedFilterModal = ({
  filters,
  setFilters,
  modalState,
  toggleFilterModal,
}) => {
  const [purchasedDate, setPurchasedDate] = useState({
    purchasedFrom: filters.purchasedFrom,
    purchasedTo: filters.purchasedTo,
  });
  const [status, setStatus] = useState(filters.status);

  const handleOnCheckBoxChange = useCallback(
    ({ target: { name, checked } }) => {
      setStatus((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    },
    []
  );

  const onClearFilter = useCallback(() => {
    setFilters(PURCHASE_HISTORY_INITIAL_FILTER);
    toggleFilterModal();
  }, [setFilters, toggleFilterModal]);

  const onApplyFilter = useCallback(() => {
    setFilters((state) => ({
      ...state,
      ...purchasedDate,
      status,
    }));
    toggleFilterModal();
  }, [status, purchasedDate, setFilters, toggleFilterModal]);

  return (
    <Modal
      className="bulk-sms-advanced-filter"
      toggle={toggleFilterModal}
      size="lg"
      isOpen={modalState.isOpen}
      centered>
      <ModalHeader toggle={toggleFilterModal}>Advanced Filters</ModalHeader>
      <ModalBody>
        <Row>
          <Col className="col-md-6 col-12 mb-2">
            <label>Purchased At</label>
            <DateRange
              fromDateName="purchasedFrom"
              fromDateValue={purchasedDate.purchasedFrom}
              fromDateLabel="Purchased From"
              toDateName="purchasedTo"
              toDateValue={purchasedDate.purchasedTo}
              toDateLabel="Purchased To"
              onChange={setPurchasedDate}
            />
          </Col>
          <Col className="col-md-6 col-12 mb-2">
            <label htmlFor="status">Status</label>
            <div className="mt-2 sms-custom-input">
              <CustomInput
                className="d-inline-block mr-3"
                checked={status.pending}
                onChange={handleOnCheckBoxChange}
                type="checkbox"
                id="pendingStatus"
                name="pending"
                label="Pending"
              />
              <CustomInput
                className="d-inline-block mr-3"
                checked={status.completed}
                onChange={handleOnCheckBoxChange}
                type="checkbox"
                id="completedStatus"
                name="completed"
                label="Completed"
              />
              <CustomInput
                className="d-inline-block"
                checked={status.failed}
                onChange={handleOnCheckBoxChange}
                type="checkbox"
                id="failedStatus"
                name="failed"
                label="Failed"
              />
            </div>
          </Col>
        </Row>
      </ModalBody>
      <div className="text-right p-3">
        <Button
          color="primary-alt"
          className="mr-3"
          type="button"
          onClick={onClearFilter}
          outline>
          Clear All
        </Button>
        <Button color="primary-alt" type="button" onClick={onApplyFilter}>
          Filter
        </Button>
      </div>
    </Modal>
  );
};

AdvancedFilterModal.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  modalState: PropTypes.object.isRequired,
  toggleFilterModal: PropTypes.func.isRequired,
};
