import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Container } from 'reactstrap';

import { CardWallet } from '@app/modules/Card';
import { Header } from '@app/modules/Header';
import { BankListModal, WithdrawModal } from '@app/modules/Modal';
import { PaymentAutomateSelector } from '@app/modules/Payment';
import {
  useFetchAccounts,
  useSetDisbursementAccount,
} from '@app/modules/Payment/hooks/use-assembly';
import { selectProfileData, selectUserFingerprint } from '@app/redux/profile';
import { selectIsUsersLoading, selectUser } from '@app/redux/users';
import { toCents } from '@app/utils';

import {
  QUERY_KEYS,
  useDisburseWalletBalance,
  useVerifyWalletDisburse,
} from '../useWalletWithdrawal';
import { AddBpay, SelectPaymentMethod } from './AddPaymentMethod';
import PayIn from './PayIn';
// import PayOut from './PayOut';
import Transactions from './Transactions';
import './styles.scss';

const INITIAL_STATE = {
  selectBank: false,
  indicateAmount: false,
  confirmWithdrawal: false,
  smsVerification: false,
};

const modalStateReducer = (state, action) => {
  switch (action.type) {
    case 'selectBank':
      return {
        ...state,
        selectBank: !state.selectBank,
      };
    case 'indicateAmount':
      return {
        ...state,
        selectBank: false,
        indicateAmount: !state.indicateAmount,
      };
    case 'confirmWithdrawal':
      return {
        ...state,
        confirmWithdrawal: !state.confirmWithdrawal,
      };
    case 'smsVerification':
      return {
        ...state,
        smsVerification: !state.smsVerification,
      };
    case 'reset':
      return INITIAL_STATE;
    default:
      return state;
  }
};

const Walletv2 = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const queryClient = useQueryClient();

  const user = useSelector(selectUser);
  const fingerprint = useSelector(selectUserFingerprint);
  const { id: profileId, isAuthyEnabled } = useSelector(selectProfileData);

  const isUserLoading = useSelector(selectIsUsersLoading);

  const { data: accounts } = useFetchAccounts();

  const {
    data: verifyWalletBalanceData,
    refetch: verifyWalletBalance,
    isFetching: isVerifyingWallet,
    error: verifyWalletError,
  } = useVerifyWalletDisburse();

  const {
    mutate: disburseWalletBalance,
    error: disburseError,
    isLoading: isDisbursingBalance,
    data,
    isSuccess,
  } = useDisburseWalletBalance();

  const { mutate: setDisbursementAccount } = useSetDisbursementAccount();

  const [modalState, setModalState] = useReducer(
    modalStateReducer,
    INITIAL_STATE
  );

  const [amount, setAmount] = useState(0);
  const [bankAccount, setBankAccount] = useState({
    promisepayId: '',
    bankAccountId: '',
  });

  const navigateToWalletPage = useCallback(() => {
    history.replace('/payments/wallet');
  }, [history]);

  const toggleBankModal = useCallback(() => {
    setModalState({ type: 'selectBank' });
    setBankAccount({ promisepayId: '', bankAccountId: '' });
  }, []);

  const toggleResetModal = useCallback(() => {
    setModalState({ type: 'reset' });
    setAmount(0);
  }, []);

  // call api to compute withdrawal amount against pending bills
  const onConfirmAmount = useCallback(({ amount }) => {
    setAmount(amount);
    verifyWalletBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // confirm withdrawal
  const onConfirmWithdrawal = useCallback(() => {
    setModalState({ type: 'smsVerification' });
  }, []);

  // send 2FA code
  const onConfirmIdentity = (authyToken = 0) => {
    if (authyToken !== 0) {
      setAmount(0);
      disburseWalletBalance({
        id: profileId,
        amountInCents: toCents(amount),
        bankAccountId: parseInt(bankAccount.bankAccountId),
        authyToken,
      });
    }
  };

  // Side effects

  // open confirmation modal
  useEffect(() => {
    if (
      typeof verifyWalletBalanceData?.totalAmountWithFeesInCents === 'number' &&
      amount > 0
    ) {
      // if wallet amount - fees to pay is less than amount to withdraw
      if (
        user?.walletBalanceAmountCents -
          verifyWalletBalanceData.totalAmountWithFeesInCents <
        toCents(amount)
      ) {
        setModalState({ type: 'confirmWithdrawal' });
      } else {
        onConfirmWithdrawal();
      }
    }
  }, [
    amount,
    disburseWalletBalance,
    profileId,
    user.walletBalanceAmountCents,
    verifyWalletBalance,
    verifyWalletBalanceData,
    onConfirmWithdrawal,
  ]);

  useEffect(() => {
    if (verifyWalletError || disburseError) {
      setModalState({ type: 'reset' });
    }
  }, [verifyWalletError, disburseError]);

  useEffect(() => {
    if (data && isSuccess) {
      setDisbursementAccount({
        promisepayId: bankAccount.promisepayId,
        fingerprint,
      });
      setModalState({ type: 'reset' });
      queryClient.invalidateQueries(QUERY_KEYS.TRANSACTIONS);
    }
  }, [
    data,
    queryClient,
    isSuccess,
    setDisbursementAccount,
    bankAccount.promisepayId,
    fingerprint,
  ]);

  const allowedPaymentMethods = ['dd', 'cc'].filter(
    (method) =>
      (method === 'dd' && user.isAllowedToAddDdAccount) ||
      (method === 'cc' && user.isAllowedToAddCcAccount)
  );

  return (
    <>
      <Header title="My Wallet" />
      <Container className="mt-3 wrapper filter-blur">
        <Switch>
          <Route exact path={path}>
            <CardWallet
              amountCents={user?.walletBalanceAmountCents || 0}
              isLoading={isUserLoading}
              toggle={toggleBankModal}
              isAllowedToWithdraw={user?.isAllowedToWithdraw}
              isAuthyEnabled={isAuthyEnabled}
            />
            <PayIn />
            {/* <PayOut /> */}
            {/* Withdraw modals */}
            {modalState?.selectBank && (
              <BankListModal
                isOpen={modalState?.selectBank}
                setIsOpenModal={setModalState}
                toggleModal={toggleBankModal}
                bankAccountId={bankAccount.bankAccountId}
                setBankAccount={setBankAccount}
              />
            )}
            {modalState?.indicateAmount && (
              <WithdrawModal
                isOpen={modalState}
                toggleResetModal={toggleResetModal}
                onConfirmAmount={onConfirmAmount}
                onConfirmWithdrawal={onConfirmWithdrawal}
                // eslint-disable-next-line react/jsx-no-bind
                onConfirmIdentity={onConfirmIdentity}
                amountCents={user?.walletBalanceAmountCents}
                isLoading={isVerifyingWallet || isDisbursingBalance}
                billsToPayAmount={
                  verifyWalletBalanceData?.totalAmountWithFeesInCents
                }
                bankAccountId={bankAccount.bankAccountId}
              />
            )}
            <PaymentAutomateSelector
              isAutoBills={user.autoBillPayment}
              isAutoRent={user.autoRentPayment}
              tenantId={user.id}
              isLoading={isUserLoading}
              isPayByBpay={accounts?.isPayByBpay}
            />
          </Route>
          <Route path={`${path}/transactions`}>
            <Transactions />
          </Route>
          <Route path={`${path}/add-payment-method`}>
            <SelectPaymentMethod
              allowedPaymentMethods={allowedPaymentMethods}
            />
          </Route>

          <Route path={`${path}/add-bpay`}>
            <AddBpay navigateToWalletPage={navigateToWalletPage} />
          </Route>
          <Route path={`${path}/add-income`}>
            <div>
              <h1>Add Income</h1>
            </div>
          </Route>
        </Switch>
      </Container>
    </>
  );
};

export default Walletv2;
