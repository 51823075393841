import PropTypes from 'prop-types';
import React from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { Link } from '@app/modules/Link';

import { MessageInfoStatus } from '../MessageInfoStatus';
import { UserContact } from '../UserContact';
import { useFetchSmsDelivery } from '../hooks/use-sms-accounts';

export const MessageInfoModal = ({
  isOpen,
  toggle,
  messageId,
  agencyId = 1,
}) => {
  const { data, isFetching } = useFetchSmsDelivery({
    id: messageId,
    params: { agencyId },
  });

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      id="message-info-modal">
      <ModalHeader>Message Info</ModalHeader>
      <ModalBody>
        {isFetching ? (
          <div className="text-center">
            <PulseLoader color="#dee2e6" />
          </div>
        ) : (
          <>
            <UserContact
              className="border-bottom mb-3 pb-3"
              label="Sent To"
              user={data?.recipient}
              email={data?.recipientEmail}
              number={data?.recipientPhoneNumber}
              avatarUrl={data?.recipientAvatar}
            />
            <UserContact
              label="From"
              user={data?.sender}
              email={data?.senderEmail}
              number={data?.senderPhoneNumber}
              avatarUrl={data?.senderAvatar}
            />
            <MessageInfoStatus
              {...data}
              sentAt={data.createdAt}
              createdAt={null}
            />
            <p className="message-content p-3">{data?.message}</p>
            <div className="text-right mt-3">
              <Button
                outline={!!data?.propertyTaskId}
                color="primary-alt"
                onClick={toggle}>
                Close
              </Button>
              {data?.propertyTaskId && (
                <Link
                  className="ml-2 align-items-center"
                  color="primary-alt"
                  to={`/property/${data?.propertyId}/tasks/${data?.propertyTaskId}`}>
                  Go to Task
                </Link>
              )}
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

MessageInfoModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  messageId: PropTypes.number,
  agencyId: PropTypes.number,
};
