import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Collapse, Row } from 'reactstrap';

import { useIsOpen, useSortableData } from '@app/hooks';
import { ButtonDestroy, ButtonIcon } from '@app/modules/Button';
import { ModalConfirm } from '@app/modules/Modal';
import SortableColumn from '@app/modules/Task/SortableColumn';
import { getPagination } from '@app/redux/pagination';
import { isInPast } from '@app/utils';

import { Divider } from '../Divider';
import { useDeleteRentIncrease, useFetchRentHistory } from '../useLeaseFlex';
import { LeaseInformationSectionCol } from './LeaseInformationSectionCol';

export const LeaseModifications = ({
  leaseId,
  payFrequency,
  canCancelModification,
}) => {
  const [isOpen, actions] = useIsOpen();
  const pagination = useSelector((state) =>
    getPagination(state.pagination, 'history')
  );
  const [modifications, setModifications] = useState([]);
  const [modificationToDelete, setModicationToDelete] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [isViewRentalHistory, setIsViewRentalHistory] = useState(true);

  const {
    data = [],
    refetch,
    isSuccess,
  } = useFetchRentHistory({
    leaseId,
    page: pageNum,
    enabled: false,
  });
  const { mutateAsync: deleteRent } = useDeleteRentIncrease();

  useEffect(() => {
    if (leaseId) {
      refetch();
    }
  }, [leaseId, refetch, pageNum]);

  useEffect(() => {
    if (isSuccess) {
      setModifications(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  const handleSort = useCallback(() => {
    setModifications((prev) => prev.toReversed());
  }, []);

  const openDeleteModal = (id) => {
    actions.handleOpen();
    setModicationToDelete(id);
  };

  const handleSubmit = () => {
    actions.handleClose();
    deleteRent({
      leaseId,
      rentId: modificationToDelete,
    }).then(() => {
      refetch();
    });
  };

  const { ...sortProps } = useSortableData(handleSort, {
    direction: 'descending',
    key: 'rental-history',
  });

  const loadMore = useCallback(() => {
    setPageNum(pageNum + 1);
  }, [pageNum]);

  return (
    <Row className="mt-3" hidden={!data?.length}>
      <LeaseInformationSectionCol>
        <div className="h5 mb-4 d-flex justify-content-between">
          <SortableColumn
            column={{ name: 'Rental History', id: 'rental-history' }}
            {...sortProps}
          />
          <ButtonIcon
            className="p-0"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => setIsViewRentalHistory((prev) => !prev)}
            icon={['far', isViewRentalHistory ? 'chevron-up' : 'chevron-down']}
          />
        </div>
        <Collapse isOpen={isViewRentalHistory}>
          <div
            style={{
              maxHeight: '40vh',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}>
            {modifications.map((modification, i) => (
              <React.Fragment key={`modification-${modification.id}`}>
                {i > 0 && <Divider />}
                <Row className="align-items-center">
                  <Col>
                    <FontAwesomeIcon icon={['far', 'calendar-alt']} />{' '}
                    {modification.effectiveDateFormatted}
                  </Col>
                  <Col className="text-right font-weight-bold">
                    {modification.type === 'LeaseRent'
                      ? ` Change to ${modification.amountDollars[payFrequency]}`
                      : `${modification.action}${
                          modification.value ? `: ${modification.value}` : ''
                        }`}
                  </Col>
                  <Col xs={1}>
                    {isInPast(modification.effectiveDate) ||
                    modification.type !== 'LeaseRent' ? (
                      <FontAwesomeIcon
                        icon={['fas', 'check-circle']}
                        className="text-success mr-2"
                      />
                    ) : (
                      <>
                        {canCancelModification && (
                          <ButtonDestroy
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={() => openDeleteModal(modification.value)}
                          />
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </React.Fragment>
            ))}
            {pagination.totalPages > pageNum && (
              <div className="d-flex justify-content-center">
                <Button color="primary" onClick={loadMore} disabled={false}>
                  Load more
                </Button>
              </div>
            )}
          </div>
        </Collapse>
        <ModalConfirm
          body="Are you sure you want to delete this rental adjustment?"
          btnSubmit={{ color: 'danger', text: 'Yes' }}
          isOpen={isOpen}
          title="Confirmation"
          onCancel={actions.handleClose}
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={() => handleSubmit()}
        />
      </LeaseInformationSectionCol>
    </Row>
  );
};

LeaseModifications.propTypes = {
  leaseId: PropTypes.number,
  payFrequency: PropTypes.string,
  canCancelModification: PropTypes.bool,
};
