import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { centsToDollar } from '../../../utils';
import { Link } from '../../Link';

const ReportPaymentItemComponent = ({
  label,
  type,
  feeType,
  report,
  ...props
}) => {
  const { total, totalGst } = report;
  const hasAction = type && total;

  const location = useLocation();

  const linkTo = useMemo(() => {
    return hasAction
      ? `/reports/financials/${type}/${feeType}${location.search}`
      : null;
  }, [feeType, hasAction, location.search, type]);

  return (
    <tr>
      <td>{label}</td>
      <td className="text-right">{centsToDollar(totalGst, true)}</td>
      <td className="text-right">{centsToDollar(total, true)}</td>
      <td className="text-right d-flex justify-content-end">
        {hasAction && (
          <>
            <Link to={linkTo} className="small d-print-none mr-2">
              Show Details
            </Link>
          </>
        )}
      </td>
    </tr>
  );
};

ReportPaymentItemComponent.propTypes = {
  report: PropTypes.object,
  type: PropTypes.string,
  feeType: PropTypes.string,
  label: PropTypes.string,
  linkTo: PropTypes.string,
};

ReportPaymentItemComponent.defaultProps = {
  report: {
    total: 0,
    totalGst: 0,
  },
};

export const ReportPaymentItem = connect(null)(ReportPaymentItemComponent);
