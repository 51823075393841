export const TOP_UP_OPTIONS = [
  {
    value: 'never',
    label: 'Never',
  },
  {
    value: 'whenLow',
    label: 'When Low',
  },
  {
    value: 'always',
    label: 'Always',
  },
];

export const NAV_ITEMS = [
  {
    exact: true,
    title: 'Buy More Credits',
    to: '/sms/plans',
    icon: ['far', 'badge-dollar'],
    limitedAccess: true,
  },
  {
    exact: true,
    title: 'Bulk Messaging',
    to: '/sms/bulk-messaging',
    icon: ['far', 'message-sms'],
    limitedAccess: true,
  },
  {
    exact: true,
    title: 'Sent History',
    to: '/sms/sent-history',
    icon: ['far', 'clock-rotate-left'],
  },
  {
    exact: true,
    title: 'Purchase History',
    to: '/sms/purchase-history',
    icon: ['far', 'list'],
    limitedAccess: true,
  },
  {
    exact: true,
    title: 'SMS Settings',
    to: '/sms/settings',
    icon: ['far', 'sliders'],
    limitedAccess: true,
  },
];

export const PURCHASE_HISTORY_COLUMNS = [
  {
    header: 'Credit Pack',
    accessor: 'name',
    size: 2,
  },
  {
    header: 'Credits',
    accessor: 'credits',
    size: 1,
  },
  {
    header: 'Amount',
    accessor: 'amount',
    size: 1,
  },
  {
    header: 'Status',
    accessor: 'status',
    size: 2,
  },
  {
    header: 'Purchase Date',
    accessor: 'purchaseDate',
    size: 3,
  },
  {
    header: 'Completed At',
    accessor: 'completedAt',
    size: 2,
  },
  {
    header: 'Actions',
    accessor: 'actions',
    size: 1,
    className: 'text-center',
  },
];

export const PURCHASE_HISTORY_INITIAL_FILTER = {
  purchasedFrom: null,
  purchasedTo: null,
  status: { pending: true, completed: true, failed: true },
};

export const CREDIT_PURCHASE_STATUS = [
  { value: 'pending', label: 'Pending' },
  { value: 'completed', label: 'Completed' },
  { value: 'failed', label: 'Failed' },
];

export const ACCEPTED_DATE_FORMATS = [
  'dd-MM-yyyy',
  'dd/MM/yyyy',
  'dd-MM-yy',
  'dd/MM/yy',
];

export const SENT_HISTORY_TABS = [
  {
    title: 'All',
    key: '',
  },
  {
    title: 'Tasks',
    key: 'PropertyTaskMessage',
  },
  {
    title: 'Bulk Messages',
    key: 'BulkSmsMessage',
  },
];

export const SENT_HISTORY_SEARCH_BY_OPTIONS = [
  { value: 'phone', label: 'To' },
  { value: 'email', label: 'Email' },
  { value: 'name', label: 'Name' },
  { value: 'message', label: 'Message' },
];

export const SENT_HISTORY_MESSAGE_STATUS_OPTIONS = [
  { value: 'pending', label: 'Pending' },
  { value: 'completed', label: 'Completed' },
  { value: 'failed', label: 'Failed' },
];

export const BULK_SMS_RECIPIENTS = Object.freeze([
  { label: 'Owners', value: 'owners' },
  { label: 'Tenants', value: 'tenants' },
]);

export const getMessageProperties = (status, isBulkMessage) => {
  if (isBulkMessage) {
    if (status === 'draft') {
      return {
        color: 'draft',
        text: 'draft',
      };
    } else if (status === 'sent')
      return {
        color: 'success',
        text: 'sent',
      };
  }

  switch (status) {
    case 'read':
    case 'delivered':
      return {
        color: 'success',
        text: 'completed',
      };
    case 'draft':
    case 'sent':
    case 'queued':
      return {
        color: 'warning',
        text: 'pending',
      };
    case 'failed':
    case 'canceled':
    case 'dead':
    case 'undelivered':
      return {
        color: 'danger',
        text: 'failed',
      };
    default:
      return 'secondary';
  }
};
