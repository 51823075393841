/* eslint-disable react/boolean-prop-naming */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';

import { canCreateLease } from '@app/redux/profile';
import {
  ATTACHMENT_CATEGORIES,
  centsToDollar,
  formatDate,
  isConditionReport,
  isLeaseAgreement,
} from '@app/utils';

import {
  AdditionalInformation,
  AttachmentInformation,
  DocumentInformation,
  Information,
} from '../common/information';
import { LeaseActions } from './LeaseActions';

export const InactiveLeaseInfo = ({
  id,
  propertyId,
  startDate,
  terminationDate,
  amountDollars,
  payFrequency,
  primaryTenantName,
  bondNumber,
  bondCents,
  depositCents,
  hasBond,
  bondReturnedCents,
  onViewInactiveLease,
}) => {
  const canReleaseBond = useSelector(({ profile }) => canCreateLease(profile));

  const showReleaseBond = hasBond && !bondReturnedCents && canReleaseBond;

  return (
    <Card className="mb-3">
      <CardBody>
        <Row>
          <Col
            xs={1}
            id={`inactive-toggler-${id}`}
            className="d-flex align-items-center justify-content-center">
            <FontAwesomeIcon icon={['far', 'chevron-down']} />
          </Col>
          <Col xs={10}>
            <Row>
              <CardTitle tag="h5" className="pl-3">
                {`Rental Period: ${formatDate(startDate)} - ${formatDate(
                  terminationDate
                )}`}
              </CardTitle>
            </Row>
            <Row>
              <Information
                label="Lease ID"
                value={id}
                sizes={{ xs: 12, md: 6 }}
              />
              <Information
                label="Rent"
                value={`${amountDollars[payFrequency]} ${payFrequency}`}
                sizes={{ xs: 12, md: 6 }}
              />
            </Row>
            <Row>
              <Information
                label="Primary Tenant"
                value={primaryTenantName}
                sizes={{ xs: 12, md: 6 }}
              />
              <Information
                label="Bond disbursed to owner"
                value={centsToDollar(bondReturnedCents)}
                sizes={{ xs: 12, md: 6 }}
              />
            </Row>
          </Col>
        </Row>
        <Col xs={{ size: 11, offset: 1 }} className="pt-3 px-0">
          <UncontrolledCollapse toggler={`#inactive-toggler-${id}`}>
            <AdditionalInformation
              label="Rent Amount"
              value={amountDollars[payFrequency]}
            />
            <AdditionalInformation
              label="Rent Frequency"
              value={payFrequency}
            />
            <AdditionalInformation
              label="Deposit"
              value={centsToDollar(depositCents)}
            />
            <AdditionalInformation
              label="Bond"
              value={centsToDollar(bondCents)}
            />
            <AdditionalInformation
              label="Bond Id"
              value={bondNumber || 'N/A'}
            />
            <AdditionalInformation
              label="First Payment"
              value={formatDate(startDate)}
            />
          </UncontrolledCollapse>
        </Col>
      </CardBody>
      <LeaseActions>
        <LeaseActions.ViewLease
          leaseId={id}
          propertyId={propertyId}
          onViewLease={onViewInactiveLease}
        />
        <LeaseActions.ViewTransactions
          leaseId={id}
          propertyId={propertyId}
          isFromList
        />
        {showReleaseBond && (
          <LeaseActions.ReleaseBond
            isOutline
            bondCents={bondCents}
            bondNumber={bondNumber}
            leaseId={id}
          />
        )}
      </LeaseActions>
    </Card>
  );
};

InactiveLeaseInfo.propTypes = {
  id: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
  attachments: PropTypes.array,
  startDate: PropTypes.string,
  terminationDate: PropTypes.string,
  amountDollars: PropTypes.shape({
    annually: PropTypes.string,
    fortnightly: PropTypes.string,
    monthly: PropTypes.string,
    weekly: PropTypes.string,
  }),
  payFrequency: PropTypes.oneOf([
    'annually',
    'fortnightly',
    'monthly',
    'weekly',
  ]),
  primaryTenantName: PropTypes.string,
  bondNumber: PropTypes.string,
  bondCents: PropTypes.number,
  depositCents: PropTypes.number,
  bondReturnedCents: PropTypes.number,
  hasBond: PropTypes.bool,
  canCreateLease: PropTypes.bool,
  onViewInactiveLease: PropTypes.func,
};
InactiveLeaseInfo.defaultProps = {
  attachments: [],
  bondNumber: '',
  bondCents: 0,
  depositCents: 0,
  bondReturnedCents: 0,
  hasBond: false,
  canCreateLease: false,
  onViewInactiveLease: null,
};

export const ActiveLeaseInfo = ({
  id,
  attachments,
  propertyId,
  leaseStartDate,
  endDate,
  inspectionDate,
  amountDollars,
  payFrequency,
  tenant,
  depositCents,
  bondNumber,
  bondCents,
}) => {
  const { firstName = '-', lastName = '-' } = tenant || {};

  return (
    <Card className="mb-3 border-success">
      <CardBody>
        <Row>
          <Col
            id={`active-toggler-${id}`}
            xs={1}
            className="d-flex align-items-center justify-content-center">
            <FontAwesomeIcon icon={['far', 'chevron-down']} />
          </Col>
          <Col xs={10}>
            <Row className="align-items-baseline">
              <CardTitle tag="h5" className="pl-3">
                {`${formatDate(leaseStartDate)} to ${formatDate(endDate)} - `}
                <span className="text-capitalize">
                  {firstName || lastName
                    ? `${firstName} ${lastName}`
                    : 'No Tenant added'}
                </span>
              </CardTitle>
              <CardSubtitle className="text-capitalize"></CardSubtitle>
            </Row>
            <Row>
              <Information
                label="Start Date"
                value={formatDate(leaseStartDate)}
              />
              <Information label="End Date" value={formatDate(endDate)} />
              <Information
                label="Primary Tenant"
                value={`${firstName} ${lastName}`}
              />
            </Row>
            <Row>
              <Information label="Rent" value={amountDollars[payFrequency]} />
              <Information label="Period" value={payFrequency || '-'} />
            </Row>
          </Col>
        </Row>
        <Col xs={{ size: 11, offset: 1 }} className="pt-3 px-0">
          <UncontrolledCollapse toggler={`#active-toggler-${id}`}>
            <AdditionalInformation
              label="Rent Amount"
              value={amountDollars[payFrequency]}
            />
            <AdditionalInformation
              label="Rent Frequency"
              value={payFrequency}
            />
            <AttachmentInformation
              label="Tenancy Agreement"
              attachments={attachments.filter(isLeaseAgreement)}
              category={ATTACHMENT_CATEGORIES.leaseAgreement}
              attachableType="Lease"
              attachableId={id}
            />
            <AttachmentInformation
              label="Condition Report"
              attachments={attachments.filter(isConditionReport)}
              category={ATTACHMENT_CATEGORIES.conditionReport}
              attachableType="Lease"
              attachableId={id}
            />
            <DocumentInformation
              label="Tenant Ledger Documents"
              links={[
                { label: 'Download to PDF', type: 'pdf' },
                { label: 'Download to CSV', type: 'csv' },
              ]}
              leaseId={id}
              tenantName={`${firstName} ${lastName}`}
            />
          </UncontrolledCollapse>
        </Col>
      </CardBody>
      <LeaseActions>
        <LeaseActions.Manage leaseId={id} propertyId={propertyId} />
      </LeaseActions>
    </Card>
  );
};

ActiveLeaseInfo.propTypes = {
  id: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
  attachments: PropTypes.array,
  startDate: PropTypes.string,
  leaseStartDate: PropTypes.string,
  endDate: PropTypes.string,
  inspectionDate: PropTypes.string,
  amountDollars: PropTypes.shape({
    annually: PropTypes.string,
    fortnightly: PropTypes.string,
    monthly: PropTypes.string,
    weekly: PropTypes.string,
  }),
  payFrequency: PropTypes.oneOf([
    'annually',
    'fortnightly',
    'monthly',
    'weekly',
  ]),
  tenant: PropTypes.object,
  bondNumber: PropTypes.string,
  bondCents: PropTypes.number,
  depositCents: PropTypes.number,
};
ActiveLeaseInfo.defaultProps = {
  attachments: [],
  bondNumber: '',
  bondCents: 0,
  depositCents: 0,
};
