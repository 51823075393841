import PropTypes from 'prop-types';
import React from 'react';

import { UserAvatar } from '@app/modules/User';

import { HoverPopover } from '../Popup/HoverPopover';

export const TaskFollower = ({
  label,
  role,
  isPopupVisible,
  followerTenant,
}) => {
  console.log(followerTenant);

  const tenantContent = (
    <>
      {followerTenant.length > 0 &&
        followerTenant.map(({ user }, index) =>
          user ? (
            <div
              key={`tenant-${user.id}` || `tenant-${index}`}
              className="d-flex align-items-center">
              <UserAvatar className="mr-2" user={user} size={0.65} />
              <span>
                {user.firstName} {user.lastName}
              </span>
            </div>
          ) : null
        )}
    </>
  );

  return isPopupVisible ? (
    <HoverPopover targetId={`task-follower-${role}`} content={tenantContent}>
      <div className="d-flex align-items-center">
        <UserAvatar className="mt-1" role={role} size={0.65} />
        <div className="ml-1 mr-2">
          <small className="text-capitalize">{label}</small>
        </div>
      </div>
    </HoverPopover>
  ) : (
    <div className="d-flex align-items-center">
      <UserAvatar className="mt-1" role={role} size={0.65} />
      <div className="ml-1 mr-2">
        <small className="text-capitalize">{label}</small>
      </div>
    </div>
  );
};

TaskFollower.propTypes = {
  label: PropTypes.string,
  role: PropTypes.string,
  isPopupVisible: PropTypes.bool,
  followerTenant: PropTypes.array,
};

TaskFollower.defaultProps = {
  followerTenant: [],
  isPopupVisible: false,
};
