/**
 * Returns an object containing the fields from `values` that differ from `initialObject`.
 *
 * @param {object} values - The current values object.
 * @param {object} initialObject - The initial values object to compare against.
 * @returns {object} An object containing only the keys with modified values.
 */
export const getDirtyValues = (values, initialObject) => {
  return Object.keys(values).reduce((dirty, key) => {
    if (values[key] !== initialObject[key]) {
      dirty[key] = values[key];
    }
    return dirty;
  }, {});
};
