import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { ReleaseBondForm } from './ReleaseBondForm';

export const ReleaseBond = ({
  modalState = {
    isOpen: false,
  },
  toggle,
  bondNumber,
  ...props
}) => {
  return (
    <Modal centered isOpen={modalState.isOpen} toggle={toggle}>
      <ModalHeader
        toggle={toggle}>{`Claim for Bond ID: ${bondNumber}`}</ModalHeader>
      <ModalBody>
        <ReleaseBondForm bondNumber={bondNumber} onCancel={toggle} {...props} />
      </ModalBody>
    </Modal>
  );
};

ReleaseBond.propTypes = {
  modalState: PropTypes.object,
  toggle: PropTypes.func.isRequired,
  bondNumber: PropTypes.string.isRequired,
};
