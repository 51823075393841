import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Col } from 'reactstrap';

import { selectProfileData } from '@app/redux/profile';

import { useCreateBulkSms } from '../hooks/use-bulk-sms';
import { BulkMessageForm } from './BulkMessageForm';
import './styles.scss';

export const CreateBulkMessage = ({ currentAgencyOption }) => {
  const history = useHistory();
  const user = useSelector(selectProfileData);
  const { mutate: createBulkSms, isLoading: isLoadingCreateBulkSms } =
    useCreateBulkSms();

  const handleCreateDraftMessage = ({ message, recipients }) => {
    createBulkSms(
      {
        body: message,
        toTenants: recipients.includes('tenants'),
        toOwners: recipients.includes('owners'),
        agencyId: currentAgencyOption?.value,
      },
      {
        onSuccess: () => {
          history.push('/sms/bulk-messaging');
        },
      }
    );
  };

  return (
    <Col>
      <Card>
        <CardHeader className="bg-white border-400 d-flex justify-content-between">
          <CardTitle className="mb-0" tag="h5">
            New Bulk SMS
          </CardTitle>
        </CardHeader>
        <CardBody>
          <BulkMessageForm
            // eslint-disable-next-line react/jsx-no-bind
            onSave={handleCreateDraftMessage}
            onSaveButtonText="Save as Draft"
            isSaving={isLoadingCreateBulkSms}
            initialValues={{
              message: '',
              recipients: [],
              sender: user.id,
            }}
            currentAgencyOption={currentAgencyOption}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

CreateBulkMessage.propTypes = {
  currentAgencyOption: PropTypes.object,
};
CreateBulkMessage.defaultProps = {};
