/* eslint-disable react/jsx-no-bind */
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import { getProperty } from '@app/redux/property';
import { toCents, toFrequencyAmountsCents } from '@app/utils';

import { useLeaseState } from '../../Provider';
import Stepper, { useStepper } from '../../common/stepper';
import { useLeaseCreation, useRequestDeposit } from '../../useLeaseFlex';
import { CommercialOutgoingItems } from './CommercialOutgoingItems';
import CommercialOutgoingsOptions from './CommercialOutgoingsOptions';
import Deposit from './Deposit';
import DepositCollection from './DepositCollection';
import Documents from './Documents';
import LeaseDates from './LeaseDates';
import Overview from './Overview';
import RentActivities from './RentActivities';
import RentAmount from './RentAmount';
import RentalBond from './RentalBond';
import StartOfPayment from './StartOfPayment';
import Tenants from './Tenants';

const LEASE_STEPS = [
  {
    name: 'Lease dates',
    content: <LeaseDates />,
    fields: [
      'leaseStartDate',
      'endDate',
      'leaseTerm',
      'tenantStartDate',
      'periodic',
      'daysRentInvoiceInAdvance',
    ],
  },
  {
    name: 'Rent amount',
    content: <RentAmount />,
    fields: [
      'payFrequency',
      'rentDollars',
      'gstIncluded',
      'tenantPaysWater',
      'ingoingWaterMeterReading',
    ],
  },
  {
    name: 'Rental bond',
    content: <RentalBond />,
    fields: ['bondNumber', 'bondDollars'],
  },
  {
    name: 'Start of payment',
    content: <StartOfPayment />,
    fields: ['startDate'],
  },
  {
    name: 'Monthly Outgoings',
    content: <CommercialOutgoingsOptions />,
    fields: [],
    type: 'commercial',
  },
  {
    name: 'Monthly Outgoing Bills',
    content: <CommercialOutgoingItems />,
    fields: [],
    type: 'commercial',
  },
  {
    name: 'Deposit',
    content: <Deposit />,
    fields: ['deposit'],
  },
  {
    name: 'Schedule Activities',
    content: <RentActivities />,
    fields: [
      'inspectionDate',
      'inspectionDateFrequencyInMonths',
      'reviewDate',
      'reviewDateFrequencyInMonths',
    ],
  },
  {
    name: 'Upload Lease Agreements',
    content: <Documents />,
    fields: ['leaseUpload', 'ingoingReportUpload'],
  },
  {
    name: 'Tenants',
    content: <Tenants />,
    fields: ['tenants'],
  },
  {
    name: 'Rental Summary',
    content: <Overview />,
    fields: [],
  },
  {
    name: 'Deposit Collection',
    content: <DepositCollection />,
    fields: [],
  },
];

const Steps = ({ propertyId, currentStep: currentStepOnLoad, leaseId }) => {
  const [
    state,
    { incrementCurrentStep, decrementCurrentStep, setCurrentStep },
  ] = useStepper();
  const { setLeaseState, leaseState } = useLeaseState();
  const {
    submitForm,
    values,
    validateForm,
    setTouched,
    setFieldTouched,
    initialValues,
  } = useFormikContext();

  const property = useSelector(({ property }) =>
    getProperty(property, propertyId)
  );
  const { mutate, data, isSuccess } = useLeaseCreation();
  const { mutate: requestDeposit } = useRequestDeposit();
  const { canActivate = false } = leaseState?.lease;

  // set current step based on data on mount
  useEffect(() => {
    setCurrentStep(currentStepOnLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setLeaseState((prev) => ({ ...prev, lease: data }));
      incrementCurrentStep();
    }
  }, [data, incrementCurrentStep, isSuccess, setLeaseState]);

  const STEP_MARKERS = useMemo(() => {
    // commercial leases have 2 extra steps
    if (property.propertyType === 'commercial') {
      return {
        TENANT: 9,
        CONFIRMATION: 10,
        DEPOSIT: 11,
      };
    } else {
      return {
        TENANT: 7,
        CONFIRMATION: 8,
        DEPOSIT: 9,
      };
    }
  }, [property.propertyType]);

  const LEASE_CREATION_STEPS = useMemo(() => {
    // filter based on condition then add IDS
    return LEASE_STEPS.reduce((acc, curr) => {
      if (
        property.propertyType !== 'commercial' &&
        curr.type === 'commercial'
      ) {
        return [...acc];
      } else {
        return [...acc, { ...curr, id: acc.length }];
      }
    }, []);
  }, [property.propertyType]);

  const confirmLabel = useMemo(() => {
    if (state.currentStep === STEP_MARKERS.DEPOSIT && values?.deposit) {
      if (canActivate) {
        return 'Done';
      }
      return 'Request Deposit';
    } else if (state.currentStep >= STEP_MARKERS.CONFIRMATION) {
      return 'Confirm';
    } else if (state.canContinue) {
      return 'Next';
    } else {
      return 'Save';
    }
  }, [
    STEP_MARKERS.CONFIRMATION,
    STEP_MARKERS.DEPOSIT,
    canActivate,
    state.canContinue,
    state.currentStep,
    values.deposit,
  ]);

  const handleRequestDeposit = () => {
    requestDeposit({ leaseId, propertyId });
    submitForm();
  };

  const handleNext = async () => {
    const validationErrors = await validateForm();
    const hasValidationError = LEASE_CREATION_STEPS[
      state.currentStep
    ].fields.some((field) => validationErrors.hasOwnProperty(field));

    if (hasValidationError) {
      const errorFields = {};
      LEASE_CREATION_STEPS[state.currentStep].fields.forEach((fieldName) => {
        errorFields[fieldName] = true;
      });
      if (state.currentStep === STEP_MARKERS.TENANT) {
        setFieldTouched('tenants.primary.firstName');
        setFieldTouched('tenants.primary.lastName');
      } else {
        setTouched(errorFields);
      }
    } else {
      // filter only fields in the current step
      const currentFields = Object.keys(values)
        .filter((key) =>
          LEASE_CREATION_STEPS[state.currentStep].fields.includes(key)
        )
        .reduce((acc, key) => {
          acc[key] = values[key];
          return acc;
        }, {});

      // compute for annual rent cents
      const amountCents = toFrequencyAmountsCents(toCents(values.rentDollars));
      const annualRentCents = Math.round(amountCents[values.payFrequency]);

      // compute for bondCents using bondDollars
      const bondCents = values?.bondDollars ? toCents(values.bondDollars) : 0;
      // compute for depositCents using depositAmount
      const depositCents = values?.deposit ? toCents(values.deposit) : 0;

      if (currentFields.tenants?.primary?.type === 'private') {
        delete currentFields.tenants.primary.company;
        delete currentFields.tenants.primary.taxNumber;
      }

      const tenants = {
        ...(Object.hasOwn(currentFields, 'tenants')
          ? {
              tenants: {
                ...(Object.hasOwn(currentFields.tenants, 'primary') &&
                !initialValues?.tenants?.primary?.email
                  ? { primary: currentFields.tenants.primary }
                  : {}),
                ...(Object.hasOwn(currentFields.tenants, 'secondary')
                  ? { secondary: currentFields.tenants.secondary }
                  : {}),
              },
            }
          : {}),
      };

      if (
        !state.canContinue ||
        (values?.deposit === 0 &&
          state.currentStep === STEP_MARKERS.CONFIRMATION)
      ) {
        submitForm();
      } else {
        mutate({
          ...currentFields,
          leaseId: data?.id || leaseId,
          currentStep: state.currentStep + 1,
          ...(Object.hasOwn(currentFields, 'rentDollars')
            ? { annualRentCents }
            : {}),
          ...(Object.hasOwn(currentFields, 'bondDollars') ? { bondCents } : {}),
          ...(Object.hasOwn(currentFields, 'deposit') ? { depositCents } : {}),
          ...tenants,
        });
      }
    }
  };

  return (
    <Stepper>
      <Stepper.Steps>
        {LEASE_CREATION_STEPS.map((leaseStep) => (
          <Stepper.Step key={leaseStep.id} {...leaseStep}>
            <>
              {leaseStep.content}
              <div className="action-buttons">
                <Button
                  color="secondary"
                  className="mr-3"
                  onClick={decrementCurrentStep}>
                  Previous
                </Button>
                <Button
                  color="primary"
                  onClick={
                    state.currentStep === STEP_MARKERS.DEPOSIT &&
                    values?.deposit &&
                    !canActivate
                      ? handleRequestDeposit
                      : () => handleNext(leaseStep.id)
                  }>
                  {confirmLabel}
                </Button>
              </div>
            </>
          </Stepper.Step>
        ))}
      </Stepper.Steps>
    </Stepper>
  );
};

Steps.propTypes = {
  propertyId: PropTypes.number,
  currentStep: PropTypes.number,
  leaseId: PropTypes.number,
};

Steps.defaultProps = {
  propertyId: 0,
  currentStep: 0,
  leaseId: 0,
};

export default Steps;
