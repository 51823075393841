import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { useRolesContext } from '@app/modules/Profile';
import { UploaderButton, UploaderFiles } from '@app/modules/Uploader';
import { updateLeaseAttachments } from '@app/redux/lease';

export const AttachmentInformation = ({
  label,
  attachableId,
  attachments,
  category,
}) => {
  const dispatch = useDispatch();
  const { isManager } = useRolesContext();

  const onUpload = useCallback(
    (data) => {
      dispatch(updateLeaseAttachments(data));
    },
    [dispatch]
  );

  return (
    <Row className="text-muted small text-capitalize pt-2">
      <Col xs={8}>
        <span>{label}</span>
      </Col>
      <Col xs={4}>
        {isManager && (
          <UploaderButton
            attachableType="Lease"
            attachableId={attachableId}
            attachableCategory={category}
            onComplete={onUpload}
          />
        )}
        <UploaderFiles
          attachments={attachments}
          attachableType="Lease"
          attachableId={attachableId}
          onDestroyComplete={null}
        />
      </Col>
    </Row>
  );
};

AttachmentInformation.propTypes = {
  label: PropTypes.string,
  attachableId: PropTypes.number.isRequired,
  attachments: PropTypes.array,
  category: PropTypes.string.isRequired,
};
AttachmentInformation.defaultProps = {
  label: 'Attachment',
  attachments: [],
};
