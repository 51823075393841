import { add, sub } from 'date-fns';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormGroup, Label } from 'reactstrap';

import {
  DateField,
  InputField,
  RadioField,
  SelectField,
} from '@app/modules/Form/FormikFields';

import { MONTHS_OPTIONS } from '../constants';
import { ModalContentContainer } from './ModalContentContainer';

export const LeaseDates = ({ isRenew }) => {
  const {
    values: { leaseStartDate, leaseTerm, leased, isCommercial },
    touched,
    setFieldValue,
    errors,
  } = useFormikContext();

  //  set the value of endDate, based on startDate and leaseTerm
  useEffect(() => {
    if (touched.leaseTerm || touched.leaseStartDate) {
      // check if valid
      if (!errors.leaseTerm && !errors.leaseStartDate) {
        const result = sub(
          add(new Date(leaseStartDate), {
            months: leaseTerm,
          }),
          { days: 1 }
        );

        setFieldValue('endDate', result);
      }
    }
  }, [
    leaseStartDate,
    leaseTerm,
    touched.leaseStartDate,
    errors.leaseStartDate,
    touched.leaseTerm,
    errors.leaseTerm,
    setFieldValue,
  ]);

  useEffect(() => {
    if (leased === 'false') {
      setFieldValue('tenantStartDate', '');
    }
  }, [leased, setFieldValue, touched.leased]);

  return (
    <>
      <h3>{`Lets ${isRenew ? 'update' : 'start with'} the lease dates`}</h3>

      <ModalContentContainer>
        <FormGroup>
          <Label for="leaseStartDate">
            {`What is the start date of this ${
              isRenew ? 'renewed ' : ''
            }tenancy?`}
          </Label>
          <DateField name="leaseStartDate" />
        </FormGroup>
        <FormGroup>
          <Label for="leaseTerm">How long will it be</Label>
          <SelectField name="leaseTerm" options={MONTHS_OPTIONS} />
        </FormGroup>
        <FormGroup>
          <Label for="endDate">{`This ${
            isRenew ? 'renewed ' : ''
          }lease will end on`}</Label>
          <DateField name="endDate" />
        </FormGroup>
        {isCommercial && !isRenew && (
          <FormGroup>
            <Label for="daysRentInvoiceInAdvance">
              Send Invoice in Advance
            </Label>
            <InputField
              name="daysRentInvoiceInAdvance"
              type="number"
              append="Days"
            />
          </FormGroup>
        )}

        {!isRenew && (
          <>
            <FormGroup>
              <Label for="leased">
                Have they lived in the property before this tenancy period?
              </Label>
              <div role="group" className="radio-group-wrapper">
                <RadioField name="leased" label="Yes" value="true" />
                <RadioField name="leased" label="No" value="false" />
              </div>
            </FormGroup>
            {leased === 'true' && (
              <FormGroup>
                <DateField name="tenantStartDate" />
              </FormGroup>
            )}
          </>
        )}

        <FormGroup>
          <Label for="periodic">
            Is this lease on a month to month/periodic agreement?
          </Label>
          <div role="group1" className="radio-group-wrapper">
            <RadioField name="periodic" label="Yes" value="true" />
            <RadioField name="periodic" label="No" value="false" />
          </div>
        </FormGroup>
      </ModalContentContainer>
    </>
  );
};

LeaseDates.propTypes = {
  isRenew: PropTypes.bool,
};

export default LeaseDates;
