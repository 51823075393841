import React from 'react';
import { Progress } from 'reactstrap';

import { useStepper } from '@app/modules/LeaseFlex/common/stepper';
import {
  StepperStep,
  StepperSteps,
} from '@app/modules/LeaseFlex/common/stepper/Step';

// eslint-disable-next-line react/prop-types
export const Stepper = ({ children }) => {
  const [{ currentStep, steps }] = useStepper();

  return (
    <div>
      <>
        <div className="step-navigation">
          {steps.map((step, stepIndex) => (
            <Progress
              key={`step-${step.name}`}
              color="primary"
              value={currentStep >= stepIndex ? 100 : 0}
            />
          ))}
        </div>
        <div className="selected-step-section">{children}</div>
      </>
    </div>
  );
};

Stepper.Step = StepperStep;
Stepper.Steps = StepperSteps;

export default Stepper;
