import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { Col, Container, Input, Row } from 'reactstrap';

import { useDebouncedFetch } from '@app/hooks/useDebouncedFetch';
import { useExternalCreditors } from '@app/modules/ExternalCreditor/hooks/useExternalCreditor';
import { Header } from '@app/modules/Header';
import { Link } from '@app/modules/Link';
import { Pagination } from '@app/modules/Pagination';
import { List } from '@app/modules/Tradie';
import { selectUserAgencyId } from '@app/redux/users';
import { replaceSearchParams, toQueryObject } from '@app/utils';

export const Tradies = () => {
  const history = useHistory();
  const params = useMemo(
    () => toQueryObject(history.location.search),
    [history.location.search]
  );

  const searchTerm = params.search;
  const page = Number(params.page) || 1;

  const userAgencyId = useSelector(selectUserAgencyId);

  const {
    isLoading,
    data: tradies,
    refetch,
  } = useExternalCreditors({
    params: {
      agencyId: userAgencyId,
      search: params.search,
      page: params.page,
      perPage: params.perPage || 10,
    },
  });
  const { debouncedRefetch } = useDebouncedFetch({
    fetchFn: refetch,
  });

  const historyReplace = useCallback(
    (params) => {
      history.replace({
        page,
        search: replaceSearchParams({
          params,
          search: history.location.search,
        }),
      });
    },

    [history, page]
  );

  const handleSearch = useCallback(
    (event) => {
      const value = event.currentTarget.value;
      historyReplace({ search: value });
    },
    [historyReplace]
  );

  useEffect(() => {
    if (searchTerm !== undefined && searchTerm !== null) {
      debouncedRefetch();
    }
  }, [searchTerm, debouncedRefetch]);

  return (
    <>
      <Header className="mb-0" title="Tradies">
        <Link color="primary" to="/contacts/tradies/invite">
          Invite a tradie
        </Link>
      </Header>
      <Container className="py-4" data-testid="marketplace-tradie-list">
        <Row className="mb-3 justify-content-end">
          <Col xs={12} md={6} lg={4} className="mb-2">
            <Input
              data-testid="contact-traide-search"
              type="search"
              className="form-control"
              placeholder="Search Tradies"
              name="searchValue"
              disabled={isLoading}
              onChange={handleSearch}
              value={params.search || null}
            />
          </Col>
        </Row>
        {isLoading ? (
          <Row>
            <Col className="py-5 text-center">
              <PulseLoader color="#dee2e6" />
            </Col>
          </Row>
        ) : (
          <>
            <List
              tradies={tradies}
              hiddenColumns={['Admin fee']}
              isFiltered={searchTerm !== undefined && searchTerm !== null}
            />
            <Pagination name="external-creditors" isReload={false} />
          </>
        )}
      </Container>
    </>
  );
};

export default Tradies;
