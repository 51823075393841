/* eslint-disable react/jsx-no-bind */
import React, { memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';

import { UploaderFiles, UploaderForm } from '@app/modules/Uploader';
import { updateLeaseAttachments } from '@app/redux/lease';
import {
  ATTACHMENT_CATEGORIES,
  isConditionReport,
  isLeaseAgreement,
} from '@app/utils';

import { useLeaseState } from '../../Provider';
import { ModalContentContainer } from './ModalContentContainer';

const Documents = () => {
  const dispatch = useDispatch();

  const { leaseState } = useLeaseState();
  const { lease } = leaseState;

  const attachments = useMemo(
    () => ({
      conditionReport: (lease?.attachments || []).filter(isConditionReport),
      leaseAgreement: (lease?.attachments || []).filter(isLeaseAgreement),
    }),
    [lease]
  );

  const onUploaderComplete = (attachments) => {
    dispatch(updateLeaseAttachments(attachments));
  };

  return (
    <>
      <h3>Upload Lease Agreements</h3>
      <ModalContentContainer>
        <FormGroup>
          <Label for="leaseUpload">Tenancy Agreement</Label>
          {lease.id && (
            <div>
              <UploaderFiles
                attachments={attachments.leaseAgreement}
                attachableType="Lease"
                attachableId={lease.id}
                className="d-flex flex-wrap"
                onDestroyComplete={onUploaderComplete}
              />
              <UploaderForm
                attachableType="Lease"
                attachableId={lease.id}
                attachableCategory={ATTACHMENT_CATEGORIES.leaseAgreement}
                onComplete={onUploaderComplete}
              />
            </div>
          )}
        </FormGroup>

        {/* if manager */}
        {/* add lease upload  */}

        <FormGroup className="mb-5">
          <Label for="ingoingReportUpload">Ingoing Report</Label>
          {lease.id && (
            <div>
              <UploaderFiles
                attachments={attachments.conditionReport}
                attachableType="Lease"
                attachableId={lease.id}
                className="d-flex flex-wrap"
                onDestroyComplete={onUploaderComplete}
              />
              <UploaderForm
                attachableType="Lease"
                attachableId={lease.id}
                attachableCategory={ATTACHMENT_CATEGORIES.conditionReport}
                onComplete={onUploaderComplete}
              />
            </div>
          )}
        </FormGroup>
      </ModalContentContainer>
    </>
  );
};

export default memo(Documents);
