import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import './styles.scss';

const getChipClass = (state) => {
  switch (state) {
    case 'default':
      return 'overdue-chip--default';
    case 'active':
      return 'overdue-chip--active';
    case 'processing':
      return 'overdue-chip--processing';
    case 'success':
      return 'overdue-chip--success';
    default:
      return 'overdue-chip--default';
  }
};

const stateIconMap = {
  success: {
    icon: ['fal', 'check-circle'],
    className: 'text-success mr-1',
  },
  processing: {
    icon: ['far', 'arrows-rotate'],
    className: 'text-warning mr-1',
  },
};

export const OverdueChip = ({ amount, label, state = 'default', onClick }) => {
  const stateIcon = stateIconMap[state];

  return (
    <button
      type="button"
      disabled={state !== 'default'}
      onClick={onClick}
      className={`overdue-chip ${getChipClass(state)}`}>
      {stateIcon && (
        <FontAwesomeIcon
          icon={stateIcon.icon}
          size="sm"
          className={stateIcon.className}
        />
      )}
      {label} - <span className="amount">{amount}</span>
    </button>
  );
};

OverdueChip.propTypes = {
  state: PropTypes.oneOf(['active', 'processing', 'success', 'default']),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onClick: PropTypes.func,
};
