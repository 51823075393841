/* eslint-disable react/jsx-no-bind */
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import { CardLight } from '@app/modules/Card';
import { RadioField } from '@app/modules/Form/FormikFields';
import { UploaderWidget } from '@app/modules/Uploader';
import { selectBillInvoiceEnabled } from '@app/redux/settings/selectors';

import { DEFAULT_FOLLOWERS } from '../../../redux/task';
import {
  ATTACHMENT_CATEGORIES,
  ATTACHMENT_EXTENSIONS_PER_TYPE,
  dollarToCents,
  joinKey,
  toDollars,
} from '../../../utils';
import { DividerDouble } from '../../Divider';
import { FormField, FormLabel, FormOptionsList } from '../../Form';
import { Payments } from './Payments';

export const mapBillFieldsProps = (
  invoice = {},
  debtorList = [],
  acceptedQuote,
  isEdit = false
) => {
  const debtorOwner = debtorList.find((debtor) => debtor.type === 'Owner');

  return {
    payments: [
      {
        amountDollars: toDollars(
          (invoice?.isIntentionTriggered && invoice?.amountCents) ||
            acceptedQuote?.bidCents ||
            acceptedQuote?.limitCents ||
            invoice?.amountCents ||
            100
        ),
        bpayBillerCode: invoice?.bpayBillerCode || '',
        bpayReference: invoice?.bpayReference || '',
        creditorKey: invoice?.creditorId
          ? joinKey(invoice?.creditorId, invoice?.creditorType)
          : acceptedQuote?.tradie?.id
          ? joinKey(acceptedQuote?.tradie?.id, 'ExternalCreditor')
          : '',
        debtorKey: invoice?.debtorId
          ? joinKey(invoice?.debtorId, invoice?.debtorType)
          : debtorOwner?.id
          ? joinKey(debtorOwner.id, 'Owner')
          : null,
        gstIncluded:
          typeof invoice?.gstIncluded !== 'undefined'
            ? invoice?.gstIncluded
            : acceptedQuote?.tradie?.id
            ? acceptedQuote?.tradie?.gstIncluded
            : false,
        isBpayBiller: !!invoice?.bpayBillerCode,
        isAgencyCoveringFees: invoice?.isAgencyCoveringFees || false,
        referenceNumber: invoice?.referenceNumber || '',
        followers: DEFAULT_FOLLOWERS,
      },
    ],
    invoiceCategory: invoice?.category
      ? invoice?.category
      : acceptedQuote?.id
      ? 'maintenance'
      : '',
    hasOwnTaxInvoice:
      isEdit && invoice && invoice?.generateInvoiceAttachmentOnSchedule
        ? 'generate'
        : 'none',
    sendTaxInvoice:
      isEdit && invoice ? invoice?.sendInvoiceAttachmentOnSchedule : false,
  };
};

export const validationSchemaForBillFields = {
  invoiceCategory: Yup.string().when('isBill', {
    is: true,
    then: Yup.string().required(`Select a tax category`),
  }),
  isBill: Yup.bool(),
  taxInvoice: Yup.array().when('hasOwnTaxInvoice', {
    is: 'upload',
    then: Yup.array().required('Please upload an invoice'),
  }),

  payments: Yup.array().when('isBill', {
    is: true,
    then: Yup.array().of(
      Yup.object().shape({
        amountDollars: Yup.number()
          .min(1, 'Amount must be equal to or greater than $1')
          .required('Amount is required'),
        bpayBillerCode: Yup.string().when('isBpayBiller', {
          is: true,
          then: Yup.string().required('BPay biller code is required'),
        }),
        bpayReference: Yup.string().when('isBpayBiller', {
          is: true,
          then: Yup.string().required('BPay reference is required'),
        }),
        creditorKey: Yup.string().required(`Select who's paying`),
        debtorKey: Yup.string().required(`Select who to pay`),
        isBpayBiller: Yup.bool(),
        referenceNumber: Yup.string().matches(/^([A-Za-z0-9\s])*$/, {
          message: 'Payment Reference may only contain letters and numbers',
        }),
      })
    ),
  }),
};

export const TaskFieldsBill = (props) => {
  const isBillInvoiceEnabled = useSelector(selectBillInvoiceEnabled);
  const {
    creditorList,
    creditorType,
    debtorList,
    defaultFollowers,
    fetchBpayBillers,
    hasAllowedBpayBillerAsCreditor,
    hasBpayOptions,
    invoice,
    invoiceCategories,
    isEditPage,
    isMarketplaceEnabled,
    onChange,
    onChangeFollowers,
    onUpdateCreditor,
    pastTenants,
    property,
    setFieldValue,
    values,
    upcomingTenants,
    errors,
  } = props;
  const isIntentionTriggered = invoice?.isIntentionTriggered;

  /* eslint-enable react-hooks/exhaustive-deps */

  const onChangeInvoiceCategory = useCallback(
    (e) => {
      if (
        e.currentTarget.value === 'advertising_for_tenants' &&
        values.payments.every(
          (payment) => dollarToCents(payment.amountDollars) === 100
        )
      ) {
        values.payments.forEach((_, index) =>
          setFieldValue(
            `values.payments[${index}].amountDollars`,
            toDollars(property.advertisingFeeCents)
          )
        );
      }
      onChange(e);
    },
    [onChange, property.advertisingFeeCents, setFieldValue, values.payments]
  );

  return (
    <div id="add" className="pt-4 my-4">
      <CardLight>
        {values.isMaintenance && (
          <p className="ml-1 font-weight-bold">
            Add a bill to this maintenance task
          </p>
        )}
        <FieldArray name="payments">
          {Payments({
            isMarketplaceEnabled,
            creditorList,
            debtorList,
            defaultFollowers,
            onChange,
            setFieldValue,
            values,
            invoice,
            pastTenants,
            upcomingTenants,
            onChangeFollowers,
            onUpdateCreditor,
            creditorType,
            hasBpayOptions,
            property,
            hasAllowedBpayBillerAsCreditor,
            fetchBpayBillers,
            isEditPage,
          })}
        </FieldArray>
        <DividerDouble />
        <div className="px-3 pb-3">
          <Row>
            <Col md={6} lg={6}>
              <FormGroup>
                <FormLabel for="invoiceCategory" isRequired>
                  Tax Category
                </FormLabel>
                <FormField
                  name="invoiceCategory"
                  type="select"
                  onChange={onChangeInvoiceCategory}
                  disabled={invoiceCategories.length === 0}>
                  <FormOptionsList
                    hasBlank={true}
                    name="invoiceCategory"
                    options={invoiceCategories}
                  />
                </FormField>
              </FormGroup>
            </Col>
          </Row>
          {isBillInvoiceEnabled && (
            <Row className="mb-4 flex-column">
              <Col>
                <Label for="hasOwnTaxInvoice" className="mb-2">
                  Tax Invoice
                  <span className="text-danger ml-1">*</span>
                </Label>
                <div role="group" style={{ display: 'flex', gap: '2rem' }}>
                  <RadioField
                    name="hasOwnTaxInvoice"
                    label="Upload invoice"
                    value="upload"
                    disabled={isIntentionTriggered}
                  />
                  <RadioField
                    name="hasOwnTaxInvoice"
                    label="Generate invoice"
                    value="generate"
                    disabled={isIntentionTriggered}
                  />
                  <RadioField
                    name="hasOwnTaxInvoice"
                    label="No invoice"
                    value="none"
                    disabled={isIntentionTriggered}
                  />
                </div>
                {values.hasOwnTaxInvoice === 'upload' && (
                  <FormGroup className="mt-3">
                    <input name="taxInvoice" type="text" hidden />
                    <div id="taxInvoice" className="d-block">
                      <UploaderWidget
                        allowedFileTypes={[
                          ...ATTACHMENT_EXTENSIONS_PER_TYPE.image,
                          ...ATTACHMENT_EXTENSIONS_PER_TYPE.doc,
                        ]}
                        attachments={values?.taxInvoice}
                        attachableType="PropertyTask"
                        attachableId={0}
                        attachableCategory={ATTACHMENT_CATEGORIES.taxInvoice}
                        maxNumFiles={1}
                        onUploaderComplete={({ attachmentIds }) => {
                          if (!attachmentIds) {
                            setFieldValue('taxInvoice', []);
                            return;
                          }
                          setFieldValue(
                            'taxInvoice',
                            values.taxInvoice.filter(
                              ({ id }) => !attachmentIds.includes(id)
                            )
                          );
                        }}
                        setAttachments={(attachments) => {
                          setFieldValue('taxInvoice', attachments);
                        }}
                        disabled={isIntentionTriggered}
                        isAttachOnCreate
                      />
                    </div>
                    {errors.taxInvoice && (
                      <FormFeedback>{errors.taxInvoice}</FormFeedback>
                    )}
                  </FormGroup>
                )}
              </Col>
              {values.hasOwnTaxInvoice !== 'none' && (
                <Col
                  className={`mt-${
                    values.hasOwnTaxInvoice === 'upload' ? '3' : '4'
                  }`}>
                  <Label for="sendTaxInvoice" className="mb-2">
                    Send invoice to debtor
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <div role="group" className="d-flex flex-column">
                    <RadioField
                      className="mb-2"
                      name="sendTaxInvoice"
                      label="Now (after this task is scheduled)"
                      value={true}
                      disabled={isIntentionTriggered}
                    />
                    <RadioField
                      className="mb-2"
                      name="sendTaxInvoice"
                      label="Manually send later"
                      value={false}
                      disabled={isIntentionTriggered}
                    />
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
      </CardLight>
    </div>
  );
};

TaskFieldsBill.propTypes = {
  creditorList: PropTypes.array,
  creditorType: PropTypes.array,
  debtorList: PropTypes.array,
  defaultFollowers: PropTypes.array,
  fetchBpayBillers: PropTypes.func.isRequired,
  hasAllowedBpayBillerAsCreditor: PropTypes.bool,
  hasBpayOptions: PropTypes.bool,
  invoice: PropTypes.object,
  invoiceCategories: PropTypes.array,
  isEditPage: PropTypes.bool,
  isMarketplaceEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeFollowers: PropTypes.func,
  onUpdateCreditor: PropTypes.func.isRequired,
  pastTenants: PropTypes.array,
  property: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  upcomingTenants: PropTypes.array,
};

TaskFieldsBill.defaultProps = {
  creditorList: [],
  creditorType: '',
  debtorList: [],
  invoiceCategories: [],
  pastTenants: [],
  property: {},
  values: {},
  errors: {},
  upcomingTenants: [],
};
