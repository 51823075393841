import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import localStorage from 'store';

import { Alert } from '@app/containers/Alert';
import { useLocationParams } from '@app/hooks';
import { AuthLayout } from '@app/modules/AuthLayout';
import { FormLogin } from '@app/modules/Form';
import { OauthAppLogin } from '@app/modules/Oauth';
import { useRolesContext } from '@app/modules/Profile';
import { hasError as selectHasError } from '@app/redux/notifier';
import { fetchOauthApp } from '@app/redux/oauth';
import {
  loginUser,
  selectIsProfileAuthorized,
  selectIsProfileLoading,
  selectProfileData,
} from '@app/redux/profile';

export const Login = () => {
  const user = useSelector(selectProfileData);
  const isLoading = useSelector(selectIsProfileLoading);
  const isAuthorized = useSelector(selectIsProfileAuthorized);
  const oauthAppInfo = useSelector((state) => state.oauthAppInfo);
  const hasError = useSelector(selectHasError);

  const dispatch = useDispatch();

  const { isCorporateUser, isExternalCreditor, isManager, isPrincipal } =
    useRolesContext();

  const params = useLocationParams();
  const { clientId, redirectUri, responseType, state } = params;

  useEffect(() => {
    if (clientId && !oauthAppInfo?.hasTried) {
      dispatch(fetchOauthApp({ clientId }));
    }
  }, [clientId, dispatch, oauthAppInfo]);

  const oauthAuthorizationPath = useMemo(() => {
    let path = `/authorize?client_id=${clientId}`;
    if (redirectUri)
      path = path.concat(`&redirect_uri=${encodeURIComponent(redirectUri)}`);
    path = responseType
      ? path.concat(`&response_type=${responseType}`)
      : path.concat(`&response_type=code`);
    if (state) {
      path = path.concat(`&state=${state}`);
    }
    return path;
  }, [clientId, redirectUri, responseType, state]);

  const handleLoginUser = useCallback(
    (values) => {
      dispatch(loginUser(values));
    },
    [dispatch]
  );

  if (isAuthorized) {
    const redirectTo = clientId
      ? oauthAuthorizationPath
      : localStorage.get('redirectTo');

    const redirectDefault =
      (isPrincipal || isManager) && !isCorporateUser
        ? '/dashboard'
        : isCorporateUser
        ? '/tasks'
        : isExternalCreditor
        ? '/marketplace'
        : '/property';

    localStorage.set('redirectTo');
    user && localStorage.set('userType', user.role);

    return <Redirect to={redirectTo || redirectDefault} />;
  } else if (user.isAuthyEnabled && !user.isThisDeviceRemembered) {
    return <Redirect to="/two-factor-auth" />;
  } else {
    return (
      <AuthLayout
        subHeader="A better real estate experience."
        isAlertVisible={false}>
        {oauthAppInfo?.isSuccess && (
          <OauthAppLogin oauthAppInfo={oauthAppInfo} />
        )}
        <Alert containerClassName="p-0" />
        <FormLogin
          onSubmit={handleLoginUser}
          hasError={hasError}
          isLoading={isLoading}
        />
        <div className="text-center text-decoration-underline py-2">
          <Link className="text-primary" to="/reset-password">
            Forgot Password
          </Link>
        </div>
      </AuthLayout>
    );
  }
};
