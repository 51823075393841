import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { hideAlert, showAlert } from '@app/redux/notifier';
import { httpClient } from '@app/utils';

export const QUERY_KEYS = {
  FETCH_AGENCY_SMS_ACCOUNT: 'fetchAgencySmsAccount',
  FETCH_SMS_CREDIT_PACK_LIST: 'fetchSmsCreditPackList',
  FETCH_SMS_CREDIT_PACK: 'fetchSmsCreditPack',
  PURCHASE_SMS_CREDIT_PACK: 'purchaseSmsCreditPack',
  FETCH_CREDIT_PURCHASES: 'fetchCreditPurchases',
  REPROCESS_CREDIT_PURCHASE: 'reprocessCreditPurchase',
  FETCH_SMS_DELIVERIES: 'fetchSmsDeliveries',
  FETCH_SMS_DELIVERY: 'fetchSmsDelivery',
};

const getAgencySmsAccount = async ({ agencyId }) => {
  try {
    const response = await httpClient.get(`sms/agencies/${agencyId}`, {
      params: { agencyId },
    });

    return response?.data?.agency;
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

const getSmsCreditPackList = async (params) => {
  try {
    const response = await httpClient.get('sms/credit_packs', {
      params,
    });

    return response?.data?.smsCreditPacks;
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

const getSmsDeliveries = async ({ params }) => {
  try {
    const response = await httpClient.get(`sms/message_delivery_trackers`, {
      params,
    });

    return response?.data?.messageDeliveryTrackers || [];
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

const getSmsCreditPack = async ({ creditPackId }) => {
  try {
    const response = await httpClient.get(`sms/credit_packs/${creditPackId}`);

    return response?.data?.creditPack;
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

const purchaseSmsCreditPack = async (payload) => {
  try {
    const response = await httpClient.post('sms/credit_purchases', payload);

    return {
      data: response?.data,
      agencyId: payload.agencyId,
    };
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

const getSmsDelivery = async ({ id, params }) => {
  try {
    const response = await httpClient.get(
      `/sms/message_delivery_trackers/${id}`,
      {
        params,
      }
    );

    return response?.data?.messageDeliveryTracker || {};
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

const getSmsCreditPurchases = async ({ params }) => {
  try {
    const response = await httpClient.get('sms/credit_purchases', { params });

    return response?.data?.smsCreditPackPurchases;
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

const reprocessCreditPurchase = async ({ id, ...payload }) => {
  try {
    const response = await httpClient.post(
      `sms/credit_purchases/${id}/reprocess-payment`,
      payload
    );

    return response?.data;
  } catch (error) {
    console.error('errors: ', error);
    throw new Error(error);
  }
};

export const useFetchAgencySmsAccount = ({ agencyId }) => {
  const dispatch = useDispatch();

  return useQuery(
    [QUERY_KEYS.FETCH_AGENCY_SMS_ACCOUNT, agencyId],
    () => getAgencySmsAccount({ agencyId }),
    {
      enabled: !!agencyId,
      retry: false,
      staleTime: 180000,
      refetchOnMount: 'always',
      onSettled: () => {
        dispatch(hideAlert());
      },
      initialData: {
        smsAccount: undefined,
        hasUnpaidPlatformCharges: undefined,
        hasPaymentAccount: undefined,
      },
      // we refetch every 10 seconds here if there is an ongoing purchase
      refetchInterval: (query) => {
        if (query?.hasOngoingPurchase) {
          return 10000;
        }

        return false;
      },
    }
  );
};

const PLANS_PLACEHOLDER = [
  { id: 111, isRecommended: false },
  { id: 222, isRecommended: false },
  { id: 333, isRecommended: false },
];

export const useFetchSmsCreditPackList = ({ params }) => {
  return useQuery(
    [QUERY_KEYS.FETCH_SMS_CREDIT_PACK_LIST],
    () => getSmsCreditPackList({ agencyId: params.agencyId }),
    {
      enabled: !!params.agencyId,
      retry: false,
      staleTime: 180000,
      refetchOnMount: 'always',
      placeholderData: PLANS_PLACEHOLDER,
    }
  );
};

export const useFetchSmsCreditPurchases = ({ params }) => {
  return useQuery(
    [QUERY_KEYS.FETCH_CREDIT_PURCHASES, params],
    () => getSmsCreditPurchases({ params }),
    {
      enabled: !!params?.agencyId,
      retry: false,
      staleTime: 10000,
      refetchOnMount: 'always',
      // we refetch on an interval if there are any pending purchases
      refetchInterval: (data = []) => {
        if (
          data.find(({ status }) =>
            ['draft', 'pending', 'intention_triggered', 'invoiced'].includes(
              status
            )
          )
        ) {
          return 30000;
        }

        return false;
      },
    }
  );
};

export const useFetchSmsDeliveries = ({ params }) => {
  return useQuery(
    [QUERY_KEYS.FETCH_SMS_DELIVERIES],
    () => getSmsDeliveries({ params }),
    {
      enabled: false,
      retry: false,
      staleTime: 180000,
      refetchOnMount: 'always',
    }
  );
};

export const useFetchSmsCreditPack = ({ creditPackId }) => {
  return useQuery(
    [QUERY_KEYS.FETCH_SMS_CREDIT_PACK, { creditPackId }],
    () => getSmsCreditPack({ creditPackId }),
    {
      enabled: !!creditPackId,
      retry: false,
      staleTime: 180000,
      refetchOnMount: 'always',
    }
  );
};

export const useFetchSmsDelivery = ({ params, id }) => {
  return useQuery(
    [QUERY_KEYS.FETCH_SMS_DELIVERY, { id }],
    () => getSmsDelivery({ params, id }),
    {
      retry: false,
      staleTime: 30000,
    }
  );
};

export const usePurchaseSmsCreditPack = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(purchaseSmsCreditPack, {
    mutationKey: QUERY_KEYS.PURCHASE_SMS_CREDIT_PACK,
    onSuccess: ({ data, agencyId }) => {
      const { smsAccount } = data;

      dispatch(
        showAlert({
          color: 'success',
          message: 'Successfully purchased SMS credits.',
        })
      );

      queryClient.setQueryData(
        [QUERY_KEYS.FETCH_AGENCY_SMS_ACCOUNT, agencyId],
        smsAccount
      );

      return smsAccount;
    },
    onError: (error) => error,
  });
};

export const useReprocessCreditPurchase = () => {
  const queryClient = useQueryClient();

  return useMutation(reprocessCreditPurchase, {
    mutationKey: QUERY_KEYS.REPROCESS_CREDIT_PURCHASE,
    onSuccess: () => {
      return queryClient.invalidateQueries(QUERY_KEYS.FETCH_CREDIT_PURCHASES);
    },
    onError: (error) => error,
  });
};
