import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import * as Yup from 'yup';

import { InputField } from '@app/modules/Form/FormikFields';
import { dollarToCents, toDollars } from '@app/utils';

import { useDisburseBond } from '../useLeaseFlex';

const validationSchema = ({ bondCents }) => {
  const bondDollars = toDollars(bondCents);

  return Yup.object().shape({
    bondDollars: Yup.number()
      .required('Bond amount is required')
      .moreThan(0, 'Bond amount should be more than $0')
      .max(bondDollars, `Bond amount must be less than $${bondDollars}`),
  });
};

export const ReleaseBondForm = ({
  bondCents,
  onCancel,
  bondNumber,
  leaseId,
}) => {
  const { mutate, isSuccess, isLoading } = useDisburseBond();

  useEffect(() => {
    if (isSuccess) {
      onCancel();
    }
  }, [isSuccess, onCancel]);

  const formik = useFormik({
    initialValues: {
      bondDollars: bondCents ? toDollars(bondCents) : 0,
    },
    validationSchema: validationSchema({ bondCents }),
    onSubmit: ({ bondDollars }) => {
      mutate({
        bondReturnedCents: dollarToCents(bondDollars),
        bondNumber,
        leaseId,
      });
    },
  });
  const { values, isValid, handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="bondDollars">Amount Claimed from Bond</Label>
          <InputField
            type="number"
            name="bondDollars"
            prepend="$"
            value={values?.bondDollars}
          />
        </FormGroup>
        <div className="d-flex flex-row-reverse">
          <Button color="primary" disabled={!isValid || isLoading}>
            {isLoading && (
              <FontAwesomeIcon
                className="text-white mr-1"
                icon={['far', 'spinner']}
                spin
              />
            )}
            {isLoading ? 'Claiming' : 'Claim Amount'}
          </Button>
          <Button
            color="secondary"
            className="mr-2"
            type="button"
            onClick={onCancel}
            disabled={isLoading}
            outline>
            Cancel
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};

ReleaseBondForm.propTypes = {
  bondCents: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  bondNumber: PropTypes.string.isRequired,
  leaseId: PropTypes.number.isRequired,
};
