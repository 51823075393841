/**
 * TODO: sent history tabs are commented out for now until campaigns and templates are implemented
 */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import { Pagination } from '@app/modules/Pagination';

import { SENT_HISTORY_TABS } from '../constants';
import { Filter } from './Filter';
import { TaskMessages } from './TaskMessages';
import { INITIAL_FILTER } from './constants';
import './styles.scss';

export const SentHistory = ({ agencyId }) => {
  const [filter, setFilter] = useState(INITIAL_FILTER);

  const onChangeActiveTab = (tab) =>
    setFilter((state) => ({ ...state, type: tab }));

  return (
    <Col className="col-12">
      <Card className="sms-history">
        <CardHeader className="bg-white border-400">
          <CardTitle className="mb-0" tag="h5">
            Sent History
          </CardTitle>
        </CardHeader>
        <CardBody className="p-0">
          <Nav fill pills className="sent-history-nav px-3 py-2">
            {SENT_HISTORY_TABS.map(({ title, key, disabled }) => (
              <NavItem key={`sent-history-nav-item-${key}`} className="p-0">
                <NavLink
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => onChangeActiveTab(key)}
                  className={`${filter.type === key ? 'active-tab' : ''}`}
                  disabled={disabled}
                  href="#">
                  {title}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <Filter
            filters={filter}
            setFilters={setFilter}
            type="sent-history-filter"
          />
          <TaskMessages agencyId={agencyId} filter={filter} />
          {/* <TabContent activeTab={activeTab}>
            <TabPane tabId="all" className="text-center">
              <TaskMessages agencyId={agencyId} filter={filter} />
            </TabPane>
            <TabPane tabId="campaigns">
              <div>TBD - CAMPAIGNS</div>
            </TabPane>
            <TabPane tabId="tasks">
              <div>TBD - TASKS</div>
            </TabPane>
          </TabContent> */}
        </CardBody>
      </Card>
      <Pagination
        className="mt-3 sms-pagination"
        name="message_delivery_trackers"
        isReload={false}
      />
    </Col>
  );
};

SentHistory.propTypes = {
  propertyCount: PropTypes.number,
  agencyId: PropTypes.number,
};
