/* eslint-disable react/jsx-no-bind */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Badge,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';

import snug from '@app/assets/snug-logo.png';
import { ButtonIcon, ButtonSave, ButtonTerminate } from '@app/modules/Button';
import { CardLight } from '@app/modules/Card';
import { FormField, Switch } from '@app/modules/Form';
import { useLeaseDates } from '@app/modules/Lease/hooks';
import { Link } from '@app/modules/Link';
import { updateLease } from '@app/redux/lease';
import { centsToDollar, dollarToCents, toDollars } from '@app/utils';

import { Divider } from '../Divider';
import { LeaseAlerts } from './LeaseAlerts';
import { LeaseInformationSectionCol } from './LeaseInformationSectionCol';
import { LeaseModifications } from './LeaseModifications';

const getLeaseStatus = ({
  status,
  periodic,
  leaseTermString,
  hasDeposit,
  hasDepositIntention,
  canActivate,
}) => {
  switch (status) {
    case 'active':
      if (periodic) {
        return {
          label: 'Periodic',
          color: 'secondary',
        };
      }
      if (!leaseTermString) {
        return {
          label: 'Expired',
          color: 'warning',
        };
      }
      return {
        label: 'Current',
        color: 'success',
      };
    case 'pending_clearance':
    case 'draft':
      if (hasDeposit && hasDepositIntention) {
        return {
          label: canActivate ? 'Deposit Paid' : 'Deposit Requested',
          color: 'secondary',
        };
      }
      return {
        label: 'Draft',
        color: 'secondary',
      };
    case 'pending_activate':
      return {
        label: 'Pending Activate',
        color: 'secondary',
      };
    case 'expired':
      return {
        label: 'Terminated',
        color: 'danger',
      };
    default:
      return {
        label: status,
        color: 'secondary',
      };
  }
};

export const LeaseInformationCard = ({
  lease,
  handleCancelTermination,
  handleEditLease,
  canEditInline,
}) => {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(null);
  const [dates, leaseTermString] = useLeaseDates(lease);
  const {
    id: leaseId,
    amountDollars,
    payFrequency,
    bondCents,
    bondNumber,
    bondReturnedCents,
    inspectionDateFrequencyInMonths,
    reviewDateFrequencyInMonths,
    daysRentInvoiceInAdvance,
    currentCommercialOutgoingsEstimate,
    status,
    // bools
    canActivate,
    hasDeposit,
    hasDepositIntention,
  } = lease;
  const leaseStatusBadge = getLeaseStatus({
    status,
    periodic: lease.periodic,
    leaseTermString,
    canActivate,
    hasDeposit,
    hasDepositIntention,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      gstIncluded: lease?.gstIncluded,
      periodic: lease?.periodic,
      tenantPaysWater: lease?.tenantPaysWater,
      bondNumber: lease?.bondNumber,
      bondCents: toDollars(lease?.bondCents),
      ingoingWaterMeterReading: lease?.ingoingWaterMeterReading,
    },
    validationSchema: Yup.object().shape({
      bondNumber: Yup.string()
        .nullable()
        .when('bondCents', {
          is: (bondCents) => !!bondCents && bondCents > 0 && isEditing?.bond,
          then: Yup.string()
            .required('Bond ID is required')
            .matches(
              /^(\w|-)+$/,
              'Bond ID may only contain letters, numbers and some special characters like underscores and hyphens'
            ),
        }),
      bondCents: Yup.number()
        .required()
        .min(0, 'Bond amount must be equal or greater than 0'),
    }),
    onSubmit: (values) => {
      dispatch(
        updateLease({
          id: leaseId,
          ...values,
          bondCents: dollarToCents(values.bondCents),
          bondNumber: values.bondCents > 0 ? values.bondNumber : null,
        })
      );
      setIsEditing({});
    },
  });

  const { values, setFieldValue, resetForm, handleSubmit } = formik;
  const {
    tenantPaysWater,
    periodic,
    gstIncluded,
    bondCents: bondCentsVal,
    ingoingWaterMeterReading = 0,
  } = values;

  const toggleEdit = (value) => setIsEditing(value);

  useEffect(() => resetForm(), [isEditing, resetForm]);

  const isSnug = useMemo(() => {
    return (
      !!lease.integrationSourceId &&
      lease.integrationPartnerName.toLowerCase() === 'snug'
    );
  }, [lease.integrationSourceId, lease.integrationPartnerName]);

  return (
    <CardLight>
      <CardHeader className="bg-white">
        <Row className="px-3 d-flex justify-content-between">
          <CardTitle tag="h5" className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div>Lease Info</div>
              <Badge pill color={leaseStatusBadge.color}>
                {leaseStatusBadge.label}
              </Badge>
            </div>
          </CardTitle>
        </Row>
        <Row className="px-3 justify-content-between">
          <CardSubtitle>{`ID: ${leaseId}`}</CardSubtitle>
          {isSnug && (
            <small className="d-flex align-items-center align-top text-muted justify-content-end">
              Application stored securely in
              <Link
                className="ml-1"
                href={lease.integrationSourceId}
                target="_blank">
                <img src={snug} alt="Snug.com" width={70} />
                <FontAwesomeIcon
                  icon={['far', 'fa-up-right-from-square']}
                  className="ml-2 font-weight-bold"
                  style={{ color: '#ff5777' }}
                />
              </Link>
            </small>
          )}
        </Row>
      </CardHeader>
      <CardBody className="pt-2">
        <LeaseAlerts
          lease={lease}
          handleCancelTermination={handleCancelTermination}
          handleEditLease={handleEditLease}
        />
        <FormikProvider value={formik}>
          <form>
            <Row>
              <Col xs={12} lg={6} className="d-flex flex-column">
                <Row className="flex-grow-1">
                  <LeaseInformationSectionCol>
                    <FontAwesomeIcon icon={['far', 'usd-circle']} />
                    <Row>
                      <Col xs={8} className="text-capitalize">
                        Payment Frequency:
                      </Col>
                      <Col className="text-right font-weight-bold text-capitalize">
                        {payFrequency}
                      </Col>
                    </Row>
                    <Row className="pb-lg-3">
                      <Col>Payable:</Col>
                      <Col className="text-right font-weight-bold">
                        {amountDollars?.[payFrequency]}
                      </Col>
                    </Row>
                  </LeaseInformationSectionCol>
                </Row>
                <Row className="mt-lg-2 mt-3 flex-grow-1">
                  <LeaseInformationSectionCol>
                    <Row className="px-3 d-flex justify-content-between align-items-center">
                      <FontAwesomeIcon icon={['far', 'hand-holding-usd']} />
                      {canEditInline && (
                        <>
                          {isEditing?.bond ? (
                            <div className="d-flex">
                              <ButtonTerminate
                                onClick={() => toggleEdit(null)}
                                outline>
                                Cancel
                              </ButtonTerminate>
                              <ButtonSave
                                className="px-0"
                                onClick={handleSubmit}
                                outline>
                                Save
                              </ButtonSave>
                            </div>
                          ) : (
                            <ButtonIcon
                              className="px-0"
                              icon={['fas', 'edit']}
                              onClick={() => toggleEdit({ bond: true })}
                              disabled={!canEditInline}
                            />
                          )}
                        </>
                      )}
                    </Row>
                    <Row>
                      <Col>Bond Amount:</Col>
                      {isEditing?.bond ? (
                        <Col>
                          <FormField
                            name="bondCents"
                            type="number"
                            min={0}
                            bsSize="sm"
                            step={1}
                            prepend="$"
                            value={bondCentsVal}
                          />
                        </Col>
                      ) : (
                        <Col className="text-right font-weight-bold">
                          {bondCents ? centsToDollar(bondCents) : 'N/A'}
                        </Col>
                      )}
                    </Row>
                    {bondReturnedCents && (
                      <Row className="small">
                        <Col>Bond Claimed:</Col>
                        <Col className="text-right font-weight-bold">
                          {centsToDollar(bondReturnedCents)}
                        </Col>
                      </Row>
                    )}
                    <Row className={`${!bondCents ? 'pb-lg-3' : ''}`}>
                      {isEditing?.bond ? (
                        <>
                          <Col>Bond ID:</Col>
                          <Col>
                            <FormField name="bondNumber" bsSize="sm" />
                          </Col>
                        </>
                      ) : (
                        <Col
                          className="small"
                          hidden={!bondCents}>{`ID: ${bondNumber}`}</Col>
                      )}
                    </Row>
                  </LeaseInformationSectionCol>
                </Row>
              </Col>
              <LeaseInformationSectionCol
                xs={12}
                lg={6}
                className="pl-lg-0 mt-3 mt-lg-0">
                <Row>
                  <Col>
                    <FontAwesomeIcon icon={['far', 'calendar-alt']} />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-capitalize">Start Date:</Col>
                  <Col className="text-right font-weight-bold">
                    {dates?.leaseStart}
                  </Col>
                </Row>
                <Row>
                  <Col className="text-capitalize">End Date:</Col>
                  <Col className="text-right font-weight-bold">
                    {dates?.end}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span
                      className={`small ${
                        !leaseTermString ? 'text-danger' : ''
                      }`}>
                      {leaseTermString || '(Expired)'}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-capitalize">First Payment:</Col>
                  <Col className="text-right font-weight-bold">
                    {dates?.start}
                  </Col>
                </Row>

                <Row hidden={!dates?.rentPaidUpUntil}>
                  <Col className="text-capitalize">Paid Until:</Col>
                  <Col className="text-right font-weight-bold">
                    {dates?.rentPaidUpUntil}
                  </Col>
                </Row>

                <Divider />
                <Row>
                  <Col>Next Inspection:</Col>
                  <Col className="text-right font-weight-bold">
                    {dates?.inspection || '-'}
                  </Col>
                </Row>
                <Row>
                  <Col tag="small">{`every ${
                    inspectionDateFrequencyInMonths || 0
                  } months`}</Col>
                </Row>
                <Row>
                  <Col>Next Rent Review:</Col>
                  <Col className="text-right font-weight-bold">
                    {dates?.review || '-'}
                  </Col>
                </Row>
                <Row>
                  <Col tag="small">{`every ${
                    reviewDateFrequencyInMonths || 0
                  } months`}</Col>
                </Row>
                {!!currentCommercialOutgoingsEstimate && (
                  <Row>
                    <Col>Send Invoice in Advance</Col>
                    <Col className="text-right font-weight-bold">
                      {`${daysRentInvoiceInAdvance} Days`}
                    </Col>
                  </Row>
                )}
              </LeaseInformationSectionCol>
            </Row>
            <Row className="mt-3">
              <LeaseInformationSectionCol>
                <Row className="px-3 d-flex justify-content-between align-items-center">
                  <FontAwesomeIcon icon={['far', 'exclamation-circle']} />
                  {canEditInline && (
                    <>
                      {isEditing?.info ? (
                        <div className="d-flex">
                          <ButtonTerminate
                            onClick={() => toggleEdit(null)}
                            outline>
                            Cancel
                          </ButtonTerminate>
                          <ButtonSave
                            className="px-0"
                            onClick={handleSubmit}
                            outline>
                            Save
                          </ButtonSave>
                        </div>
                      ) : (
                        <ButtonIcon
                          className="px-0"
                          icon={['fas', 'edit']}
                          onClick={() => toggleEdit({ info: true })}
                          disabled={!canEditInline}
                        />
                      )}
                    </>
                  )}
                </Row>
                <Row>
                  <Col className="text-capitalize">
                    Is GST included in rent?
                  </Col>
                  {isEditing?.info ? (
                    <Switch
                      id="gstIncluded"
                      value={gstIncluded}
                      handleChange={() =>
                        setFieldValue('gstIncluded', !gstIncluded)
                      }
                    />
                  ) : (
                    <Col className="text-right font-weight-bold">
                      {gstIncluded ? 'Yes' : 'No'}
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col className="text-capitalize">Do Tenants Pay Water?</Col>
                  {isEditing?.info ? (
                    <Switch
                      id="tenantPaysWater"
                      value={tenantPaysWater}
                      handleChange={() =>
                        setFieldValue('tenantPaysWater', !tenantPaysWater)
                      }
                    />
                  ) : (
                    <Col className="text-right font-weight-bold">
                      {tenantPaysWater ? 'Yes' : 'No'}
                    </Col>
                  )}
                </Row>
                {tenantPaysWater && (
                  <Row className="py-2">
                    <Col sm={10}>Water Meter Reading</Col>
                    <Col sm={2} className="text-right font-weight-bold">
                      {isEditing?.info ? (
                        <FormField
                          name="ingoingWaterMeterReading"
                          className="text-right"
                          type="number"
                          min={0}
                          bsSize="sm"
                          value={ingoingWaterMeterReading}
                        />
                      ) : (
                        ingoingWaterMeterReading
                      )}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>Periodic</Col>
                  {isEditing?.info ? (
                    <Switch
                      id="periodic"
                      value={periodic}
                      handleChange={() => setFieldValue('periodic', !periodic)}
                    />
                  ) : (
                    <Col className="text-right font-weight-bold">
                      {periodic ? 'Yes' : 'No'}
                    </Col>
                  )}
                </Row>
              </LeaseInformationSectionCol>
            </Row>
            <LeaseModifications
              canCancelModification={canEditInline}
              leaseId={lease?.id}
              payFrequency={lease?.payFrequency}
            />
          </form>
        </FormikProvider>
      </CardBody>
    </CardLight>
  );
};

LeaseInformationCard.propTypes = {
  lease: PropTypes.object,
  handleCancelTermination: PropTypes.func,
  handleEditLease: PropTypes.func,
  canEditInline: PropTypes.bool,
};
LeaseInformationCard.defaultProps = {
  canEditInline: false,
};
