/* eslint-disable react/jsx-no-bind */
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

import { InputField } from '@app/modules/Form/FormikFields';

import { STEPS_INDEX } from './constants';
import { stepHasErrors } from './utils';

const CompanyForm = ({ onCancel, onBack, onSubmit }) => {
  const { errors } = useFormikContext();

  const hasErrors = stepHasErrors(errors, STEPS_INDEX.bank);

  return (
    <>
      <div className="step-content">
        <div className="form-content">
          <div className="mb-2 form-group">
            <label htmlFor="company-name-field" className="ml-1">
              Your Name:
              <span className="text-danger ml-1">*</span>
            </label>
            <InputField
              data-testid="company-name-field"
              name="extraInfo.name"
              id="company-name-field"
            />
          </div>

          <div className="mb-2 form-group">
            <label htmlFor="company-position-field" className="ml-1">
              Position
              <span className="text-danger ml-1">*</span>
            </label>
            <InputField
              data-testid="company-position-field"
              name="extraInfo.position"
              id="company-position-field"
            />
          </div>

          <div className="mb-2 form-group">
            <label htmlFor="company-address-field" className="ml-1">
              Company Address:
              <span className="text-danger ml-1">*</span>
            </label>
            <InputField
              data-testid="company-address-field"
              name="extraInfo.companyAddress"
              id="company-address-field"
            />
          </div>
        </div>
      </div>

      <div className="modal-action-buttons">
        <div className="secondary-actions">
          <Button type="button" color="primary" outline onClick={onCancel}>
            Cancel
          </Button>
        </div>
        <div className="primary-actions">
          <Button type="button" color="primary" outline onClick={onBack}>
            Back
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={hasErrors}
            onClick={onSubmit}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

CompanyForm.propTypes = {
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CompanyForm;
