import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, {
  useCallback, // useImperativeHandle,
  useState,
} from 'react';
import SignaturePad from 'react-signature-canvas';
import { Button, Spinner } from 'reactstrap';

import { formatDate } from '@app/utils';

export const Signature = ({ onCancel, onBack, onSubmit }) => {
  const {
    setFieldValue,
    isSubmitting: isFormikSubmitting,
    status,
  } = useFormikContext();

  const [hasError, setHasError] = useState(false);
  const [sigPad, setSigPad] = useState({});
  const signDate = formatDate(Date.now());

  const isSubmitting = isFormikSubmitting || status === 'submitting';
  const isDisabled = hasError || isSubmitting;
  const handleClear = useCallback(() => {
    sigPad.clear();
    setHasError(false);
  }, [sigPad]);

  const setRef = useCallback(
    (ref) => {
      setSigPad(ref);
      // if (props.signatureUrl) {
      //   renderSvgToCanvas(props.signatureUrl, ref);
      // }
    },
    []
    // [props.signatureUrl]
  );

  const handleSignEnd = () => {
    setFieldValue('signature', sigPad.toDataURL('image/svg+xml'));
    setHasError(false);
  };

  const handleSubmit = useCallback(() => {
    if (sigPad.isEmpty()) {
      setHasError(true);
      return false;
    } else {
      onSubmit();
    }
  }, [onSubmit, sigPad]);

  return (
    <>
      <div className="step-content">
        <p>
          By signing and/or providing us with a valid instruction in respect to
          your authority form, you have understood and agreed to the terms and
          conditions governing the arrangement between you and Managed. Funds
          will be processed instantly to your bank account, some payments may
          take 1 business day depending on the financial institution.
        </p>
        <br />
        <div className="container" data-testid="signature-canvas">
          <div
            className={`signature-canvas-wrapper bg-white rounded-lg ${
              hasError ? 'border-danger' : ''
            }`}
            data-disabled={isSubmitting}>
            <SignaturePad
              canvasProps={{
                style: {
                  backgroundColor: 'white',
                  width: '100%',
                  height: '200px',
                },
              }}
              // eslint-disable-next-line react/jsx-no-bind
              onEnd={handleSignEnd}
              ref={setRef}
            />
            {hasError && (
              <small className="text-danger d-block px-2">
                Please provide a signature
              </small>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between py-2">
            <span>{signDate}</span>
            <Button
              color="link"
              size="sm"
              onClick={handleClear}
              disabled={isSubmitting}>
              <u>Clear Signature</u>
            </Button>
          </div>
        </div>
      </div>
      <div className="modal-action-buttons">
        <div className="secondary-actions">
          <Button type="button" color="primary" outline onClick={onCancel}>
            Cancel
          </Button>
        </div>
        <div className="primary-actions">
          <Button type="button" color="primary" outline onClick={onBack}>
            Back
          </Button>

          <Button
            type="button"
            color="primary"
            disabled={isDisabled}
            onClick={handleSubmit}>
            {isSubmitting ? (
              <>
                <Spinner size="sm">Adding Account...</Spinner>
                <span> Add Account</span>
              </>
            ) : (
              'Add Account'
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

Signature.propTypes = {
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  signatureUrl: PropTypes.string,
};

export default Signature;
