import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { Card, CardBody, CardHeader, CardTitle, Col } from 'reactstrap';

import { useToggle } from '@app/hooks';
import { ButtonIcon } from '@app/modules/Button';
import { ModalConfirm } from '@app/modules/Modal';
import { selectProfileData } from '@app/redux/profile';

import {
  useDeleteDraftBulkSms,
  useFetchBulkSms,
  useUpdateBulkSms,
} from '../hooks/use-bulk-sms';
import { BulkMessageForm } from './BulkMessageForm';
import './styles.scss';

const parseRecipients = (recipients = []) => {
  return recipients.map((recipient) => recipient?.toLowerCase());
};

export const EditBulkMessage = ({ currentAgencyOption, match }) => {
  const id = match?.params?.id;
  const history = useHistory();
  const user = useSelector(selectProfileData);
  const { mutate: updateBulkSms, isLoading: isUpdatingBulkSms } =
    useUpdateBulkSms();
  const { data, isLoading } = useFetchBulkSms({
    params: {
      agencyId: currentAgencyOption?.value,
      id,
    },
  });
  const { mutate: deleteBulkMessage } = useDeleteDraftBulkSms();
  const [modalState, toggleModal] = useToggle();
  const isEditMode = data?.status === 'Draft';

  const handleBack = useCallback(() => {
    history.push('/sms/bulk-messaging');
  }, [history]);

  const handleDelete = useCallback(() => {
    deleteBulkMessage(
      {
        agencyId: currentAgencyOption?.value,
        id,
      },
      {
        onSuccess: () => {
          history.push('/sms/bulk-messaging');
        },
      }
    );
  }, [currentAgencyOption, deleteBulkMessage, id, history]);

  const handleToggleModal = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleSaveMessage = (values) => {
    updateBulkSms({
      body: values.message,
      toTenants: values.recipients.includes('tenants'),
      toOwners: values.recipients.includes('owners'),
      agencyId: currentAgencyOption?.value,
      id,
    });
  };

  return (
    <Col>
      <Card>
        <CardHeader className="bg-white border-400 d-flex justify-content-between align-items-center">
          <CardTitle className="d-flex align-items-end mb-0" tag="h5">
            <FontAwesomeIcon
              className="text-primary-alt p-0 mr-2 pointer"
              icon={['far', 'chevron-left']}
              onClick={handleBack}
            />
            {`${isEditMode ? 'Edit' : 'View'}`} Message
          </CardTitle>
          {isEditMode && (
            <div className="sms-delete">
              <ButtonIcon
                icon={['far', 'trash-alt']}
                color="danger-alt"
                onClick={handleToggleModal}>
                Delete
              </ButtonIcon>
            </div>
          )}
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <div className="text-center">
              <PulseLoader color="#dee2e6" />
            </div>
          ) : (
            <BulkMessageForm
              // eslint-disable-next-line react/jsx-no-bind
              onSave={handleSaveMessage}
              onSaveButtonText="Save"
              isSaving={isUpdatingBulkSms}
              initialValues={{
                message: data?.message,
                recipients: parseRecipients(data?.recipients),
                sender: user.id,
                id,
              }}
              currentAgencyOption={currentAgencyOption}
              sentAt={data?.sentAt}
            />
          )}

          <ModalConfirm
            isOpen={modalState.isOpen}
            size="md"
            btnCancel={{ text: 'Close' }}
            btnSubmit={{ text: 'Confirm', color: 'danger-alt' }}
            onCancel={handleToggleModal}
            onSubmit={handleDelete}
            title="Delete Bulk Message"
            body="Are you sure you want to delete this message?"
          />
        </CardBody>
      </Card>
    </Col>
  );
};

EditBulkMessage.propTypes = {
  currentAgencyOption: PropTypes.object,
  match: PropTypes.object,
};
