import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, CardText, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { CardLight } from '@app/modules/Card';
import AccountDetails from '@app/modules/Payment/AccountDetails';
// import { useSetDefaultPaymentAccount } from '@app/modules/Payment/hooks/use-assembly';
// import { selectUserFingerprint } from '@app/redux/profile';
import { selectUser } from '@app/redux/users/selectors';

export const AddBpay = ({ isOpen, setModal }) => {
  const { bpayBillerCode, bpayReference } = useSelector(selectUser);
  // const fingerprint = useSelector(selectUserFingerprint);

  // const { mutate: setDefaultPayment } = useSetDefaultPaymentAccount();

  // const handlePayByBpay = useCallback(() => {
  //   setDefaultPayment({
  //     promisepayId: null,
  //     fingerprint,
  //     callback: onSuccessCallback,
  //   });
  // }, [fingerprint, onSuccessCallback, setDefaultPayment]);

  const hideModal = useCallback(() => {
    setModal(false);
  }, [setModal]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={hideModal}
      centered
      size="lg"
      className="bpay-page-container">
      <ModalHeader toggle={hideModal}>Deposit Funds BPAY</ModalHeader>
      <ModalBody>
        <CardLight>
          <div className="text-center">
            <CardText>
              Use your internet banking to make payment to the BPAY details
              below.
              <br />
              Please include the reference number below when making the payment.
              <br />
              These BPAY details are unique to your wallet.
            </CardText>

            <AccountDetails
              isBpay
              code={bpayBillerCode}
              refNumber={bpayReference}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              color="primary"
              outline
              className="mr-3"
              onClick={hideModal}>
              Close
            </Button>
            {/* <Button
              type="submit"
              data-testid="form-submit-button"
              onClick={handlePayByBpay}
              color="primary">
              Use BPAY
            </Button> */}
          </div>
        </CardLight>
      </ModalBody>
    </Modal>
  );
};

AddBpay.propTypes = {
  isOpen: PropTypes.bool,
  setModal: PropTypes.func,
  onSuccessCallback: PropTypes.func,
};
