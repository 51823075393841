import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CardText } from 'reactstrap';

import VirtualAccountCard from '@app/modules/Payment/VirtualAccountCard';
import { useProfile } from '@app/modules/Profile';
import { selectIsUsersLoading, selectUser } from '@app/redux/users/selectors';

export const Wallet = ({ handleGenerateVirtualAccount }) => {
  const { virtualAccount } = useSelector(selectUser);
  const isLoading = useSelector(selectIsUsersLoading);
  const { data: profile } = useProfile();

  useEffect(() => {
    if (virtualAccount === null && !isLoading) {
      handleGenerateVirtualAccount();
    }
  }, [isLoading, virtualAccount, handleGenerateVirtualAccount]);

  return (
    <VirtualAccountCard
      title="Default Payment Method"
      data={{
        ...virtualAccount,
        accountName: profile?.accountName,
      }}
      badge={{
        pill: true,
        color: 'success',
        text: 'Active',
      }}
      isLoading={isLoading}>
      <CardText>
        You can add other payment methods in Payments &gt; My Wallet.
      </CardText>
    </VirtualAccountCard>
  );
};

Wallet.propTypes = {
  handleGenerateVirtualAccount: PropTypes.func,
};

export default Wallet;
