import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import localStorage from 'store';

import { selectProfileTheme } from '@app/redux/profile';
import { adjustLightness, hexToRgb } from '@app/utils/theme';

const ThemeStyles = () => {
  const cachedTheme = localStorage.get('theme');
  const theme = useSelector(selectProfileTheme) || cachedTheme;

  if (theme) {
    document.body.classList.toggle(
      'navbar-sub-primary',
      theme.navbarSubPrimary
    );
    document.body.classList.toggle(
      'navbar-sub-text-dark',
      theme.navbarSubTextDark
    );
  }

  const themeCSS = useMemo(() => {
    if (!theme) return '';

    const themeValues = Object.entries(theme).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc += `--theme-${key
          .replace(/([A-Z])/g, '-$1')
          .toLowerCase()}: ${value};\n`;
      }
      return acc;
    }, '');

    return `
      :root {
           ${themeValues}
          --theme-primary-color: ${theme.primaryColor};
          --theme-primary-color-rgb: ${hexToRgb(theme.primaryColor)};
          --theme-primary-hover: ${adjustLightness(theme.primaryColor, 10)};
          --theme-primary-active: ${adjustLightness(theme.primaryColor, 15)};
          --theme-primary-border-active: ${adjustLightness(
            theme.primaryColor,
            18
          )};
          --theme-light-color: ${theme.lightColor || '#fff'};
          --theme-light-color-rgb: ${hexToRgb(theme.lightColor || '#fff')};
      }

      .brand-logo, .brand-default {
        ${
          theme.brandLogoUrl
            ? `background-image: url("${
                theme.brandLogoUrl || '../../assets/brand-default.webp'
              }");`
            : ''
        }
      }
    `;
  }, [theme]);

  return <style>{themeCSS}</style>;
};

export default ThemeStyles;
