import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

import { centsToDollar } from '@app/utils';

import './styles.scss';

export const Plan = ({
  id,
  credit,
  amount,
  name,
  isRecommended,
  onClick,
  disabled,
}) => (
  <Card
    className={`buy-credit-widget col-sm-3 d-flex align-items-start text-center ${
      isRecommended ? 'recommended-plan' : ''
    }`}>
    {isRecommended && (
      <div className="w-100">
        <CardHeader className=" mx-n3 px-3 text-small">
          <span>Recommended for your agency size</span>
        </CardHeader>
      </div>
    )}
    <CardBody className="w-100 d-flex flex-column justify-content-between">
      <span
        className={`text-small credit-name ${
          isRecommended ? 'recommended' : ''
        }`}>
        {name}
      </span>
      <span as="h2" className="pb-3 credit-size">
        {credit} SMS
      </span>
      <span className="credit-amount">
        {amount ? centsToDollar(amount) : '...'}
      </span>
      <Button
        color={isRecommended ? 'secondary-alt' : 'primary-alt'}
        className="w-100"
        disabled={amount === 0 || disabled}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => onClick({ credit, amount, id })}>
        Buy Credit
      </Button>
    </CardBody>
  </Card>
);

Plan.propTypes = {
  id: PropTypes.number,
  credit: PropTypes.number,
  amount: PropTypes.number,
  name: PropTypes.string,
  isRecommended: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Plan.defaultProps = {
  credit: 0,
  amount: 0,
  name: '',
  isRecommended: false,
  disabled: false,
};
