import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { CustomInput, FormFeedback } from 'reactstrap';

export const RadioField = ({ name, value, label, ...props }) => {
  const [field, meta, { setValue }] = useField({ name, value });
  const invalid = meta.touched && meta.error;
  const valid = meta.touched && !meta.error;

  const handleChange = () => {
    setValue(value);
  };

  return (
    <>
      <CustomInput
        type="radio"
        checked={field.value === value}
        id={name + value}
        label={label}
        name={name}
        value={field.value}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleChange}
        {...props}
      />

      {invalid && (
        <FormFeedback invalid={invalid} valid={valid}>
          {meta.error}
        </FormFeedback>
      )}
    </>
  );
};

RadioField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  label: PropTypes.string.isRequired,
};

RadioField.defaultProps = {};

export default RadioField;
