import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { FormGroup, FormText, Label } from 'reactstrap';

import { InputField, RadioField } from '@app/modules/Form/FormikFields';

import { useLeaseState } from '../../Provider';
import { ModalContentContainer } from './ModalContentContainer';

const Deposit = () => {
  const {
    values: { hasDeposit },
    setFieldValue,
  } = useFormikContext();
  const { leaseState } = useLeaseState();
  const { renewingLeaseId } = leaseState?.lease;

  useEffect(() => {
    if (hasDeposit === 'false') {
      setFieldValue('deposit', 0);
    }
  }, [hasDeposit, setFieldValue]);

  return (
    <>
      <h3>Deposit</h3>
      <ModalContentContainer>
        <FormGroup>
          {renewingLeaseId ? (
            <FormText className="mb-3">
              No deposit needed since this lease is renewed.
            </FormText>
          ) : (
            <>
              <Label for="hasDeposit">
                Are you collecting a deposit on this lease?
              </Label>
              <div role="group" className="radio-group-wrapper">
                <RadioField name="hasDeposit" label="Yes" value="true" />
                <RadioField name="hasDeposit" label="No" value="false" />
              </div>
            </>
          )}
        </FormGroup>

        {hasDeposit === 'true' && (
          <FormGroup>
            <Label for="deposit" className="mb-3">
              Enter the deposit amount the tenant is paying to secure the lease,
              if applicable.
            </Label>
            <InputField
              name="deposit"
              type="number"
              prepend="$"
              disabled={renewingLeaseId}
            />
          </FormGroup>
        )}
      </ModalContentContainer>
    </>
  );
};

export default Deposit;
