/* eslint-disable react/jsx-no-bind */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Alert, Button, CustomInput, Modal, ModalBody } from 'reactstrap';

import { useTaskExcelExport, useTaskExcelExportCount } from './hooks';

const DUE_DATE_FILTERS = [
  { label: 'Today', value: 'today' },
  { label: 'This Month', value: 'thisMonth' },
  { label: 'Last Month', value: 'lastMonth' },
  { label: 'Last 6 Months', value: 'last6Months' },
  { label: 'Last 12 Months', value: 'last12Months' },
  { label: 'Last 7 Days', value: 'last7Days' },
  { label: 'Last 30 Days', value: 'last30Days' },
  { label: 'Last 3 Months', value: 'last3Months' },
  { label: 'All-time', value: 'allTime' },
  { label: 'Custom', value: 'custom' },
];

export const ExportTaskModal = ({
  modalState,
  toggleModalState,
  filters,
  userEmail,
}) => {
  const [dueDate, setDueDate] = useState({ allTime: true });
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const { sort, ...visibleFilters } = filters;

  const { mutate } = useTaskExcelExport();
  const { data, isFetching } = useTaskExcelExportCount({
    ...filters,
    fromDate,
    toDate,
  });

  const handleExportTasks = () => {
    mutate({ ...filters, fromDate, toDate });
    toggleModalState();
  };

  const handleDueDateChange = ({ target: { value } }) => {
    setDueDate({ [value]: true });

    const today = new Date();
    const startOfMonth = (date, offset = 0) =>
      new Date(date.getFullYear(), date.getMonth() + offset, 1);
    const endOfMonth = (date, offset = 0) =>
      new Date(date.getFullYear(), date.getMonth() + offset + 1, 0);

    switch (value) {
      case 'today':
        setFromDate(today);
        setToDate(today);
        break;
      case 'thisMonth':
        setFromDate(startOfMonth(today));
        setToDate(today);
        break;
      case 'lastMonth':
        setFromDate(startOfMonth(today, -1));
        setToDate(endOfMonth(today, -1));
        break;
      case 'last6Months':
        setFromDate(startOfMonth(today, -6));
        setToDate(today);
        break;
      case 'last12Months':
        setFromDate(startOfMonth(today, -12));
        setToDate(today);
        break;
      case 'last7Days':
        setFromDate(new Date(today.setDate(today.getDate() - 7)));
        setToDate(new Date());
        break;
      case 'last30Days':
        setFromDate(new Date(today.setDate(today.getDate() - 30)));
        setToDate(new Date());
        break;
      case 'last3Months':
        setFromDate(startOfMonth(today, -3));
        setToDate(today);
        break;
      case 'allTime':
      case 'custom':
        setFromDate('');
        setToDate('');
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      isOpen={modalState.isOpen}
      toggle={toggleModalState}
      size="lg"
      centered>
      <ModalBody className="p-4">
        <h5>Export Task</h5>
        <p className="text-muted">
          An email with the excel will be sent to <strong>{userEmail}</strong>
        </p>
        <Alert color="info">
          <FontAwesomeIcon
            icon={['fal', 'circle-info']}
            className="mr-2"
            size="lg"
          />
          {`You're exporting ${isFetching ? '...' : data} rows with ${
            Object.keys(visibleFilters)?.length
          } filters applied.`}
        </Alert>
        <label>Date Range (Due Date)</label>
        <div className="d-flex flex-wrap">
          {DUE_DATE_FILTERS.map((filter) => (
            <CustomInput
              className="d-flex flex-basis-50"
              style={{ flexBasis: '50%', color: 'red' }}
              key={filter.value}
              checked={!!dueDate[filter.value]}
              id={`due-date--${filter.value}`}
              label={filter.label}
              name="dueDateFilter"
              type="radio"
              value={filter.value}
              onChange={handleDueDateChange}
            />
          ))}
        </div>
        {dueDate.custom && (
          <div className="d-flex mt-3">
            <div className="col-6 pl-0 pr-2">
              <label>Date From</label>
              <DatePicker
                name="fromDate"
                className="form-control"
                dateFormat="dd-MM-yyyy"
                placeholderText="Pick a date"
                onChange={(value) => setFromDate(value)}
                selected={fromDate}
                maxDate={toDate}
                startDate={fromDate}
                endDate={toDate}
                selectsStart
              />
            </div>
            <div className="col-6 pr-0 pl-2">
              <label>Date To</label>
              <DatePicker
                name="toDate"
                className="form-control"
                dateFormat="dd-MM-yyyy"
                placeholderText="Pick a date"
                onChange={(value) => setToDate(value)}
                selected={toDate}
                minDate={fromDate}
                startDate={fromDate}
                endDate={toDate}
                selectsEnd
              />
            </div>
          </div>
        )}
        <div className="text-right mt-3">
          <Button
            onClick={toggleModalState}
            type="button"
            className="bg-gray-100 text-dark border-light">
            Cancel
          </Button>
          <Button
            color="primary"
            className="ml-3"
            type="submit"
            disabled={isFetching || data <= 0}
            onClick={handleExportTasks}>
            Export
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

ExportTaskModal.propTypes = {
  modalState: PropTypes.shape({
    isOpen: PropTypes.bool,
  }).isRequired,
  toggleModalState: PropTypes.func.isRequired,
  filters: PropTypes.object,
  userEmail: PropTypes.string,
};
