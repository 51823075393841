import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import { Header } from '@app/modules/Header';
import { NavSub } from '@app/modules/Nav';
import { selectCanManagePlans } from '@app/redux/profile';
import { USER_TYPES } from '@app/redux/users';

import { Invoices } from './Invoices';
import { Plans } from './Plans';

export const Billing = () => {
  const canManagePlans = useSelector(selectCanManagePlans);

  const navItems = useMemo(() => {
    const items = [
      {
        title: 'Invoices',
        to: '/payments/billing/invoices',
        type: USER_TYPES.principal,
        component: Invoices,
      },
    ];

    if (canManagePlans) {
      items.push({
        title: 'Plans',
        to: '/payments/billing/plans',
        type: USER_TYPES.principal,
        component: Plans,
      });
    }

    return items;
  }, [canManagePlans]);

  return (
    <>
      <Header
        title="Billings"
        subHeaderComponent={
          navItems.length > 1 ? <NavSub items={navItems} /> : null
        }
      />

      <Container className="mb-5">
        <Switch>
          {navItems.map((route) => (
            <Route
              key={`payments.billings.${route.title}`}
              path={route.to}
              component={route.component}
            />
          ))}

          <Redirect to="/payments/billing/invoices" />
        </Switch>
      </Container>
    </>
  );
};
