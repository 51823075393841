import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthLayout } from '@app/modules/AuthLayout';
import { useSendSecurityCode } from '@app/modules/SmsVerification';
import Form from '@app/modules/SmsVerification/Form';
import {
  selectProfileData,
  selectProfilePhoneNumber,
  verifyAuthy,
} from '@app/redux/profile';
import { obfuscatePhoneNumber } from '@app/utils/obfuscateParams';

export const TwoFactorAuth = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectProfileData);
  const { isLoadingUser, email } = user;

  const phoneNumber = useSelector(selectProfilePhoneNumber);
  const maskedNumber = obfuscatePhoneNumber(phoneNumber || '');

  const { mutate, isLoading, isSuccess, error } = useSendSecurityCode();

  const requestSecurityCode = useCallback(() => {
    mutate({ email });
  }, [mutate, email]);

  const verify = useCallback(
    ({ securityCode }) => {
      dispatch(verifyAuthy({ authyToken: securityCode, email }));
    },
    [dispatch, email]
  );

  useEffect(() => {
    if (email) {
      requestSecurityCode();
    }
  }, [email, requestSecurityCode]);

  if (user.isAuthyVerified) {
    window.location.assign('/dashboard');
  } else if (user.email === undefined) {
    window.location.assign('/');
  }

  return (
    <AuthLayout
      header="Enter Verification Code"
      subHeader={`Please enter the verification code we sent to ${maskedNumber}.`}>
      <Form
        onSubmit={verify}
        isRequestingSecurityCode={isLoading || isLoadingUser}
        requestSecurityCode={requestSecurityCode}
        isSecurityCodeSuccess={isSuccess}
        disableResendButton={error?.disableResendButton}
      />
    </AuthLayout>
  );
};
