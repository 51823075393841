import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import { STATUSES } from '@app/redux/platformCharge/constants';

const getStatusColorClass = (status) => {
  switch (status) {
    case STATUSES.INTENTION_TRIGGERED:
      return 'text-warning';
    case STATUSES.FAILED:
    case STATUSES.BAD_DEBT:
      return 'text-danger';
    case STATUSES.COMPLETED:
      return 'text-success';
    case STATUSES.INVOICED:
      return 'text-primary';
    case STATUSES.CALCULATED:
    default:
      return 'text-secondary';
  }
};

const statusIcon = (status, colorClass) => {
  switch (status) {
    case STATUSES.INTENTION_TRIGGERED:
      return (
        <FontAwesomeIcon
          icon={['far', 'arrows-rotate']}
          className={`ml-2 ${colorClass}`}
        />
      );
    case STATUSES.FAILED:
    case STATUSES.BAD_DEBT:
      return (
        <FontAwesomeIcon
          icon={['far', 'times-circle']}
          className={`ml-2 ${colorClass}`}
        />
      );
    case STATUSES.COMPLETED:
    case STATUSES.INVOICED:
      return (
        <FontAwesomeIcon
          icon={['far', 'check-circle']}
          className={`ml-2 ${colorClass}`}
        />
      );
    case STATUSES.CALCULATED:
    default:
      return (
        <FontAwesomeIcon
          icon={['far', 'exclamation-circle']}
          className={`ml-2 ${colorClass}`}
        />
      );
  }
};

export const Status = ({ platformCharge }) => {
  const status = platformCharge.status;
  const hasCharge = platformCharge.totalAmountCents > 0;
  const colorClass = getStatusColorClass(status);

  if (!hasCharge) {
    return (
      <span className="text-success text-capitalize">
        No charge
        <FontAwesomeIcon
          icon={['far', 'check-circle']}
          className="ml-2 text-success"
        />
      </span>
    );
  }

  return (
    <span className={`${colorClass} text-capitalize`}>
      {status === STATUSES.INTENTION_TRIGGERED ? 'Processing' : status}
      {statusIcon(status, colorClass)}
    </span>
  );
};

Status.propTypes = {
  platformCharge: PropTypes.object,
};
