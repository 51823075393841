import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import SignaturePad from 'react-signature-canvas';
import { Button } from 'reactstrap';

import { formatDate } from '@app/utils';

export const SignatureCanvasV2 = forwardRef((props, ref) => {
  const [isError, setIsError] = useState(false);
  const [sigPad, setSigPad] = useState({});
  const signDate = formatDate(Date.now());

  const handleClear = useCallback(() => {
    sigPad.clear();
    setIsError(false);
  }, [sigPad]);

  const setRef = useCallback(
    (ref) => {
      setSigPad(ref);
      // if (props.signatureUrl) {
      //   renderSvgToCanvas(props.signatureUrl, ref);
      // }
    },
    []
    // [props.signatureUrl]
  );

  const handleSubmit = useCallback(() => {
    if (sigPad.isEmpty()) {
      setIsError(true);
      return false;
    } else {
      // debugger;
      props.onSubmit && props.onSubmit(sigPad.toDataURL('image/svg+xml'));
      return sigPad.toDataURL('image/svg+xml');
    }
  }, [props, sigPad]);

  // const renderSvgToCanvas = async (url, sigPad) => {
  //   try {
  //     // eslint-disable-next-line no-undef
  //     const response = await fetch(url);
  //     const svgText = await response.text();

  //     // eslint-disable-next-line no-undef
  //     const img = new Image();

  //     // eslint-disable-next-line no-undef
  //     const svgBlob = new Blob([svgText], { type: 'image/svg+xml' });
  //     const blobUrl = URL.createObjectURL(svgBlob);

  //     img.onload = () => {
  //       const canvas = sigPad.getCanvas();
  //       const context = canvas.getContext('2d');

  //       // Get the actual rendered width and height of the canvas
  //       const canvasWidth = canvas.clientWidth;
  //       const canvasHeight = 200;

  //       // debugger;

  //       // Adjust the canvas resolution to match the CSS size
  //       // canvas.width = canvasWidth;
  //       // canvas.height = canvasHeight;

  //       // Clear the canvas before rendering
  //       sigPad.clear();
  //       // Calculate scaling to fit the canvas size while maintaining aspect ratio
  //       const imgAspectRatio = img.width / img.height;
  //       const canvasAspectRatio = canvasWidth / canvasHeight;

  //       let renderWidth, renderHeight, offsetX, offsetY;

  //       if (imgAspectRatio > canvasAspectRatio) {
  //         console.log('wider');
  //         // Image is wider relative to canvas
  //         renderWidth = canvasWidth;
  //         renderHeight = canvasWidth / imgAspectRatio;
  //         offsetX = 0;
  //         offsetY = (canvasHeight - renderHeight) / 2;
  //       } else {
  //         console.log('taller');
  //         // Image is taller relative to canvas
  //         renderWidth = canvasHeight * imgAspectRatio;
  //         renderHeight = canvasHeight;
  //         offsetX = (canvasWidth - renderWidth) / 2;
  //         offsetY = 0;
  //       }

  //       // Draw the SVG image on the canvas with correct scaling and position
  //       context.drawImage(img, offsetX, offsetY, renderWidth, renderHeight);

  //       // Clean up the created URL object
  //       URL.revokeObjectURL(blobUrl);
  //     };

  //     img.src = blobUrl;
  //   } catch (error) {
  //     console.error('Error fetching SVG:', error);
  //   }
  // };

  // Expose the handleSubmit method to parent components
  useImperativeHandle(ref, () => ({
    submitSignature: handleSubmit,
    clearSignature: handleClear,
  }));

  return (
    <div className="container" data-testid="signature-canvas">
      <div className={`bg-white rounded-lg ${isError ? 'border-danger' : ''}`}>
        <SignaturePad
          canvasProps={{
            style: {
              backgroundColor: 'white',
              width: '100%',
              height: '200px',
            },
          }}
          // eslint-disable-next-line react/jsx-no-bind
          onEnd={() => setIsError(false)}
          ref={setRef}
        />
        {isError && (
          <small className="text-danger d-block px-2">
            Please provide a signature
          </small>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-between py-2">
        <span>{signDate}</span>
        <Button color="link" size="sm" onClick={handleClear}>
          <u>Clear Signature</u>
        </Button>
      </div>
    </div>
  );
});

SignatureCanvasV2.propTypes = {
  onSubmit: PropTypes.func,
  signatureUrl: PropTypes.string,
};

export default SignatureCanvasV2;
