import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { Col, Row } from 'reactstrap';

import { useAgencies } from '@app/modules/Agency/hooks';
import { Filter } from '@app/modules/Filter';
import { useRolesContext } from '@app/modules/Profile';
import { useUsers } from '@app/modules/User/hooks';
import { USER_TYPES } from '@app/redux/users';

const formatOptions = (items, labelKey, valueKey) => {
  const options = items
    ? items.map((item) => ({
        label: item.label || item[labelKey],
        value: item.value || item[valueKey],
      }))
    : [];

  return orderBy(options, [(option) => option.label.toLowerCase()], ['asc']);
};

const AgencyAndManagerFiltersComponent = ({ setFilterValues }) => {
  const { isLoading: isManagersOptionsLoading, data: managerOptions = [] } =
    useUsers({
      type: USER_TYPES.manager,
      options: {
        select: (users) => formatOptions(users, 'name', 'id'),
      },
      params: {
        perPage: 25,
      },
    });

  const { isLoading: isAgenciesOptionsLoading, data: agencyOptions = [] } =
    useAgencies({
      type: USER_TYPES.manager,
      useQueryOptions: {
        select: (agencies) => formatOptions(agencies, 'company_name', 'id'),
      },
    });

  const { isManager } = useRolesContext();

  const handleSelect = useCallback(
    (name, values) => {
      setFilterValues((prevState) => ({
        ...prevState,
        [name]: values,
      }));
    },
    [setFilterValues]
  );

  const handleSelectManager = useCallback(
    (values) => handleSelect('managerIds', values.managerIds),
    [handleSelect]
  );
  const handleSelectAgency = useCallback(
    (values) => handleSelect('agencyIds', values.agencyIds),
    [handleSelect]
  );

  if (!isManager) return null;

  return (
    <Row className="mx-3">
      <Col
        xs={{ size: 12, order: 1 }}
        sm={{ size: 12, order: 1 }}
        md={{ size: 6, order: 1 }}
        className="mb-2 text-primary">
        Property Manager
      </Col>
      <Col
        xs={{ size: 12, order: 3 }}
        sm={{ size: 12, order: 3 }}
        md={{ size: 6, order: 2 }}
        className="mb-2 text-primary">
        Office Branch
      </Col>
      <Col
        xs={{ size: 12, order: 1 }}
        sm={{ size: 12, order: 1 }}
        md={{ size: 6, order: 3 }}
        className="mb-2">
        <Filter.TypeaheadMultiSelect
          name="managerIds"
          label="Search Property Manager"
          isLoading={isManagersOptionsLoading}
          values={managerOptions}
          onSelect={handleSelectManager}
          isShowSearchIcon
          isClearable
        />
      </Col>
      <Col
        xs={{ size: 12, order: 3 }}
        sm={{ size: 12, order: 3 }}
        md={{ size: 6, order: 4 }}
        className="mb-2">
        <Filter.TypeaheadMultiSelect
          name="agencyIds"
          label="Search Branch"
          isLoading={isAgenciesOptionsLoading}
          values={agencyOptions}
          onSelect={handleSelectAgency}
          isShowSearchIcon
          isClearable
        />
      </Col>
    </Row>
  );
};

AgencyAndManagerFiltersComponent.propTypes = {
  setFilterValues: PropTypes.func.isRequired,
};

export const AgencyAndManagerFilters = memo(AgencyAndManagerFiltersComponent);
