import * as Yup from 'yup';

export const DEFAULT_VALUES = {
  bankName: '',
  bankAccountName: '',
  bankRoutingNumber: '',
  bankAccountNumber: '',
  accountType: 'savings',
  holderType: 'personal',
  amountCents: '1000',
  country: 'AUS',
  agreement: false,
  setAsDisbursementAccount: false,
  isCompany: false, // for validation purposes
};

export const ACCOUNT_TYPE_OPTIONS = [
  {
    value: 'savings',
    label: 'Savings',
  },
  {
    value: 'checking',
    label: 'Checking',
  },
];

export const HOLDER_TYPE_OPTIONS = [
  {
    value: 'personal',
    label: 'Personal',
  },
  {
    value: 'business',
    label: 'Business',
  },
];

export const AddBankSchema = Yup.object().shape({
  bankName: Yup.string().required('Bank name is required'),

  // TODO: Add validation for bank account name
  // accountName: Yup.string()
  //   .required('Account name is required')
  //   .min(5, 'Account name must be between 5 and 32 characters')
  //   .max(32, 'Account name must be between 5 and 32 characters'),
  // TODO: Add validation for accountNumber
  // accountNumber: Yup.number()
  //   .required('Account number is required')
  //   .typeError('Account number must be valid'),

  agreement: Yup.boolean().oneOf(
    [true],
    'You must accept the terms & conditions'
  ),

  // TODO: Add validation for routingNumber
  // routingNumber: Yup.string()
  //   .required('BSB is required')
  //   .min(
  //     7,
  //     'BSB must contain 6 numbers with a dash in the middle (eg.: 000-000)'
  //   ),

  extraInfo: Yup.object()
    .shape({
      position: Yup.string(),
      name: Yup.string(),
      companyAddress: Yup.string(),
    })
    .when('isCompany', {
      is: true,
      then: Yup.object().shape({
        name: Yup.string().required('Name is required'),
        position: Yup.string().required('Company position is required'),
        companyAddress: Yup.string().required('Company address is required'),
      }),
    }),
});

export const STEP_FIELDS = {
  0: ['bankName', 'agreement'],
  1: ['extraInfo.name', 'extraInfo.position', 'extraInfo.companyAddress'],
};

export const STEPS_INDEX = {
  bank: 0,
  company: 1,
  signature: 2,
};
