import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';

export const Status = ({ status }) => {
  const { badgeColor, textColor } =
    status === 'draft'
      ? {
          badgeColor: 'light',
          textColor: 'text-dark',
        }
      : {
          badgeColor: 'success',
          textColor: '',
        };

  return (
    <Badge
      pill
      color={badgeColor}
      className={`text-capitalize text-white py-1 px-2 ${textColor}`}>
      {status}
    </Badge>
  );
};

Status.propTypes = {
  status: PropTypes.string,
};
