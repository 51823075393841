import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';

import PaymentMethodItem from '@app/containers/Payments/Walletv2/PaymentMethodItem';
import {
  getMaskedAccounts,
  useFetchAccounts,
} from '@app/modules/Payment/hooks/use-assembly';
import { useRolesContext } from '@app/modules/Profile';

export const PaymentMethods = ({
  onSelectPaymentMethod,
  selectedPaymentMethod,
}) => {
  const history = useHistory();

  const { data: accounts, isSuccess } = useFetchAccounts(null, {
    select: getMaskedAccounts,
  });

  const { isCorporateUser } = useRolesContext();

  const handleSelectPaymentMethod = (account) => () => {
    onSelectPaymentMethod(account);
  };

  const handleAddBankAccount = useCallback(() => {
    // wallet V2:
    // history.push('/payments/wallet/add-payment-method?payment-method=bank');
    history.push('/payments/settings#default');
  }, [history]);

  // Set default payment method if no payment method is selected
  useEffect(() => {
    if (isSuccess && !selectedPaymentMethod?.id) {
      const defaultAccount = accounts.find((account) => account.isDefault);
      if (defaultAccount) {
        onSelectPaymentMethod(defaultAccount);
      }
    }
  }, [accounts, isSuccess, onSelectPaymentMethod, selectedPaymentMethod]);

  return (
    <div>
      <Card className="border my-3">
        <CardHeader>Bank Accounts (direct debit)</CardHeader>
        <CardBody>
          <ListGroup className="payment-method-container">
            {accounts &&
              accounts
                .filter((account) => account.type === 'bank')
                .map((account) => (
                  <div key={`${account.id}-${account.promisepayId}`}>
                    <ListGroupItem className="border-0">
                      <PaymentMethodItem
                        id={account.id}
                        type={account.type}
                        name={account.name}
                        accountNumber={account.maskedValue}
                        isActive={account?.isDefault}
                        isInUse={account?.isInUse}
                        isVirtualAccount={account?.isVirtual}
                        isMarkedForVerification={
                          account?.isMarkedForVerification
                        }
                        buttonText=""
                        ddrFormUrl={account?.ddrFormUrl}
                        onSelectPaymentMethod={handleSelectPaymentMethod(
                          account
                        )}
                        selectedPaymentMethod={selectedPaymentMethod}
                      />
                    </ListGroupItem>
                  </div>
                ))}
          </ListGroup>
          <Button
            color="primary"
            outline
            className="mt-2"
            size="sm"
            onClick={handleAddBankAccount}
            disabled={isCorporateUser}>
            &#43; Add Bank Account
          </Button>
        </CardBody>
        <CardHeader>Credit or Debit Cards</CardHeader>
        <CardBody>
          <ListGroup className="payment-method-container">
            {accounts &&
              accounts
                .filter((account) =>
                  ['visa', 'master', 'mastercard', 'american_express'].includes(
                    account.type
                  )
                )
                .map((account) => (
                  <div key={`${account.id}-${account.promisepayId}`}>
                    <ListGroupItem className="border-0">
                      <PaymentMethodItem
                        id={account.id}
                        type={account.type}
                        name={account.name}
                        accountNumber={account.maskedValue}
                        isActive={account?.isDefault}
                        isInUse={account?.isInUse}
                        isVirtualAccount={account?.isVirtual}
                        isMarkedForVerification={
                          account?.isMarkedForVerification
                        }
                        buttonText=""
                        ddrFormUrl={account?.ddrFormUrl}
                        onSelectPaymentMethod={handleSelectPaymentMethod(
                          account
                        )}
                        selectedPaymentMethod={selectedPaymentMethod}
                      />
                    </ListGroupItem>
                  </div>
                ))}
          </ListGroup>
          <Button
            color="primary"
            outline
            className="mt-2"
            size="sm"
            onClick={handleAddBankAccount}
            disabled={isCorporateUser}>
            &#43; Add Card
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

PaymentMethods.propTypes = {
  selectedPaymentMethod: PropTypes.object,
  onSelectPaymentMethod: PropTypes.func,
};
