/* eslint-disable react/jsx-no-bind */
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';

import { CardLight } from '@app/modules/Card';
import VirtualAccountCard from '@app/modules/Payment/VirtualAccountCard';
import {
  QUERY_KEYS,
  useSetDefaultPaymentAccount,
} from '@app/modules/Payment/hooks/use-assembly';
import { useProfile } from '@app/modules/Profile';
import { selectUserFingerprint } from '@app/redux/profile';
import { selectUser } from '@app/redux/users/selectors';
import { toQueryObject } from '@app/utils';

import { BasicPaymentMethodItem } from '../PaymentMethodItem';
import { AddBankAccount } from './AddBankAccount';
import { AddBpay } from './AddBpay';
import { AddCardAccount } from './AddCardAccount';

const initialState = {
  bankAccountModal: false,
  cardAccountModal: false,
  cardBpayModal: false,
};

const modalReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_BANK_ACCOUNT_MODAL':
      return { ...state, bankAccountModal: !state.bankAccountModal };
    case 'TOGGLE_CARD_ACCOUNT_MODAL':
      return { ...state, cardAccountModal: !state.cardAccountModal };
    case 'TOGGLE_CARD_BPAY_MODAL':
      return { ...state, cardBpayModal: !state.cardBpayModal };
    case 'RESET_MODAL':
      return initialState;
    default:
      return state;
  }
};

export const SelectPaymentMethod = ({ allowedPaymentMethods = [] }) => {
  const fingerprint = useSelector(selectUserFingerprint);
  const queryClient = useQueryClient();
  const history = useHistory();

  const params = useMemo(
    () => toQueryObject(history.location.search),
    [history.location.search]
  );

  const { virtualAccount } = useSelector(selectUser);
  const { data: profile } = useProfile();

  const { mutate: setDefaultPayment, isLoading } =
    useSetDefaultPaymentAccount();

  const handleSetAsBankTransfer = useCallback(() => {
    setDefaultPayment({
      promisepayId: null,
      fingerprint,
      callback: () => {
        queryClient.invalidateQueries(QUERY_KEYS.FETCH_ACCOUNTS);
        history.replace('/payments/wallet');
      },
    });
  }, [fingerprint, history, queryClient, setDefaultPayment]);

  const [state, dispatch] = useReducer(modalReducer, initialState);

  const isBpay = profile?.paymentMethod === 'bpay';

  const handleSuccessAccount = useCallback(() => {
    history.replace('/payments/wallet');
    dispatch({ type: 'RESET_MODAL' });
  }, [history]);

  useEffect(() => {
    if (params && params.paymentMethod) {
      switch (params.paymentMethod) {
        case 'bank':
          dispatch({ type: 'TOGGLE_BANK_ACCOUNT_MODAL' });
          break;
        case 'card':
          dispatch({ type: 'TOGGLE_CARD_ACCOUNT_MODAL' });
          break;
        case 'bpay':
          dispatch({ type: 'TOGGLE_CARD_BPAY_MODAL' });
          break;
        default:
          break;
      }
    }
  }, [params]);

  return (
    <div className="select-payment-method-container">
      <VirtualAccountCard
        title="Primary Payment Method"
        className="mb-3"
        data={{
          routingNumber: virtualAccount?.routingNumber,
          accountNumber: virtualAccount?.accountNumber,
          accountName: profile?.accountName,
        }}
        badge={
          isBpay
            ? {
                pill: true,
                color: 'success',
                text: 'Active',
              }
            : undefined
        }>
        {!isBpay && (
          <Button
            color="primary"
            disabled={isLoading}
            onClick={handleSetAsBankTransfer}>
            Use as payment method
          </Button>
        )}
      </VirtualAccountCard>

      <CardLight title="Other payment methods" className="mb-3">
        <ListGroup flush className="payment-method-container">
          {allowedPaymentMethods.includes('dd') && (
            <ListGroupItem
              tag="button"
              onClick={() => dispatch({ type: 'TOGGLE_BANK_ACCOUNT_MODAL' })}>
              <BasicPaymentMethodItem
                type="bank"
                name="Bank Account (direct debit)"
                info="1-3 business days, bank fee applied"
              />
            </ListGroupItem>
          )}

          {allowedPaymentMethods.includes('cc') && (
            <ListGroupItem
              tag="button"
              onClick={() => dispatch({ type: 'TOGGLE_CARD_ACCOUNT_MODAL' })}>
              <BasicPaymentMethodItem
                type="card"
                name="Credit or Debit Card"
                info="1 business day, fee applied"
              />
            </ListGroupItem>
          )}

          <ListGroupItem
            tag="button"
            onClick={() => dispatch({ type: 'TOGGLE_CARD_BPAY_MODAL' })}>
            <BasicPaymentMethodItem
              type="bpay"
              name="BPAY"
              info="1-3 business days, fee applied"
            />
          </ListGroupItem>
        </ListGroup>
      </CardLight>
      <Button
        color="primary"
        outline
        onClick={() => history.replace('/payments/wallet')}>
        Cancel
      </Button>

      {/* modals */}
      <div>
        <AddBankAccount
          isOpen={state.bankAccountModal}
          setModal={() => dispatch({ type: 'TOGGLE_BANK_ACCOUNT_MODAL' })}
          onSuccessCallback={handleSuccessAccount}
        />
        <AddCardAccount
          isOpen={state.cardAccountModal}
          setModal={() => dispatch({ type: 'TOGGLE_CARD_ACCOUNT_MODAL' })}
          onSuccessCallback={handleSuccessAccount}
        />
        <AddBpay
          isOpen={state.cardBpayModal}
          setModal={() => dispatch({ type: 'TOGGLE_CARD_BPAY_MODAL' })}
          onSuccessCallback={handleSuccessAccount}
        />
      </div>
    </div>
  );
};

SelectPaymentMethod.propTypes = {
  allowedPaymentMethods: PropTypes.arrayOf(PropTypes.string),
};

export default memo(SelectPaymentMethod);
