import { startCase } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { Badge, Col, Row } from 'reactstrap';

import { featurePropertyImage, imageSrcMedium } from '@app/utils';

import { CardImage } from '../Card';
import { PropertyAddress, PropertyBadge } from '../Property';

const PrimaryBadge = () => (
  <Badge className="justify-end" color="success">
    Primary Tenant
  </Badge>
);
const SecondaryBadge = () => (
  <Badge
    className="justify-end"
    style={{ color: 'white', backgroundColor: '#FF7D46' }}>
    Other Tenant
  </Badge>
);

export const PastLeaseCard = ({
  startDate,
  endDate,
  propertyAddress,
  propertyId,
  status,
  attachments,
  onClick,
  propertyType,
  isPrimaryTenant,
}) => (
  <Row className="my-2 p-0">
    <Col xs={12} onClick={onClick({ id: propertyId })}>
      <CardImage
        className="border-0 shadow-sm"
        src={imageSrcMedium(featurePropertyImage(attachments))}>
        <div className="d-flex col-12 px-0 justify-content-between align-items-start align-items-md-center flex-column flex-md-row">
          <PropertyAddress {...propertyAddress} className="col-md-4 mb-2" />
          <Col md={2} xs={6} className="card-text">
            <p>
              <strong>Start Date</strong>
              <br />
              {startDate}
            </p>
          </Col>
          <Col md={2} xs={6} className="card-text">
            <p>
              <strong>End Date</strong>
              <br />
              {endDate}
            </p>
          </Col>
          <Col md={2} className="align-self-start">
            <Badge pill color="primary" className="opacity-50 mr-2">
              {startCase(propertyType)}
            </Badge>
            {isPrimaryTenant ? <PrimaryBadge /> : <SecondaryBadge />}
          </Col>
          <Col
            md={2}
            className="d-flex pr-3 align-self-start justify-content-end">
            <PropertyBadge status={status} />
          </Col>
        </div>
      </CardImage>
    </Col>
  </Row>
);

PastLeaseCard.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  propertyAddress: PropTypes.shape({
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }).isRequired,
  propertyId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  attachments: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  propertyType: PropTypes.string.isRequired,
  isPrimaryTenant: PropTypes.bool.isRequired,
};
