import { FETCH, UPDATE_STATUS } from './constants';

export const fetchPlatformCharges = () => ({ type: FETCH });

export const updatePlatformChargesStatus = (ids, status) => ({
  type: UPDATE_STATUS,
  payload: {
    ids,
    status,
  },
});
