import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

import { useFetch } from '@app/hooks';
import { ProfilePlanWithFormik } from '@app/modules/Profile';
import { getProfile } from '@app/redux/profile';
import { getUser } from '@app/redux/users';
import { httpClient } from '@app/utils';

function usePlans() {
  const [key, setKey] = useState(1);

  const endpoint = useSelector((state) => {
    const { agency, managerAgencies } = getUser(
      state.users,
      getProfile(state.profile)
    );

    // Get the first manager agency (handle corporate users)
    let { agencyId } = (managerAgencies || [])[0] || {};

    // Fallback to the agency
    if (!agencyId && agency) {
      agencyId = agency.id;
    }

    if (agencyId) {
      return `agencies/${agencyId}/plans`;
    }
  });

  const action = useCallback(
    async (action, params) => {
      const response = await httpClient[action](endpoint, params);

      // Updating key will trigger useFetch
      if (response.status < 300) {
        setKey((key) => (key += 1));
      }
    },
    [endpoint, setKey]
  );

  const [response] = useFetch(endpoint, null, key);
  const plans = response?.plans || [];

  return [plans, action];
}

export const Plans = () => {
  const [plans, handleSubmit] = usePlans();

  const hasScheduled = useMemo(() => {
    return plans.findIndex((plan) => plan.status === 'scheduled') >= 0;
  }, [plans]);

  return (
    <Container>
      <Row>
        {plans.map((plan) => (
          <Col key={`plan-${plan.id}`}>
            <ProfilePlanWithFormik
              plan={plan}
              hasScheduled={hasScheduled}
              onSubmit={handleSubmit}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
