import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';

export const Information = ({ label, value, sizes }) => (
  <Col xs={sizes.xs} md={sizes.md} lg={sizes.lg}>
    <span className="text-capitalize">
      <b>{label}: </b>
      {value}
    </span>
  </Col>
);

Information.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sizes: PropTypes.object,
};

Information.defaultProps = {
  value: '-',
  sizes: {
    xs: 10,
    md: 6,
    lg: 4,
  },
};

export const AdditionalInformation = ({ label, value }) => (
  <Row className="text-muted small text-capitalize pt-1">
    <Col xs={8}>
      <span>{label}</span>
    </Col>
    <Col xs={4}>
      <span>{value}</span>
    </Col>
  </Row>
);

AdditionalInformation.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AdditionalInformation.defaultProps = {
  value: '-',
};
