import PropTypes from 'prop-types';
import React from 'react';

import { PendingWalletTransactions } from '../PendingWalletTransactions';
import { WalletTransactions } from '../WalletTransactions';

export const Transactions = ({ history }) => {
  return (
    <div>
      <PendingWalletTransactions />
      <WalletTransactions history={history} />
    </div>
  );
};

Transactions.propTypes = {
  history: PropTypes.object,
};

Transactions.defaultProps = {};

export default Transactions;
