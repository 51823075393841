import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, PopoverBody, UncontrolledPopover } from 'reactstrap';

import { UserAvatar } from '../User';

export const MessageStatus = ({
  messageId,
  failed,
  pending,
  completed,
  isMessageCreator,
}) => (
  <div
    className={`d-flex ${
      isMessageCreator ? 'flex-row-reverse' : ''
    } align-items-center mr-1 mb-2`}>
    {!!completed?.length && (
      <StatusBadge
        messageId={messageId}
        status="completed"
        value={completed.length}
        userList={completed}
      />
    )}
    {!!pending?.length && (
      <StatusBadge
        messageId={messageId}
        status="pending"
        value={pending.length}
        userList={pending}
      />
    )}
    {!!failed?.length && (
      <StatusBadge
        messageId={messageId}
        status="failed"
        value={failed.length}
        userList={failed}
      />
    )}
  </div>
);

MessageStatus.propTypes = {
  failed: PropTypes.array,
  pending: PropTypes.array,
  completed: PropTypes.array,
  isMessageCreator: PropTypes.bool,
  messageId: PropTypes.number,
};

MessageStatus.defaultProps = {
  failed: [],
  pending: [],
  completed: [],
  isMessageCreator: false,
};

const getBadgeProperties = (status) => {
  switch (status) {
    case 'failed':
      return {
        badgeColor: 'danger',
        color: 'danger',
        icon: ['far', 'exclamation-circle'],
      };
    case 'pending':
      return { color: 'warning', icon: ['far', 'clock-rotate-left'] };
    case 'completed':
      return { color: 'success', icon: ['far', 'check-circle'] };
    default:
      return { color: 'secondary', icon: ['far', 'question-circle'] };
  }
};

const StatusBadge = ({ status, value, userList, messageId }) => {
  const { badgeColor = 'light', color, icon } = getBadgeProperties(status);

  return (
    <div
      className={`d-flex align-items-center`}
      id={`status-badge-${status}-${messageId}`}>
      <FontAwesomeIcon icon={icon} className={`mr-1 text-${color}`} />
      <span className="message-status__text mr-1">
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
      <Badge className="px-2" color={badgeColor} pill>
        {value}
      </Badge>
      {!!userList.length && (
        <UncontrolledPopover
          placement="bottom"
          target={`status-badge-${status}-${messageId}`}
          trigger="legacy"
          size="1x">
          <PopoverBody>
            <div>
              {userList.map(({ id, recipient, recipientRole, avatarUrl }) => (
                <div
                  key={`${recipientRole}-${id}-popover-item`}
                  className="d-flex mb-2">
                  <UserAvatar
                    className="ml-1 mr-2 align-self-center"
                    size={0.65}
                    user={{ avatarUrl: avatarUrl }}
                  />
                  <p className="mb-0 text-small">
                    <Link
                      to={`/contacts/${recipientRole.toLowerCase()}s/${id}`}>
                      {recipient}
                    </Link>
                    <br />
                    <span> {recipientRole}</span>
                  </p>
                </div>
              ))}
            </div>
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </div>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.string,
  value: PropTypes.number,
  userList: PropTypes.array,
  messageId: PropTypes.number,
};

StatusBadge.defaultProps = {
  status: 'Tooltip Missing',
  value: 0,
  userList: [],
};
