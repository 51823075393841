import { ErrorMessage, Field, getIn } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormFeedback, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import { ButtonIcon } from '@app/modules/Button';

import { disableScroll, toClassName } from '../../../utils';

export const FormField = ({ append, prepend, type, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <InputGroup size={props.bsSize}>
      {prepend && (
        <InputGroupAddon addonType="prepend">{prepend}</InputGroupAddon>
      )}
      <Field name={props.name}>
        {({ field, form }) => {
          const { name, onBlur, onChange, value } = field;
          const isTouched = getIn(form.touched, name);
          const error = getIn(form.errors, name);

          if (type === 'password') {
            return (
              <div className="w-100 position-relative">
                <Input
                  className={toClassName(
                    props.type === 'select' ? ['text-capitalize'] : [],
                    props.className
                  )}
                  data-testid={`form-field-${name}`}
                  invalid={isTouched && !!error}
                  valid={isTouched && !error}
                  value={value || ''}
                  onBlur={onBlur}
                  onChange={onChange}
                  onWheel={disableScroll}
                  type={type === 'password' && showPassword ? 'text' : type}
                  {...props}>
                  {props.children}
                </Input>
                {type === 'password' && value && value.length >= 1 && (
                  <ButtonIcon
                    color="dark"
                    className="toggle-password"
                    icon={['far', showPassword ? 'eye' : 'eye-slash']}
                    onClick={togglePassword}
                  />
                )}
              </div>
            );
          }

          return (
            <Input
              className={toClassName(
                props.type === 'select' ? ['text-capitalize'] : [],
                props.className
              )}
              data-testid={`form-field-${name}`}
              invalid={isTouched && !!error}
              valid={isTouched && !error}
              value={value || ''}
              onBlur={onBlur}
              onChange={onChange}
              onWheel={disableScroll}
              type={type === 'password' && showPassword ? 'text' : type}
              {...props}>
              {props.children}
            </Input>
          );
        }}
      </Field>
      {append && <InputGroupAddon addonType="append">{append}</InputGroupAddon>}
      <ErrorMessage name={props.name} component={FormFeedback} />
    </InputGroup>
  );
};

FormField.propTypes = {
  append: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  prepend: PropTypes.string,
  type: PropTypes.string,
  bsSize: PropTypes.string,
};

FormField.defaultProps = {
  className: '',
  type: 'text',
};
