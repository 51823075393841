/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { PropertyLeaseLog } from '@app/containers/Property';
import { ButtonIcon } from '@app/modules/Button';
import { CardPlain } from '@app/modules/Card';
import {
  LeaseFormAdjust,
  LeaseFormBond,
  LeaseFormDates,
  LeaseFormTerminate,
} from '@app/modules/Lease/Form';
import { ModalConfirm } from '@app/modules/Modal';
import { canCreateLease } from '@app/redux/profile';

import { modifyRent, updateLease } from '../../../redux/lease';
import { LeaseActions } from '../List/LeaseActions';
import { SET_LEASE_TO_RENEW } from '../common/stepper';
import { StepperDispatchContext } from '../common/stepper/Provider';
import { LEASE_CATEGORY } from '../constants';

const initialState = {
  renew: false,
  frequency: false,
  rent: false,
  dates: false,
  bond: false,
  terminate: false,
  auditLog: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case action.type:
      return {
        ...initialState,
        [action.type]: !state[action.type],
      };
    default:
      return initialState;
  }
};

export const Actions = ({
  hasError,
  lease,
  upcomingLease,
  handleChangeLeaseEnd,
  isExistingRenewalTaskComplete,
  isShowAuditLog,
  handleToggleAuditLog,
  handleOnBack,
  history,
  leaseCategory,
  handleEditLease,
  hasActiveLease,
  ...props
}) => {
  const dispatch = useDispatch();
  const localDispatch = useContext(StepperDispatchContext);

  const { isLoading, isActivatingLease } = useSelector(({ lease }) => lease);
  const canReleaseBond = useSelector(({ profile }) => canCreateLease(profile));

  const [state, toggle] = useReducer(reducer, initialState);
  const {
    canCancel,
    canActivate,
    status,
    isActive,
    isTerminating,
    hasBond,
    bondReturnedCents,
    isActivating,
  } = lease;

  const showReleaseBond = hasBond && !bondReturnedCents && canReleaseBond;
  const colClassName = 'flex-fill pt-2 pt-lg-0 d-flex justify-content-center';

  const handleToggleAction = useCallback(
    (type) => {
      toggle({ type });
      if (isShowAuditLog) {
        handleToggleAuditLog(false);
      }
    },
    [handleToggleAuditLog, isShowAuditLog]
  );

  const handleRenewLease = useCallback(() => {
    localDispatch({
      type: SET_LEASE_TO_RENEW,
      payload: { id: lease.id },
    });
    toggle('renew');
  }, [lease.id, localDispatch]);

  const actionButtons = useMemo(
    () => [
      {
        key: 'renew',
        name: 'Renew Lease',
        icon: ['fas', 'repeat'],
        onClick: () => handleToggleAction('renew'),
        hidden: leaseCategory !== LEASE_CATEGORY.active,
      },
      {
        key: 'rent',
        name: 'Adjust Rent',
        icon: ['far', 'usd-circle'],
        onClick: () => handleToggleAction('rent'),
        hidden: leaseCategory !== LEASE_CATEGORY.active || lease.isTerminating,
      },
      {
        key: 'dates',
        name: 'Adjust Dates',
        icon: ['far', 'calendar-alt'],
        onClick: () => handleToggleAction('dates'),
        hidden: leaseCategory !== LEASE_CATEGORY.active,
      },
      {
        key: 'terminate',
        name: 'Add a Termination Date',
        icon: ['far', 'ban'],
        onClick: () => handleToggleAction('terminate'),
        hidden: leaseCategory !== LEASE_CATEGORY.active || lease.isTerminating,
      },
      {
        key: 'auditLog',
        name: isShowAuditLog ? 'View Lease Information' : 'View Audit Log',
        icon: ['far', 'notebook'],
        onClick: () => {
          toggle({ type: 'auditLog' });
          handleToggleAuditLog(!isShowAuditLog);
        },
        hidden: leaseCategory !== LEASE_CATEGORY.active,
      },
      {
        key: 'edit',
        name: 'Edit',
        icon: ['far', 'pencil'],
        button: (
          <LeaseActions.Edit
            onClick={handleEditLease}
            disabled={isActivating || isActivatingLease}
          />
        ),
        hidden: isActive || isTerminating,
      },
      {
        key: 'cancel',
        name: 'Cancel',
        icon: ['far', 'circle'],
        button: (
          <LeaseActions.Cancel
            leaseId={lease.id}
            disabled={isActivating || isActivatingLease}
          />
        ),
        hidden: !canCancel && status !== 'activating',
      },
      {
        key: 'activate',
        name: 'Activate',
        color: 'success',
        icon: ['far', 'thumbs-up'],
        button: (
          <LeaseActions.Activate
            lease={{
              id: lease.id,
              propertyId: lease.propertyId,
              startDate: lease.startDate,
            }}
            disabled={hasActiveLease}
            isActivating={isActivating || isActivatingLease}
          />
        ),
        hidden: !canActivate,
      },
      {
        key: 'back',
        name: 'Go Back',
        color: 'secondary',
        icon: ['far', 'chevron-left'],
        onClick: handleOnBack,
        hidden: leaseCategory !== LEASE_CATEGORY.past,
      },
      {
        key: 'release',
        name: 'Claim Bond',
        color: 'success',
        button: (
          <LeaseActions.ReleaseBond
            leaseId={lease.id}
            bondCents={lease.bondCents}
            bondNumber={lease.bondNumber}
          />
        ),
        hidden: leaseCategory !== LEASE_CATEGORY.past || !showReleaseBond,
      },
      {
        key: 'viewTransactions',
        name: 'View Transactions',
        color: 'success',
        icon: ['far', 'thumbs-up'],
        button: (
          <LeaseActions.ViewTransactions
            leaseId={lease.id}
            propertyId={lease.propertyId}
          />
        ),
        hidden: leaseCategory !== LEASE_CATEGORY.past,
      },
    ],
    [
      canActivate,
      canCancel,
      handleEditLease,
      handleOnBack,
      handleToggleAction,
      handleToggleAuditLog,
      hasActiveLease,
      isActivating,
      isActivatingLease,
      isActive,
      isShowAuditLog,
      isTerminating,
      lease.bondCents,
      lease.bondNumber,
      lease.id,
      lease.isTerminating,
      lease.propertyId,
      lease.startDate,
      leaseCategory,
      showReleaseBond,
      status,
    ]
  );

  const handleUpdateLease = useCallback(
    (data) => {
      dispatch(updateLease({ ...data, hideAlert: true }));
      toggle('reset');
    },
    [dispatch]
  );

  const handleModifyRent = useCallback(
    (data) => {
      dispatch(modifyRent(data));
      toggle('reset');
    },
    [dispatch]
  );

  return (
    <CardPlain {...props}>
      <Row className="text-center d-flex">
        {actionButtons.map((actionButton, index) => {
          return !actionButton.hidden ? (
            <Col
              key={actionButton.key}
              className={colClassName}
              sm={{ size: 'auto' }}>
              {actionButton.button ? (
                actionButton.button
              ) : (
                <ButtonIcon
                  icon={actionButton.icon || ['far', 'arrow-circle-up']}
                  onClick={actionButton.handleClick}
                  style={
                    state[actionButton.key]
                      ? {
                          borderBottom: '0.25em solid',
                          fontWeight: 'bold',
                          borderRadius: '0',
                        }
                      : {}
                  }
                  className="py-3"
                  {...actionButton}>
                  {actionButton.name}
                </ButtonIcon>
              )}
            </Col>
          ) : null;
        })}
      </Row>

      {state.renew && (
        <ModalConfirm
          isOpen={state.renew}
          size="md"
          btnCancel={{ text: 'No' }}
          btnSubmit={{ text: 'Yes' }}
          title="Would you like to renew this lease?"
          onCancel={() => handleToggleAction('renew')}
          onSubmit={handleRenewLease}>
          <span>
            Let&apos;s update the details for this lease to renew it.
            <br />
            <br />
            <small>
              Note: This renewal workflow applies if the primary tenant remains
              the same.
            </small>
          </span>
        </ModalConfirm>
      )}

      {state.rent && (
        <FormWrapper title="Adjust Rent">
          <LeaseFormAdjust
            hasError={hasError}
            isLoading={isLoading}
            lease={lease}
            onCancel={() => toggle({ type: 'rent' })}
            onComplete={() => toggle({ type: 'rent' })}
            onSubmit={handleModifyRent}
          />
        </FormWrapper>
      )}

      {state.dates && (
        <FormWrapper title="Adjust Dates">
          <LeaseFormDates
            hasError={hasError}
            isLoading={isLoading}
            lease={lease}
            onCancel={() => toggle({ type: 'dates' })}
            onComplete={() => toggle({ type: 'dates' })}
            onSubmit={handleUpdateLease}
            handleChangeLeaseEnd={handleChangeLeaseEnd}
            isExistingRenewalTaskComplete={isExistingRenewalTaskComplete}
          />
        </FormWrapper>
      )}

      {state.bond && (
        <FormWrapper title="Adjust Bond Id">
          <LeaseFormBond
            hasError={hasError}
            isLoading={isLoading}
            lease={lease}
            onCancel={() => toggle({ type: 'bond' })}
            onComplete={() => toggle({ type: 'bond' })}
            onSubmit={handleUpdateLease}
          />
        </FormWrapper>
      )}

      {state.terminate && (
        <FormWrapper title="Terminate Lease">
          <LeaseFormTerminate
            hasError={hasError}
            isLoading={isLoading}
            lease={lease}
            onCancel={() => toggle({ type: 'terminate' })}
            onComplete={() => toggle({ type: 'terminate' })}
            onSubmit={handleUpdateLease}
          />
        </FormWrapper>
      )}

      {state.auditLog && isShowAuditLog && (
        <FormWrapper>
          <PropertyLeaseLog
            leaseId={lease.id}
            payFrequency={lease.payFrequency}
          />
        </FormWrapper>
      )}
    </CardPlain>
  );
};

Actions.propTypes = {
  lease: PropTypes.object,
  upcomingLease: PropTypes.array,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleChangeLeaseEnd: PropTypes.func,
  isExistingRenewalTaskComplete: PropTypes.bool,
  handleToggleAuditLog: PropTypes.func,
  isShowAuditLog: PropTypes.bool,
  history: PropTypes.object.isRequired,
  leaseCategory: PropTypes.string,
  handleEditLease: PropTypes.func,
  handleOnBack: PropTypes.func,
  hasActiveLease: PropTypes.bool,
};

Actions.defaultProps = {
  leaseCategory: LEASE_CATEGORY.active,
  hasActiveLease: false,
};

const FormWrapper = ({ title, children }) => (
  <Row>
    <Col className="pb-3">
      <hr className="mt-0" />
      {children}
    </Col>
  </Row>
);

FormWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
