import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { Col, Container, Row } from 'reactstrap';

import { useIsMobile, useLocationParams, useToggle } from '@app/hooks';
import { useDebouncedFetch } from '@app/hooks/useDebouncedFetch';
import { formatDate } from '@app/utils';

import { SmsOverview } from '../SmsOverview';
import { useFetchBulkSmsList } from '../hooks/use-bulk-sms';
import { Recipients } from './Recipients';
import { Status } from './Status';
import './styles.scss';

const COLUMNS = [
  {
    id: 'from',
    name: 'From',
    size: 2,
  },
  {
    id: 'message',
    name: 'Message',
    size: 3,
  },
  {
    id: 'recipients',
    name: 'Recipients',
    size: 2,
  },
  {
    id: 'status',
    name: 'Status',
    size: 1,
  },
  {
    id: 'createdAt',
    name: 'Created at',
    size: 2,
  },
  {
    id: 'sentAt',
    name: 'Sent at',
    size: 2,
  },
];

export const BulkMessages = ({ agencyId, filter }) => {
  const history = useHistory();
  const [modalState, setModalState] = useToggle();
  const isMobile = useIsMobile();
  const params = useLocationParams();

  const {
    data = [],
    isLoading,
    refetch,
  } = useFetchBulkSmsList({
    params: {
      agencyId,
      perPage: params.perPage || 10,
      page: params.page || 1,
      ...filter,
      recipients: filter?.recipients?.map(({ value }) => value),
      status: Object.keys(filter?.status || {}).filter(
        (key) => filter.status[key]
      ),
    },
  });

  const { debouncedRefetch } = useDebouncedFetch({ fetchFn: refetch });

  // Fetch is debounced due to text filter
  useEffect(() => {
    if (agencyId) {
      debouncedRefetch();
    }
  }, [filter, debouncedRefetch, agencyId, params.page]);

  const handleOnClickBulkMessage = (item) => {
    setModalState({ itemToView: item, isOpen: true });
  };

  const smsOverviewAction = () => {
    history.push(`/sms/bulk-messaging/edit/${modalState.itemToView.id}`);
  };

  const handleClose = () => {
    setModalState({ itemToView: null, isOpen: false });
  };

  const renderMessages = () => {
    if (data.length === 0) {
      return (
        <Row className="m-0 p-5 text-left no-gutters text-small">
          <Col className="text-center">No messages found</Col>
        </Row>
      );
    }

    return data.map((item) => {
      const { id, sender, sentAt, createdAt, recipients, status, message } =
        item;
      return (
        <Row
          key={`bulk-sms-${id}`}
          className="m-0 px-3 py-2 text-left no-gutters text-small align-items-center bulk-messages-list pointer"
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => handleOnClickBulkMessage(item)}>
          <Col className="col-md-2 col-10">{sender}</Col>
          <Col className="col-md-3 col-12 message-container">
            <span>{message}</span>
          </Col>

          <Col className="col-md-2 col-10">
            <Recipients recipients={recipients} />
          </Col>
          <Col className="col-md-1 col-2 d-flex">
            <Status status={status.toLowerCase()} />
          </Col>
          <Col className="col-md-2 col-10">
            {createdAt ? formatDate(createdAt, 'short') : '-'}
          </Col>
          <Col className="col-md-2 col-10">
            {sentAt ? formatDate(sentAt, 'shortWithTime') : '-'}
          </Col>
        </Row>
      );
    });
  };

  return (
    <Container className="p-0 mb-1">
      {!isMobile && (
        <Row
          lg={12}
          className="m-0 px-3 py-2 text-left no-gutters task-messages-header">
          {COLUMNS.map((column) => (
            <Col
              key={column.id}
              lg={column.size || 2}
              className={column.className}>
              {column.name}
            </Col>
          ))}
        </Row>
      )}
      {isLoading ? (
        <Row className="m-0 p-5 text-left no-gutters text-small">
          <Col className="text-center">
            <PulseLoader color="#dee2e6" />
          </Col>
        </Row>
      ) : (
        renderMessages()
      )}
      {modalState.isOpen && (
        <SmsOverview
          title="Message Info"
          modalState={modalState}
          // eslint-disable-next-line react/jsx-no-bind
          toggleModal={handleClose}
          onSubmit={
            modalState.itemToView.status === 'Draft' ? smsOverviewAction : null
          }
          btnSubmitText="Edit Message"
          {...modalState.itemToView}
          senderName={modalState.itemToView.sender}
        />
      )}
    </Container>
  );
};

BulkMessages.propTypes = {
  agencyId: PropTypes.number,
  filter: PropTypes.object,
};
