import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CardBody, CardHeader, CardTitle, List } from 'reactstrap';

import { CardLight } from '@app/modules/Card';
import { ContentDefinition } from '@app/modules/Content';
import { useRolesContext } from '@app/modules/Profile';
import { UploaderButton, UploaderFiles } from '@app/modules/Uploader';
import { updateLeaseAttachments } from '@app/redux/lease';
import {
  ATTACHMENT_CATEGORIES,
  isConditionReport,
  isLeaseAgreement,
} from '@app/utils';

import { Divider } from '../Divider';

export const Documents = ({ attachments, lease, canEditInline }) => {
  const dispatch = useDispatch();
  const { isManager } = useRolesContext();
  const tenancyAgreement = attachments.filter(isLeaseAgreement);
  const conditionReport = attachments.filter(isConditionReport);

  const onUpload = useCallback(
    (data) => {
      dispatch(updateLeaseAttachments(data));
    },
    [dispatch]
  );

  return (
    <CardLight className="mt-2">
      <CardHeader className="bg-white">
        <div className="d-flex justify-content-between">
          <CardTitle tag="h5">Documents</CardTitle>
        </div>
      </CardHeader>
      <CardBody className="py-0">
        <List type="unstyled">
          <li className="mb-2">
            <ContentDefinition
              label="Tenancy Agreement"
              data-test-id="current-lease-documents-card-tenancy-agreement"
              labelClassName="d-inline-block mb-2">
              <UploaderFiles
                attachments={tenancyAgreement}
                attachableType="Lease"
                attachableId={lease?.id}
                onDestroyComplete={canEditInline ? onUpload : null}
              />
              {isManager && canEditInline && (
                <UploaderButton
                  key={`upload-tenancy-agreement-${lease?.id}`}
                  attachableType="Lease"
                  attachableId={lease?.id}
                  attachableCategory={ATTACHMENT_CATEGORIES.leaseAgreement}
                  onComplete={onUpload}
                  isLeaseFlex
                />
              )}
            </ContentDefinition>
            <Divider />
          </li>
          <li className="mb-2">
            <ContentDefinition
              label="Condition Report"
              data-test-id="current-lease-documents-card-condition-report"
              labelClassName="d-inline-block mb-2">
              <UploaderFiles
                attachments={conditionReport}
                attachableType="Lease"
                attachableId={lease?.id}
                onDestroyComplete={canEditInline ? onUpload : null}
              />
              {isManager && canEditInline && (
                <UploaderButton
                  key={`upload-condition-report-${lease?.id}`}
                  attachableType="Lease"
                  attachableId={lease?.id}
                  attachableCategory={ATTACHMENT_CATEGORIES.conditionReport}
                  onComplete={onUpload}
                  isLeaseFlex
                />
              )}
            </ContentDefinition>
          </li>
        </List>
      </CardBody>
    </CardLight>
  );
};

Documents.propTypes = {
  lease: PropTypes.object.isRequired,
  attachments: PropTypes.array,
  canEditInline: PropTypes.bool,
};
Documents.defaultProps = {
  attachments: [],
  canEditInline: false,
};
