import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, CardBody, CardText, CardTitle } from 'reactstrap';

import './styles.scss';

export const OverdueBanner = ({ onClick }) => (
  <Card outline color="danger">
    <CardBody className="d-flex flex-column gap-2">
      <div>
        <CardText tag="h6" className="text-small text-muted mb-1">
          Friendly reminder
        </CardText>
        <CardTitle tag="h5" className="text-danger">
          Accounts overdue
        </CardTitle>
        <CardText>
          Your <strong>monthly payment has failed.</strong> We know that
          payments fail every now and then and this is due to subscriptions
          processing at the same time. Let’s get this resolved to avoid any
          disruption to your service.
        </CardText>
      </div>
      <div className="resolve-btn-wrapper">
        <Button color="primary" onClick={onClick}>
          Resolve
        </Button>
      </div>
    </CardBody>
  </Card>
);

OverdueBanner.propTypes = {
  onClick: PropTypes.func,
};

export default OverdueBanner;
