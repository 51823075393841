import * as Yup from 'yup';

export const INITIAL_FILTER = {
  createdFrom: null,
  createdTo: null,
  sentFrom: null,
  sentAt: null,
  recipients: [],
  status: { sent: true, draft: true },
  search: '',
};

export const INITIAL_MODAL_STATE = {
  isOpen: false,
  hasEnoughCredits: false,
};

export const BulkMessageSchema = Yup.object().shape({
  message: Yup.string().required('Message is required'),
  recipients: Yup.array().required('Recipients are required'),
  sender: Yup.string().required('Sender is required'),
});
