import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  Col,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';

import InspectionExpressLogo from '@app/assets/InspectionExpress.png';
import { useIsOpen } from '@app/hooks';
import { ModalConfirm } from '@app/modules/Modal';

import {
  InspectionReportDownloadLink,
  InspectionReportUpload,
  InspectionStatusBadge,
  useInspectionPermissions,
} from '..';
import { formatDate } from '../../../utils';
import { Link } from '../../Link';
import { PropertyUserIcon } from '../../Property';
import { LogItem } from './LogItem';

export const InspectionReportListItem = ({
  report,
  userId,
  onDelete,
  onUploaderComplete,
  path,
  propertyId,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLogsOpen, setIsLogsOpen] = useState(false);
  const { report: reportFile } = report;
  const [isDeleteModalOpen, deleteActions] = useIsOpen(onDelete(report.id));

  const toggle = useCallback(() => {
    setIsDropdownOpen((prev) => !prev);
  }, []);

  const toggleViewLogs = useCallback(() => {
    setIsLogsOpen((prev) => !prev);
  }, []);

  const permissions = useInspectionPermissions(report, userId);

  const isPdfUpload =
    permissions.type.isUploadedReport || permissions.status.isPendingUpload;

  const inspectionExpressUrl = report.fromInspectionExpress
    ? report.extraInfo?.reportUrl
    : null;

  return (
    <>
      <tr data-testid="inspection-report-list-item">
        <td className="px-md-2 py-2">
          <div className="d-flex align-items-center">
            {isPdfUpload && (
              <FontAwesomeIcon
                icon={['far', 'file-pdf']}
                className="text-primary mx-1"
              />
            )}
            <p
              className={
                isPdfUpload
                  ? 'ml-2 text-capitalize mb-0'
                  : 'text-capitalize mb-0'
              }>
              {report.typeOf}
            </p>
          </div>
        </td>
        <td className="px-md-2 py-2">{formatDate(report.inspectionDate)}</td>
        <td className="px-md-2 py-2">
          {report.tenant && Object.keys(report.tenant).length > 0 && (
            <PropertyUserIcon
              className="mb-1 mb-sm-0 text-dark text-left"
              role="tenant"
              isActive={true}
              size={0.6}
              user={report.tenant}
              userName={report.tenant.name}
              textSize={'large'}
            />
          )}
        </td>
        <td className="px-md-2 py-2">
          <InspectionStatusBadge status={report.status} />
        </td>
        <td className="px-md-4 py-2">
          {report.fromInspectionExpress && (
            <img
              className="inspection-express-logo"
              src={InspectionExpressLogo}
              alt="Inspection Express"
            />
          )}
        </td>
        <td className="px-md-2 py-2">
          <div className="d-flex align-items-center">
            {permissions.action.canViewReport && !isPdfUpload && (
              <Link
                color="primary"
                className="py-1 px-2 mr-2"
                data-testid="report-link"
                to={`${path}/report/${report.id}`}>
                <span>
                  {permissions.status.isDraft ? 'Start report' : 'View report'}
                </span>
              </Link>
            )}
            {permissions.action.canDeleteReport && report.propertyTaskId && (
              <Link
                color="secondary"
                className="py-1 px-2 mr-2"
                to={`/property/${propertyId}/tasks/${report.propertyTaskId}`}>
                <span className="text-white">View task</span>
              </Link>
            )}
            {permissions.status.isCompleted &&
              (reportFile || inspectionExpressUrl) && (
                <InspectionReportDownloadLink
                  className="btn btn-secondary"
                  link={inspectionExpressUrl || reportFile.link}
                  showIcon={false}
                />
              )}
            {permissions.action.canUploadReport && (
              <InspectionReportUpload
                isEdit={true}
                reportId={report.id}
                onUploaderComplete={onUploaderComplete}
              />
            )}
          </div>
        </td>
        <td>
          <Dropdown isOpen={isDropdownOpen} toggle={toggle} direction="left">
            <DropdownToggle tag="span" style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={['far', 'circle-ellipsis']} />
            </DropdownToggle>
            <DropdownMenu>
              {permissions.action.canDeleteReport && onDelete && (
                <DropdownItem
                  className="text-danger"
                  data-testid="button-delete-report"
                  onClick={deleteActions.handleOpen}>
                  Delete
                  <FontAwesomeIcon
                    className="ml-3"
                    icon={['far', 'trash-alt']}
                  />
                </DropdownItem>
              )}
              <DropdownItem onClick={toggleViewLogs}>
                {isLogsOpen ? 'Hide Logs' : 'View Logs'}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <ModalConfirm
            title="Delete Report"
            body="Are you sure you would like to delete this report?"
            isOpen={isDeleteModalOpen}
            btnCancel={{ text: 'Cancel' }}
            btnSubmit={{ text: 'Delete', color: 'danger' }}
            onCancel={deleteActions.handleClose}
            onSubmit={deleteActions.handleSubmit}
          />
        </td>
      </tr>
      <tr>
        <td className="border-0" colSpan="7">
          <Collapse isOpen={isLogsOpen}>
            <hr />
            <Row data-testid={`inspection-report-list-item-log-${report.id}`}>
              <Col className="text-left h6-font-size font-weight-bold">
                Audit Logs
              </Col>
            </Row>
            {report?.createdAt && (
              <LogItem action="Created report" auditedAt={report.createdAt} />
            )}
            {report?.managerFirstCheckedItemAt && (
              <LogItem
                action="Manager first check item"
                auditedAt={report.managerFirstCheckedItemAt}
              />
            )}
            {report?.managerSignedAt && (
              <LogItem
                action="Manager signed report"
                auditedAt={report.managerSignedAt}
              />
            )}
            {report?.tenantNotifiedAt && (
              <LogItem
                action="Sent to tenant"
                auditedAt={report.tenantNotifiedAt}
              />
            )}
            {report?.tenantSignedAt && (
              <LogItem
                action="Tenant responded and signed"
                auditedAt={report.tenantSignedAt}
              />
            )}
            {report?.managerNotifiedAt && (
              <LogItem
                action="Sent to Manager"
                auditedAt={report.managerNotifiedAt}
              />
            )}
            {report?.ownerNotifiedAt && (
              <LogItem
                action="Sent to Owner"
                auditedAt={report.ownerNotifiedAt}
              />
            )}
            {report?.completedReportAt && (
              <LogItem
                action="Finalised Report"
                auditedAt={report.completedReportAt}
              />
            )}
          </Collapse>
        </td>
      </tr>
    </>
  );
};

InspectionReportListItem.propTypes = {
  onDelete: PropTypes.func,
  onUploaderComplete: PropTypes.func,
  path: PropTypes.string,
  propertyId: PropTypes.number,
  report: PropTypes.object,
  userId: PropTypes.number,
};

InspectionReportListItem.defaultProps = {
  path: '',
  report: {},
};
