import { createSelector } from '@reduxjs/toolkit';
import { chain } from 'lodash';

import { getAgenciesAsFilters, getAgency } from '../agency/selectors';
import { getManagers, getManagersAsFilters } from '../users';
import { selectIsManagersLoading } from '../users/selectors';

export const selectDashboard = (state) => state.dashboard;

export const selectDashboardData = createSelector(
  selectDashboard,
  (state) => state.dashboardData
);

export const selectDashboardAgenciesAndManagersFilters = createSelector(
  [
    (state) => getManagers(state.users),
    getAgenciesAsFilters,
    (state) => getManagersAsFilters(state.users),
    selectIsManagersLoading,
    getAgency,
  ],
  (
    managers,
    agencyFilters,
    managerFilters,
    isManagersLoading,
    { isAgenciesLoading }
  ) => {
    const groupedManagerFilters = chain(managers)
      .map('managerAgencies')
      .flatten()
      .map(({ agencyId, managerId }) => {
        const managerFilter = managerFilters.find(
          (filter) => filter.value === managerId
        );

        return managerFilter ? { ...managerFilter, agencyId } : null;
      })
      .compact()
      .orderBy([(filter) => filter.label.toLowerCase()], ['asc'])
      .groupBy('agencyId')
      .value();

    return {
      isLoading: isAgenciesLoading || isManagersLoading,
      filters: {
        agencies: agencyFilters,
        managers: managerFilters,
        managersGroupedByAgencyId: groupedManagerFilters || [],
      },
    };
  }
);
