export const COLUMNS = [
  {
    id: 'businessName',
    name: 'Business name',
  },
  {
    id: 'contactName',
    name: 'Contact name',
  },
  {
    id: 'email',
    name: 'Email',
  },
  {
    id: 'mobile',
    name: 'Mobile',
  },
  {
    id: 'adminFee',
    name: 'Admin fee',
  },
  {
    id: 'preferred',
    name: 'Preferred',
  },
  {
    id: 'action',
    name: '',
  },
];
