/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import { Button } from 'reactstrap';

export const Enable2FAConfirmation = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.assign('/');
    }, 5000);
  }, []);

  const onClick = () => {
    window.location.assign('/');
  };

  return (
    <>
      <p className="text-on-disabled">
        You&rsquo;ll be redirected to your Dashboard shortly.
      </p>
      <Button block color="primary" onClick={onClick} className="mt-4">
        Go to Dashboard
      </Button>
    </>
  );
};
