import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import ContentLoader from 'react-content-loader';
import Select from 'react-select';
import { Button, Modal, ModalBody } from 'reactstrap';

import { useToggle } from '@app/hooks';
import { CardLight } from '@app/modules/Card';

import { Switch } from '../Form';
import { useUpdateWithdrawal } from '../Tenant/hooks';

export const PaymentDisbursementAccountCard = ({
  id,
  canWithdraw,
  daysUntilWithdrawalEnabled,
  tenantDisbursementAccount,
  isLoading,
}) => {
  const [duration, setDuration] = useState(0);
  const [modalState, setModalState] = useToggle();
  const { mutate } = useUpdateWithdrawal();

  const toggleModalState = useCallback(() => {
    setModalState();
  }, [setModalState]);

  const handleSubmit = useCallback(() => {
    const payload = canWithdraw
      ? {
          canWithdraw: false,
          disableUntil: addDays(new Date(), duration.value),
          reason: 'action done through the app',
        }
      : {
          canWithdraw: true,
          disableUntil: null,
          reason: null,
        };

    mutate({
      id,
      payload,
    });
    toggleModalState();
  }, [canWithdraw, id, mutate, duration, toggleModalState]);

  return (
    <CardLight
      className="mb-3 h-100"
      data-testid="tenant-disbursement-details"
      title="Tenant Disbursement Details">
      <div
        className="d-block m-0"
        id="disbursement-details"
        data-testid="disbursement-details">
        <div className="mb-3">
          {isLoading ? (
            <Skeleton />
          ) : (
            <div className="d-flex justify-content-between">
              <Switch
                id="can-tenant-withdraw"
                label={`Tenant can${!canWithdraw ? "'t" : ''} withdraw`}
                value={canWithdraw}
                handleChange={toggleModalState}
              />
              <p className="text-small m-0">
                {canWithdraw ? (
                  ' Withdrawals are currently enabled'
                ) : daysUntilWithdrawalEnabled > 0 ? (
                  <>
                    Withdrawals will be re-enabled in{' '}
                    <strong>{daysUntilWithdrawalEnabled} days</strong>
                  </>
                ) : (
                  'Withdrawals are currently disabled'
                )}
              </p>
            </div>
          )}
        </div>
        <hr />
        {tenantDisbursementAccount ? (
          <p className="text-small m-0">
            <div className="d-flex align-items-center gap-2">
              <FontAwesomeIcon
                icon={['far', 'money-check-alt']}
                size="2xl"
                color="#142C61"
              />
              <strong>{tenantDisbursementAccount.bankName}</strong>
            </div>
            <AccountDetail
              label="Account Name"
              value={tenantDisbursementAccount.accountName}
            />
            <br />
            <AccountDetail
              label="BSB"
              value={tenantDisbursementAccount.routingNumber}
            />
            <br />
            <AccountDetail
              label="Account Number"
              value={tenantDisbursementAccount.accountNumber}
            />
          </p>
        ) : (
          !isLoading && (
            <p className="text-small m-0">
              <span id="missing-disbursement-tooltip">
                <FontAwesomeIcon
                  className="mr-2 text-warning"
                  icon={['fas', 'exclamation-triangle']}
                />
              </span>
              This account doesn&apos;t have a disbursement account
            </p>
          )
        )}
      </div>
      {modalState.isOpen && (
        <Modal
          isOpen={modalState.isOpen}
          size="xs"
          toggle={toggleModalState}
          centered>
          <ModalBody>
            <h5>
              {canWithdraw
                ? 'Disable withdrawal access'
                : 'Enable withdrawal access'}
            </h5>
            <p>
              {canWithdraw
                ? 'Disabling withdrawal access will prevent tenants from withdrawing funds for the selected duration. Please choose a period from the dropdown before confirming this action.'
                : 'Are you sure you want to enable withdrawals for this tenant? They will be able to request withdrawals from their account.'}
            </p>
            {canWithdraw && (
              <div>
                <label className="font-weight-bold" htmlFor="disable-duration">
                  Disable withdrawals for
                </label>
                <Select
                  id="disable-duration"
                  placeholder="Select duration"
                  options={[
                    { label: '30 days', value: 30 },
                    { label: '60 days', value: 60 },
                    { label: '90 days', value: 90 },
                  ]}
                  value={duration}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => setDuration(value)}
                  isSearchable={false}
                />
              </div>
            )}
            <div className="text-right mt-3">
              <Button
                onClick={toggleModalState}
                type="button"
                className="bg-gray-100 text-dark border-light">
                Cancel
              </Button>
              <Button
                color="primary"
                className="ml-3"
                type="submit"
                disabled={canWithdraw ? !duration.value : false}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={handleSubmit}>
                {canWithdraw ? 'Disable' : 'Enable'}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </CardLight>
  );
};

PaymentDisbursementAccountCard.propTypes = {
  id: PropTypes.string,
  tenantDisbursementAccount: PropTypes.shape({
    accountName: PropTypes.string,
    accountNumber: PropTypes.string,
    bankName: PropTypes.string,
    routingNumber: PropTypes.string,
  }),
  canWithdraw: PropTypes.bool,
  daysUntilWithdrawalEnabled: PropTypes.number,
  isLoading: PropTypes.bool,
};

PaymentDisbursementAccountCard.defaultProps = {
  tenantDisbursementAccount: null,
  canWithdraw: false,
};

const AccountDetail = ({ label, value }) => (
  <>
    <strong>{label}:&nbsp;</strong>
    <span>{value}</span>
  </>
);

AccountDetail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

AccountDetail.defaultProps = {
  value: '-',
};

export const Skeleton = ({ width = '160', ...props }) => (
  <ContentLoader
    viewBox={`0 0 ${width} 22`}
    height={22}
    width={width}
    {...props}>
    <rect x="0" y="0" rx="3" ry="3" width="15" height="12" />
    <rect x="20" y="0" rx="3" ry="3" width={width} height="12" />
  </ContentLoader>
);

Skeleton.propTypes = {
  width: PropTypes.string,
};
