import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useIsOpen } from '@app/hooks';
import { useRolesContext } from '@app/modules/Profile';
import {
  selectOverduePaymentsCtaApproach,
  selectPrimaryAgencyOverduePaymentsCount,
} from '@app/redux/users';

import { OVERDUE_PAYMENTS_CTA_APPROACH } from '../constants';

export const useOverdueModal = () => {
  const history = useHistory();
  const location = useLocation();
  const primaryAgencyOverduePaymentsCount = useSelector(
    selectPrimaryAgencyOverduePaymentsCount
  );

  const overduePaymentsCtaApproach = useSelector(
    selectOverduePaymentsCtaApproach
  );

  const { isPrincipal, isManager } = useRolesContext();

  const locationPathname = location.pathname;

  const hasManagerAccess = isPrincipal || isManager;

  const overdueModalSubmit = () => {
    history.push('/payments/billing/invoices?resolve-overdue=true');
  };

  const [isOverdueModalOpen, overdueModalActions] =
    useIsOpen(overdueModalSubmit);

  useEffect(() => {
    if (
      overduePaymentsCtaApproach === OVERDUE_PAYMENTS_CTA_APPROACH.disabled ||
      !overduePaymentsCtaApproach
    )
      return;

    const hasTriggered = sessionStorage.getItem('overdueModalTriggered');
    const count =
      overduePaymentsCtaApproach === OVERDUE_PAYMENTS_CTA_APPROACH.soft ? 3 : 1;

    if (
      hasManagerAccess &&
      !hasTriggered &&
      isOverdueModalOpen === false &&
      primaryAgencyOverduePaymentsCount >= count &&
      locationPathname !== '/payments/billing/invoices' &&
      locationPathname !== '/payments/settings'
    ) {
      setTimeout(() => {
        overdueModalActions.handleOpen();
        if (overduePaymentsCtaApproach === OVERDUE_PAYMENTS_CTA_APPROACH.soft) {
          sessionStorage.setItem('overdueModalTriggered', 'true');
        }
      }, 2000);
    }
  }, [
    hasManagerAccess,
    isOverdueModalOpen,
    locationPathname,
    overdueModalActions,
    overduePaymentsCtaApproach,
    primaryAgencyOverduePaymentsCount,
  ]);

  return [isOverdueModalOpen, overdueModalActions];
};
