import PropTypes from 'prop-types';
import React from 'react';
import { getCountryCallingCode } from 'react-phone-number-input/input';

import { CountrySelect } from './CountrySelect';

export const CountrySelectWithIcon = ({ value, options, labels, ...rest }) => (
  <div className="PhoneInputCountry">
    <CountrySelect
      {...rest}
      value={value}
      options={options}
      labels={labels}
      className={`PhoneInputCountrySelect + className`}
    />
    {value && (
      <>
        <div
          aria-hidden="true"
          className="PhoneInputCountryIcon PhoneInputCountryIcon--border">
          <img
            className="PhoneInputCountryIconImg"
            alt={value}
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`}
          />
        </div>
        <span className="px-2">+{getCountryCallingCode(value)}</span>
      </>
    )}
    <div className="PhoneInputCountrySelectArrow" />
  </div>
);

CountrySelectWithIcon.propTypes = {
  value: PropTypes.string,
  labels: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CountrySelectWithIcon.defaultProps = {
  value: 'AU',
};
