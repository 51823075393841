import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRolesContext } from '@app/modules/Profile';
import { UserAvatarModule } from '@app/modules/User';
import { createAvatar, deleteAvatar, getProfile } from '@app/redux/profile';

import { ProfileExternalCreditorForm } from './ExternalCreditorForm';
import { ProfileForm } from './Form';

export const MyProfile = () => {
  const dispatch = useDispatch();
  const profile = getProfile(useSelector((state) => state.profile));
  const { isExternalCreditor } = useRolesContext();

  const handleOnDelete = useCallback(
    (values) => {
      dispatch(deleteAvatar(values));
    },
    [dispatch]
  );

  const handleCreateAvatar = useCallback(
    (values) => {
      dispatch(createAvatar(values));
    },
    [dispatch]
  );

  return (
    <>
      {isExternalCreditor ? <ProfileExternalCreditorForm /> : <ProfileForm />}
      {profile.id && (
        <UserAvatarModule
          className="my-4"
          defaultImage={profile.avatarUrl}
          onDelete={handleOnDelete}
          onSubmit={handleCreateAvatar}
        />
      )}
    </>
  );
};
