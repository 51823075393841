import PropTypes from 'prop-types';
import React from 'react';

import PaymentIcon from './PaymentIcon';
import { PAYMENT_METHOD_TYPES } from './constants';

const Item = ({ type, name, accountNumber }) => (
  <span>
    <PaymentIcon type={type} />
    {name}
    <br className="d-sm-none" />
    {accountNumber && (
      <span className="ml-1 text-note masked-value">{accountNumber}</span>
    )}
  </span>
);

Item.propTypes = {
  type: PropTypes.oneOf(PAYMENT_METHOD_TYPES),
  name: PropTypes.string,
  accountNumber: PropTypes.string,
};

export default Item;
