import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import { Header } from '@app/modules/Header';
import { ProfileNavigation, useRolesContext } from '@app/modules/Profile';
import { selectCanViewPaymentSettings } from '@app/redux/profile';

import { AgencyProfile } from './AgencyProfile';
import { MyProfile } from './MyProfile';
import { Notifications } from './Notifications';
import { Plans } from './Plans';

export const Profile = () => {
  const canViewPaymentSettings = useSelector(selectCanViewPaymentSettings);
  const { isCorporateUser, isPrincipal, isOwner, isTenant } = useRolesContext();

  return (
    <>
      <Header
        title="Profile"
        subHeaderComponent={
          <ProfileNavigation
            canViewNotificationSettings={isOwner || isTenant}
            canViewPaymentSettings={canViewPaymentSettings}
            canViewAgencyProfile={isPrincipal && !isCorporateUser}
          />
        }
      />
      <Container className="mt-3">
        <Switch>
          <Route path="/profile/agency" component={AgencyProfile} />
          <Route path="/profile/notifications" component={Notifications} />
          <Route path="/profile/plans" component={Plans} />
          <Route path="/profile" component={MyProfile} />
        </Switch>
      </Container>
    </>
  );
};

Profile.propTypes = {};
