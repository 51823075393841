import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Badge, Button } from 'reactstrap';

import { useIsOpen } from '@app/hooks';
import { Dropdown } from '@app/modules/Dropdown';
import { FormFieldRadio } from '@app/modules/Form';
import { ModalConfirm } from '@app/modules/Modal';
import { downloadFile } from '@app/utils';

import Item from './Item';
import { PAYMENT_METHOD_TYPES } from './constants';

export const PaymentMethodItem = ({
  id,
  type,
  name,
  accountNumber,
  ddrFormUrl,
  isVirtualAccount,
  isActive,
  isInUse,
  isLoading,
  isMarkedForVerification,
  buttonText,
  onChangePaymentMethod,
  onDeletePaymentMethod,
  onSelectPaymentMethod,
  selectedPaymentMethod,
}) => {
  const [isOpen, actions] = useIsOpen(onDeletePaymentMethod);

  const handleDownloadDDr = useCallback(() => {
    downloadFile(ddrFormUrl, `${name} DDR Form`);
  }, [ddrFormUrl, name]);

  const actionItems = useMemo(() => {
    const items = [];

    if (!isActive && !isInUse) {
      items.push({
        title: 'Delete',
        onClick: actions.handleOpen,
      });
    }

    if (type === 'bank' && ddrFormUrl) {
      items.push({
        title: 'Download DDR Form',
        onClick: handleDownloadDDr,
      });
    }

    return items;
  }, [
    isActive,
    isInUse,
    type,
    ddrFormUrl,
    actions.handleOpen,
    handleDownloadDDr,
  ]);

  return (
    <div className="payment-method-item">
      <div className="d-flex align-items-center">
        {onSelectPaymentMethod && (
          <FormFieldRadio
            className="p-0"
            id={`bank-account-${name}`}
            name={`bank-account-${name}`}
            isChecked={selectedPaymentMethod?.id === id}
            disabled={isLoading}
            value={selectedPaymentMethod?.id}
            onChange={onSelectPaymentMethod}
          />
        )}

        <Item type={type} name={name} accountNumber={accountNumber} />
        {isMarkedForVerification && (
          <Badge className="ml-2" color="warning">
            Marked for Verification
          </Badge>
        )}
      </div>
      {buttonText !== '' && (
        <div className="account-actions">
          {isActive ? (
            <Badge pill className="mr-2" color={isLoading ? 'dark' : 'success'}>
              Active
            </Badge>
          ) : (
            <Button
              size="small"
              color="link"
              onClick={onChangePaymentMethod}
              disabled={isLoading || isMarkedForVerification}>
              {buttonText}
            </Button>
          )}

          <Dropdown
            header="Actions"
            isDisabled={
              !actionItems.length ||
              isLoading ||
              type === 'wallet' ||
              isVirtualAccount
            }
            modifiers={{
              offset: {
                offset: '-5, -8',
              },
              flip: {
                enabled: false,
              },
            }}
            items={actionItems}
          />
        </div>
      )}

      <ModalConfirm
        title="Delete Payment Method"
        body="Are you sure you want to delete this payment method?"
        isOpen={isOpen}
        onCancel={actions.handleClose}
        onSubmit={actions.handleSubmit}
      />
    </div>
  );
};

PaymentMethodItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(PAYMENT_METHOD_TYPES),
  name: PropTypes.string,
  accountNumber: PropTypes.string,
  buttonText: PropTypes.string,
  ddrFormUrl: PropTypes.string,
  isActive: PropTypes.bool,
  isInUse: PropTypes.bool,
  isVirtualAccount: PropTypes.bool,
  isMarkedForVerification: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedPaymentMethod: PropTypes.object,
  onChangePaymentMethod: PropTypes.func,
  onDeletePaymentMethod: PropTypes.func,
  onSelectPaymentMethod: PropTypes.func,
};

PaymentMethodItem.defaultProps = {
  isVirtualAccount: false,
  isActive: false,
  isInUse: false,
  isLoading: false,
  buttonText: 'Use as payment method',
};

export default PaymentMethodItem;
