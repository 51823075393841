import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { CardText } from 'reactstrap';

import bpayImg from '@app/assets/bpay.svg';
import PaymentIcon from '@app/containers/Payments/Walletv2/PaymentMethodItem/PaymentIcon';
import { ButtonCopyToClipboard } from '@app/modules/Button';

import './styles.scss';

export const AccountDetails = ({
  accountName,
  code,
  refNumber,
  isBpay,
  isLoading,
}) => {
  const labels = isBpay
    ? {
        code: 'Biller Code',
        refNumber: 'Ref Number',
      }
    : {
        code: 'BSB',
        refNumber: 'Account Number',
      };

  return (
    <div className="account-details-container py-4 px-5 mb-4 text-center">
      {!isBpay && (
        <CardText tag="h4" className="mb-4 text-center">
          <PaymentIcon type="wallet" />
          Fast Bank Transfer (OSKO)
        </CardText>
      )}

      <div className="account-value mb-2">
        {isBpay && <img src={bpayImg} width="27" height="40" alt="BPAY icon" />}

        <div className="account-value-grid" data-testid="bpay-value">
          <strong>Bank Name:</strong>
          <span>Cuscal</span>

          <strong>Account Name:</strong>
          <div className="d-flex">
            <span>{accountName}</span>
            {accountName && (
              <ButtonCopyToClipboard
                text={accountName}
                id="copy-account-name-button"
              />
            )}
          </div>

          <strong>{labels.code}:</strong>
          <div className="d-flex">
            <span>
              {isLoading ? `Generating ${labels.code.toLowerCase()}...` : code}
            </span>
            {code && (
              <ButtonCopyToClipboard text={code} id="copy-code-button" />
            )}
          </div>

          <strong>{labels.refNumber}:</strong>
          <div className="d-flex">
            <span>
              {isLoading
                ? `Generating ${labels.refNumber.toLowerCase()}...`
                : refNumber}
            </span>
            {refNumber && (
              <ButtonCopyToClipboard
                text={refNumber}
                id="copy-ref-number-button"
              />
            )}
          </div>
        </div>
      </div>

      <div>
        <span className="text-note">Processing time:</span>

        {isBpay ? (
          <>
            <FontAwesomeIcon
              className="mx-2"
              icon={['fas', 'turtle']}
              color="#979797"
              size="lg"
            />
            <span className="text-note">1-3 business days</span>
          </>
        ) : (
          <>
            <FontAwesomeIcon
              className="mx-2"
              icon={['fas', 'bolt']}
              color="#E4CE0B"
              size="lg"
            />

            <span className="text-note">Instant</span>
          </>
        )}
      </div>

      <div></div>
    </div>
  );
};

AccountDetails.propTypes = {
  accountName: PropTypes.string,
  code: PropTypes.string,
  refNumber: PropTypes.string,
  isBpay: PropTypes.bool,
  isLoading: PropTypes.bool,
};

AccountDetails.defaultProps = {
  accountName: '',
  code: '',
  refNumber: '',
  isBpay: false,
  isLoading: false,
};

export default AccountDetails;
