import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';

import { Loading } from '@app/containers';
import { downloadFile } from '@app/utils';

export const DocumentInformation = ({ label, links, leaseId, tenantName }) => {
  const [isReportDownloading, setIsReportDownloading] = useState(false);

  const handleDownloadTenantREport = useCallback(
    (type) => () => {
      setIsReportDownloading(true);
      downloadFile(
        `/api/leases/${leaseId}/current-tenant-transactions.${type}`,
        `${tenantName}-tenant_ledger.${type}`,
        setIsReportDownloading
      );
    },
    [leaseId, tenantName]
  );

  return (
    <Row className="text-muted small text-capitalize pt-2">
      <Loading isOpen={isReportDownloading} isLoading={isReportDownloading} />
      <Col xs={8}>
        <span>{label}</span>
      </Col>
      <Col xs={4}>
        {links.map(({ label, type }, i) => (
          <Row key={`link-type-${i}`}>
            <Col>
              <Button
                className="p-0"
                color="link"
                style={{ fontSize: '100%' }}
                onClick={handleDownloadTenantREport(type)}>
                {label}
              </Button>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  );
};

DocumentInformation.propTypes = {
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  leaseId: PropTypes.number.isRequired,
  tenantName: PropTypes.string.isRequired,
};

DocumentInformation.defaultProps = {
  links: [],
};
