import { useMutation } from '@tanstack/react-query';

import { httpClient } from '@app/utils';

export const QUERY_KEYS = {
  CREATE_SIGNATURE: 'createSignature',
};

const createSignature = async (signature) => {
  const body = {
    signature,
  };

  try {
    const response = await httpClient.post('user/create-signature', body);
    return response.data;
  } catch (error) {
    console.log('error', error);
    throw new Error('Something went wrong');
  }
};

export const useCreateSignature = () => {
  return useMutation(createSignature, {
    mutationKey: QUERY_KEYS.CREATE_SIGNATURE,
    onError: (error) => error,
  });
};

// TODO: Create Update signature
export default useCreateSignature;
