/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';

import { ACCEPTED_DATE_FORMATS } from './constants';
import './styles.scss';

export const DateRange = ({
  fromDateName,
  fromDateValue,
  fromDateLabel,
  toDateName,
  toDateValue,
  toDateLabel,
  onChange,
}) => {
  const handleChange = useCallback(
    (value, name) => {
      onChange((state) => ({ ...state, [name]: value }));
    },
    [onChange]
  );

  return (
    <div className="d-flex date-pickers">
      <DatePicker
        placeholderText={fromDateLabel}
        dateFormat={ACCEPTED_DATE_FORMATS}
        name={fromDateName}
        className="form-control"
        onChange={(value) => handleChange(value, fromDateName)}
        selected={fromDateValue}
        maxDate={toDateValue}
        startDate={fromDateValue}
        endDate={toDateValue}
        selectsStart
        isClearable
      />
      <DatePicker
        placeholderText={toDateLabel}
        dateFormat={ACCEPTED_DATE_FORMATS}
        name={toDateName}
        className="form-control"
        onChange={(value) => handleChange(value, toDateName)}
        selected={toDateValue}
        minDate={fromDateValue}
        startDate={fromDateValue}
        endDate={toDateValue}
        selectsEnd
        isClearable
      />
    </div>
  );
};

DateRange.propTypes = {
  fromDateName: PropTypes.string.isRequired,
  fromDateValue: PropTypes.instanceOf(Date),
  fromDateLabel: PropTypes.string,
  toDateName: PropTypes.string.isRequired,
  toDateValue: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  toDateLabel: PropTypes.string,
};

DateRange.defaultProps = {
  fromDateValue: null,
  toDateValue: null,
  fromDateLabel: 'From Date',
  toDateLabel: 'To Date',
};
