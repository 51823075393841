import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import { useIsMobile } from '@app/hooks';
import { CardLight } from '@app/modules/Card';
import { aggregateByYear, financialYearFromMonth } from '@app/utils';
import { parseDateIgnoreTz } from '@app/utils/date';

export const TabbedAttachments = ({ title, attachments }) => {
  const isMobile = useIsMobile();

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const currentFinancialYear = financialYearFromMonth(
    currentMonth,
    currentYear
  );

  const mappedAttachments = useMemo(
    () =>
      attachments.length
        ? aggregateByYear(attachments)
        : {
            // If there are no attachments yet, show the current financial year with empty data
            [currentFinancialYear]: [],
          },
    [currentFinancialYear, attachments]
  );

  // Set latest financial year with attachments as active tab
  const [activeTab, setActiveTab] = useState(
    Object.keys(mappedAttachments).find(
      (year) => year === currentFinancialYear.toString()
    )
      ? currentFinancialYear.toString()
      : (currentFinancialYear - 1).toString()
  );

  const handleClickTab = useCallback(
    (year) => () => {
      setActiveTab(year);
    },
    []
  );

  const formatAttachmentPeriod = (startDate, endDate) => {
    const startYearShort = format(startDate, 'yyyy');
    const endYearShort = format(endDate, 'yyyy');
    const startDateNoYear = format(startDate, 'dd/MM');
    const endDateNoYear = format(endDate, 'dd/MM');

    return `${startDateNoYear}/${startYearShort} - ${endDateNoYear}/${endYearShort}`;
  };

  return (
    <Card className="my-2">
      <CardHeader className="bg-white border-400">
        <CardTitle tag="h5">{title}</CardTitle>
      </CardHeader>
      <CardBody>
        <Nav tabs>
          {Object.keys(mappedAttachments)
            .sort((a, b) => b - a)
            .map((year) => (
              <NavItem className="p-0" key={year}>
                <NavLink
                  className={`px-4 ${activeTab === year ? 'active' : ''}`}
                  onClick={handleClickTab(year)}>
                  {`FY ${(year % 2000) - 1}/${year % 2000}`}
                </NavLink>
              </NavItem>
            ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          {Object.entries(mappedAttachments).map(([year, attachments]) => {
            return (
              <TabPane
                className="border border-top-0 rounded-bottom"
                key={year}
                tabId={year}>
                <Row>
                  <Col sm="12">
                    <CardLight
                      className="w-100 p-4"
                      // TODO: Remove inline styles
                      style={{
                        ...(attachments.length
                          ? {
                              display: 'grid',
                              gridTemplateColumns: `repeat(${
                                isMobile ? 2 : 4
                              }, 1fr)`,
                              gridTemplateRows: `repeat(${
                                isMobile ? 6 : 3
                              }, 1fr)`,
                              gridAutoFlow: 'column',
                              rowGap: '1rem',
                            }
                          : {}),
                        borderRadius: '0 0.75rem 0.75rem',
                      }}>
                      {attachments.length
                        ? attachments.map((attachment) => {
                            const startDate = new Date(
                              parseDateIgnoreTz(attachment.periodStartsAt)
                            );
                            const endDate = new Date(
                              parseDateIgnoreTz(attachment.periodEndsAt)
                            );
                            const title = formatAttachmentPeriod(
                              startDate,
                              endDate
                            );
                            return (
                              <div key={title}>
                                <strong>{title}</strong>
                                <div>
                                  <a
                                    href={attachment.urls.original}
                                    className="btn-link py-1 text-truncate"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {attachment.contentType ===
                                    'application/pdf'
                                      ? 'PDF'
                                      : 'CSV'}
                                  </a>
                                </div>
                              </div>
                            );
                          })
                        : 'No available data'}
                    </CardLight>
                  </Col>
                </Row>
              </TabPane>
            );
          })}
        </TabContent>
      </CardBody>
    </Card>
  );
};

TabbedAttachments.propTypes = {
  title: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
};
