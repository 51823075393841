import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { centsToDollar } from '@app/utils';

import './styles.scss';

export const PurchaseConfirmation = ({
  modalState = {
    isOpen: false,
  },
  toggle,
  onConfirm,
  isLoading,
}) => {
  return (
    <Modal
      centered
      className="purchase-confirmation-modal"
      isOpen={modalState.isOpen}
      toggle={toggle}>
      <ModalHeader toggle={toggle}>Top-up your SMS Credit</ModalHeader>
      <ModalBody className="m-3">
        <div className="d-flex justify-content-center align-items-center mb-3">
          <p className="pr-3 m-0">
            <span className="m-0">
              <strong>{modalState.credit} SMS&nbsp;</strong>
            </span>
            <span className="text-small">Approx $0.11 each</span>
          </p>
          <span className="pl-3 text-primary">
            <strong>{centsToDollar(modalState.amount)}</strong>
          </span>
        </div>
        <ul>
          <li>SMS credit can be used for domestic mobile numbers</li>
          <li>International numbers are not yet supported</li>
          <li>All pricing is in $AUD (GST inclusive)</li>
          <li>
            SMS Credit has no expiration date.
            <br />
            Learn more about SMS&nbsp;
            <a
              href="https://support.managedapp.com.au/en/"
              rel="noopener noreferrer"
              target="_blank">
              here
            </a>
          </li>
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          color="primary-alt"
          onClick={toggle}
          disabled={isLoading}>
          Close
        </Button>
        <Button color="primary-alt" onClick={onConfirm} disabled={isLoading}>
          {isLoading ? (
            <FontAwesomeIcon
              className="text-white mr-1"
              icon={['far', 'spinner']}
              spin
            />
          ) : (
            'Confirm'
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PurchaseConfirmation.propTypes = {
  modalState: PropTypes.object,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
