import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { httpClient } from '@app/utils';

export const EXTERNAL_CREDITORS_QUERY_KEYS = {
  FETCH_EXTERNAL_CREDITORS: 'fetchExternalCreditors',
  CREATE_AGENCY_EXTERNAL_CREDITOR: 'createAgencyExternalCreditor',
  TOGGLE_PREFERRED_EXTERNAL_CREDITOR: 'togglePreferredExternalCreditor',
};

const DEFAULT_FETCH_PARAMS = {
  classification: 'tradie',
  page: 1,
  perPage: 10,
};

const fetchExternalCreditors = async (params) => {
  try {
    const { search, agencyId, ...paramValues } = params;

    const queryParams = {
      ...DEFAULT_FETCH_PARAMS,
      ...paramValues,
      ...(search ? { 'q[g][0][any_name_email_phone_cont]': search } : {}),
      ...(agencyId
        ? { 'q[g][0][agency_external_creditors_agency_id_in]': agencyId }
        : {}),
    };

    const response = await httpClient.get('external-creditors', {
      params: queryParams,
    });

    return response?.data || [];
  } catch (error) {
    console.error('error: ', error);
    throw new Error('Something went wrong');
  }
};

export const useExternalCreditors = ({ params }) => {
  const queryKey = params.agencyId
    ? [
        EXTERNAL_CREDITORS_QUERY_KEYS.FETCH_EXTERNAL_CREDITORS,
        params.agencyId,
        params.page || 1,
      ]
    : [null];

  return useQuery(queryKey, () => fetchExternalCreditors(params), {
    enabled: !!params.agencyId,
  });
};

const togglePreferredExternalCreditor = async (payload) => {
  try {
    const response = await httpClient.patch(
      'agency-external-creditors/toggle-preferred',
      payload
    );

    return response?.data?.externalCreditor;
  } catch (error) {
    console.log('error', error);
    throw new Error('Something went wrong');
  }
};

export const useTogglePreferredExternalCreditor = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(togglePreferredExternalCreditor, {
    mutationKey:
      EXTERNAL_CREDITORS_QUERY_KEYS.TOGGLE_PREFERRED_EXTERNAL_CREDITOR,
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        EXTERNAL_CREDITORS_QUERY_KEYS.FETCH_EXTERNAL_CREDITORS
      );
      dispatch({
        type: 'users/updatePreferredAgencies',
        payload: {
          data,
        },
      });
      return data;
    },
  });
};
