import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { ButtonCancel, ButtonSubmit } from '@app/modules/Button';

export const TopUpSmsAccountModal = ({
  modalState,
  toggleModal,
  isLoading,
  onSubmit,
  creditBalance,
  creditsNeeded,
  btnCancelText,
  btnSubmitText,
  title,
  body,
  note,
}) => {
  return (
    <Modal isOpen={modalState.isOpen} toggle={toggleModal} size="md" centered>
      <ModalHeader>
        <FontAwesomeIcon
          icon={['far', 'exclamation-triangle']}
          className="mr-2"
        />
        {title}
      </ModalHeader>
      <ModalBody>
        {body}
        <dl className="my-3">
          <div className="d-flex justify-content-between">
            <dt className="font-weight-light">Current credits:</dt>
            <dd className="font-weight-bold">{creditBalance} Credits</dd>
          </div>
          <div className="d-flex justify-content-between">
            <dt className="font-weight-light">Credits needed:</dt>
            <dd className="font-weight-bold text-danger-alt">
              {creditsNeeded} Credits
            </dd>
          </div>
        </dl>
        {note && (
          <span className="text-muted text-small">
            {note}
            <br />
          </span>
        )}
        <div className="text-right mt-3">
          <ButtonCancel
            className="mr-2"
            color="primary-alt"
            onClick={toggleModal}>
            {btnCancelText}
          </ButtonCancel>
          <ButtonSubmit
            color="primary-alt"
            onClick={onSubmit}
            disabled={isLoading}>
            {isLoading ? (
              <FontAwesomeIcon
                className="text-white mr-1"
                icon={['far', 'spinner']}
                spin
              />
            ) : (
              btnSubmitText
            )}
          </ButtonSubmit>
        </div>
      </ModalBody>
    </Modal>
  );
};

TopUpSmsAccountModal.propTypes = {
  modalState: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  creditBalance: PropTypes.number.isRequired,
  creditsNeeded: PropTypes.number.isRequired,
  btnCancelText: PropTypes.string,
  btnSubmitText: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  note: PropTypes.string,
};

TopUpSmsAccountModal.defaultProps = {
  isLoading: false,
  btnCancelText: 'Cancel',
  btnSubmitText: 'Top up Credits',
  title: 'Insufficient SMS credits',
  body: 'Your SMS credits are insufficient to send this message. Please top up your account to proceed.',
};
