import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { FormGroup, Label } from 'reactstrap';

import {
  DateField,
  RadioField,
  SelectField,
} from '@app/modules/Form/FormikFields';

import { MONTHS_OPTIONS } from '../constants';
import { ModalContentContainer } from './ModalContentContainer';

const frequencyOptions = MONTHS_OPTIONS.map((option) => {
  if (option.value === 1) {
    return {
      ...option,
      label: 'every month',
    };
  }

  return {
    ...option,
    label: `every ${option.label}`,
  };
});

const RentActivities = () => {
  const {
    values: { hasRoutineInspection, hasRentReview },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (hasRoutineInspection === 'false') {
      setFieldValue('inspectionDate', '');
      setFieldValue('inspectionDateFrequencyInMonths', 0);
    }
  }, [hasRoutineInspection, setFieldValue]);

  useEffect(() => {
    if (hasRentReview === 'false') {
      setFieldValue('rentReviewDate', '');
      setFieldValue('rentReviewDateFrequencyInMonths', 0);
    }
  }, [hasRentReview, setFieldValue]);

  return (
    <>
      <h3>Lets set up some activities</h3>
      <ModalContentContainer>
        <FormGroup>
          <Label for="hasRoutineInspection">
            Do you want to setup some routine inspection activities?
          </Label>
          <div role="group" className="radio-group-wrapper">
            <RadioField name="hasRoutineInspection" label="Yes" value="true" />
            <RadioField name="hasRoutineInspection" label="No" value="false" />
          </div>
        </FormGroup>
        {hasRoutineInspection === 'true' && (
          <>
            <FormGroup>
              <Label for="inspectionDate">
                When will the first inspection occur
              </Label>
              <DateField name="inspectionDate" />
            </FormGroup>
            <FormGroup>
              <Label for="inspectionDateFrequencyInMonths">
                How often should it repeat
              </Label>
              <SelectField
                name="inspectionDateFrequencyInMonths"
                options={frequencyOptions}
              />
            </FormGroup>
          </>
        )}
        <FormGroup>
          <Label for="hasRentReview">Do you want to setup a rent review?</Label>
          <div role="group" className="radio-group-wrapper">
            <RadioField name="hasRentReview" label="Yes" value="true" />
            <RadioField name="hasRentReview" label="No" value="false" />
          </div>
        </FormGroup>
        {hasRentReview === 'true' && (
          <>
            <FormGroup>
              <Label for="reviewDate">
                When will the first rent review occur
              </Label>
              <DateField name="reviewDate" />
            </FormGroup>
            <FormGroup>
              <Label for="reviewDateFrequencyInMonths">
                How often should it repeat
              </Label>
              <SelectField
                name="reviewDateFrequencyInMonths"
                options={frequencyOptions}
              />
            </FormGroup>
          </>
        )}
      </ModalContentContainer>
    </>
  );
};

export default RentActivities;
