import PropTypes from 'prop-types';
import React from 'react';

import './style.scss';

export const Divider = ({ title }) => (
  <div className="d-flex flex-align-middle align-items-center divider text-muted my-3">
    {title.toUpperCase()}
  </div>
);

Divider.propTypes = {
  title: PropTypes.string,
};
Divider.defaultProps = {
  title: '',
};
