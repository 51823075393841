/* eslint-disable no-case-declarations */
import React, { createContext, useReducer } from 'react';

import {
  DECREMENT_CURRENT_STEP,
  INCREMENT_CURRENT_STEP,
  INITIAL_STATE,
  RESET,
  SET_CURRENT_STEP,
  SET_LEASE_TO_EDIT,
  SET_LEASE_TO_RENEW,
  SET_STEPS,
} from './constants';

const reducer = (state = INITIAL_STATE, action) => {
  const { currentStep, steps } = state;
  switch (action.type) {
    case SET_STEPS:
      return {
        ...state,
        steps: action.payload.steps,
        canContinue: state.currentStep < action.payload.steps.length - 1,
      };
    case INCREMENT_CURRENT_STEP:
      const incrementValue =
        currentStep < steps.length - 1 ? currentStep + 1 : currentStep;
      return {
        ...state,
        currentStep: incrementValue,
        canContinue: incrementValue < steps.length - 1,
        changed: true,
      };
    case DECREMENT_CURRENT_STEP:
      const decrementValue = currentStep > 0 ? currentStep - 1 : currentStep;
      return {
        ...state,
        currentStep: decrementValue,
        canContinue: decrementValue < steps.length - 1,
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload.currentStep,
        canContinue: action.payload.currentStep < steps.length - 1,
      };
    case SET_LEASE_TO_EDIT:
      return {
        ...state,
        leaseIdToEdit: action.payload.id,
      };
    case SET_LEASE_TO_RENEW:
      return {
        ...state,
        leaseIdToRenew: action.payload.id,
      };
    case RESET:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const StepperStateContext = createContext(INITIAL_STATE);
export const StepperDispatchContext = createContext(undefined);

// eslint-disable-next-line react/prop-types
export const StepperProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <StepperStateContext.Provider value={state}>
      <StepperDispatchContext.Provider value={dispatch}>
        {children}
      </StepperDispatchContext.Provider>
    </StepperStateContext.Provider>
  );
};
