import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { PulseLoader } from 'react-spinners';

import { BrandLogo } from '../../modules/Brand';
import { CardCentered } from '../../modules/Card';
import { Link } from '../../modules/Link';
import { useVerifyBankAccount } from '../../modules/Payment/hooks/use-assembly';
import { Alert } from '../Alert';

export const VerifyPromisepayBankAccount = (props) => {
  const { bankAccountId, verificationToken } = props.match.params;
  const { mutate, isLoading, isSuccess } = useVerifyBankAccount();

  useEffect(() => {
    if (bankAccountId && verificationToken) {
      mutate({
        id: bankAccountId,
        verificationToken,
      });
    }
  }, [bankAccountId, mutate, verificationToken]);

  return (
    <CardCentered>
      <BrandLogo className="mb-3">Managed</BrandLogo>
      <Alert containerClassName="p-0" />
      {isLoading ? (
        <div>
          <p>We are verifying your bank account.</p>
          <PulseLoader color="#dee2e6" />
        </div>
      ) : isSuccess ? (
        <>
          <p>
            Your bank account has been verified and will be unlocked in 24
            hours.
          </p>
          <Link to="/" className="mb-3">
            Continue to the application
          </Link>
        </>
      ) : (
        <>
          <p>There was an error verifying your bank account.</p>
          <Link to="/" className="mb-3">
            Continue to the application
          </Link>
        </>
      )}
    </CardCentered>
  );
};

VerifyPromisepayBankAccount.propTypes = {
  bankAccountId: PropTypes.string,
  verificationToken: PropTypes.string,
  match: PropTypes.object,
};
