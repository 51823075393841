/* eslint-disable react/jsx-no-bind */
import { debounce, startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Select from 'react-select';

import { SearchIndicator } from '../Common';
import { FilterContext } from './Filter';

export const TypeaheadSelect = ({
  clearKeysOnChange,
  disabled,
  isRequired,
  label,
  name,
  onKeyDown,
  onSelect,
  values,
  isSearchable,
  isShowSearchIcon,
  isLoading,
  isClearable,
  onClear,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const { params, actions } = useContext(FilterContext);

  const optionValues = useMemo(() => {
    let options;
    if (label === 'Type') {
      // Assuming that the first Default task TYPE is always Advertising, we split the default tasks and custom tasks to display grouped options.
      // This logic needs to be reviewed if advertising is no more the first default task
      const advertisingIndex =
        (values.length &&
          values.findIndex((value) => value === 'advertising')) ||
        0;
      const customValues = values.length
        ? values.slice(0, advertisingIndex)
        : [];
      const defaultValues = values.length
        ? values.slice(advertisingIndex, values.length)
        : [];

      const defaultOptions = defaultValues.length
        ? defaultValues.map((entry) => ({
            label: entry.label || startCase(entry),
            value: entry.value !== undefined ? entry.value : entry,
          }))
        : [];

      const customOptions = customValues.length
        ? customValues.map((entry) => ({
            label: entry.label || startCase(entry),
            value: entry.value !== undefined ? entry.value : entry,
          }))
        : [];

      options = [
        ...(customValues
          ? [{ label: 'Custom Tasks', options: customOptions }]
          : []),
        { label: 'Default Tasks', options: defaultOptions },
      ];
    } else {
      options = values.map((entry) => ({
        label: entry.label || startCase(entry),
        value: entry.value !== undefined ? entry.value : entry,
      }));
    }

    // If filter has an option selected, add option to clear
    return !isRequired && params[name] && !isShowSearchIcon
      ? [
          {
            label: '-- Clear Filter --',
            value: '',
          },
          ...options,
        ]
      : options;
  }, [isRequired, isShowSearchIcon, label, name, params, values]);

  useEffect(() => {
    // Check if options are grouped (example: Task Type)
    const options = optionValues.find((option) => option.options?.length)
      ? optionValues.reduce((acc, option) => {
          if (option.options?.length) return acc.concat(option.options);
          else return acc;
        }, [])
      : optionValues;

    const value = params[name]
      ? options.find((item) => String(item.value) === String(params[name]))
      : null;

    setSearchValue(value);
  }, [params, name, optionValues]);

  const handleChange = useCallback(
    (props, { action, name }) => {
      // Clear filter
      if (action === 'clear') {
        onClear && onClear(name);
        actions.onChange({ [name]: undefined });
        return;
      }

      const { value } = props;
      let values = { [name]: value };

      if (clearKeysOnChange) {
        values = clearKeysOnChange.reduce(
          (acc, name) => ({ ...acc, [name]: undefined }),
          values
        );
      }

      actions.onChange(values);

      if (onSelect) {
        onSelect(values, actions.onChange);
      }
    },
    [actions, clearKeysOnChange, onClear, onSelect]
  );

  const debouncedSearch = useCallback(debounce(onKeyDown, 1000), [onKeyDown]);

  const handleKeyDown = useCallback(
    (event) => {
      event.stopPropagation();

      if (event.target && event.target.value.length > 1 && onKeyDown) {
        debouncedSearch(event.target.value);
      }
    },
    [debouncedSearch, onKeyDown]
  );

  return (
    <Select
      name={name}
      onChange={handleChange}
      onKeyDown={onKeyDown && handleKeyDown}
      options={optionValues}
      placeholder={label}
      value={searchValue}
      isDisabled={disabled}
      isSearchable={isSearchable}
      onFocus={(event) => {
        if (isShowSearchIcon) {
          setSearchValue('');
        }
        event.target.select();
      }}
      isLoading={isLoading}
      isClearable={isClearable}
      {...(isShowSearchIcon && { components: { Control: SearchIndicator } })}
      blurInputOnSelect
    />
  );
};

TypeaheadSelect.propTypes = {
  clearKeysOnChange: PropTypes.array,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func,
  onSelect: PropTypes.func,
  onClear: PropTypes.func,
  values: PropTypes.array.isRequired,
  value: PropTypes.string,
  isSearchable: PropTypes.bool,
  isShowSearchIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
};

TypeaheadSelect.defaultProps = {
  onKeyDown: () => {},
  isSearchable: true,
  isShowSearchIcon: false,
  isLoading: false,
  isClearable: false,
};
