import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useRolesContext } from '@app/modules/Profile';
import { selectOverduePaymentsCtaApproach } from '@app/redux/users';

import { OVERDUE_PAYMENTS_CTA_APPROACH } from '../constants';

export const OverdueModal = ({ isOpen = false, onSubmit, onCancel }) => {
  const { isManager, isPrincipal } = useRolesContext();
  const overduePaymentsCtaApproach = useSelector(
    selectOverduePaymentsCtaApproach
  );

  const hasPrincipalRoleAccess = isManager && isPrincipal;

  if (
    !overduePaymentsCtaApproach ||
    overduePaymentsCtaApproach === OVERDUE_PAYMENTS_CTA_APPROACH.disabled ||
    overduePaymentsCtaApproach === 'disabled'
  )
    return null;

  const renderMessage = () => {
    switch (overduePaymentsCtaApproach) {
      case OVERDUE_PAYMENTS_CTA_APPROACH.soft:
        return (
          <p>
            Please note, you have{' '}
            <strong>more than 3 months in arrears </strong>
            and we recommend you get in touch with our customer service team at
            subscriptions@managedapp.com.au. Our team is more than happy to
            assist you with a payment plan to bring your account up to date. As
            long as you have a plan in place, your account will remain active
            and there will be no disruption to your service.
          </p>
        );

      case OVERDUE_PAYMENTS_CTA_APPROACH.hard:
        return (
          <p>
            Please note, your <strong>monthly payment has failed.</strong> We
            know that payments fail every now and then and this is due to
            subscriptions processing at the same time.{' '}
            {hasPrincipalRoleAccess
              ? `Let’s get this resolved to avoid any disruption to your service.`
              : 'Your agency principal or accounts team will need to get this resolved to avoid any disruption to your service.'}
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      centered
      size="md"
      className="resolve-overdue-container mobile-fullscreen">
      <ModalHeader>Friendly reminder - Accounts overdue</ModalHeader>
      <ModalBody>
        <p>
          We have recently released our new billings page to help customers keep
          on top of their monthly subscription.
        </p>
        {renderMessage()}
      </ModalBody>
      <ModalFooter>
        {hasPrincipalRoleAccess ? (
          <Button color="primary" onClick={onSubmit}>
            Go to Billings
          </Button>
        ) : (
          <Button color="primary" onClick={onCancel}>
            Okay
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

OverdueModal.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};
