export const FETCH = '@@app/platform_charge/FETCH';
export const FETCH_SUCCESS = '@@app/platform_charge/FETCH_SUCCESS';
export const ERROR = '@@app/platform_charge/ERROR';
export const UPDATE_STATUS = '@@app/platform_charge/UPDATE_STATUS';

export const STATUSES = Object.freeze({
  CALCULATED: 'calculated',
  INVOICED: 'invoiced',
  INTENTION_TRIGGERED: 'intention_triggered',
  COMPLETED: 'completed',
  FAILED: 'failed',
  BAD_DEBT: 'bad_debt',
});
