import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import { CustomInput, InputGroupAddon } from 'reactstrap';

import { httpClient } from '../../utils';

export const PropertyLeaseOutgoingsModalHeader = ({
  title,
  subTitle,
  isGstIncluded,
  leaseId,
  outgoingsEstimateId,
  canEditGst,
  chargeFromDate,
  setChargeFromDate,
}) => {
  const [isGstIncludedOutgoings, setIsGstIncludedOutgoings] = useState(
    isGstIncluded ?? false
  );

  const handleChangeGst = useCallback(
    (e) => {
      // Call api to update gst
      let checkedValue = e.target.checked;
      setIsGstIncludedOutgoings(checkedValue);
      httpClient
        .put(
          `/leases/${leaseId}/commercial/outgoings_estimates/${outgoingsEstimateId}`,
          {
            gstIncluded: checkedValue,
            chargeFromDate: format(chargeFromDate, 'dd-MM-yyyy'),
          }
        )
        .then((response) => {
          return null;
        })
        .catch((error) => {
          setIsGstIncludedOutgoings(!checkedValue);
          console.log('Internal Server error. Please contact Support', error);
        });
    },
    [leaseId, outgoingsEstimateId, chargeFromDate]
  );

  const handleDateChange = useCallback(
    (value) => {
      setChargeFromDate(value);

      httpClient.put(
        `/leases/${leaseId}/commercial/outgoings_estimates/${outgoingsEstimateId}`,
        {
          gstIncluded: isGstIncludedOutgoings,
          chargeFromDate: format(value, 'dd-MM-yyyy'),
        }
      );
    },
    [setChargeFromDate, leaseId, outgoingsEstimateId, isGstIncludedOutgoings]
  );

  return (
    <div className="w-100">
      <div>{title}</div>
      <div className="d-inline-flex w-100 align-items-center justify-content-between">
        <div className="h6 text-muted mt-2">{subTitle}</div>
        <div className="d-flex">
          <label className="ml-1 text-small my-auto">
            Charge Outgoings From
            <span className="text-danger ml-1">*</span>
            :&nbsp;&nbsp;
          </label>
          <InputGroupAddon addonType="prepend">
            <span className="input-group-text">
              <FontAwesomeIcon icon={['far', 'calendar-alt']} />
            </span>
          </InputGroupAddon>
          <DatePicker
            autoComplete="off"
            className="form-control"
            dateFormat="dd-MM-yyyy"
            disabledKeyboardNavigation
            name="fromChargeDate"
            onChange={handleDateChange}
            placeholderText="dd-mm-yyyy"
            value={chargeFromDate}
            selected={chargeFromDate}
            disabled={!!chargeFromDate}
          />
        </div>
        {canEditGst && (
          <CustomInput
            className="ml-1 text-small"
            checked={isGstIncludedOutgoings}
            id="gstIncluded"
            label="GST Included"
            name="gstIncluded"
            type="checkbox"
            onChange={handleChangeGst}
          />
        )}
      </div>
    </div>
  );
};

PropertyLeaseOutgoingsModalHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isGstIncluded: PropTypes.bool,
  leaseId: PropTypes.number.isRequired,
  outgoingsEstimateId: PropTypes.number.isRequired,
  canEditGst: PropTypes.bool,
  chargeFromDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  setChargeFromDate: PropTypes.func,
};

PropertyLeaseOutgoingsModalHeader.defaultProps = {
  isGstIncluded: false,
  title: 'Outgoings Estimate Bill',
  subTitle: 'This will automatically generate in monthly instalments.',
  canEditGst: false,
  chargeFromDate: '',
  setChargeFromDate: () => {},
};
